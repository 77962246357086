import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { FavoritesApi } from '../../api/Favorites'
import { FavorieUserItem } from '../../api/Favorites/types'
import { ApiResponse } from '../../api/types'
import { Routes } from '../../navigation/Routes'
import { isOfflineError } from '../../services/errorManager'
import { navigate } from '../../services/navigation'
import { showDanger, showWarning } from '../../services/toast'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { RootState } from '../reducers'
import { FavoritesUserActionObjectTypes, FavoritesUsersActions } from './actions'
import { favoritesUserSelectorNew } from './selector'
import { FavoritesUserActionTypes } from './types'

export function* loadFavoritesUserSaga() {
  try {
    const favoritesUser: { content: FavorieUserItem[] } = yield call(FavoritesApi.loadFavoritesUser)
    yield put(FavoritesUsersActions.storeFavoritesUser(favoritesUser.content))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* pushFavoriteUserSaga() {
  const favoritesUser: FavorieUserItem[] = yield select((state: RootState) => favoritesUserSelectorNew(state))
  const favoritesUserIds = favoritesUser.map(item => item.id)
  try {
    const apiResponse: ApiResponse = yield call(FavoritesApi.storeFavoritesUserId, favoritesUserIds)
    if (!apiResponse.success) {
      showDanger(apiResponse.message)
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* addFavoritesUserSaga(action: ActionsOfType<FavoritesUserActionObjectTypes, FavoritesUserActionTypes.ADD_FAVORITES_USER>) {
  try {
    const favoritesUser: FavorieUserItem = yield call(FavoritesApi.addFavoritesUser, action.payload.id)
    yield put(FavoritesUsersActions.storeFavoriteUser(favoritesUser))
    yield call(navigate, Routes.HomeTabs)
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      const { message } = JSON.parse((error as Error).message)
      showWarning(message)
    }
  }
}

function* deleteFavoritesUserSaga(action: ActionsOfType<FavoritesUserActionObjectTypes, FavoritesUserActionTypes.DELETE_FAVORITES_USER>) {
  try {
    const favoritesUser: FavorieUserItem[] = yield select(favoritesUserSelectorNew)
    const deletedfavoritesUser: { success: boolean; message: string } = yield call(FavoritesApi.deleteFavoritesUser, action.payload.id)
    if (!deletedfavoritesUser.success) {
      return
    }
    const newData = favoritesUser.filter((i: FavorieUserItem) => i.id !== action.payload.id)
    yield put(FavoritesUsersActions.storeFavoritesUser(newData))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* addMultipleFavoritesUserSaga(action: ActionsOfType<FavoritesUserActionObjectTypes, FavoritesUserActionTypes.ADD_MULTIPLE_FAVORITES_USER>) {
  try {
    const favoritesUser: FavorieUserItem[] = yield select(favoritesUserSelectorNew)
    const favoriteUserRes: { content: FavorieUserItem[] } = yield call(FavoritesApi.addMultipleFavoritesUser, action.payload.id, action.payload.ids)
    const favoritesUsers = [...favoritesUser, ...favoriteUserRes.content]
    yield put(FavoritesUsersActions.storeFavoritesUser(favoritesUsers))
    yield call(navigate, Routes.HomeTabs)
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      const { message } = JSON.parse((error as Error).message)
      showWarning(message)
    }
  }
}

function* loadFavoritesItemSaga(action: ActionsOfType<FavoritesUserActionObjectTypes, FavoritesUserActionTypes.LOAD_FAVORITES_ITEM>) {
  try {
    const favoritesUser: FavorieUserItem[] = yield select(favoritesUserSelectorNew)
    const favoritesItem: FavorieUserItem = yield call(FavoritesApi.loadFavoritesItem, action.payload.id)
    const newFavoriteUser = favoritesUser.map((favorite: FavorieUserItem) => {
      if (favorite.id === favoritesItem.id) {
        return favoritesItem
      }
      return favorite
    })

    yield put(FavoritesUsersActions.storeFavoritesUser(newFavoriteUser))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

export function* loadFavoritesUserWatcher() {
  yield takeEvery(FavoritesUserActionTypes.LOAD_FAVORITES_USER, addLoader(loadFavoritesUserSaga, LoaderName.LOAD_FAVORITES_USER))
}

export function* addFavoritesUserWatcher() {
  yield takeEvery(FavoritesUserActionTypes.ADD_FAVORITES_USER, addLoader(addFavoritesUserSaga, LoaderName.ADD_FAVORITES_USER))
}

export function* deleteFavoritesUserWatcher() {
  yield takeEvery(FavoritesUserActionTypes.DELETE_FAVORITES_USER, addLoader(deleteFavoritesUserSaga, LoaderName.DELETE_FAVORITES_USER))
}

export function* pushFavoritesUserIdWatcher() {
  yield takeEvery(FavoritesUserActionTypes.PUSH_FAVORITES_USER_IDS, addLoader(pushFavoriteUserSaga, LoaderName.PUSH_FAVORITES_USER_IDS))
}

export function* addMultipleFavoritesUserWatcher() {
  yield takeEvery(FavoritesUserActionTypes.ADD_MULTIPLE_FAVORITES_USER, addLoader(addMultipleFavoritesUserSaga, LoaderName.ADD_MULTIPLE_FAVORITES_USER))
}

export function* loadFavoritesItemWatcher() {
  yield takeEvery(FavoritesUserActionTypes.LOAD_FAVORITES_ITEM, addLoader(loadFavoritesItemSaga, LoaderName.LOAD_FAVORITES_ITEM))
}
