import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { MovieInTheater } from '../../api/Movie/types'
import { SegmentItemResult } from '../../api/types'
import { FlatListPaging } from '../../components/FlatListPaging'
import { MovieCard } from '../../components/MovieCard'
import { LeftButtonBack } from '../../components/Page'
import { PageSegment } from '../../components/PageSegment'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { MovieActions } from '../../redux/movies/actions'
import { lastPageMovieListSelector, movieListSelector } from '../../redux/movies/selector'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'

const CinemaSeance: FunctionComponent<StackScreenProps<ActionViewParamsType, 'CinemaSeance'>> = ({ route }) => {
  const movieSegments: SegmentItemResult[] = [
    {
      id: MovieInTheater.Now,
      libelle: i18n.t(`movie.now`),
    },
    {
      id: MovieInTheater.Coming,
      libelle: i18n.t(`movie.coming`),
    },
  ]

  const dispatch = useDispatch()
  const title = route.params.titre
  const id = route.params.id as number
  const movieResult = useSelector((state: RootState) => movieListSelector(state, id))
  const [currentSegment, setCurrentSegment] = useState<SegmentItemResult>(movieSegments[0])

  const isLoadingReferentiels = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_MOVIE_LIST))
  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_MOVIE_LIST))
  const isLastPage = useSelector((state: RootState) => lastPageMovieListSelector(state, id))

  useEffect(() => {
    dispatch(MovieActions.loadMovieList(true, id, MovieInTheater.Now))
  }, [])

  const isLoadingPage = () => isEmpty(movieResult) && isLoadingReferentiels && !isRefreshing
  const isLoadingMore = () => !isLastPage && !isRefreshing
  const canLoadMore = () => !isLoadingReferentiels && isLoadingMore()

  const onRefresh = () => {
    dispatch(MovieActions.refreshMovieList(id, currentSegment.id))
  }

  const onEndReached = () => {
    canLoadMore() ? dispatch(MovieActions.loadMovieList(false, id, currentSegment.id)) : null
  }

  const fetchReferentielFromSegment = (segment: SegmentItemResult) => {
    if (currentSegment.id === segment.id) {
      return
    }
    setCurrentSegment(segment)
    dispatch(MovieActions.refreshMovieList(id, segment.id))
  }

  return (
    <PageSegment left={<LeftButtonBack />} title={title || ''} currentSegment={currentSegment} segments={movieSegments} onSegmentPress={fetchReferentielFromSegment}>
      <SafeAreaView style={styles.safeArea}>
        <FlatListPaging
          data={movieResult}
          itemStyle={styles.itemStyle}
          renderItem={({ item }) => <MovieCard movie={item} />}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingPage={isLoadingPage()}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.id.toString()}
        />
      </SafeAreaView>
    </PageSegment>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  itemStyle: {
    marginHorizontal: theme.padding.x1,
    marginTop: theme.padding.x1,
  },
})

export { CinemaSeance }
