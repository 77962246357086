import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { intervalForHumans } from '../../../services/dateFormatter'
import { theme } from '../../../theme'

interface ReportStatusProps {
  date: number | null
}

const ICON_SIZE = 20
const TEXT_SIZE = 15

export const ReportInterval: FunctionComponent<ReportStatusProps> = ({ date }) => {
  if (!date) {
    return null
  }

  return (
    <View style={styles.row}>
      <Icon style={styles.icon} as={FontAwesome} name="calendar-o" size={6} />
      <Text style={styles.text}>{intervalForHumans(date)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginRight: theme.padding.x1,
    fontSize: TEXT_SIZE,
    color: theme.colors.silverStrong,
  },
  icon: {
    marginRight: theme.padding.x1,
    paddingVertical: theme.padding.x05,
    fontSize: ICON_SIZE,
    color: theme.colors.silverStrong,
  },
})
