import { StackScreenProps } from '@react-navigation/stack'
import { Container } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { CityItemResult } from '../../api/City/types'
import { CityNotification } from '../../components/CityNotification'
import { Body, Content, Header, Left, Right, Title } from '../../components/NativeBaseLegacy'
import { LeftButtonBack } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'

export const CityAddConfig: FunctionComponent<StackScreenProps<ActionViewParamsType, 'cityAddConfig'>> = ({ route }) => {
  const city = route.params.city as CityItemResult

  return (
    <Container style={styles.container}>
      <Header>
        <Left>
          <LeftButtonBack />
        </Left>
        <Body>
          <Title>{i18n.t('notifications.notifications')}</Title>
        </Body>
        <Right />
      </Header>
      <Content style={styles.content}>
        <CityNotification city={city} />
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  container: {
    maxWidth: '100%',
  },
})
