import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { Icon, Text } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { FlatList, SafeAreaView, StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { getReadableVersion } from 'react-native-device-info'
import { MenuItemResult, MenuResult } from '../../api/Menu/types'
import { menuSelector } from '../../redux/menu/selector'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'
import { DrawerHeader } from '../DrawerHeader'
import { DrawerItem, MenuItem } from '../DrawerItem'
import { i18n } from '../../lib/i18n'

interface Props {
  navigation: DrawerNavigationHelpers
}

const getDisplayMenu = (menu: MenuResult, activeItemId: number | null) => {
  if (activeItemId) {
    const findItem = menu.items.find(item => item.id === activeItemId)
    if (findItem) {
      return findItem.items
    }
  }
  return menu.items
}

const Sidebar: FunctionComponent<Props> = props => {
  const menu = useSelector(menuSelector)
  const [activeItemId, setActiveItemId] = useState<number | null>(null)

  if (!menu) {
    return null
  }

  const onPress = async (item: MenuItem) => {
    if (item.items.length) {
      setActiveItemId(item.id)
    }
    if (item.action) {
      const action = getItemAction(item.action)
      await action()
      setActiveItemId(null)
    }
  }

  const items: MenuItemResult[] = getDisplayMenu(menu, activeItemId)

  return (
    <SafeAreaView style={styles.sidebarContainer}>
      <DrawerHeader {...menu.header} />
      {activeItemId && <Icon as={Ionicons} style={styles.backButton} name="arrow-back" onPress={() => setActiveItemId(null)} size={6} />}
      <FlatList
        style={styles.list}
        keyExtractor={item => item.id.toString()}
        data={items}
        renderItem={item => <DrawerItem navigation={props.navigation} {...item.item} onPress={onPress} />}
      />
      <Text style={styles.version}>{`${i18n.t('sidebar.version')} ${getReadableVersion()}`}</Text>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  sidebarContainer: {
    flex: 1,
  },
  list: {
    flex: 1,
    marginTop: theme.padding.x6,
  },
  backButton: {
    alignSelf: 'flex-start',
    paddingHorizontal: theme.padding.x3,
    paddingVertical: theme.padding.x1,
  },
  version: {
    textAlign: 'center',
    color: 'grey',
    paddingVertical: theme.padding.x05,
    fontSize: theme.textSize.x1_25,
  },
})

export { Sidebar }
