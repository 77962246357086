import { RootState } from '../reducers'
import { CatalogUserType, FavoriteFooter, FavoriteHeader } from '../../api/Favorites/types'
import { ItemAction } from '../../api/types'

export const favoritesCatalogMostUsedSelector = (state: RootState) =>
  state.favoritesCatalog.favoritesCatalogByMostUsed ? state.favoritesCatalog.favoritesCatalogByMostUsed : undefined

export const favoritesCatalogByThematicSelector = (state: RootState) =>
  state.favoritesCatalog.favoritesCatalogByThematic ? state.favoritesCatalog.favoritesCatalogByThematic : undefined

export const favoritesCatalogByNameSelector = (state: RootState) => (state.favoritesCatalog.favoritesCatalogByName ? state.favoritesCatalog.favoritesCatalogByName : undefined)

export interface DataFormatted {
  id: number
  key: string
  title: string
  header: FavoriteHeader | null
  image: string
  footer: FavoriteFooter | null
  type: CatalogUserType
  action: ItemAction | null
  disabledReSorted?: boolean
  disabledDrag?: boolean
}

export const favoritesSubThematicCatalogSelector = (state: RootState, id: number) => state.favoritesCatalog.favoritesSubThematique[id] || undefined
