import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'

const ICON_WIDTH = 20

interface ItemListMapProps {
  address?: string | null
}

export const ItemListMap: FunctionComponent<ItemListMapProps> = ({ address }) => {
  if (!address) {
    return null
  }

  const themeData = useSelector(themeSelector)
  const colorIcon = (themeData && themeData.brandPrimary) || theme.colors.silver

  return (
    <View style={styles.content}>
      <View style={styles.contentIcon}>
        <Icon name="map-marker" as={FontAwesome} color={colorIcon} size={6} />
      </View>
      <Text style={styles.text} numberOfLines={2}>
        {address}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentIcon: {
    alignItems: 'flex-start',
    width: ICON_WIDTH,
  },
  text: {
    flex: 1,
  },
})
