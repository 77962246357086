import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { WeatheIconerResult } from '../../../api/GridPage/types'
import { CurrentWeatherDay } from '../../../api/Weather/types'
import { themeSelector } from '../../../redux/theme/selector'
import { iconFont } from '../../../services/Icon'
import { weatherConditions } from '../../../services/weather'
import { theme } from '../../../theme'

interface WeatherCurrentDayProps {
  title: string
  current: CurrentWeatherDay | null
}

export const WeatherCurrentDay: FunctionComponent<WeatherCurrentDayProps> = ({ title, current }) => {
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
  const intTemp = current ? Math.floor(current.temp) : '.'

  const renderWeather = (weather: WeatheIconerResult) => {
    const condition = weatherConditions(weather.icone)
    if (!condition) {
      return <View />
    }

    return <Icon name={condition.icon} as={iconFont(condition.type)} style={style.icon} size={9} color={colorText} />
  }

  const renderCurrent = (current: CurrentWeatherDay | null) => {
    if (!current) {
      return <View />
    }

    return (
      <View style={style.content}>
        {renderWeather(current.weather)}
        <Text style={[style.item, { color: colorText }]}>{intTemp}°</Text>
      </View>
    )
  }

  return (
    <View style={style.main}>
      <Text style={[style.title, { color: colorText }]}>{title}</Text>
      {renderCurrent(current)}
    </View>
  )
}

const style = StyleSheet.create({
  main: {
    flex: 1,
    padding: theme.padding.x4,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  title: {
    flex: 1,
    padding: theme.padding.x1,
    fontSize: theme.textSize.x3,
  },
  content: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    padding: theme.padding.x1,
    fontSize: theme.textSize.x4,
  },
  icon: {
    marginRight: theme.padding.x1,
  },
})
