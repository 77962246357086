import React, { ComponentType, PureComponent, ReactNode } from 'react'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

export const withSafeArea = (Component: ComponentType): ComponentType => {
  class SafeArea extends PureComponent {
    public render(): ReactNode {
      return (
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <Component />
        </SafeAreaProvider>
      )
    }
  }

  return SafeArea
}
