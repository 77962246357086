import { WeatherConditionName } from '../../api/GridPage/types'

export type IconType =
  | 'AntDesign'
  | 'Entypo'
  | 'EvilIcons'
  | 'Feather'
  | 'FontAwesome'
  | 'FontAwesome5'
  | 'Foundation'
  | 'Ionicons'
  | 'MaterialCommunityIcons'
  | 'MaterialIcons'
  | 'Octicons'
  | 'SimpleLineIcons'
  | 'Zocial'
  | 'Fontisto'

interface WeatherConditionIconConfigProps {
  title: string
  icon: string
  type: IconType
}

const WeatherConditionIconConfig: { [key in WeatherConditionName]: WeatherConditionIconConfigProps } = {
  Thunderstorm: {
    title: 'Orage',
    icon: 'weather-lightning',
    type: 'MaterialCommunityIcons',
  },
  Drizzle: {
    title: 'Bruine',
    icon: 'weather-rainy',
    type: 'MaterialCommunityIcons',
  },
  Rain: {
    title: 'Pluie',
    icon: 'weather-pouring',
    type: 'MaterialCommunityIcons',
  },
  Snow: {
    title: 'Neige',
    icon: 'weather-snowy',
    type: 'MaterialCommunityIcons',
  },
  Mist: {
    title: 'Brouillard',
    icon: 'weather-fog',
    type: 'MaterialCommunityIcons',
  },
  Squall: {
    title: 'Bourrasque',
    icon: 'weather-windy',
    type: 'MaterialCommunityIcons',
  },
  Tornado: {
    title: 'Tornade',
    icon: 'weather-windy',
    type: 'MaterialCommunityIcons',
  },
  Clear: {
    title: 'Clair',
    icon: 'sunny',
    type: 'Ionicons',
  },
  ClearClouds: {
    title: 'Clair',
    icon: 'partly-sunny',
    type: 'Ionicons',
  },
  Clouds: {
    title: 'Nuages',
    icon: 'weather-cloudy',
    type: 'MaterialCommunityIcons',
  },
}

export const weatherConditions = (key: WeatherConditionName): WeatherConditionIconConfigProps => WeatherConditionIconConfig[key]
