import { Reducer } from 'redux'
import { GrcYpokState, GrcYpokActionTypes } from './types'
import { GrcYpokActionObjectTypes } from './actions'

const initialGrcYpokState: GrcYpokState = { config: null }

export const GrcYpokReducer: Reducer<GrcYpokState, GrcYpokActionObjectTypes> = (state = initialGrcYpokState, action) => {
  switch (action.type) {
    case GrcYpokActionTypes.STORE_YPOK_CONFIG:
      return {
        ...state,
        config: action.payload.config,
      }
    default:
      return state
  }
}
