import { IntermediatePageResult } from '../../api/GridPage/types'

export type IntermediatePageState = {
  [key: number]: IntermediatePageResult
}

export enum IntermediatePageActionTypes {
  LOAD_INTERMEDIATE_PAGE = 'LOAD_INTERMEDIATE_PAGE',
  STORE_INTERMEDIATE_PAGE = 'STORE_INTERMEDIATE_PAGE',
}
