import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from 'redux-saga/effects'
import { CartographyBeaconApi } from '../../api/CartographyBeacons/CartographyBeaconApi'
import { CartographyBeaconResult } from '../../api/CartographyBeacons/type'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { CartographyBeaconsActionObjectTypes, CartographyBeaconsActions } from './actions'
import { CartographyBeaconsActionTypes } from './types'

function* loadCartographyBeaconsSaga(action: ActionsOfType<CartographyBeaconsActionObjectTypes, CartographyBeaconsActionTypes.LOAD_CARTOGRAPHY_BEACONS>) {
  try {
    const cartographyBeaconResult: CartographyBeaconResult = yield call(CartographyBeaconApi.loadBeacon, action.payload.id)
    yield put(CartographyBeaconsActions.storeCartographyBeacons(cartographyBeaconResult.id.toString(), cartographyBeaconResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadCartographyBeaconsWatcher() {
  yield takeEvery(CartographyBeaconsActionTypes.LOAD_CARTOGRAPHY_BEACONS, addLoader(loadCartographyBeaconsSaga, LoaderName.LOAD_CARTOGRAPHY_BEACON))
}
