import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { SignalementCommentResult } from '../../../api/Signalement/types'
import { themeSelector } from '../../../redux/theme/selector'
import { datetimeToHuge } from '../../../services/dateFormatter'
import { capitalize } from '../../../services/textUtil/textUtil.service'
import { theme } from '../../../theme'
import { CardItem } from '../../NativeBaseLegacy'

const WIDTH_LINE = 60
const WIDTH_LINEBAR = 2
const DOT_SIZE = 14
const DOT_RADIUS = 8

interface SignalementCommentProps {
  item: SignalementCommentResult
}

export const SignalementComment: FunctionComponent<SignalementCommentProps> = ({ item }) => {
  if (!item) {
    return null
  }

  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  const date = capitalize(datetimeToHuge(item.date))

  const title = item.title ? <Text style={styles.textTitle}>{item.title}</Text> : null

  return (
    <CardItem>
      <View style={styles.line}>
        <Text style={[styles.lineDot, { backgroundColor: color }]} />
        <Text style={[styles.lineBar, { backgroundColor: color }]} />
      </View>
      <View style={styles.comment}>
        <Text style={styles.note}>{date}</Text>
        <Text>{item.commentaire}</Text>
        {title}
      </View>
    </CardItem>
  )
}

const styles = StyleSheet.create({
  note: {
    opacity: 0.4,
  },
  line: {
    flex: 0,
    width: WIDTH_LINE,
    height: '100%',
    alignItems: 'center',
  },
  lineDot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_RADIUS,
    backgroundColor: theme.colors.silver,
  },
  lineBar: {
    flex: 1,
    width: WIDTH_LINEBAR,
    backgroundColor: theme.colors.silver,
    marginTop: 0,
  },
  comment: {
    flex: 1,
    paddingRight: theme.padding.x1,
    paddingBottom: theme.padding.x1,
  },
  textTitle: {
    color: theme.colors.silver,
  },
})
