export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '474e7e9d-1ffc-46da-805b-97b25fa667fb',
  ENV: 'neuillySurSeineAgentStaging',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
    authentication: {
      enabled: true,
      appleProviderEnabled: false,
      facebookProviderEnabled: false,
      googleProviderEnabled: false,
    },
  },
  googleClientId: '',
  emailValidationDynamicLink: 'https://nvpagentstaging.page.link/email-confirmation',
}
