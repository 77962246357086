import React, { FunctionComponent } from 'react'
import { StyleSheet, FlatList } from 'react-native'
import { View } from 'native-base'
import { useSelector } from 'react-redux'
import { TimeLineItem } from './TimeLineItem.component'
import { theme } from '../../../theme'
import { themeSelector } from '../../../redux/theme/selector'

interface TimeLineProps {
  timeStamps: number[]
  height: number
}

export const TimeLine: FunctionComponent<TimeLineProps> = props => {
  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  return (
    <View style={styles.content}>
      <FlatList<number>
        data={props.timeStamps}
        renderItem={({ item }) => TimeLineItem({ date: item, height: props.height, color })}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  /* eslint react-native/no-unused-styles: */
  content: {
    width: 60,
  },
})
