import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { UrgentNumbersItem } from '../../api/UrgentNumbers/types'
import { i18n } from '../../lib/i18n'
import { phoneCall } from '../../services/phoneCall'
import { theme } from '../../theme'
import { BubbleAction } from '../BubbleAction'
import { Card } from '../Card'

interface PhoneNumberItemProps {
  item: UrgentNumbersItem
}

const WIDTH_BUBBLE = 50
const PADDING_TITLE = 4

export const PhoneNumberItem: FunctionComponent<PhoneNumberItemProps> = ({ item }) => {
  if (!item) {
    return null
  }

  const callPhoneNumber = () => phoneCall(item.telephone)

  return (
    <TouchableOpacity style={styles.container} onPress={callPhoneNumber}>
      <Card spacing column style={styles.card}>
        <View style={styles.body}>
          <View style={styles.containerText}>
            <Text style={styles.bold} numberOfLines={1}>
              {item.libelle}
            </Text>
            <Text style={styles.tel}>{i18n.t('phone', { phone: item.telephone })} </Text>
            {item.description ? <Text numberOfLines={2}>{item.description}</Text> : null}
          </View>
        </View>
        <View style={styles.right}>
          <BubbleAction link={{ icon: 'phone', iconTheme: 'Entypo' }} onPress={callPhoneNumber} />
        </View>
      </Card>
    </TouchableOpacity>
  )
}

interface Style {
  bold: ViewStyle
  container: ViewStyle
  containerText: ViewStyle
  card: ViewStyle
  body: ViewStyle
  right: ViewStyle
  tel: ViewStyle
}

const styles = StyleSheet.create<Style>({
  bold: { fontWeight: 'bold', marginBottom: PADDING_TITLE, paddingVertical: 0 },
  container: {
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'nowrap',
    marginHorizontal: theme.padding.x1,
  },
  containerText: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 0,
  },
  card: {
    // height: HEIGHT_CARD,
  },
  body: {
    flex: 1,
    padding: theme.padding.x1,
  },
  right: {
    width: WIDTH_BUBBLE,
    paddingVertical: 0,
  },
  tel: {
    marginBottom: theme.padding.x1,
  },
})
