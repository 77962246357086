import wretch from 'wretch'
import { CantineEliorFilter, CantineEliorItemResult } from './types'

export class CantineEliorApi {
  public static async loadCantine(filter: CantineEliorFilter) {
    return wretch(filter.url)
      .url(`contrat/${filter.contrat}/niveau/${filter.niveau}/restaurant/${filter.restaurant}/3`)
      .headers({ 'menuapi-key': filter.apiKey })
      .get()
      .json(json => json) as Promise<CantineEliorItemResult[]>
  }
}
