import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import isEmpty from 'lodash/isEmpty'
import { HomeConfigResult, PromotionResult } from '../../api/Home'
import { HomeApi } from '../../api/Home/HomeApi'
import { ApiResponse } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { RootState } from '../reducers'
import { HomeActionObjectTypes, HomeActions } from './actions'
import { displayedPromotionsSelector, promotionByIdSelector } from './selector'
import { HomeActionTypes } from './types'

function* loadHomeConfigSaga() {
  try {
    const homeConfig: HomeConfigResult = yield call(HomeApi.loadHomeConfig)
    if (homeConfig.promotionId) {
      const isPromotionStored = yield select((state: RootState) => promotionByIdSelector(state, (homeConfig.promotionId && homeConfig.promotionId.toString()) || ''))
      if (!isPromotionStored) {
        const promotion = yield call(HomeApi.loadPromotion, homeConfig.promotionId)
        yield put(HomeActions.storePromotion({ ...promotion, displayed: false }))
      }
    }
    yield put(HomeActions.storeHomeConfig(homeConfig))

    const displayedPromotions: PromotionResult[] = yield select(displayedPromotionsSelector)
    if (!isEmpty(displayedPromotions)) {
      for (const displayedPromotion of displayedPromotions) {
        yield fetchDisplayedPromotion({
          payload: { promotionId: displayedPromotion.id },
          type: HomeActionTypes.FETCH_DISPLAYED_PROMOTION,
        })
      }
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* fetchDisplayedPromotion(action: ActionsOfType<HomeActionObjectTypes, HomeActionTypes.FETCH_DISPLAYED_PROMOTION>) {
  try {
    const response: ApiResponse = yield call(HomeApi.saveDisplayedPromotion, action.payload.promotionId)
    if (response.success) {
      yield put(HomeActions.clearPromotion(action.payload.promotionId))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  } finally {
    yield put(HomeActions.displayedPromotion(action.payload.promotionId))
  }
}

export function* loadHomeConfigWatcher() {
  yield takeEvery(HomeActionTypes.LOAD_HOME_CONFIG, loadHomeConfigSaga)
}

export function* fetchDisplayedPromotionWatcher() {
  yield takeEvery(HomeActionTypes.FETCH_DISPLAYED_PROMOTION, fetchDisplayedPromotion)
}
