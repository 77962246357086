import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import { FavoritesApi } from '../../api/Favorites'
import { FavoriteSelectItem } from '../../api/Favorites/types'
import { ListWithSelection } from '../../components/ListWithSelection'
import { LeftButtonBack, Page } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'
import { FavoritesUsersActions } from '../../redux/favoritesUser/actions'
import { goBack } from '../../services/navigation'
import { theme } from '../../theme'

export const FavoriesMultipleChoice: FunctionComponent<StackScreenProps<ActionViewParamsType, 'FavoriesMultipleChoice'>> = ({ route }) => {
  const { title } = route.params
  const { id } = route.params

  const [intermediaryData, setIntermediaryData] = useState<FavoriteSelectItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
    callApi()
  }, [])

  const callApi = () => {
    FavoritesApi.loadFavoritesIntermediaryData(id)
      .then((data: { content: FavoriteSelectItem[] }) => {
        setIntermediaryData(data.content)
        setIsLoading(false)
        setIsRefreshing(false)
      })
      .catch(error => {
        console.log(error)
        goBack()
      })
  }

  const onRefresh = () => {
    callApi()
    setIsRefreshing(true)
  }

  const onConfirm = (selectedData: number[]) => {
    dispatch(FavoritesUsersActions.addMultipleFavoritesUser(id, selectedData))
  }

  const isLoadingPage = () => isEmpty(intermediaryData) && isLoading && !isRefreshing

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <ListWithSelection
        keyExtractor={item => item.id.toString()}
        data={intermediaryData}
        onConfirm={onConfirm}
        isLoadingPage={isLoadingPage()}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        flatListStyle={styles.list}
      />
    </Page>
  )
}

export const styles = StyleSheet.create({
  list: {
    flex: 1,
    marginTop: theme.padding.x4,
  },
})
