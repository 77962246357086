import _ from 'lodash'
import { BaseApi } from '../BaseApi'
import { FieldValueRequest } from '../Form/types'
import { ReferentielFilters } from '../types'
import { ReferencielLandFill, ReferentielItemResult, ReferentielListResult, ReferentielSearchResult } from './types'

export class ReferentielsApi {
  public static async loadReferentielList(page: number, size: number, filters: ReferentielFilters, query?: FieldValueRequest[] | null) {
    if (query) {
      return ReferentielsApi.queryReferentielList(page, size, filters, query)
    }
    const categories = filters.categories ? filters.categories.join(',') : null

    return BaseApi.authenticatedCall()
      .url('v1/mobile/referentiel')
      .query({
        page,
        size,
        referentielType: filters.referentielType,
        categories,
        schedulePeriodEnum: filters.schedulePeriodEnum || '',
        scheduleSortEnum: _.isNil(filters.scheduleSortEnum) ? '' : filters.scheduleSortEnum,
        referentielParentId: filters.referentielParentId || '',
        code: filters.referentielCode || '',
      })
      .get()
      .json(json => json) as Promise<ReferentielListResult>
  }

  public static async loadReferentielItem(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/referentiel/${id}`)
      .get()
      .json(json => json) as Promise<ReferentielItemResult>
  }

  public static async loadSearchFields(referentielType: number, categoryList?: number[] | null) {
    const categories = categoryList ? categoryList.join(',') : null

    return BaseApi.authenticatedCall()
      .url('v1/mobile/referentiel/search')
      .query({
        referentielType,
        categories,
      })
      .get()
      .json(json => json) as Promise<ReferentielSearchResult>
  }

  private static queryReferentielList(page: number, size: number, filters: ReferentielFilters, query: FieldValueRequest[]) {
    const categories = filters.categories ? filters.categories.join(',') : null
    return BaseApi.authenticatedCall()
      .url('v1/mobile/referentiel')
      .query({
        page,
        size,
        referentielType: filters.referentielType,
        categories,
        schedulePeriodEnum: filters.schedulePeriodEnum || '',
        scheduleSortEnum: _.isNil(filters.scheduleSortEnum) ? '' : filters.scheduleSortEnum,
        code: filters.referentielCode || '',
      })
      .post({
        reponses: [...query],
      })
      .json(json => json) as Promise<ReferentielListResult>
  }

  public static async loadReferentielSubDependancies(url: string) {
    return BaseApi.publicCall()
      .url(url)
      .get()
      .json(json => json) as Promise<ReferencielLandFill>
  }
}
