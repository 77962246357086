import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { ReportAuthor, ReportButtonProcess, ReportDate, ReportInterval, ReportStatus, ReportTabs, ReportTags } from '.'
import { TaskReportItem } from '../../../api/TaskReport/types'
import { theme } from '../../../theme'
import { ReferentielImage } from '../../Referentiel/components'

interface ReportTaskProps {
  taskReport: TaskReportItem
}

const renderImage = (task: TaskReportItem) =>
  task.images && task.images[0] ? <ReferentielImage title={task.title} fastImage={task.images[0]} maxHeight={theme.referentielImageMaxHeight} /> : null

export const TaskReportItemComponent: FunctionComponent<ReportTaskProps> = ({ taskReport }) => (
  <ScrollView style={styles.scrollView}>
    {renderImage(taskReport)}
    <View style={styles.content}>
      <View style={styles.row}>
        <View style={styles.leftSide}>
          <ReportInterval date={taskReport.createAt} />
          <ReportAuthor author={taskReport.user} />
        </View>
        <View style={styles.rightSide}>
          <View>
            <ReportDate date={taskReport.dueAt} />
            <ReportStatus state={taskReport.state} />
          </View>
        </View>
      </View>
      <ReportTags tags={taskReport.tags} />
      <View style={styles.separator} />
      <ReportButtonProcess task={taskReport} />
      <View style={styles.separator} />
      <ReportTabs history={taskReport.history} submissions={taskReport.submissions} />
    </View>
  </ScrollView>
)

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: theme.padding.x2,
    marginTop: theme.padding.x2,
  },
  leftSide: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightSide: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    borderBottomWidth: 1,
    borderColor: theme.colors.silver,
  },
})
