import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { ItemAction } from '../../api/types'
import { themeSelector } from '../../redux/theme/selector'
import { theme } from '../../theme'
import { ItemListMap } from '../ItemDefault/components/ItemListMap.component'
import { CardItem } from '../NativeBaseLegacy'
import { TouchableAction } from '../TouchableAction'

const DEFAULT_SIZE = 100
const BORDER_RADIUS = 2
const BAR_WIDTH = 4

interface ItemSimpleProps {
  title: string
  subTitle?: string | null
  address?: string | null
  description?: string | null
  barColor?: string | null
  action?: ItemAction
}

export const ItemSimple: FunctionComponent<ItemSimpleProps> = ({ title, subTitle, address, description, barColor, action }) => {
  const themeData = useSelector(themeSelector)
  const colorTitle = (themeData && themeData.brandPrimary) || theme.colors.silver

  const renderDescription = () => {
    if (!address && description) {
      return <Text numberOfLines={3}>{description}</Text>
    }
  }

  return (
    <TouchableAction action={action}>
      <View style={{ paddingHorizontal: theme.padding.x1 }}>
        <Card p="0" m="1">
          <CardItem p="1" style={styles.content}>
            <View style={styles.contentInfo}>
              <Text style={[styles.title, { color: colorTitle }]} numberOfLines={1}>
                {title}
              </Text>
              <Text style={styles.subTitle}>{subTitle}</Text>
              <ItemListMap address={address} />
              {renderDescription()}
            </View>
            {barColor && <View style={[styles.barLine, { backgroundColor: barColor }]} />}
          </CardItem>
        </Card>
      </View>
    </TouchableAction>
  )
}

export const getTextColor = (textColor?: string) => (textColor ? { color: textColor } : null)

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    minHeight: DEFAULT_SIZE,
    paddingLeft: theme.padding.x1,
    paddingBottom: theme.padding.x1,
    paddingRight: theme.padding.x1,
    alignItems: 'flex-start',
    height: '100%',
  },
  contentInfo: {
    flex: 1,
  },
  title: { fontWeight: '400' },
  subTitle: {
    paddingBottom: theme.padding.x1,
  },
  barLine: {
    height: '100%',
    width: BAR_WIDTH,
    borderTopRightRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
  },
})
