/* eslint-disable prettier/prettier */
import { StackScreenProps } from '@react-navigation/stack'
import { Card, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { NotificationUserItem } from '../../api/Notification/types'
import { ActionViewParamsType } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme/theme'
import { Body, CardItem, Left, Right, Thumbnail } from '../NativeBaseLegacy'

interface NotificationItemProps extends StackScreenProps<ActionViewParamsType, 'Notifications'> {
  item: NotificationUserItem
}

const COLOR_TEXT = 'black'

const styles = StyleSheet.create({
  view: {
    backgroundColor: theme?.colors.white,
  },
  body: {
    flex: 1,
  },
  message: {
    color: COLOR_TEXT,
  },
  right: {
    flex: 0,
  },
  note: {
    opacity: 0.4,
  },
})

const hasAction = (item: NotificationUserItem) => item.notification.action !== null

export const NotificationItem: FunctionComponent<NotificationItemProps> = props => {
  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
  const {notification} = props.item
  if (!notification) {
    return null
  }
  const dateFormated = notification.sendDate ? toDDMMYYYYatHHMM(notification.sendDate) : ''

  let footer = dateFormated
  if (notification.categorie) {
    footer = `${notification.categorie.libelle  } - ${  dateFormated}`
  }

  let content = (
    <CardItem>
      <Body>
        <Text style={[styles.note, styles.message]}>
          {notification.message}
        </Text>
      </Body>
    </CardItem>
  )

  if (hasAction(props.item)) {
    content = (
      <TouchableOpacity onPress={notification.action ? getItemAction(notification.action) : undefined}>
        <CardItem>
          <Body style={styles.body}>
            <Text style={[styles.note, styles.message]}>
              {notification.message}
            </Text>
          </Body>
          <Right style={styles.right}>
            <Icon as={Ionicons} name="arrow-forward" color={color} size={6} />
          </Right>
        </CardItem>
      </TouchableOpacity>
    )
  }

  return (
    <Card p="1" m="1">
      <View p="3" style={styles.view}>
        <Left>
          <Thumbnail source={{ uri: notification.image }} />
          <Body>
            <Text numberOfLines={3}>{notification.libelle}</Text>
          </Body>
        </Left>
        {content}
        <Text style={styles.note}>{footer}</Text>
      </View>
    </Card>
  )
}
