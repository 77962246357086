import AntDesign from 'react-native-vector-icons/AntDesign'
import Entypo from 'react-native-vector-icons/Entypo'
import EvilIcons from 'react-native-vector-icons/EvilIcons'
import Feather from 'react-native-vector-icons/Feather'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5'
import Fontisto from 'react-native-vector-icons/Fontisto'
import Foundation from 'react-native-vector-icons/Foundation'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import Octicons from 'react-native-vector-icons/Octicons'
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import Zocial from 'react-native-vector-icons/Zocial'

export const iconFont = (type: string) => {
  let lib
  switch (type) {
    case 'MaterialCommunityIcons':
      lib = MaterialCommunityIcons
      break
    case 'AntDesign':
      lib = AntDesign
      break
    case 'Entypo':
      lib = Entypo
      break
    case 'EvilIcons':
      lib = EvilIcons
      break
    case 'Feather':
      lib = Feather
      break
    case 'FontAwesome':
      lib = FontAwesome
      break
    case 'FontAwesome5':
      lib = FontAwesome5
      break
    case 'Fontisto':
      lib = Fontisto
      break
    case 'Foundation':
      lib = Foundation
      break
    case 'MaterialIcons':
      lib = MaterialIcons
      break
    case 'Octicons':
      lib = Octicons
      break
    case 'SimpleLineIcons':
      lib = SimpleLineIcons
      break
    case 'Zocial':
      lib = Zocial
      break
    default:
      lib = Ionicons
  }
  return lib
}
