import { Icon } from 'native-base'
import React from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { goBack } from '../../services/navigation'

interface Props {
  style?: ViewStyle
  iconColor?: string
  callBack?: () => void
}

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

export const CloseButton: React.FC<Props> = ({ style, iconColor, callBack }) => {
  const action = callBack || goBack
  const styleIcon = iconColor || undefined
  return (
    <TouchableOpacity style={[styles.close, style]} onPress={action}>
      <Icon as={Ionicons} name="close" color={styleIcon} size={6} />
    </TouchableOpacity>
  )
}
