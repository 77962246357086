import { Badge, Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Alert, StyleSheet } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { useDispatch, useSelector } from 'react-redux'
import { CityItemResult } from '../../api/City/types'
import { i18n } from '../../lib/i18n'
import { RoutesCityConfig } from '../../navigation/Routes'
import { CityActions } from '../../redux/city/action'
import { notificationsEnabledSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'
import { ImageRounded } from '../ImageRounded'

interface CityItemProps {
  cityItem: CityItemResult
}

export const CityItemEdit: FunctionComponent<CityItemProps> = ({ cityItem }) => {
  const dispatch = useDispatch()

  const permission = useSelector((state: RootState) => notificationsEnabledSelector(state))

  const confirmRemove = (item: CityItemResult) => {
    Alert.alert(i18n.t('myCitys.removeTitle'), i18n.t('myCitys.removeMessage', { name: item.title }), [
      {
        text: i18n.t('confirm.no'),
        style: 'cancel',
      },
      { text: i18n.t('confirm.yes'), onPress: () => removeCity(item) },
    ])
  }

  const removeCity = (item: CityItemResult) => {
    dispatch(CityActions.removeCity(item))
  }

  const RenderBadgeNotif: FunctionComponent = () => {
    if (!cityItem.notification || !cityItem.countNewCategory || cityItem.countNewCategory === 0) {
      return null
    }

    return (
      <Badge style={styles.badge}>
        <Text>{cityItem.countNewCategory}</Text>
      </Badge>
    )
  }

  const RenderNotif: FunctionComponent = () => {
    if (cityItem.notification === null) {
      if (cityItem.countNewCategory && cityItem.countNewCategory > 0) {
        return (
          <Button
            style={styles.button}
            colorScheme="light"
            onPress={() =>
              navigate(RoutesCityConfig.cityEdit, {
                city: cityItem,
              })
            }
          >
            <Badge style={styles.badge}>
              <Text>{cityItem.countNewCategory}</Text>
            </Badge>
            <Icon name="notifications-off" as={MaterialIcons} size={6} />
          </Button>
        )
      }
      return <View />
    }
    if (!permission) {
      return (
        <Button style={styles.button} colorScheme="primary" onPress={() => Alert.alert(i18n.t('notifications.permissionDenied'), i18n.t('notifications.phonePermission'))}>
          <Icon name="notifications-paused" as={MaterialIcons} size={6} />
        </Button>
      )
    }

    return (
      <Button
        style={styles.button}
        colorScheme="primary"
        onPress={() =>
          navigate(RoutesCityConfig.cityEdit, {
            city: cityItem,
          })
        }
      >
        <RenderBadgeNotif />
        <Icon name={cityItem.notification ? 'notifications-active' : 'notifications-off'} as={MaterialIcons} size={6} />
      </Button>
    )
  }

  return (
    <View style={styles.content}>
      <View style={styles.logo}>
        <ImageRounded image={cityItem.image} />
      </View>
      <View style={styles.infos}>
        <Text numberOfLines={1}>{cityItem.title}</Text>
        <Text numberOfLines={1}>
          {cityItem.postalCode} - {cityItem.department}
        </Text>
      </View>
      <View style={styles.buttons}>
        <RenderNotif />
        <Button style={styles.button} colorScheme="danger" onPress={() => confirmRemove(cityItem)}>
          <Icon name="delete" as={MaterialCommunityIcons} size={6} />
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingVertical: theme.padding.x2,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
  },
  logo: {
    paddingLeft: theme.padding.x2,
  },
  infos: {
    flex: 1,
    paddingLeft: theme.padding.x1,
    justifyContent: 'space-between',
  },
  buttons: {
    flex: 0,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  button: {
    marginHorizontal: theme.padding.x1,
  },
  badge: { position: 'absolute', right: -8, top: -8, zIndex: 1, minWidth: 27 },
})
