/* eslint react-native/no-unused-styles: 0 */
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ActualiteItemResult } from '../../api/GridPage/types'
import { i18n } from '../../lib/i18n'
import { GridPageActions } from '../../redux/gridPage/actions'
import { actualiteSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'
import { CardsLine } from './components/CardsLine.component'
import { TimeLine } from './components/TimeLine.component'

interface GridPageActualitesProps {
  id: number
}

const HEIGHT = 120

const mapTimeLineProp = (items: ActualiteItemResult[]) => ({
  timeStamps: items.map(item => item.date),
  height: HEIGHT,
})

const mapCardsLineProp = (items: ActualiteItemResult[]) => ({
  items,
  height: HEIGHT,
})

export const GridPageActualites: FunctionComponent<GridPageActualitesProps> = props => {
  const dispatch = useDispatch()

  const actualites = useSelector((state: RootState) => actualiteSelector(state, props.id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_ACTUALITES))

  useEffect(() => {
    dispatch(GridPageActions.loadActualites(props.id))
  }, [props.id])

  if (isLoading) {
    return <Spinner />
  }

  const items = (actualites && actualites.items) || []

  return (
    <View style={styles.content}>
      <Text>{i18n.t('gridActu.lastActu')}</Text>
      <View style={styles.lines}>
        <TimeLine {...mapTimeLineProp(items)} />
        <CardsLine {...mapCardsLineProp(items)} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: { paddingLeft: theme.padding.x1 },
  lines: {
    flex: 1,
    flexDirection: 'row',
    padding: theme.padding.x1,
  },
})
