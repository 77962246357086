import { Reducer } from 'redux'

import { AuthenticationActionObjectTypes } from './actions'
import { AuthenticationActionTypes, AuthenticationState } from './types'

export const initialAuthenticationState: AuthenticationState = {
  credentials: undefined,
  apiToken: undefined,
  set2fa: false,
  isConnected: false,
}

export const authenticationReducer: Reducer<AuthenticationState, AuthenticationActionObjectTypes> = (state = initialAuthenticationState, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.LOGOUT:
      return {
        ...initialAuthenticationState,
        credentials: {
          ...action.payload,
        },
      }
    case AuthenticationActionTypes.STORE_USER_CREDENTIALS:
      return {
        ...state,
        credentials: {
          ...action.payload,
        },
      }
    case AuthenticationActionTypes.STORE_API_TOKEN:
      return {
        ...state,
        apiToken: {
          ...action.payload,
        },
      }
    case AuthenticationActionTypes.STORE_TOKEN_SET_2FA:
      return {
        ...state,
        set2fa: action.payload.set2fa,
      }
    case AuthenticationActionTypes.IS_CONNECTED:
      return {
        ...state,
        isConnected: action.payload.isConnected,
      }
    default:
      return state
  }
}
