import { Reducer } from 'redux'
import { UrgentNumbersState, UrgentNumbersActionTypes } from './types'
import { ActionUrgentNumbersObjectTypes } from './action'

const initialUrgentNumbersState: UrgentNumbersState = {
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
}

export const urgentNumbersReducer: Reducer<UrgentNumbersState, ActionUrgentNumbersObjectTypes> = (state = initialUrgentNumbersState, action) => {
  switch (action.type) {
    case UrgentNumbersActionTypes.STORE_URGENTNUMBERS:
      return {
        ...state,
        paging: {
          last: action.payload.numbersResult.last,
          page: action.payload.numbersResult.page,
          size: action.payload.numbersResult.size,
          totalElements: action.payload.numbersResult.totalElements,
          totalPages: action.payload.numbersResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.numbersResult.content],
        },
      }
    case UrgentNumbersActionTypes.CLEAR_URGENTNUMBERS:
      return initialUrgentNumbersState
    default:
      return state
  }
}
