export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: 'e061ff1e-d8a2-441e-85e3-655fcd01320f',
  ENV: 'vincennes',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 47,
}
