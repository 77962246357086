import { CartographyResult, MarkerResult } from '../../api/Cartography/types'

export interface CartographyKeyState {
  config: CartographyResult | null
  menuItems: { [menuId: string]: number[] }
  markers: {
    [markerId: string]: MarkerResult
  }
}

export type CartographyState = {
  [cartographyId: string]: CartographyKeyState | null
}

export enum CartographyActionTypes {
  LOAD_CARTOGRAPHY = 'LOAD_CARTOGRAPHY',
  LOAD_CARTOGRAPHY_MENU_MARKER = 'LOAD_CARTOGRAPHY_MENU_MARKER',
  STORE_CARTOGRAPHY = 'STORE_CARTOGRAPHY',
  STORE_CARTOGRAPHY_MENU_MARKER = 'STORE_CARTOGRAPHY_MENU_MARKER',
}
