import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { ActualitesActionTypes } from './types'
import { ActualiteItemResult } from '../../api/Actualites'
import { NewsResult } from '../../api/Actualites/types'

export const ActualitesActions = {
  loadActualite: (id: number) => createAction(ActualitesActionTypes.LOAD_ACTUALITE, id),
  loadNews: (clear: boolean, referentielId?: number | null) => createAction(ActualitesActionTypes.LOAD_NEWS, { clear, referentielId }),
  storeNews: (newsResult: NewsResult) => createAction(ActualitesActionTypes.STORE_NEWS, { newsResult }),
  storeActualite: (item: ActualiteItemResult) => createAction(ActualitesActionTypes.STORE_ACTUALITE, item),
  refreshNews: () => createAction(ActualitesActionTypes.REFRESH_NEWS),
  clearNewsState: () => createAction(ActualitesActionTypes.CLEAR_NEWS),
}

export type ActualitesActionObjectTypes = ActionsUnion<typeof ActualitesActions>
