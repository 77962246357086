import { Reducer } from 'redux'
import { FavoritesCatalogState, FavoritesCatalogActionTypes } from './types'
import { FavoritesCatalogActionObjectTypes } from './actions'

const initialFavoritesState: FavoritesCatalogState = {
  favoritesCatalogByMostUsed: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  favoritesCatalogByThematic: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  favoritesCatalogByName: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  favoritesSubThematique: {},
}

export const favoritesCatalogReducer: Reducer<FavoritesCatalogState, FavoritesCatalogActionObjectTypes> = (state = initialFavoritesState, action): FavoritesCatalogState => {
  switch (action.type) {
    case FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_MOST_USED:
      return {
        ...state,
        favoritesCatalogByMostUsed: {
          last: action.payload.favoritesCatalogResult.last,
          page: action.payload.favoritesCatalogResult.page,
          size: action.payload.favoritesCatalogResult.size,
          totalElements: action.payload.favoritesCatalogResult.totalElements,
          totalPages: action.payload.favoritesCatalogResult.totalPages,
          content: [...(state.favoritesCatalogByMostUsed ? state.favoritesCatalogByMostUsed.content : []), ...action.payload.favoritesCatalogResult.content],
        },
      }
    case FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_THEMATIC:
      return {
        ...state,
        favoritesCatalogByThematic: {
          last: action.payload.favoritesCatalogResult.last,
          page: action.payload.favoritesCatalogResult.page,
          size: action.payload.favoritesCatalogResult.size,
          totalElements: action.payload.favoritesCatalogResult.totalElements,
          totalPages: action.payload.favoritesCatalogResult.totalPages,
          content: [...(state.favoritesCatalogByThematic ? state.favoritesCatalogByThematic.content : []), ...action.payload.favoritesCatalogResult.content],
        },
      }
    case FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_NAME:
      return {
        ...state,
        favoritesCatalogByName: {
          last: action.payload.favoritesCatalogResult.last,
          page: action.payload.favoritesCatalogResult.page,
          size: action.payload.favoritesCatalogResult.size,
          totalElements: action.payload.favoritesCatalogResult.totalElements,
          totalPages: action.payload.favoritesCatalogResult.totalPages,
          content: [...(state.favoritesCatalogByName ? state.favoritesCatalogByName.content : []), ...action.payload.favoritesCatalogResult.content],
        },
      }
    case FavoritesCatalogActionTypes.STORE_FAVORITES_SUB_THEMATIC_CATALOG:
      return {
        ...state,
        favoritesSubThematique: {
          [action.payload.id]: {
            last: action.payload.favoritesSubThematicCatalogResult.last,
            page: action.payload.favoritesSubThematicCatalogResult.page,
            size: action.payload.favoritesSubThematicCatalogResult.size,
            totalElements: action.payload.favoritesSubThematicCatalogResult.totalElements,
            totalPages: action.payload.favoritesSubThematicCatalogResult.totalPages,
            content: [...(state.favoritesCatalogByName ? state.favoritesCatalogByName.content : []), ...action.payload.favoritesSubThematicCatalogResult.content],
          },
        },
      }
    case FavoritesCatalogActionTypes.STORE_CLEAR_FAVORITES_SUB_THEMATIC_CATALOG:
      return {
        ...state,
        favoritesSubThematique: {
          [action.payload.id]: {
            last: action.payload.favoritesSubThematicCatalogResult.last,
            page: action.payload.favoritesSubThematicCatalogResult.page,
            size: action.payload.favoritesSubThematicCatalogResult.size,
            totalElements: action.payload.favoritesSubThematicCatalogResult.totalElements,
            totalPages: action.payload.favoritesSubThematicCatalogResult.totalPages,
            content: [...action.payload.favoritesSubThematicCatalogResult.content],
          },
        },
      }
    case FavoritesCatalogActionTypes.CLEAR_FAVORITES_SUB_THEMATIC_CATALOG:
      return {
        ...state,
        favoritesSubThematique: {},
      }
    case FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_MOST_USED:
      return {
        ...state,
        favoritesCatalogByMostUsed: {
          content: [],
          last: undefined,
          size: undefined,
          page: undefined,
          totalElements: undefined,
          totalPages: undefined,
        },
      }
    case FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_THEMATIC:
      return {
        ...state,
        favoritesCatalogByThematic: {
          content: [],
          last: undefined,
          size: undefined,
          page: undefined,
          totalElements: undefined,
          totalPages: undefined,
        },
      }
    case FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_NAME:
      return {
        ...state,
        favoritesCatalogByName: {
          content: [],
          last: undefined,
          size: undefined,
          page: undefined,
          totalElements: undefined,
          totalPages: undefined,
        },
      }
    default:
      return state
  }
}
