import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { ActualiteItemResult } from '../../../api/Actualites'
import { Routes } from '../../../navigation/Routes'
import { themeSelector } from '../../../redux/theme/selector'
import { getItemAction } from '../../../services/itemAction'
import { theme as defaultTheme } from '../../../theme'
import { CardItem, Thumbnail } from '../../NativeBaseLegacy'

interface NewsItemCardProps {
  item: ActualiteItemResult
  height: number
  author?: boolean
}

export const NewsItemTimeCard: FunctionComponent<NewsItemCardProps> = ({ item, height, author = true }) => {
  const theme = useSelector(themeSelector)
  const primaryColor = theme ? theme.brandPrimary : defaultTheme.colors.blue
  const itemStyle = cardItemStyle(height)

  const renderAuth = () => {
    if (!author || !item.author) {
      return null
    }
    return (
      <CardItem>
        <View style={itemStyle.author}>
          <Text numberOfLines={1} style={{ color: primaryColor }}>
            {item.author.libelle}
          </Text>
        </View>
      </CardItem>
    )
  }

  return (
    <TouchableOpacity
      style={itemStyle.content}
      onPress={getItemAction({
        type: 'view',
        viewName: Routes.Actualite,
        params: { id: item.id, titre: item.libelle, image: item.image },
      })}
    >
      <Card mx="1" mb="1" mt="2" p="0" style={itemStyle.card}>
        {renderAuth()}
        <CardItem>
          <View style={itemStyle.body}>
            <View style={itemStyle.bodyContent}>
              <Text numberOfLines={2} style={itemStyle.titre}>
                {item.libelle}
              </Text>
              <Text style={itemStyle.note} numberOfLines={2}>
                {item.descriptionLight}
              </Text>
            </View>
            <View style={itemStyle.bodyImage}>{item.image && <Thumbnail square large source={{ uri: item.image }} />}</View>
          </View>
        </CardItem>
      </Card>
    </TouchableOpacity>
  )
}

export const cardItemStyle = (height: number) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    note: {
      opacity: 0.4,
    },
    content: {
      flex: 1,
      minHeight: height,
      paddingBottom: defaultTheme.padding.x1,
      paddingRight: defaultTheme.padding.x1,
    },
    card: {
      marginTop: 0,
      height: '100%',
    },
    author: {
      paddingLeft: 15,
    },
    body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    bodyContent: { flex: 1, paddingRight: defaultTheme.padding.x1 },
    titre: {
      paddingBottom: defaultTheme.padding.x1,
    },
    bodyImage: { flex: 0 },
  })
