import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { CartographyBeaconsActionTypes } from './types'
import { CartographyBeaconResult } from '../../api/CartographyBeacons/type'

export const CartographyBeaconsActions = {
  loadCartographyBeacons: (id: number) => createAction(CartographyBeaconsActionTypes.LOAD_CARTOGRAPHY_BEACONS, { id }),
  storeCartographyBeacons: (id: string, config: CartographyBeaconResult) => createAction(CartographyBeaconsActionTypes.STORE_CARTOGRAPHY_BEACONS, { id, config }),
}

export type CartographyBeaconsActionObjectTypes = ActionsUnion<typeof CartographyBeaconsActions>
