import React, { ComponentType, PureComponent } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '../redux/store'

export const withRedux = (Component: ComponentType): ComponentType => {
  class ReduxHOC extends PureComponent {
    public render(): JSX.Element {
      return (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>
      )
    }
  }

  return ReduxHOC
}
