import { IImageProps, Image } from 'native-base'
import React, { FunctionComponent } from 'react'

type imgProps = {
  size: string
}

type ImageProps = {
  square?: boolean
  small?: boolean
  large?: boolean
}

export const Thumbnail: FunctionComponent<IImageProps & ImageProps> = ({ square, small, large, ...props }) => {
  const imgProps: imgProps = { size: 'md' }
  if (square && small) imgProps.size = '12'
  else if (square && large) imgProps.size = '20'
  else if (square) imgProps.size = '16'
  else if (!square && small) imgProps.size = 'xs'
  else if (!square && large) imgProps.size = 'lg'
  else imgProps.size = 'md'

  return <Image {...imgProps} {...props} />
}
