import React, { FunctionComponent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { FlatListPaging } from '../FlatListPaging'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { ActualitesActions } from '../../redux/actualites/actions'
import { newsSelector, lastPageSelector } from '../../redux/actualites/selector'
import { NewsItem } from '../NewsItem'
import { NewsItemTime } from '../NewsItemTime'
import { theme } from '../../theme'
import { ActualiteItemResult } from '../../api/Actualites'

export type NewsComponentItem = 'default' | 'NewsTimeLine' | 'NewsTimeLineAuthor'

interface NewsProps {
  referentielId?: number | null
  componentItem?: NewsComponentItem
}

export const News: FunctionComponent<NewsProps> = ({ referentielId, componentItem = 'default' }) => {
  const dispatch = useDispatch()
  const news = useSelector(newsSelector)

  useEffect(() => {
    dispatch(ActualitesActions.loadNews(true, referentielId))
  }, [])

  const isLoadingNews = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_NEWS))

  const isLastPage = useSelector(lastPageSelector)

  const onEndReached = () => {
    canLoadMore() ? dispatch(ActualitesActions.loadNews(false, referentielId)) : null
  }

  const onRefresh = () => {
    dispatch(ActualitesActions.refreshNews())
  }

  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_NEWS))

  const isLoadingPage = () => isEmpty(news) && isLoadingNews && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoadingNews && isLoadingMore()

  const renderItem = (item: ActualiteItemResult) => {
    switch (componentItem) {
      case 'NewsTimeLine':
        return <NewsItemTime actuality={item} author={false} />
      case 'NewsTimeLineAuthor':
        return <NewsItemTime actuality={item} author />
      default:
        return <NewsItem item={item} />
    }
  }

  return (
    <FlatListPaging
      data={news}
      renderItem={({ item }) => renderItem(item)}
      onEndReached={onEndReached}
      onRefresh={onRefresh}
      isRefreshing={isRefreshing}
      isLoadingPage={isLoadingPage()}
      isLoadingMore={isLoadingMore}
      keyExtractor={item => item.id.toString()}
      flatListStyle={{ padding: theme.padding.x1 }}
    />
  )
}
