import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { CantineEliorPrestationResult } from '../../../api/CantineElior/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { Card } from '../../Card'
import { CantineFamille } from './CantineFamille.component'
import { CantineNormes } from './CantineNormes.component'

export interface CantinePrestationProps {
  presta: CantineEliorPrestationResult
}
export const CantinePrestation: FunctionComponent<CantinePrestationProps> = ({ presta }) => {
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver
  const famille = presta.FamillePlats.map((object, index) => <CantineFamille key={index} famille={object} />)

  return (
    <View style={styles.content}>
      <Text style={[styles.title, { color: colorText }]}>{presta.Libelle}</Text>
      <Card style={styles.card}>{famille}</Card>
      <CantineNormes presta={presta} />
    </View>
  )
}

const styles = StyleSheet.create({
  content: { paddingTop: theme.padding.x2 },
  card: { padding: theme.padding.x2, marginBottom: theme.padding.x1 },
  title: { fontSize: theme.textSize.x2 + theme.textSize.x1 / 3, marginBottom: theme.padding.x2 },
})
