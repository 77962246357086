import React, { FunctionComponent } from 'react'
import { View, Spinner } from 'native-base'
import { StyleSheet } from 'react-native'
import { theme } from '../../theme'

const HEIGHT_AND_WIDTH_OF_SPINNER_BOX = 60

const BORDERRADIUS_OF_SPINNER_BOX = 8

const SHADOW_OPACITY_OF_SPINNER_BOX = 0.1

const SHADOW_RADIUS_OF_SPINNER_BOX = BORDERRADIUS_OF_SPINNER_BOX

const SHADOW_OFFSET_WIDTH_OF_SPINNER_BOX = 0

const SHADOW_OFFSET_HEIGHT_OF_SPINNER_BOX = 2

export const CartographyLoading: FunctionComponent = () => (
  <View style={styles.viewWhite}>
    <Spinner />
  </View>
)

const styles = StyleSheet.create({
  viewWhite: {
    zIndex: 2,
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: HEIGHT_AND_WIDTH_OF_SPINNER_BOX,
    width: HEIGHT_AND_WIDTH_OF_SPINNER_BOX,
    backgroundColor: theme.colors.white,
    borderRadius: BORDERRADIUS_OF_SPINNER_BOX,
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: SHADOW_OFFSET_WIDTH_OF_SPINNER_BOX,
      height: SHADOW_OFFSET_HEIGHT_OF_SPINNER_BOX,
    },
    shadowOpacity: SHADOW_OPACITY_OF_SPINNER_BOX,
    shadowRadius: SHADOW_RADIUS_OF_SPINNER_BOX,
  },
})
