import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { SignalementsActionTypes } from './types'
import { SignalementsResult, SignalementItemResult } from '../../api/Signalement/types'

export const SignalementsActions = {
  loadSignalements: (clear: boolean) => createAction(SignalementsActionTypes.LOAD_SIGNALEMENTS, { clear }),
  storeClearSignalements: (signalementsResult: SignalementsResult) => createAction(SignalementsActionTypes.STORE_CLEAR_SIGNALEMENTS, { signalementsResult }),
  storeSignalements: (signalementsResult: SignalementsResult) => createAction(SignalementsActionTypes.STORE_SIGNALEMENTS, { signalementsResult }),
  refreshSignalements: () => createAction(SignalementsActionTypes.REFRESH_SIGNALEMENTS),
  loadSignalementItem: (id: number) => createAction(SignalementsActionTypes.LOAD_SIGNALEMENT_ITEM, { id }),
  StoreSignalementItem: (id: number, item: SignalementItemResult) => createAction(SignalementsActionTypes.STORE_SIGNALEMENT_ITEM, { id, item }),
}

export type ActionSignalementObjectTypes = ActionsUnion<typeof SignalementsActions>
