import { call, put, takeEvery } from 'redux-saga/effects'
import { MenuApi } from '../../api/Menu'
import { MenuResult } from '../../api/Menu/types'
import { isOfflineError } from '../../services/errorManager'
import { MenuActions } from './actions'
import { LoadMenuActionTypes } from './types'

function* loadMenuSaga() {
  try {
    const menuConfig: MenuResult = yield call(MenuApi.load)
    yield put(MenuActions.storeMenuConfig(menuConfig))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

export function* loadMenuWatcher() {
  yield takeEvery(LoadMenuActionTypes.LOAD_MENU, loadMenuSaga)
}
