import { BaseApi } from '../BaseApi'
import { UrgentNumbersResult } from './types'
import { urlApi } from '../url'

export class UrgentNumbersApi {
  public static async load(page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.urgentNumbers.submit(page, size))
      .get()
      .json(json => json) as Promise<UrgentNumbersResult>
  }
}
