import isEmpty from 'lodash/isEmpty'
import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import AntDesign from 'react-native-vector-icons/AntDesign'
import { useSelector } from 'react-redux'
import { CityItemResult } from '../../api/City/types'
import { i18n } from '../../lib/i18n'
import { RoutesCityAdd } from '../../navigation/Routes'
import { citysSelectSelector } from '../../redux/city/selector'
import { navigate } from '../../services/navigation'
import { News, NewsComponentItem } from '../News/News.component'

export const MyCitysHome: FunctionComponent = () => {
  const citys = useSelector(citysSelectSelector)

  if (isEmpty(citys)) {
    return (
      <View style={styles.contentAdd}>
        <Button colorScheme="default" onPress={() => navigate(RoutesCityAdd.cityAdd)}>
          <Icon name="pluscircleo" as={AntDesign} size={6} />
          <Text>{i18n.t('addCity.title')}</Text>
        </Button>
      </View>
    )
  }

  const citysList: CityItemResult[] = Object.values(citys)

  const newsComponentItem = (): NewsComponentItem => {
    if (citysList.length === 1) {
      return 'NewsTimeLine'
    }
    return 'NewsTimeLineAuthor'
  }

  return (
    <View style={styles.contentNews} key={citysList.length}>
      <News componentItem={newsComponentItem()} />
    </View>
  )
}

const styles = StyleSheet.create({
  contentAdd: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentNews: {
    flex: 1,
  },
})
