import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { FavoritesUserActionTypes } from './types'
import { FavorieUserItem } from '../../api/Favorites/types'

export const FavoritesUsersActions = {
  loadFavoritesUser: () => createAction(FavoritesUserActionTypes.LOAD_FAVORITES_USER),
  addFavoritesUser: (id: number) => createAction(FavoritesUserActionTypes.ADD_FAVORITES_USER, { id }),
  storeFavoritesUser: (favoritesUsers: FavorieUserItem[]) => createAction(FavoritesUserActionTypes.STORE_FAVORITES_USER, favoritesUsers),
  storeFavoriteUser: (favoritesUser: FavorieUserItem) => createAction(FavoritesUserActionTypes.STORE_FAVORITE_USER, favoritesUser),
  deleteFavoritesUser: (id: number) => createAction(FavoritesUserActionTypes.DELETE_FAVORITES_USER, { id }),
  addMultipleFavoritesUser: (id: number, ids: number[]) => createAction(FavoritesUserActionTypes.ADD_MULTIPLE_FAVORITES_USER, { id, ids }),
  pushFavoritesUserIds: () => createAction(FavoritesUserActionTypes.PUSH_FAVORITES_USER_IDS),
  loadFavoritesItem: (id: number) => createAction(FavoritesUserActionTypes.LOAD_FAVORITES_ITEM, { id }),
}

export type FavoritesUserActionObjectTypes = ActionsUnion<typeof FavoritesUsersActions>
