import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { SignalementApi } from '../../api/Signalement'
import { SignalementItemResult, SignalementsResult } from '../../api/Signalement/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActionSignalementObjectTypes, SignalementsActions } from './action'
import { signalementsPagingSelector } from './selector'
import { SignalementsActionTypes } from './types'

const PAGE_SIZE = 40

function* LoadSignalementsSaga(action: ActionsOfType<ActionSignalementObjectTypes, SignalementsActionTypes.LOAD_SIGNALEMENTS>) {
  const paging: PagingResult<SignalementsResult> = yield select(signalementsPagingSelector)
  let page = 0

  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const signalementsResult: SignalementsResult = yield call(SignalementApi.load, page, PAGE_SIZE)
    if (action.payload.clear) {
      yield put(SignalementsActions.storeClearSignalements(signalementsResult))
    } else {
      yield put(SignalementsActions.storeSignalements(signalementsResult))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* LoadSignalementItemSaga(action: ActionsOfType<ActionSignalementObjectTypes, SignalementsActionTypes.LOAD_SIGNALEMENT_ITEM>) {
  try {
    const itemResult: SignalementItemResult = yield call(SignalementApi.loadItem, action.payload.id)
    yield put(SignalementsActions.StoreSignalementItem(action.payload.id, itemResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* refreshSignalementsSaga() {
  yield call(LoadSignalementsSaga, SignalementsActions.loadSignalements(true))
}

export function* loadSignalementsWatcher() {
  yield takeEvery(SignalementsActionTypes.LOAD_SIGNALEMENTS, addLoader(LoadSignalementsSaga, LoaderName.LOAD_SIGNALEMENTS))
}

export function* loadSignalemenItemtWatcher() {
  yield takeEvery(SignalementsActionTypes.LOAD_SIGNALEMENT_ITEM, addLoader(LoadSignalementItemSaga, LoaderName.LOAD_SIGNALEMENT_ITEM))
}

export function* refreshSignalementsWatcher() {
  yield takeEvery(SignalementsActionTypes.REFRESH_SIGNALEMENTS, addLoader(refreshSignalementsSaga, LoaderName.REFRESH_SIGNALEMENTS))
}
