/* eslint-disable @typescript-eslint/no-explicit-any */
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/fr'
import React, { ComponentType, PureComponent, ReactNode } from 'react'
import { addEventListener, removeEventListener } from 'react-native-localize'
import { setI18nConfig } from '../lib/i18n'

export const withTranslation = (Component: ComponentType): ComponentType => {
  class TranslationListener extends PureComponent {
    public constructor(props: any) {
      super(props)
      setI18nConfig()

      Intl && (Intl as any).__disableRegExpRestore && (Intl as any).__disableRegExpRestore()
    }

    public componentDidMount(): void {
      addEventListener('change', this.handleLocalizationChange)
    }

    public componentWillUnmount(): void {
      removeEventListener('change', this.handleLocalizationChange)
    }

    private handleLocalizationChange = (): void => {
      setI18nConfig()
      this.forceUpdate()
    }

    public render(): ReactNode {
      return <Component />
    }
  }

  return TranslationListener
}
