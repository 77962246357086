import { StackScreenProps } from '@react-navigation/stack'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderScrollView } from '../../components/HeaderScrollView'
import { LeftButtonBack, LeftRoundButtonBack } from '../../components/Page/Page.component'
import { Separator } from '../../components/Separator'
import { HtmlWebView } from '../../components/WebView'
import { ActionViewParamsType } from '../../navigation/Routes'
import { ActualitesActions } from '../../redux/actualites/actions'
import { actualiteSelector } from '../../redux/actualites/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { theme } from '../../theme'

import imageDefault from '../../assets/images/actualite.jpg'

export const Actualite: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Actualite'>> = ({ route }) => {
  const id = route.params.id as number
  const { image } = route.params

  const dispatch = useDispatch()

  const actualite = useSelector((state: RootState) => actualiteSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_ACTUALITE))

  const loadActualite = () => {
    dispatch(ActualitesActions.loadActualite(id))
  }

  useEffect(loadActualite, [])

  if (!actualite || isLoading) {
    const images = image ? [image] : []
    return (
      <HeaderScrollView images={images} imageDefault={imageDefault as ImageSourcePropType} title="" left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
        <Spinner />
      </HeaderScrollView>
    )
  }

  const libelle = actualite ? actualite.libelle : ''
  const img = actualite && actualite.image ? [actualite.image] : []

  return (
    <HeaderScrollView images={img} imageDefault={imageDefault as ImageSourcePropType} title={libelle} left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
      <View style={styles.content}>
        <Text style={styles.date}>{toDDMMYYYYatHHMM(actualite.date)}</Text>
        <Separator />
        <HtmlWebView content={actualite.description} />
      </View>
    </HeaderScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: theme.padding.x2,
  },
  date: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    color: theme.colors.silver,
  },
})
