import wretch from 'wretch'
import { BaseApi } from '../BaseApi'
import { AirQualityConfig, AirParifConfig, AirParifResult } from './types'

export class AirQualityApi {
  public static async loadAirQuality(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/airquality/${id}`)
      .get()
      .json(json => json) as Promise<AirQualityConfig>
  }

  public static async loadAirParif(config: AirParifConfig) {
    return wretch(`${config.url}/indices/prevision/commune`)
      .headers({ 'X-Api-Key': config.key })
      .query({ insee: config.code })
      .get()
      .json(json => json) as Promise<AirParifResult>
  }
}
