import React, { FunctionComponent } from 'react'
import { GestureResponderEvent, TextStyle } from 'react-native'
import HTML, { MixedStyleDeclaration } from 'react-native-render-html'
import { openLink } from '../../services/browserInApp'
import { theme } from '../../theme'

interface HtmlProps {
  content: string | null
  baseFontStyle?: TextStyle
}

export const Html: FunctionComponent<HtmlProps> = ({ content, baseFontStyle }) => {
  if (!content) {
    return null
  }

  const renderersProps = {
    a: {
      onPress: (event: GestureResponderEvent, href: string) => {
        openLink(href)
      },
    },
  }

  return (
    <HTML
      source={{ html: content }}
      allowedStyles={['color']}
      baseStyle={(baseFontStyle || { fontSize: theme.textSize.x2 }) as MixedStyleDeclaration}
      renderersProps={renderersProps}
      contentWidth={theme.deviceWidth}
    />
  )
}
