import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../redux/theme/selector'
import { theme } from '../../theme'

interface SeparatorProps {
  paddingVertical?: boolean
}

export const Separator: FunctionComponent<SeparatorProps> = ({ paddingVertical = true }) => {
  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  if (paddingVertical) {
    return <View style={[styles.separatorBase, { backgroundColor: color }]} />
  }

  return <View style={styles.separator} />
}

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    borderColor: theme.colors.silver,
  },
  separatorBase: {
    height: 2,
    marginVertical: theme.padding.x1,
  },
})
