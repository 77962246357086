import { AirQualityConfig, AirQualityDayResult } from '../../api/AirQuality/types'

export type AirQualityState = {
  configs: {
    [key: string]: AirQualityConfig
  }
  days: { [key: string]: AirQualityDayResult }
}

export enum AirQualityActionTypes {
  LOAD_AIR_QUALITY_CONFIG = 'LOAD_AIR_QUALITY_CONFIG',
  STORE_AIR_QUALITY_CONFIG = 'STORE_AIR_QUALITY_CONFIG',
  CHECK_AIR_QUALITY_DAY = 'CHECK_AIR_QUALITY_DAY',
  STORE_AIR_QUALITY_DAY = 'STORE_AIR_QUALITY_DAY',
}
