import _ from 'lodash'
import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Swiper from 'react-native-web-swiper'
import { theme } from '../../../theme'
import { Body, Header, Left } from '../../NativeBaseLegacy'

interface HeaderCarrouselProps {
  images: string[]
  maxHeight: number
  displayBottom: boolean
  left?: React.ReactNode
}

const DOT_WIDTH = 10
const BORDER_RADIUS = 20

export const HeaderCarrousel: FunctionComponent<HeaderCarrouselProps> = ({ images, displayBottom, maxHeight, left }) => {
  const renderCarrouselItem = (image: string) => (
    <View style={styles.carouselItem}>
      <Header style={theme.isAndroid ? styles.header : {}} transparent>
        <Left style={styles.left}>{left}</Left>
        <Body style={styles.body} />
      </Header>
      <FastImage style={[styles.image, { height: maxHeight }]} source={{ uri: image }} resizeMode={FastImage.resizeMode.cover} />
      {displayBottom && <View style={styles.renderForegroundBottom} />}
    </View>
  )

  return (
    <View style={styles.carouselContainer}>
      <Swiper
        loop
        controlsProps={{
          prevPos: false,
          nextPos: false,
        }}
      >
        {_.map(images, item => renderCarrouselItem(item))}
      </Swiper>
    </View>
  )
}

const styles = StyleSheet.create({
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    zIndex: 1,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    alignSelf: 'stretch',
    zIndex: -1,
    opacity: 0.9,
  },
  header: {
    paddingTop: 0,
  },
  carouselItem: {
    height: '100%',
    backgroundColor: theme.colors.silver,
  },
  carouselContainer: {
    backgroundColor: theme.colors.white,
  },

  pagination: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    paddingVertical: theme.padding.x1,
  },
  dot: {
    width: DOT_WIDTH,
    height: DOT_WIDTH,
    borderRadius: DOT_WIDTH / 2,
    marginHorizontal: theme.padding.x2,
  },
  renderForegroundBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.white,
  },
})
