import { BaseApi } from '../BaseApi/BaseApi'
import { ExternalLinksResult } from './types'

export class ExternalLinksApi {
  public static async loadExternalLinks(page: number, size: number, type?: string) {
    return BaseApi.authenticatedCall()
      .url(`v1/externallinks`)
      .query({ page, size, type: type || '' })
      .get()
      .json(json => json) as Promise<ExternalLinksResult>
  }
}
