import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { TaskReportHisto } from '../../../api/TaskReport/types'
import { theme } from '../../../theme'
import { SignalementCommentList } from '../../SignalementItem/components/SignalementCommentList.component'

interface ReportStatusProps {
  history: TaskReportHisto[] | null
}

export const ReportHistoryTab: FunctionComponent<ReportStatusProps> = ({ history }) => {
  if (!history) {
    return null
  }

  const histoSorted = history.sort((a, b) => {
    if (a.date > b.date) {
      return -1
    }
    if (a.date < b.date) {
      return 1
    }
    return 0
  })

  const histo = histoSorted.map((item, index) => ({ id: index, commentaire: item.title, title: item.commentaire, date: item.date }))
  return (
    <View style={styles.row}>
      <SignalementCommentList comments={histo} />
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    marginTop: theme.padding.x2,
  },
})
