import { CommonActions } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { WebView, WebViewMessageEvent } from 'react-native-webview'
import { useDispatch, useSelector } from 'react-redux'
import { Content } from '../../components/NativeBaseLegacy'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, Routes } from '../../navigation/Routes'
import { RootState } from '../../redux/reducers'
import { TaskReportActions } from '../../redux/taskReport/action'
import { grcTokenSelector } from '../../redux/taskReport/selector'

export const TaskReportItemRequest: FunctionComponent<StackScreenProps<ActionViewParamsType, 'TaskReportItemRequest'>> = ({ route, navigation }) => {
  const task = route.params.taskReport
  const token = useSelector((state: RootState) => grcTokenSelector(state))
  const dispatch = useDispatch()

  const loadTaskReportWhenDone = async () => {
    dispatch(TaskReportActions.loadTaskReport(true, 0))
    const timer = setTimeout(() => {
      navigation.dispatch(
        CommonActions.navigate({
          name: Routes.TaskReportList,
        }),
      )
    }, 1500)
    return () => clearTimeout(timer)
  }

  const onMessage = (payload: WebViewMessageEvent) => {
    switch (payload.nativeEvent.data) {
      case 'done':
        loadTaskReportWhenDone()
        break
      case 'cancel':
        navigation.goBack()
        break
      default:
        break
    }
  }

  const INJECTED_JAVASCRIPT = `(function() {
    window.localStorage.clear()
    window.localStorage.setItem('ac-token', '${token}')
    return true
  })()`

  return (
    <Page left={<LeftButtonBack />} title={task.title ? task.title : i18n.t('signalement.process')}>
      <Content style={styles.container}>
        <WebView
          source={{
            uri: task.processUrl,
          }}
          javaScriptEnabled
          injectedJavaScriptBeforeContentLoaded={INJECTED_JAVASCRIPT}
          onMessage={onMessage}
        />
      </Content>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
})
