import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { SeanceItemResult } from '../../api/Movie/types'
import { CardAddress } from '../../components/CardAddress'
import { HeaderScrollView } from '../../components/HeaderScrollView'
import { MovieAvailableSeance } from '../../components/MovieAvailableSeance'
import { LeftButtonBack, LeftRoundButtonBack } from '../../components/Page/Page.component'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { MovieActions } from '../../redux/movies/actions'
import { movieItemSelector } from '../../redux/movies/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { dateToHuge } from '../../services/dateFormatter'
import { theme as defaultTheme } from '../../theme'

import imageDefault from '../../assets/images/empty_image.jpg'
import { Card } from '../../components/Card'

interface DaySeance {
  [key: string]: SeanceItemResult[]
}

export const Cinema: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Cinema'>> = ({ route }) => {
  const dispatch = useDispatch()
  const title = route.params.titre
  const id = route.params.id as number
  const { image } = route.params

  const theme = useSelector(themeSelector)
  const movie = useSelector((state: RootState) => movieItemSelector(state, id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_MOVIE))
  const [daySeances, setDaySeances] = useState<DaySeance | null>(null)

  const loadMovie = () => {
    dispatch(MovieActions.loadMovieItem(id))
  }
  const initSeances = () => {
    if (!movie || !movie.seances || isEmpty(movie.seances)) {
      return
    }
    const s = movie.seances
      .map(seance => ({ day: dateToHuge(seance.dateSeance), seance }))
      .reduce((days: DaySeance, seance) => {
        if (!days[seance.day]) {
          days[seance.day] = []
        }
        days[seance.day].push(seance.seance)
        return days
      }, {})
    setDaySeances(s)
  }
  useEffect(loadMovie, [])
  useEffect(initSeances, [movie])

  if (!movie || isLoading) {
    const images = image ? [image] : []
    return (
      <HeaderScrollView images={images} imageDefault={imageDefault as ImageSourcePropType} title={title || ''} left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
        <Spinner />
      </HeaderScrollView>
    )
  }

  const images = movie.imageBackdrop ? [movie.imageBackdrop] : []

  const primaryColor = theme ? theme.brandPrimary : defaultTheme.colors.blue

  const minAge = movie.minAge ? (
    <Text style={styles.minAge} adjustsFontSizeToFit={defaultTheme.isAndroid}>
      -{movie.minAge}
    </Text>
  ) : undefined

  return (
    <HeaderScrollView
      foregroundImage={movie.image}
      images={images}
      imageDefault={imageDefault as ImageSourcePropType}
      title={movie.libelle}
      left={<LeftRoundButtonBack />}
      leftHeader={<LeftButtonBack />}
      right={minAge}
    >
      <View style={styles.content}>
        <Card spacing style={styles.info}>
          <Text>
            {i18n.t('movie.duration')} <Text style={{ color: primaryColor }}>{movie.duree}</Text>
          </Text>
          <Text>
            {i18n.t('movie.from')} <Text style={{ color: primaryColor }}>{movie.directeur}</Text>
          </Text>
          <Text>
            {i18n.t('movie.with')} <Text style={{ color: primaryColor }}>{movie.acteurs}</Text>
          </Text>
          {movie.categories && (
            <Text>
              {i18n.t('movie.genre')} <Text style={{ color: primaryColor }}>{movie.categories}</Text>
            </Text>
          )}
        </Card>
        <Card spacing>
          <Text>{movie.description}</Text>
        </Card>
        <CardAddress address={movie.address} positionMap={movie.positionMap} />
        {daySeances && !isEmpty(Object.keys(daySeances)) && (
          <Card spacing>
            {Object.keys(daySeances).map(key => (
              <MovieAvailableSeance key={key} day={key} seances={daySeances[key]} />
            ))}
          </Card>
        )}
      </View>
    </HeaderScrollView>
  )
}

const MIN_AGE_SIZE = 30

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: defaultTheme.padding.x2,
  },
  info: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  minAge: {
    width: MIN_AGE_SIZE,
    height: MIN_AGE_SIZE,
    borderRadius: MIN_AGE_SIZE / 2,
    fontWeight: 'bold',
    color: defaultTheme.colors.black,
    backgroundColor: defaultTheme.colors.white,
    fontSize: defaultTheme.textSize.x2,
    lineHeight: MIN_AGE_SIZE,
    textAlign: 'center',
  },
})
