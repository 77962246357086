import { Reducer } from 'redux'
import { CartographyRealTimeActionObjectTypes } from './actions'
import { RealTimeMapState, CartographyRealTimeActionTypes } from './types'

const initialRealTimeMapState: RealTimeMapState = {
  configs: {},
  items: {},
}

export const cartographyRealTimeMapReducer: Reducer<RealTimeMapState, CartographyRealTimeActionObjectTypes> = (state = initialRealTimeMapState, action) => {
  switch (action.type) {
    case CartographyRealTimeActionTypes.STORE_REAL_TIME_MAP:
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.payload.id]: action.payload.config,
        },
      }
    case CartographyRealTimeActionTypes.STORE_REAL_TIME_MAP_ITEMS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.items,
        },
      }
    default:
      return state
  }
}
