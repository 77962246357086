import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Dimensions, StyleProp, ViewStyle } from 'react-native'

const { width: widthWindow } = Dimensions.get('window')

export interface MasonryLastItemProps {
  columnSize: number
  columnHeight?: number
  renderLastItem: () => React.ReactNode
}

export const MasonryLastItem: FunctionComponent<MasonryLastItemProps> = ({ columnSize, columnHeight, renderLastItem }) => {
  const height = columnHeight || widthWindow / columnSize
  const width = `${100 / columnSize}%`

  return <View style={{ width, height } as StyleProp<ViewStyle>}>{renderLastItem()}</View>
}
