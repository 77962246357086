/* eslint-disable prettier/prettier */
import { View } from 'native-base'
import { InterfaceViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../../redux/theme/selector'


export const CardItem: FunctionComponent<InterfaceViewProps> = ({ style, ...props }) => {
  const theme = useSelector(themeSelector)
  return <View style={[styles.content, { backgroundColor: theme?.containerBgColor }, style]} {...props} />
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
  },
})