import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { TaskReportSubmission } from '../../../api/TaskReport/types'
import { theme } from '../../../theme'

interface ReportStatusProps {
  submissions: TaskReportSubmission[] | null
}

const SIZE_TEXT = 15

export const ReportDetailsTab: FunctionComponent<ReportStatusProps> = ({ submissions }) => {
  const renderSubmissions = (submission: TaskReportSubmission, index: number) => (
    <View style={styles.margedRow} key={index}>
      <View style={styles.leftSide}>
        <Text style={styles.text}>{submission.title}</Text>
      </View>
      <View style={styles.alignLeftRightSide}>
        <Text>{submission.value}</Text>
      </View>
    </View>
  )
  return <View style={styles.view}>{submissions ? submissions.map((item, index) => renderSubmissions(item, index)) : null}</View>
}

const styles = StyleSheet.create({
  leftSide: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  alignLeftRightSide: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  margedRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.padding.x2,
  },
  text: {
    marginRight: theme.padding.x1,
    fontSize: SIZE_TEXT,
    color: theme.colors.silverStrong,
  },
  view: {
    marginTop: theme.padding.x2,
  },
})
