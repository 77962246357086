import { theme } from '../../theme'

const DEFAULT_COLOR = theme.colors.silver

export const addOpacity = (color: string, opacity: string): string | undefined => {
  if (!color) {
    return undefined
  }
  return `${color}${opacity}`
}

/**
 * Retourne dans tous les cas une couleur (primaryColor > secondColor > DEFAULT_COLOR)
 * @param primaryColor La couleur qu'on souhaite affiché de base
 * @param secondColor La couleur secondaire si la primaryColor n'est pas renseigné
 */
export const getColor = (primaryColor: string | null, secondColor?: string | null): string => {
  if (primaryColor) {
    return primaryColor
  }

  if (secondColor) {
    return secondColor
  }

  return DEFAULT_COLOR
}
