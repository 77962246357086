import { useCallback, useState } from 'react'
import { Routes } from '../../navigation/Routes'
import { navigate } from '../../services/navigation'
import { userAuthentication } from '../../services/userAuthentication'

export const useResetPasswordVerification = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const doResetPasswordVerification = useCallback(async (token, code, email) => {
    setLoading(true)
    try {
      const res = await userAuthentication.sendResetPasswordVerification(token, code)

      navigate(Routes.ResetPassword, { token: res.token, email, code, isDoubleAuthentication: res.doubleAuthentication })
    } catch (error) {
      console.warn((error as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { doResetPasswordVerification, loading }
}
