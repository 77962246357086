import { Reducer } from 'redux'
import { WeatherActionObjectTypes } from './actions'
import { WeatherState, WeatherActionTypes } from './types'

const initialWeatherState: WeatherState = {
  title: '',
  days: null,
  current: null,
}

export const weatherReducer: Reducer<WeatherState, WeatherActionObjectTypes> = (state = initialWeatherState, action) => {
  switch (action.type) {
    case WeatherActionTypes.STORE_WEATHER_DAYS:
      return {
        ...state,
        title: action.payload.result.title,
        days: action.payload.result.days,
        current: action.payload.result.current,
      }
    default:
      return state
  }
}
