import { Button, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { i18n } from '../../../lib/i18n'
import { RoutesCityAdd } from '../../../navigation/Routes'
import { navigate } from '../../../services/navigation'
import { theme } from '../../../theme'
import { ListEmpty } from '../../ListEmpty'

export const NoCitySelect: FunctionComponent = () => (
  <View style={styles.content}>
    <ListEmpty libelle={i18n.t('myCitys.emptySelectCity')} />
    <View style={styles.noCity}>
      <Button variant="ghost" onPress={() => navigate(RoutesCityAdd.cityAdd)}>
        <Text>{i18n.t('add')}</Text>
      </Button>
    </View>
  </View>
)

const styles = StyleSheet.create({
  content: {
    padding: theme.padding.x2,
  },
  noCity: {
    flex: 0,
    alignItems: 'center',
    flexDirection: 'column',
  },
})
