import { Card, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { i18n } from '../../../lib/i18n'
import { theme } from '../../../theme'
import { CardItem } from '../../NativeBaseLegacy'

interface SignalementDescriptionProps {
  description: string | null
}

export const SignalementDescription: FunctionComponent<SignalementDescriptionProps> = ({ description }) => {
  if (!description) {
    return null
  }

  return (
    <Card p="0" m="1">
      <CardItem p="1" style={styles.content}>
        <Text style={styles.title}>{i18n.t('signalement.comment')} : </Text>
        <Text>{description}</Text>
      </CardItem>
    </Card>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: theme.padding.x1,
  },
})
