import { ScrollView, Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import RNBootSplash from 'react-native-bootsplash'
import { useDispatch, useSelector } from 'react-redux'
import { GridPageItemResult } from '../../api/GridPage/types'
import { apiTokenSelector } from '../../redux/authentication/selectors'
import { GridPageActions } from '../../redux/gridPage/actions'
import { gridPageSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'
import { FavUserList } from '../FavUserList/favUserList.component'
import { GridPageActualites } from '../GridPageActualites'
import { GridPageAirQuality } from '../GridPageAirQuality'
import { GridPageCarousel } from '../GridPageCarousel'
import { GridPageGenericItem } from '../GridPageGenericItem'
import { GridPageRssFeed } from '../GridPageRssFeed/GridPageRssFeed.component'
import { GridPageTide } from '../GridPageTide'
import { GridPageWeather } from '../GridPageWeather'

const mapItemPosition = (item: GridPageItemResult) => ({
  top: item.position.calcTop,
  left: item.position.calcLeft,
  width: item.position.calcWidth,
  height: item.position.calcHeight,
})

const mapItemProp = (item: GridPageItemResult) => ({
  libelle: item.libelle,
  description: item.description,
  style: item.style,
  action: item.action,
  ...mapItemPosition(item),
})

const mapCarouselProp = (item: GridPageItemResult) => ({
  id: (item.component && item.component.id) || 0,
  ...mapItemPosition(item),
})

const mapWeatherProp = (item: GridPageItemResult) => ({
  id: (item.component && item.component.id) || 0,
  ...mapItemPosition(item),
})
const mapRssProp = (item: GridPageItemResult) => ({
  id: (item.component && item.component.id) || 0,
  ...mapItemPosition(item),
})
const mapAirQualityProp = (item: GridPageItemResult) => ({
  id: (item.component && item.component.id) || 0,
  ...mapItemPosition(item),
})
const mapTideProp = (item: GridPageItemResult) => ({
  id: (item.component && item.component.id) || 0,
  ...mapItemPosition(item),
})

const mapItemView = (item: GridPageItemResult) => {
  if (!item.component) {
    return <GridPageGenericItem key={item.id} {...mapItemProp(item)} />
  }
  switch (item.component.name) {
    case 'carrousel':
      return <GridPageCarousel key={item.id} {...mapCarouselProp(item)} />
    case 'meteo':
      return <GridPageWeather key={item.id} {...mapWeatherProp(item)} />
    case 'rss':
      return <GridPageRssFeed key={item.id} {...mapRssProp(item)} />
    case 'airquality':
      return <GridPageAirQuality key={item.id} {...mapAirQualityProp(item)} />
    case 'tide-schedules':
      return <GridPageTide key={item.id} {...mapTideProp(item)} />
    default:
      return null
  }
}

interface GridPageListProps {
  id?: number
  isFavoriesEnabled?: boolean
}

const GridPageList: FunctionComponent<GridPageListProps> = ({ id, isFavoriesEnabled }) => {
  const [dragging, setDragging] = useState(false)
  const dispatch = useDispatch()
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const gridPage = useSelector((state: RootState) => gridPageSelector(state, id || 0))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_PAGE))

  useEffect(() => {
    if (authtoken) {
      dispatch(GridPageActions.loadGridPage(theme.deviceWidth, id))
      RNBootSplash.hide()
    }
  }, [id, authtoken])

  if (!gridPage && isLoading) {
    return <Spinner />
  }

  if (!gridPage) {
    return null
  }

  const backgroundColor = (gridPage.bg && gridPage.bg.color) || theme.colors.transparent

  const renderFavUserList = () => {
    if (isFavoriesEnabled) {
      return <FavUserList onEndDragging={(value: boolean) => setDragging(value)} onStartDragging={(value: boolean) => setDragging(value)} />
    }
  }

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <SafeAreaView style={styles.container}>
        <ScrollView scrollEnabled={!dragging}>
          <View style={styles.view}>
            <View style={{ height: gridPage.height }}>{gridPage.items.map((item: GridPageItemResult) => mapItemView(item))}</View>
            {renderFavUserList()}
            {gridPage.newsId && <GridPageActualites id={gridPage.newsId} />}
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  view: {
    paddingBottom: 21,
  },
})

export { GridPageList }
