import { Reducer } from 'redux'
import { IntermediatePageActionTypes, IntermediatePageState } from './types'
import { IntermediatePageActionObjectTypes } from './actions'

const initialState: IntermediatePageState = {}

export const intermediatePageReducer: Reducer<IntermediatePageState, IntermediatePageActionObjectTypes> = (state = initialState, action) => {
  switch (action.type) {
    case IntermediatePageActionTypes.STORE_INTERMEDIATE_PAGE:
      return {
        ...state,
        [action.payload.id]: action.payload.intermediatePage,
      }
    default:
      return state
  }
}
