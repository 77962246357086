import React, { FunctionComponent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FlatList } from 'react-native'
import { Spinner } from 'native-base'
import { CityItemEdit } from '../CityItemEdit/CityItemEdit.component'
import { Separator } from '../Separator'
import { citysSelectSelector } from '../../redux/city/selector'
import { NoCitySelect } from './components/NoCitysSelect.component'
import { CityItemResult } from '../../api/City/types'
import { RootState } from '../../redux/reducers'
import { LoaderName } from '../../redux/loading/types'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { CityActions } from '../../redux/city/action'

export const CitysSelect: FunctionComponent = () => {
  const citys = useSelector(citysSelectSelector)
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_CITYS_USER))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(CityActions.loadCitysUser())
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  const citysOrders: CityItemResult[] = Object.values(citys).sort((a, b) => {
    if (a.title > b.title) {
      return 1
    }
    if (a.title < b.title) {
      return -1
    }
    return 0
  })

  return (
    <FlatList
      data={citysOrders}
      renderItem={({ item, index }) => <CityItemEdit key={index} cityItem={item} />}
      ItemSeparatorComponent={() => <Separator paddingVertical={false} />}
      keyExtractor={(item, index) => `message ${index}`}
      ListEmptyComponent={() => <NoCitySelect />}
      ListFooterComponent={() => <Separator paddingVertical={false} />}
    />
  )
}
