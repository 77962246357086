import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { HomeActionTypes } from './types'
import { HomeConfigResult, PromotionResult } from '../../api/Home'

export const HomeActions = {
  loadHomeConfig: () => createAction(HomeActionTypes.LOAD_HOME_CONFIG),
  storeHomeConfig: (homeConfig: HomeConfigResult) => createAction(HomeActionTypes.STORE_HOME_CONFIG, { homeConfig }),
  storePromotion: (promotion: PromotionResult) => createAction(HomeActionTypes.STORE_PROMOTION, { promotion }),
  fetchDisplayedPromotion: (promotionId: number) => createAction(HomeActionTypes.FETCH_DISPLAYED_PROMOTION, { promotionId }),
  displayedPromotion: (promotionId: number) => createAction(HomeActionTypes.DISPLAYED_PROMOTION, { promotionId }),
  clearPromotion: (promotionId: number) => createAction(HomeActionTypes.CLEAR_PROMOTION, { promotionId }),
}

export type HomeActionObjectTypes = ActionsUnion<typeof HomeActions>
