export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: 'a1d99b88-93f1-4d35-a460-bd21eb6bd6de',
  ENV: 'breuillet',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 31,
}
