import { StackScreenProps } from '@react-navigation/stack'
import { Container, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage, { Source } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import emailSent from '../../assets/images/email_sent.png'
import { Button } from '../../components/Button'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76
const IMAGE_HEIGHT = 160

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x3,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  image: {
    height: IMAGE_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x4,
  },
  description: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x2,
  },
  email: {
    color: theme.colors.lightBlue,
    fontWeight: 'bold',
  },
  container: {
    maxWidth: '100%',
  },
})

export const EmailVerificationSent: FunctionComponent<StackScreenProps<ActionViewParamsType, 'EmailVerificationSent'>> = ({ route }) => {
  const menu = useSelector(menuSelector)

  const logoUri = menu && menu.header && menu.header.image

  return (
    <Container safeAreaBottom background={theme.colors.white} style={styles.container}>
      <View style={styles.content}>
        {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
        <Text style={styles.title}>{i18n.t('emailVerificationSent.title')}</Text>
        <Text style={styles.subtitle}>{i18n.t('emailVerificationSent.subtitle')}</Text>
        <FastImage style={styles.image} source={emailSent as Source} resizeMode={FastImage.resizeMode.contain} />
        <Text style={styles.description}>{i18n.t('emailVerificationSent.description')}</Text>
        <Text style={styles.email}>{route.params && route.params.email}</Text>
        <Button style={{ marginTop: theme.padding.x3 }} onPress={() => navigate(Routes.HomeTabs)} label={i18n.t('emailVerificationSent.back')} />
      </View>
    </Container>
  )
}
