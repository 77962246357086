import { SignalementsResult, SignalementItemResult } from '../../api/Signalement/types'

export type SignalementsState = {
  paging: SignalementsResult
  items: {
    [key: number]: SignalementItemResult
  }
}

export enum SignalementsActionTypes {
  LOAD_SIGNALEMENTS = 'LOAD_SIGNALEMENTS',
  STORE_CLEAR_SIGNALEMENTS = 'STORE_CLEAR_SIGNALEMENTS',
  STORE_SIGNALEMENTS = 'STORE_SIGNALEMENTS',
  REFRESH_SIGNALEMENTS = 'REFRESH_SIGNALEMENTS',
  LOAD_SIGNALEMENT_ITEM = 'LOAD_SIGNALEMENT_ITEM',
  STORE_SIGNALEMENT_ITEM = 'STORE_SIGNALEMENT_ITEM',
}
