import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, ViewProps } from 'react-native'

export const Right: FunctionComponent<ViewProps> = ({ style, ...props }) => <View style={[styles.content, style]} {...props} />

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
  },
})
