import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'

import { MovieApi } from '../../api/Movie'
import { MovieItemResult, MovieListResult } from '../../api/Movie/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { MovieActionObjectTypes, MovieActions } from './actions'
import { moviePagingListSelector } from './selector'
import { MovieActionTypes } from './types'

const PAGE_SIZE = 40

function* loadMovieItemSaga(action: ActionsOfType<MovieActionObjectTypes, MovieActionTypes.LOAD_MOVIE_ITEM>) {
  try {
    const item: MovieItemResult = yield call(MovieApi.loadMovieItem, action.payload)
    yield put(MovieActions.storeMovieItem(action.payload, item))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadMovieListSaga(action: ActionsOfType<MovieActionObjectTypes, MovieActionTypes.LOAD_MOVIE_LIST>) {
  const paging: PagingResult<MovieListResult> = yield select(moviePagingListSelector, action.payload.id)
  let page = 0

  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const result: MovieListResult = yield call(MovieApi.loadMovieList, action.payload.id, action.payload.inTheater, page, PAGE_SIZE)
    if (action.payload.clear) {
      yield put(MovieActions.storeClearMovieList(action.payload.id, result))
    } else {
      yield put(MovieActions.storeMovieList(action.payload.id, result))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* refreshMovieListSaga(action: ActionsOfType<MovieActionObjectTypes, MovieActionTypes.REFRESH_MOVIE_LIST>) {
  yield call(loadMovieListSaga, MovieActions.loadMovieList(true, action.payload.id, action.payload.inTheater))
}

export function* loadMovieItemWatcher() {
  yield takeEvery(MovieActionTypes.LOAD_MOVIE_ITEM, addLoader(loadMovieItemSaga, LoaderName.LOAD_MOVIE))
}

export function* loadMovieListWatcher() {
  yield takeEvery(MovieActionTypes.LOAD_MOVIE_LIST, addLoader(loadMovieListSaga, LoaderName.LOAD_MOVIE_LIST))
}

export function* refreshMovieListWatcher() {
  yield takeEvery(MovieActionTypes.REFRESH_MOVIE_LIST, addLoader(refreshMovieListSaga, LoaderName.REFRESH_MOVIE_LIST))
}
