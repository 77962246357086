import { Dimensions, Platform } from 'react-native'

const BASE_PADDING = 8

const BASE_ICON_SIZE = 17

const BASE_TEXTE_SIZE = 8

const { height, width } = Dimensions.get('window')

const isIOS = Platform.OS === 'ios'
const ARROW_UP = isIOS ? 'chevron-up' : 'chevron-up-circle-outline'
const ARROW_DOWN = isIOS ? 'chevron-down' : 'chevron-down-circle-outline'
const ARROW_LEFT = 'arrow-back-circle-outline'
const ARROW_RIGHT = 'arrow-forward-circle-outline'
const ICON_SIZE = 50

const MARKER_SIZE = 32
const SELECTED_MARKER_SIZE_RATIO = 1.35

interface Theme {
  isIOS: boolean
  isAndroid: boolean
  // fontFamily: string
  header: {
    titleSize: number
  }
  padding: {
    x05: number
    x1: number
    x2: number
    x3: number
    x4: number
    x6: number
  }
  textSize: {
    x1: number
    x1_25: number
    x1_75: number
    x1_50: number
    x2: number
    x2_50: number
    x3: number
    x4: number
  }
  iconSize: {
    x1: number
    x2: number
    x3: number
  }
  toolbarBackgroundColor: string
  toolbarBtnColor: string
  colors: {
    black: string
    white: string
    silver: string
    silverStrong: string
    transparent: string
    orange: string
    blue: string
    lightBlue: string
    strongBlue: string
    facebook: string
    deepSkyBlue: string
    lightSkyBlue: string
    red: string
    darkRed: string
    indianRed: string
    darkPink: string
    lightPink: string
    lightBrown: string
    mediumBrown: string
    darkBrown: string
    green: string
    originalRed: string
    lightGray: string
    darkGrayishBlue: string
    azure: string
    azureLight: string
    paleGrey: string
  }
  topSafeArea: number
  opacity: string
  deviceHeight: number
  deviceWidth: number
  referentielImageMaxHeight: number
  menu: {
    header: {
      fontWeight: '400' | '500'
      imageHeight: number
    }
  }
  icon: {
    width: number
    height: number
    arrowUp: string
    arrowDown: string
    arrowLeft: string
    arrowRight: string
  }
  markerImage: {
    height: number
    width: number
  }
  selectedMarkerImage: {
    height: number
    width: number
  }
  bubble: {
    size: number
    color: string
  }
  favorites: {
    backgroundColor: string
    widget: { imageSize: number; borderRadius: number; borderInfo: number }
    widgetAdd: { borderColor: string }
  }
  tide: {
    primary: string
    secondary: string
  }
}

export const theme: Theme = {
  isIOS,
  isAndroid: !isIOS,
  header: {
    titleSize: 20,
  },
  // fontFamily: 'Bariol-Regular',
  padding: {
    x05: 0.5 * BASE_PADDING,
    x1: BASE_PADDING,
    x2: 2 * BASE_PADDING,
    x3: 3 * BASE_PADDING,
    x4: 4 * BASE_PADDING,
    x6: 6 * BASE_PADDING,
  },
  textSize: {
    x1: BASE_TEXTE_SIZE,
    x1_25: 1.25 * BASE_TEXTE_SIZE,
    x1_75: 1.75 * BASE_TEXTE_SIZE,
    x1_50: 1.5 * BASE_TEXTE_SIZE,
    x2: 2 * BASE_TEXTE_SIZE,
    x2_50: 2.5 * BASE_TEXTE_SIZE,
    x3: 3 * BASE_TEXTE_SIZE,
    x4: 4 * BASE_TEXTE_SIZE,
  },

  iconSize: {
    x1: BASE_ICON_SIZE,
    x2: 2 * BASE_ICON_SIZE,
    x3: 3 * BASE_ICON_SIZE,
  },

  toolbarBackgroundColor: '#ffffff',
  toolbarBtnColor: '#000000',
  colors: {
    black: '#000000',
    white: '#ffffff',
    silver: '#b3b3b3',
    silverStrong: '#8F8F8F',
    transparent: '#ffffff00',
    orange: '#f4ba3f',
    blue: '#285ea3',
    lightBlue: '#2792F0',
    strongBlue: '#0069cc',
    facebook: '#1B77F2',
    lightSkyBlue: 'rgba(0, 176, 255, 0.05)',
    deepSkyBlue: '#00b2ff',
    red: '#aa0000',
    darkRed: '#8e433f',
    indianRed: '#a35f5c',
    originalRed: '#d94e4e',
    lightPink: '#fdf7f6',
    darkPink: '#d9b6b4',
    lightBrown: '#FDFAF4',
    mediumBrown: '#C7BA9F',
    darkBrown: '#897A5F',
    green: '#58B893',
    lightGray: '#f2f2f2',
    darkGrayishBlue: '#96a0ab',
    azure: '#2592f0',
    azureLight: 'rgba(37, 146, 240, 0.05)',
    paleGrey: '#f5f6fa',
  },
  topSafeArea: isIOS ? height / 15 : height / 18,
  opacity: '4D',
  deviceHeight: height,
  deviceWidth: width,
  referentielImageMaxHeight: height / 2.7,
  menu: {
    header: {
      imageHeight: 100,
      fontWeight: isIOS ? '500' : '400',
    },
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    arrowUp: ARROW_UP,
    arrowDown: ARROW_DOWN,
    arrowLeft: ARROW_LEFT,
    arrowRight: ARROW_RIGHT,
  },
  markerImage: {
    height: MARKER_SIZE,
    width: MARKER_SIZE,
  },
  selectedMarkerImage: {
    height: MARKER_SIZE * SELECTED_MARKER_SIZE_RATIO,
    width: MARKER_SIZE * SELECTED_MARKER_SIZE_RATIO,
  },
  bubble: {
    size: 40,
    color: '#5c97bf',
  },
  favorites: {
    backgroundColor: '#ebf2f6',
    widget: { imageSize: 30, borderRadius: 10, borderInfo: 4 },
    widgetAdd: { borderColor: '#6897be' },
  },
  tide: {
    primary: '#121C5A',
    secondary: '#299586',
  },
}
