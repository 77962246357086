import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from 'redux-saga/effects'
import { TideApi } from '../../api/Tide/TideApi'
import { TideDetails } from '../../api/Tide/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { TideAction, TideActionObjectTypes } from './actions'
import { TideActionTypes } from './types'

function* loadTideDetailsSaga(action: ActionsOfType<TideActionObjectTypes, TideActionTypes.LOAD_TIDE_DETAILS>) {
  try {
    const tideResult: TideDetails = yield call(TideApi.loadTideDetails, action.payload.id)
    yield put(TideAction.storeTideDetails(tideResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadTideMapWatcher() {
  yield takeEvery(TideActionTypes.LOAD_TIDE_DETAILS, addLoader(loadTideDetailsSaga, LoaderName.LOAD_TIDE_DETAILS))
}
