import isEmpty from 'lodash/isEmpty'
import { Spinner, View } from 'native-base'
import React, { PropsWithChildren } from 'react'
import { FlatList, ListRenderItem, RefreshControl, StyleSheet, ViewStyle } from 'react-native'
import { ListEmpty } from '../ListEmpty'

interface FlatListPagingProps<T> {
  data: T[]
  flatListStyle?: ViewStyle
  itemStyle?: ViewStyle
  isLoadingPage: boolean
  isLoadingMore: () => boolean
  isRefreshing: boolean
  onRefresh: () => void
  onEndReached: () => void
  renderItem: ListRenderItem<T>
  keyExtractor: (item: T, index: number) => string
  libelleEmptyList?: string
  itemSeparatorComponent?: React.ComponentType | null
  numColumns?: number
  columnWrapperStyle?: ViewStyle
  keyFlatlist?: string
}

export function FlatListPaging<T>({
  data,
  isLoadingPage,
  isLoadingMore,
  isRefreshing,
  onRefresh,
  onEndReached,
  renderItem,
  itemStyle,
  flatListStyle,
  keyExtractor,
  libelleEmptyList,
  itemSeparatorComponent,
  numColumns,
  columnWrapperStyle,
  keyFlatlist,
}: PropsWithChildren<FlatListPagingProps<T>>) {
  const renderFooter = (loadingMore: boolean) => {
    if (loadingMore) {
      return <Spinner />
    }
    return itemSeparatorComponent
  }

  const getStylesFlatList = () => {
    if (flatListStyle) {
      return [styles.flex, flatListStyle]
    }
    return styles.flex
  }

  const renderList = () => (
    <FlatList
      key={keyFlatlist}
      data={data}
      style={getStylesFlatList()}
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
      keyExtractor={keyExtractor}
      ListEmptyComponent={<ListEmpty libelle={libelleEmptyList} />}
      renderItem={item => (itemStyle ? <View style={itemStyle}>{renderItem(item)}</View> : renderItem(item))}
      ListFooterComponent={renderFooter(isLoadingMore())}
      refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
      ItemSeparatorComponent={itemSeparatorComponent}
      numColumns={numColumns}
      columnWrapperStyle={columnWrapperStyle}
    />
  )

  if (isLoadingPage) {
    if (isEmpty(data)) {
      return <Spinner />
    }
    return (
      <View style={styles.flex}>
        <Spinner />
        {renderList()}
      </View>
    )
  }

  return renderList()
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
})
