import { AddressValue } from '../../components/Fields/types'

interface FormBaseElement {
  id: number
  libelle: string | null
  code: string | null
  ordre: number
}

export const DecoTypes = ['deco_label', 'deco_image', 'deco_html', 'deco_linebreak', 'deco_space']

export interface FormDeco extends FormBaseElement {
  type: 'deco_label' | 'deco_image' | 'deco_html'
  image?: string | null
  content?: string
}

export interface FormDecoSpace extends FormBaseElement {
  type: 'deco_linebreak' | 'deco_space'
}

export interface FormText extends FormBaseElement {
  type: 'field_texte' | 'field_email' | 'field_phone'
  constraint: {
    maxSize?: number | null
    mandatory: boolean | null
    height?: number | null
  }
}

export interface FormBase extends FormBaseElement {
  type: 'field_date' | 'field_secteur' | 'field_secteurjour'
  constraint: {
    mandatory?: boolean
  }
}

export interface FormSelect extends FormBaseElement {
  type: 'field_select' | 'field_checkboxes' | 'field_radio'
  constraint: {
    mandatory?: boolean | null
  }
  items: Array<{
    id?: number
    libelle: string
    ordre?: number
  }>
}

export interface FormSingleCheckbox extends FormBaseElement {
  type: 'field_checkbox'
  constraint: {
    mandatory?: boolean
    checkDefaut?: boolean
  }
}

export interface FormAddress extends FormBaseElement {
  type: 'field_adresse'
  constraint: {
    mandatory: boolean
    maxSize?: number
    height?: number
    detailAddress?: boolean | null
    verifAddress?: boolean | null
  }
}

export interface FormImage extends FormBaseElement {
  type: 'field_images'
  constraint: {
    mandatory: boolean
    nbImage?: number
  }
}

export interface FormCategory extends FormBaseElement {
  type: 'field_souscategorie'
  constraint: {
    mandatory: boolean
  }
}

export type FormElement = FormDeco | FormDecoSpace | FormText | FormBase | FormSelect | FormSingleCheckbox | FormAddress | FormImage | FormCategory

export type FormFieldElement = FormText | FormBase | FormSelect | FormSingleCheckbox | FormAddress | FormImage | FormCategory

export interface FormModel {
  id: number
  libelle: string
  elements: FormElement[]
  content: {
    titre: string
    description: string
    buttomText: string
  }
  rgpdMessage: string
}

export interface FieldValueRequest {
  id: number
  type: string
  value: FieldValue
}

export type FieldValue = string | number | boolean | string[] | number[] | AddressValue | null

export interface FormDefaultValue {
  type: string
  id: number
  value: FieldValue
}

export interface FormResult {
  dateStart: number
  formModel: FormModel
  defaultValues: FormDefaultValue[]
}

export interface FormSubmit {
  id?: number
  reponses: FormResponseSubmit[]
  rgpd: true
}

export interface FormResponseSubmit {
  id: number
  value: FieldValue
  type: string
}

export interface FormErrorResponseResult {
  id: number
  message: string
}

export interface FormResponseResult {
  errors: FormErrorResponseResult[]
  message: string
  success: boolean
}
