import { RootState } from '../reducers'

export const homeFootersSelector = (state: RootState) => state.home.footers

export const homeComponentNameSelector = (state: RootState) => state.home.homeComponentName

export const homeTitleSelector = (state: RootState) => state.home.title
export const homeTitleImageSelector = (state: RootState) => state.home.titleImage
export const promotionByIdSelector = (state: RootState, id: string) => (state.home.promotions && state.home.promotions[id]) || null
export const promotionSelector = (state: RootState) =>
  (state.home.promotions && [...Object.values(state.home.promotions).filter(promotion => !promotion.displayed)].shift()) || null
export const displayedPromotionsSelector = (state: RootState) => (state.home.promotions && Object.values(state.home.promotions).filter(promotion => promotion.displayed)) || []

export const homeCguSelector = (state: RootState) => state.home.cgu
