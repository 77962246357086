import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'
import { ItemAction } from '../../api/types'
import { getItemAction } from '../../services/itemAction'

interface Props {
  action?: ItemAction
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}
export const TouchableAction: FunctionComponent<Props> = ({ action, style, children }) => {
  if (action) {
    return (
      <TouchableOpacity style={style} onPress={getItemAction(action)}>
        {children}
      </TouchableOpacity>
    )
  }
  return <View style={style}>{children}</View>
}
