import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AuthenticationApi } from '../../api/Authentication/AuthenticationApi'
import { Routes } from '../../navigation/Routes'
import { apiTokenSelector, isConnectedSelector } from '../../redux/authentication/selectors'
import { GridPageActions } from '../../redux/gridPage/actions'
import { MenuActions } from '../../redux/menu/actions'
import { theme } from '../../theme'
import { navigate } from '../navigation'

export const ERROR_CODES = {
  GOOGLE_USER_CANCELED: '-5',
  APPLE_USER_CANCELED: '1001',
}

export const userAuthentication = {
  signUp: async (email: string) => AuthenticationApi.signUp(email),
  signIn: async (email: string, password: string) => AuthenticationApi.signInUser({ uuid: email, password }),
  signInWith2fa: async (email: string, password: string, code: string) => AuthenticationApi.signInWith2fa({ uuid: email, password, code }),
  resetPassword: async (password: string, newPassword: string) => AuthenticationApi.resetPassword({ password, newPassword }),
  sendResetPassword: async (token: string, password: string) => AuthenticationApi.sendResetPassword({ token, password }),
  sendResetPasswordSendEmail: async (email: string) => AuthenticationApi.sendResetPasswordSendEmail({ email }),
  sendResetPasswordVerification: async (token: string, code: string) => AuthenticationApi.sendResetPasswordVerification({ token, code }),
}

export const useUserAuthentication = () => {
  const [initializing, setInitializing] = useState(true)
  const apiToken = useSelector(apiTokenSelector)
  const isConnected = useSelector(isConnectedSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (initializing) setInitializing(false)
    if (apiToken && apiToken.accessToken) {
      dispatch(GridPageActions.loadGridPage(theme.deviceWidth))
      dispatch(MenuActions.loadMenuConfig())
    }
  }, [apiToken])

  const signOut = () => {
    setInitializing(true)
    navigate(Routes.SignOut)
  }

  return { initializing, isConnected, signOut }
}
