import { FavoriteConfig } from '../../api/Favorites/types'

export type FavoritesConfigState = {
  favoritesConfig: FavoriteConfig
}

export enum FavoritesConfigActionTypes {
  LOAD_FAVORITES_CONFIG = 'LOAD_FAVORITES_CONFIG',
  STORE_FAVORITES_CONFIG = 'STORE_FAVORITES_CONFIG',
}
