/* eslint-disable prettier/prettier */
import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Button, Icon, Spinner } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import {
  FlatList,
  FlexStyle, RefreshControl, SafeAreaView, StyleSheet, ViewStyle,
} from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { ItemDefault } from '../../components/ItemDefault'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, Routes } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { SignalementsActions } from '../../redux/signalements/action'
import { lastPageSelector, signalementsSelector } from '../../redux/signalements/selector'
import { themeSelector } from '../../redux/theme/selector'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { getItemAction } from '../../services/itemAction'

export const SignalementList: FunctionComponent<
  StackScreenProps<ActionViewParamsType, 'SignalementList'>
> = ({ route }) => {
  const title = route.params.titre
  const theme = useSelector(themeSelector)

  const dispatch = useDispatch()
  const signalementsResult = useSelector(signalementsSelector)
  const isLoadingSignalements = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.LOAD_SIGNALEMENTS),
  )
  const isRefreshing = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.REFRESH_SIGNALEMENTS),
  )
  const isLastPage = useSelector(lastPageSelector)

  useEffect(() => {
    dispatch(SignalementsActions.loadSignalements(true))
  }, [])

  const isLoadingPage = () => isEmpty(signalementsResult) && isLoadingSignalements && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoadingSignalements && isLoadingMore()

  const onRefresh = () => {
    dispatch(SignalementsActions.refreshSignalements())
  }

  const onEndReached = () =>
    canLoadMore() ? dispatch(SignalementsActions.loadSignalements(false)) : null

  const renderFooter = () => {
    if (isLoadingMore()) {
      return <Spinner />
    }
    return null
  }

  return (
    <Page
      left={<LeftButtonBack />}
      title={title}
      right={
        <Button
          variant="ghost"
          onPress={getItemAction({
            type: 'view',
            viewName: Routes.Signalement,
            params: { titre: 'Signalement' },
          })}
        >
          <Icon as={Ionicons} name="add-circle-outline" color={theme?.titleFontColor} size={6} />
        </Button>
      }
    >
      <SafeAreaView style={styles.safeArea}>
        {isLoadingPage() ? (
          <Spinner />
        ) : (
          <FlatList
            data={signalementsResult}
            style={styles.flatlist}
            onEndReachedThreshold={0.5}
            onEndReached={onEndReached}
            keyExtractor={item => item.id.toString()}
            ListEmptyComponent={<ListEmpty />}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderItem={({ item }: { item : any}) => (
              <ItemDefault
                title={item.sousCategorie || item.libelle || i18n.t('signalement.yourReport')}
                subTitle={toDDMMYYYYatHHMM(item.dateDemande)}
                address={item.adresse}
                image={item.image}
                tagText={item.etat && item.etat.libelle}
                tagColor={item.etat && item.etat.color}
                barColor={item.etat && item.etat.color}
                action={{
                  type: 'view',
                  viewName: Routes.SignalementItem,
                  params: { titre: item.sousCategorie, id: item.id, image: item.image },
                }}
              />
            )}
            ListFooterComponent={renderFooter}
            refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
          />
        )}
      </SafeAreaView>
    </Page>
  )
}

interface Style {
  flatlist: FlexStyle
  safeArea: ViewStyle
}

const styles = StyleSheet.create<Style>({
  flatlist: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
})
