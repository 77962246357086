import { UrgentNumbersResult } from '../../api/UrgentNumbers/types'

export type UrgentNumbersState = {
  paging: UrgentNumbersResult
}

export enum UrgentNumbersActionTypes {
  LOAD_URGENTNUMBERS = 'LOAD_URGENTNUMBERS',
  STORE_URGENTNUMBERS = 'STORE_URGENTNUMBERS',
  CLEAR_URGENTNUMBERS = 'CLEAR_URGENTNUMBERS',
  REFRESH_URGENTNUMBERS = 'REFRESH_URGENTNUMBERS',
}
