import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from '../../navigation/Routes'
import { apiTokenSelector } from '../../redux/authentication/selectors'
import { GridPageActions } from '../../redux/gridPage/actions'
import { tideConfigSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { navigate } from '../../services/navigation'
import { getColor } from '../../services/theme'
import { WeatherItem } from '../GridPageWeather/components/WeatherItem.component'
import { TideDay } from './components/TideDay.component'

interface GridPageTideProps {
  id: number
  top: number
  left: number
  width: number
  height: number
}

export const GridPageTide: FunctionComponent<GridPageTideProps> = props => {
  const styleT = styles(props)

  const gridTideConfig = useSelector((state: RootState) => tideConfigSelector(state, props.id))
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const theme = useSelector(themeSelector)

  const isLoadingConfig = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_TIDE))

  const dispatch = useDispatch()
  useEffect(() => {
    if (authtoken) dispatch(GridPageActions.loadGridTideConfig(props.id))
  }, [props.id, authtoken])

  if (isLoadingConfig) {
    return (
      <View style={styleT.container}>
        <Spinner />
      </View>
    )
  }
  if (!gridTideConfig || !gridTideConfig.weatherHorairesMareesPort) {
    return (
      <View style={styleT.container}>
        <View style={styleT.containerView} />
      </View>
    )
  }

  const color = getColor(gridTideConfig.textColor, theme && theme.toolbarDefaultBg)

  const { weather } = gridTideConfig.weatherHorairesMareesPort

  return (
    <View style={styleT.container}>
      <TouchableOpacity
        style={styleT.containerView}
        onPress={() =>
          navigate(Routes.Weather, {
            id: props.id,
          })
        }
      >
        <TideDay tideConfig={gridTideConfig} color={color} />
        <WeatherItem temperature={weather.temperature} icon={weather.weather ? weather.weather : null} width={props.width} light color={color} />
      </TouchableOpacity>
    </View>
  )
}

const styles = (props: GridPageTideProps) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    container: {
      position: 'absolute',
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    },
    containerView: {
      flex: 1,
      flexDirection: 'row',
    },
  })
