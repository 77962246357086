/* eslint react-native/no-unused-styles: 0 */
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'

import { GridPageItemStyle } from '../../api/GridPage/types'
import { ItemAction } from '../../api/types'
import { theme } from '../../theme'
import { FastImageBackground } from '../FastImageBackground'
import { TouchableAction } from '../TouchableAction'

interface GenericItemProps {
  top: number
  left: number
  width: number
  height: number
  style: GridPageItemStyle
  libelle: string | null
  description?: string | null
  action?: ItemAction
  children?: React.ReactNode
}

const touchStyle = (bgColor: string) =>
  StyleSheet.create({
    touch: {
      backgroundColor: bgColor,
    },
  })

const renderText = (colorText: string, text?: string | null) => (text ? <Text style={[styles.text, { color: colorText }]}>{text}</Text> : null)

const renderViewText = (props: GenericItemProps) => {
  if (!props.libelle && !props.description) {
    return
  }
  const colorText = (props.style && props.style.textColor) || theme.colors.white

  return (
    <View style={styles.viewText}>
      {props.style.icon && <FastImage source={{ uri: props.style.icon }} style={styles.icon} resizeMode={FastImage.resizeMode.contain} />}
      {renderText(colorText, props.libelle)}
      {renderText(colorText, props.description)}
    </View>
  )
}

const renderItem = (props: GenericItemProps) => {
  if (props.style.image) {
    return (
      <FastImageBackground source={{ uri: props.style.image }} style={styles.image} resizeMode="cover">
        {renderViewText(props)}
      </FastImageBackground>
    )
  }
  return renderViewText(props)
}

export const GridPageGenericItem: FunctionComponent<GenericItemProps> = props => (
  <View style={[styles.view, itemStyle(props.top, props.left, props.width, props.height).item]}>
    <TouchableAction action={props.action} style={[styles.touch, touchStyle(props.style.backgroundColor).touch]}>
      {renderItem(props)}
    </TouchableAction>
  </View>
)

const styles = StyleSheet.create({
  touch: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  view: {
    position: 'absolute',
  },
  text: {
    textAlign: 'center',
    color: theme.colors.white,
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  viewText: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: theme.padding.x1,
  },

  icon: {
    width: theme.icon.width,
    height: theme.icon.height,
  },
})

const itemStyle = (top: number, left: number, width: number, height: number) =>
  StyleSheet.create({
    item: {
      top,
      left,
      width,
      height,
      overflow: 'hidden',
    },
  })
