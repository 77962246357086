import { BaseApi } from '../BaseApi'
import { CartographyZoneResult } from './type'
import { urlApi } from '../url'

export class CartographyZoneApi {
  public static async loadZones(zoneId: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.cartographyZones.submit(zoneId))
      .get()
      .json(json => json) as Promise<CartographyZoneResult>
  }
}
