import { View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { DraggableGrid } from 'react-native-draggable-grid'
import { useDispatch, useSelector } from 'react-redux'
import { CatalogUserType, FavorieUserItem, FavoriteFooter, FavoriteHeader } from '../../api/Favorites/types'
import { ItemAction } from '../../api/types'
import { Routes } from '../../navigation/Routes'
import { FavoritesUsersActions } from '../../redux/favoritesUser/actions'
import { favoritesUserSelectorNew } from '../../redux/favoritesUser/selector'
import { getItemAction } from '../../services/itemAction'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'
import { FavItemUser } from '../FavItemUser'
import { FavoriteButtonConfig } from './components/FavoriteConfigButton'
import { FavoriteDragView } from './components/FavoriteDragView'
import { RootState } from '../../redux/reducers'
import { apiTokenSelector } from '../../redux/authentication/selectors'

interface DataFormatted {
  id: number
  key: string
  title: string
  header: FavoriteHeader | null
  image: string
  footer: FavoriteFooter | null
  type: CatalogUserType
  action: ItemAction | null
  disabledReSorted?: boolean
  disabledDrag?: boolean
  schedule: number | null
}

interface FavUserListProps {
  onStartDragging: (bool: boolean) => void
  onEndDragging: (bool: boolean) => void
}
const MARGIN_CONTENT = 7
const ITEM_SIZE = Math.floor((theme.deviceWidth - MARGIN_CONTENT * 2 - 10) / 3) - 2

export const FavUserList: FunctionComponent<FavUserListProps> = ({ onStartDragging, onEndDragging }) => {
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const [editMode, setEditMode] = useState<boolean>(false)
  const [firstUpdate, setFirstUpdate] = useState<boolean>(false)
  const [pushIds, setPushIds] = useState<boolean>(false)
  const dispatch = useDispatch()
  const favoritesUserCatalog: FavorieUserItem[] = useSelector(favoritesUserSelectorNew)

  useEffect(() => {
    if (!firstUpdate && authtoken) {
      setFirstUpdate(true)
      dispatch(FavoritesUsersActions.loadFavoritesUser())
    }
  }, [authtoken])

  useEffect(() => {
    if (editMode && isFavoritesUserCatalogEmpty()) {
      setEditMode(false)
    }
  }, [favoritesUserCatalog])

  useEffect(() => {
    if (pushIds) {
      dispatch(FavoritesUsersActions.pushFavoritesUserIds())
      setPushIds(false)
    }
  }, [pushIds])

  const getFullFavorites = () => {
    if (!favoritesUserCatalog || favoritesUserCatalog.length === 0) {
      return [
        {
          key: 'addButton',
          id: Math.floor(Math.random() * 100000),
          title: 'Ajouter',
          header: null,
          footer: null,
          image: '',
          type: 'default',
          disabledReSorted: true,
          disabledDrag: true,
          action: {
            type: 'addEditNewFavorite',
          },
        },
      ]
    }
    const dataFormatted: DataFormatted[] = favoritesUserCatalog.map(
      (item: FavorieUserItem): DataFormatted => ({
        ...item,
        key: item.id.toString(),
      }),
    )
    return [
      ...dataFormatted,
      {
        key: 'addButton',
        id: Math.floor(Math.random() * 100000),
        title: 'Ajouter',
        header: null,
        footer: null,
        image: '',
        type: 'default',
        disabledReSorted: true,
        disabledDrag: true,
        action: {
          type: 'addEditNewFavorite',
        },
      },
    ]
  }

  const onPressFavorites = (item: DataFormatted) => {
    if (!item.action) {
      return () => {}
    }
    return getItemAction(item.action)
  }

  const isFavoritesUserCatalogEmpty = () => favoritesUserCatalog.length === 0

  const onDelete = (id: number) => {
    dispatch(FavoritesUsersActions.deleteFavoritesUser(id))
  }

  const onAdd = () => {
    navigate(Routes.FavoriesShop)
  }

  const onEdit = () => {
    setEditMode(true)
  }

  const onQuit = () => {
    setEditMode(false)
    setPushIds(true)
  }

  const renderItem = (item: DataFormatted) => {
    if (item.action && item.action.type === 'addEditNewFavorite') {
      return (
        <View key={item.key} style={styles.item}>
          <FavoriteButtonConfig showFullAdd={isFavoritesUserCatalogEmpty()} editMode={editMode} onAdd={onAdd} onEdit={onEdit} onQuit={onQuit} />
        </View>
      )
    }
    return (
      <View key={item.key} style={styles.item}>
        <FavItemUser item={item} buttonDisable={editMode} onPress={onPressFavorites(item)} />
        {editMode ? <FavoriteDragView onDelete={() => onDelete(item.id)} /> : null}
      </View>
    )
  }

  return (
    <View style={styles.wrapperGrid}>
      <DraggableGrid
        numColumns={3}
        renderItem={renderItem}
        data={getFullFavorites() as DataFormatted[]}
        onDragRelease={(data: DataFormatted[]) => {
          const a = data.splice(0, data.length - 1)
          dispatch(FavoritesUsersActions.storeFavoritesUser(a))
          onEndDragging(false)
        }}
        onDragStart={() => onStartDragging(true)}
        style={styles.grid}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  wrapperGrid: {
    marginHorizontal: MARGIN_CONTENT,
  },
  grid: {
    padding: 0,
  },
  item: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    flexDirection: 'row',
  },
})
