import { StackScreenProps } from '@react-navigation/stack'
import { Container, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'

import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { OTPInput } from '../../components/OTPInput'

import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { theme } from '../../theme'
import { useResetPasswordVerification } from './useResetPasswordVerification'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: '700',
    lineHeight: 45,
    color: theme.colors.black,
    marginBottom: theme.padding.x3,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  container: {
    maxWidth: '100%',
    flex: 1,
  },
  view: {
    flex: 1,
    justifyContent: 'space-between',
    padding: theme.padding.x3,
  },
  alreadyRegistered: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.padding.x3,
  },
  connect: {
    color: theme.colors.blue,
    marginLeft: theme.padding.x1,
  },
})

export const ResetPasswordVerification: FunctionComponent<StackScreenProps<ActionViewParamsType, 'ResetPasswordVerification'>> = ({ route }) => {
  const { token, email } = route.params

  const menu = useSelector(menuSelector)
  const [OTPCode, setOTPCode] = useState('')
  const { doResetPasswordVerification, loading } = useResetPasswordVerification()

  const logoUri = menu && menu.header && menu.header.image

  const handleResetPassword = () => {
    doResetPasswordVerification(token, OTPCode, email)
  }

  return (
    <Container safeAreaBottom safeAreaTop background={theme.colors.white} style={styles.container}>
      <View style={styles.view}>
        <View>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Text style={styles.title}>{i18n.t('resetPasswordVerification.title')}</Text>
          <Text style={styles.subtitle}>{i18n.t('resetPasswordVerification.subtitle')}</Text>
          <OTPInput currentCode={OTPCode} setCurrentCode={setOTPCode} />
          <Button
            onPress={handleResetPassword}
            label={i18n.t('resetPasswordVerification.resetPassword')}
            style={{ backgroundColor: theme.colors.lightBlue }}
            iconName="checkmark"
            loading={loading}
          />
        </View>
        <View style={styles.alreadyRegistered}>
          <Text>{i18n.t('resetPasswordVerification.alreadyRegistered')}</Text>
          <Text style={styles.connect}>{i18n.t('resetPasswordVerification.connect')}</Text>
        </View>
      </View>
    </Container>
  )
}
