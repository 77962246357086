import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { TaskReportListItemResult } from '../../api/TaskReport/types'
import { Routes } from '../../navigation/Routes'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'
import { Card } from '../Card'
import { ReportTags } from '../TaskReportItem/components'
import { DisplayTextInfos } from './components/DisplayTextInfos.component'

const IMAGE_SIZE = 100
interface TaskReportDisplayProps {
  item: TaskReportListItemResult
}

const renderImage = (image: string | null) => {
  if (!image) return null
  return (
    <View style={styles.leftSide}>
      <FastImage
        source={{
          uri: image,
        }}
        style={styles.image}
        resizeMode="cover"
      />
    </View>
  )
}

const TaskReportDisplay: FunctionComponent<TaskReportDisplayProps> = ({ item }) => {
  const colorBorder = item.state && item.state.color ? item.state.color : theme.colors.blue
  return (
    <TouchableOpacity style={[{ borderRightColor: colorBorder }, styles.content]} onPress={() => navigate(Routes.TaskReportItem, { taskReport: item })}>
      <Card style={styles.card}>
        <View style={[styles.container]}>
          {renderImage(item.image)}
          <DisplayTextInfos task={item} />
        </View>
        <View
          style={[
            styles.container,
            {
              paddingLeft: theme.padding.x1,
              paddingRight: theme.padding.x1,
            },
          ]}
        >
          <View style={styles.separator}>
            <ReportTags tags={null} />
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  )
}

export default TaskReportDisplay

const styles = StyleSheet.create({
  card: {
    marginHorizontal: theme.padding.x1,
    marginTop: theme.padding.x1,
  },

  content: {},
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    // margin: theme.padding.x1,
    flex: 1,
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.padding.x1,
  },
  separator: {
    // marginTop: theme.padding.x05,
    borderTopWidth: 1,
    borderColor: theme.colors.lightGray,
  },
})
