import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import AntDesign from 'react-native-vector-icons/AntDesign'
import { theme } from '../../../theme'

interface ReportStatusProps {
  tags: string[] | null
}

const BORDER_RADIUS = 20
const ICON_SIZE = 15

export const ReportTags: FunctionComponent<ReportStatusProps> = ({ tags }) => {
  if (!tags) {
    return null
  }

  const tagsArray = tags.map((tag, index) => (
    <View key={index} style={styles.tags}>
      <Icon style={styles.icon} as={AntDesign} name="tag" size={6} />
      <Text style={styles.text}>{tag}</Text>
    </View>
  ))

  return <View style={styles.row}>{tagsArray}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingVertical: theme.padding.x1,
    flexWrap: 'wrap',
  },
  tags: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.lightGray,
    paddingHorizontal: theme.padding.x2,
    marginVertical: theme.padding.x05,
    borderRadius: BORDER_RADIUS,
    marginRight: theme.padding.x05,
  },
  icon: {
    color: theme.colors.silverStrong,
    fontSize: ICON_SIZE,
    paddingRight: theme.padding.x05,
  },
  text: {
    color: theme.colors.silverStrong,
  },
})
