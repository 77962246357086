import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { UrgentNumbersApi } from '../../api/UrgentNumbers'
import { UrgentNumbersItem, UrgentNumbersResult } from '../../api/UrgentNumbers/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActionUrgentNumbersObjectTypes, UrgentNumbersAction } from './action'
import { urgentNumbersPagingSelector } from './selector'
import { UrgentNumbersActionTypes } from './types'

const PAGE_SIZE = 40

function* loadUrgentNumbersSaga(action: ActionsOfType<ActionUrgentNumbersObjectTypes, UrgentNumbersActionTypes.LOAD_URGENTNUMBERS>) {
  const paging: PagingResult<UrgentNumbersItem> = yield select(urgentNumbersPagingSelector)
  let page = 0

  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const numbersResult: UrgentNumbersResult = yield call(UrgentNumbersApi.load, page, PAGE_SIZE)

    if (action.payload.clear) {
      yield put(UrgentNumbersAction.clearUrgentNumbersState())
    }

    yield put(UrgentNumbersAction.storeUrgentNumbers(numbersResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* refreshUrgentNumbersSaga() {
  yield call(loadUrgentNumbersSaga, UrgentNumbersAction.loadUrgentNumbers(true))
}

export function* loadUrgentNumbersWatcher() {
  yield takeEvery(UrgentNumbersActionTypes.LOAD_URGENTNUMBERS, addLoader(loadUrgentNumbersSaga, LoaderName.LOAD_URGENTNUMBERS))
}

export function* refreshUrgentNumbersWatcher() {
  yield takeEvery(UrgentNumbersActionTypes.REFRESH_URGENTNUMBERS, addLoader(refreshUrgentNumbersSaga, LoaderName.REFRESH_URGENTNUMBERS))
}
