import { Reducer } from 'redux'
import { FavoritesUserActionTypes, FavoritesUserState } from './types'
import { FavoritesUserActionObjectTypes } from './actions'

const initialFavoritesUserState: FavoritesUserState = {
  favorites: [],
}

export const favoritesUserReducer: Reducer<FavoritesUserState, FavoritesUserActionObjectTypes> = (state = initialFavoritesUserState, action): FavoritesUserState => {
  switch (action.type) {
    case FavoritesUserActionTypes.STORE_FAVORITES_USER:
      return {
        ...state,
        favorites: action.payload,
      }
    case FavoritesUserActionTypes.STORE_FAVORITE_USER:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      }
    default:
      return state
  }
}
