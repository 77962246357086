import { takeEvery, call, put } from '@redux-saga/core/effects'
import { ActionsOfType } from '@martin_hotell/rex-tils'
import { IntermediatePageActionTypes } from './types'
import { IntermediatePageActionObjectTypes, IntermediatePageActions } from './actions'
import { GridPageApi } from '../../api/GridPage'
import { IntermediatePageResult } from '../../api/GridPage/types'

function* loadIntermediatePageSaga(action: ActionsOfType<IntermediatePageActionObjectTypes, IntermediatePageActionTypes.LOAD_INTERMEDIATE_PAGE>) {
  try {
    const result: IntermediatePageResult = yield call(GridPageApi.loadIntermediatePage, action.payload.id)
    yield put(IntermediatePageActions.storeIntermediatePage(action.payload.id, result))
  } catch (error) {
    console.error(error)
  }
}

export function* loadIntermediatePageWatcher() {
  yield takeEvery(IntermediatePageActionTypes.LOAD_INTERMEDIATE_PAGE, loadIntermediatePageSaga)
}
