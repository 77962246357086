/* eslint react-native/no-unused-styles: 0 */
import _ from 'lodash'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Swiper from 'react-native-web-swiper'
import { useDispatch, useSelector } from 'react-redux'
import { GridPageActions } from '../../redux/gridPage/actions'
import { carouselSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { RootState } from '../../redux/reducers'
import { getLoadingCarouselName } from '../../services/loading'
import { trackEvent } from '../../services/matomo/matomo.service'
import { CarouselItem } from '../CarouselItem'
import { apiTokenSelector } from '../../redux/authentication/selectors'

interface GridPageCarouselProps {
  id: number
  top: number
  left: number
  width: number
  height: number
}

export const GridPageCarousel: FunctionComponent<GridPageCarouselProps> = props => {
  const dispatch = useDispatch()
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const carousel = useSelector((state: RootState) => carouselSelector(state, props.id))
  const isCarouselLoading = useSelector((state: RootState) => isLoadingSelector(state, getLoadingCarouselName(props.id)))

  useEffect(() => {
    if (authtoken) {
      dispatch(GridPageActions.loadCarousel(props.id))
    }
  }, [props.id, authtoken])

  const styles = carouselStyles(props)

  if (isCarouselLoading || !carousel) {
    return (
      <View style={styles.carouselContainer}>
        <Spinner style={styles.spinner} />
      </View>
    )
  }

  const { items, ...carouselConfig } = carousel

  const swipeEvent = (index: number) => {
    trackEvent({
      category: 'Carrousel',
      actionName: `Carrousel ${carousel.titre}`,
      eventName: `Swipe sur le carrousel`,
      value: items[index].libelle1,
    })
  }

  return (
    <View style={styles.carouselContainer}>
      <Swiper
        loop
        timeout={carousel.timer ? carousel.timer / 1000 : 0}
        controlsProps={{
          prevPos: false,
          nextPos: false,
        }}
        onIndexChanged={index => {
          if (!carousel.timer) swipeEvent(index)
        }}
      >
        {_.map(items, (item, index) => (
          <CarouselItem key={index} carousel={carouselConfig} item={item} />
        ))}
      </Swiper>
    </View>
  )
}

const carouselStyles = (props: GridPageCarouselProps) =>
  StyleSheet.create({
    carouselContainer: {
      position: 'absolute',
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    },
    spinner: {
      flex: 1,
    },
  })
