/* eslint-disable prettier/prettier */
import { Badge, Button, Icon } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { env } from '../../environment/index'
import { Routes } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'
import { navigate } from '../../services/navigation'
import { theme as themeUi } from '../../theme'

interface NotificationButtonProps {
  enabled: boolean
  hasNewNotification: boolean
}

export const NotificationButton: FunctionComponent<NotificationButtonProps> = props => {
  const theme = useSelector(themeSelector)

  if (!props.enabled) {
    return null
  }
  const navigateWithCheatCode = () => {
    if (env().ENV) {
      if (env().ENV === 'local' || env().ENV === 'staging') {
        return navigate(Routes.CheatCodes, [])
      }
      
    }
    
  }

  return (
    <Button
      variant="ghost"
      onPress={() => navigate(Routes.Notifications, [])}
      onLongPress={() => navigateWithCheatCode()}
    >
      {props.hasNewNotification && <Badge colorScheme="danger" rounded="full" style={notificationStyles.badge} />}
      <Icon as={Ionicons} name="notifications" size={6} color={theme?.titleFontColor} />
    </Button>
  )
}

const notificationStyles = StyleSheet.create({
  badge: {
    position: 'absolute',
    backgroundColor: themeUi.colors.red,
    top: -4,
    alignSelf: 'center',
    right: 0,
    zIndex: 1,
    width: 12,
    height: 12,
    paddingLeft: 0,
    paddingRight: 0,
  },
})
