import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { MovieActionTypes } from './types'
import { MovieItemResult, MovieListResult, MovieInTheater } from '../../api/Movie/types'

export const MovieActions = {
  loadMovieItem: (id: number) => createAction(MovieActionTypes.LOAD_MOVIE_ITEM, id),
  storeMovieItem: (id: number, item: MovieItemResult) => createAction(MovieActionTypes.STORE_MOVIE_ITEM, { id, item }),
  loadMovieList: (clear: boolean, id: number, inTheater: MovieInTheater) => createAction(MovieActionTypes.LOAD_MOVIE_LIST, { clear, id, inTheater }),
  storeMovieList: (id: number, page: MovieListResult) => createAction(MovieActionTypes.STORE_MOVIE_LIST, { id, page }),
  storeClearMovieList: (id: number, page: MovieListResult) => createAction(MovieActionTypes.STORE_CLEAR_MOVIE_LIST, { id, page }),
  refreshMovieList: (id: number, inTheater: MovieInTheater) => createAction(MovieActionTypes.REFRESH_MOVIE_LIST, { id, inTheater }),
}

export type MovieActionObjectTypes = ActionsUnion<typeof MovieActions>
