/* eslint react-native/no-unused-styles: 0 */
import isEmpty from 'lodash/isEmpty'
import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import EvilIcons from 'react-native-vector-icons/EvilIcons'
import { WeeklySchedulesItemResult } from '../../../api/WeeklySchedules/types'
import { dayOfWeekOrdinal } from '../../../services/dateFormatter'
import { theme } from '../../../theme'
import { Card } from '../../Card'
import { ModalBottom } from '../../ModalBottom/ModalBottom.component'
import { Right } from '../../NativeBaseLegacy'
import { WeekReferentielImage } from './WeekReferentielImage.component'

const INTERMEDIATE_SIZE = 85

interface WeekItemProps {
  item: WeeklySchedulesItemResult
}

export const WeekItem: FunctionComponent<WeekItemProps> = ({ item }) => {
  const [modal, setModal] = useState<number | null>()

  const renderImage = (i: number) => {
    const days = item.days.filter(day => day.day === i)
    const ids = new Set(...days.map(day => day.ids))
    const referentiels = item.referentiels.filter(referentiel => ids.has(referentiel.id))
    return referentiels.map(referentiel => <WeekReferentielImage key={referentiel.id} image={referentiel.image} />)
  }

  const getSchedules = (i: number) => {
    let r = ''
    // eslint-disable-next-line array-callback-return
    item.days.map(item => {
      if (item.day === i) {
        r = item.horaires
      }
    })
    return r
  }

  const renderModal = (day: number) => {
    const dayOrdinal = dayOfWeekOrdinal(day)
    const show = modal !== null && modal === day
    return (
      <Right style={styles.right}>
        <ModalBottom title={dayOrdinal} content={getSchedules(day)} show={show} onClose={() => setModal(null)} />
        <Button variant="outline" size="sm" onPress={() => setModal(day)}>
          <Icon name="clock" as={EvilIcons} size={6} />
        </Button>
      </Right>
    )
  }

  const renderDays = (day: number, isLast: boolean) => {
    const iconsDay = renderImage(day)
    const dayOrdinal = dayOfWeekOrdinal(day)
    return (
      <View key={day} style={[styles.container, isLast && styles.lastContainer]}>
        <View style={styles.dayContainer}>
          <Text style={styles.day} numberOfLines={1}>
            {dayOrdinal}
          </Text>
          <View style={styles.dayImages}>{iconsDay}</View>
        </View>
        {!isEmpty(iconsDay) && renderModal(day)}
      </View>
    )
  }

  const days: Array<JSX.Element> = []
  for (let i = 2; i <= 7; i++) {
    days.push(renderDays(i, false))
  }
  days.push(renderDays(1, true))

  return <Card style={styles.card}>{days}</Card>
}

const styles = StyleSheet.create({
  card: {
    marginTop: theme.padding.x3,
    paddingHorizontal: 20,
  },
  container: {
    padding: theme.padding.x3,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.silver,
  },
  lastContainer: {
    borderBottomWidth: 0,
  },
  cardItem: {
    paddingLeft: 0,
  },
  dayContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  day: {
    alignItems: 'flex-start',
    width: INTERMEDIATE_SIZE,
  },
  dayImages: {
    alignItems: 'baseline',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  right: {
    marginVertical: theme.padding.x1 * -1,
  },
})
