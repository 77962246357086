import { RootState } from '../reducers'

export const citysSelector = (state: RootState) => (state.city ? state.city.paging.content : [])

export const lastPageSelector = (state: RootState) => (state.city.paging ? state.city.paging.last : null)

export const citysPagingSelector = (state: RootState) => state.city.paging

export const citysSelectSelector = (state: RootState) => (state.city && state.city.selected ? state.city.selected : {})

export const citysEventsConfigSelector = (state: RootState) => (state.city && state.city.eventsConfig ? state.city.eventsConfig : null)

export const townHallSelector = (state: RootState) => (state.city.townHall ? state.city.townHall : null)

export const townHallsConfigSelector = (state: RootState) => (state.city.townHallsConfig ? state.city.townHallsConfig : null)

export const addingCitySelector = (state: RootState) => (state.city.addingCity ? state.city.addingCity : null)
