import { Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../../redux/theme/selector'
import { theme as defaultTheme } from '../../../theme'
import { Label } from '../../NativeBaseLegacy'

interface LabelFieldProps {
  text: string | null
  required?: boolean | null
  style?: StyleProp<TextStyle> | undefined
}

const LabelInput: FunctionComponent<LabelFieldProps> = ({ text, required, style }) => {
  const theme = useSelector(themeSelector)

  const color = theme ? theme.brandDanger : defaultTheme.colors.red

  return (
    <Label style={[styles.text, style]}>
      {text}
      <Text style={{ color }}>{required ? ' *' : ''}</Text>
    </Label>
  )
}

const PADDING_VERTICAL = 7

const styles = StyleSheet.create({
  text: {
    paddingVertical: PADDING_VERTICAL,
  },
})

export { LabelInput }
