import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Image, ImageSourcePropType, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useSelector } from 'react-redux'
import { i18n } from '../../../lib/i18n'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { CardInFo } from '../../CardInFo'

import BACKGROUND_IMAGE from '../../../assets/images/spotting_bugs.png'

const FONT_SIZE = 18
interface iProps {
  onReload: () => void
}

export const TaskReportOtherErrors: FunctionComponent<iProps> = ({ onReload }) => {
  const themeSelect = useSelector(themeSelector)
  const primaryColor = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={BACKGROUND_IMAGE as ImageSourcePropType} resizeMode={FastImage.resizeMode.center} />
      <CardInFo
        title={i18n.t('taskReport.commonErrorTitle')}
        textColor={theme.colors.darkRed}
        colorBackground={theme.colors.lightPink}
        components={[i18n.t('taskReport.commonErrorExplain')]}
        footer={i18n.t('taskReport.commonErrorFooter')}
      />
      <Button style={[styles.centered, { borderColor: primaryColor }]} onPress={() => onReload()} variant="outline" borderRadius="full">
        <Icon name="recycle" as={FontAwesome} color={primaryColor} size={6} />
        <Text style={styles.textButton}>{i18n.t('taskReport.refreshPage')}</Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.padding.x2,
    marginHorizontal: theme.padding.x3,
  },
  image: {
    width: theme.deviceWidth,
    height: theme.referentielImageMaxHeight,
  },
  centered: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  textButton: { fontSize: FONT_SIZE },
})
