import { GrcYpokConfigResult } from '../../api/GrcYpok/types'

export type GrcYpokState = {
  config: GrcYpokConfigResult | null
}

export enum GrcYpokActionTypes {
  LOAD_YPOK_CONFIG = 'LOAD_YPOK_CONFIG',
  STORE_YPOK_CONFIG = 'STORE_YPOK_CONFIG',
}
