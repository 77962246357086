/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Text, TextArea, View } from 'native-base'
import React, { PureComponent } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { withNextInputAutoFocusInput } from 'react-native-formik'
import { FormText } from '../../api/Form/types'
import { theme } from '../../theme'
import { Item } from '../NativeBaseLegacy'
import { LabelInput } from './components'
import { FormElementProps } from './types'

class TextFieldClass extends PureComponent<FormElementProps<FormText>> {
  private input: typeof Input | null = null

  public focus = () => {
    if (!this.input) {
      return
    }
    // https://github.com/GeekyAnts/NativeBase/issues/1429#issuecomment-350228398
    const root = (this.input as any)._root
    if (!root) {
      return
    }
    root.focus()
  }

  public render() {
    const { field, returnKeyType, handleChange, value } = this.props
    const elementId = `${field.id}`

    if (field.constraint.height) {
      const numberOfLines = field.constraint.height / theme.textSize.x2
      let lettersAvailables = -1
      let maxSize = null
      // inputValue === -1 quand il n'y a pas de limite de caractère
      if (value && typeof value === 'string' && value.length && field.constraint.maxSize) {
        lettersAvailables = field.constraint.maxSize ? field.constraint.maxSize - value.length : -1
        maxSize = field.constraint.maxSize as any
      }
      const color = lettersAvailables < 20 && lettersAvailables > 0 ? theme.colors.red : theme.colors.silverStrong
      return (
        <View>
          <View style={styles.titleContainer}>
            <LabelInput text={field.libelle} required={field.constraint.mandatory} />
            <Text style={{ color }}>{lettersAvailables > 0 ? lettersAvailables : null}</Text>
          </View>
          <TextArea
            autoCompleteType
            accessibilityValue={undefined}
            onAccessibilityEscape={undefined}
            focusable={undefined}
            onTextInput={undefined}
            passwordRules={undefined}
            rejectResponderTermination={undefined}
            importantForAutofill={undefined}
            showSoftInputOnFocus={undefined}
            maxLength={!maxSize ? undefined : maxSize}
            {...this.props}
            ref={(input: any) => (this.input = input)}
            totalLines={numberOfLines}
            scrollEnabled
            returnKeyType="default"
            onChangeText={handleChange(elementId)}
            onSubmitEditing={() => {}}
            value={value as string}
          />
        </View>
      )
    }

    return (
      <View>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        <Item>
          <Input
            {...this.props}
            py="4"
            ref={(input: any) => (this.input = input)}
            returnKeyType={returnKeyType}
            keyboardType={this.keyboardType}
            onChangeText={handleChange(elementId)}
            value={value as string}
            caretHidden={this.isCaretHidden}
          />
        </Item>
      </View>
    )
  }

  private get isCaretHidden() {
    const { field } = this.props
    if (field.type === 'field_email' && Platform.OS === 'android' && +Platform.Version > 28) {
      return true
    }
    return false
  }

  private get keyboardType() {
    const { field } = this.props
    switch (field.type) {
      case 'field_texte':
        return 'default'
      case 'field_email':
        return 'email-address'
      case 'field_phone':
        return 'phone-pad'
      default:
        return 'default'
    }
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingTop: theme.padding.x3,
    alignItems: 'center',
  },
})

export const TextField = withNextInputAutoFocusInput(TextFieldClass)
