import { AirQualityConfig } from '../../api/AirQuality/types'
import { RootState } from '../reducers'

export function AirQualityConfigSelector({ airQuality }: RootState, id: number) {
  if (!airQuality || !airQuality.configs) {
    return null
  }
  return airQuality.configs[id]
}

export const airQualityDaySelector = (state: RootState, airQualityConfig: AirQualityConfig | null) => {
  if (!airQualityConfig) {
    return null
  }
  const { id } = airQualityConfig
  return state.airQuality && state.airQuality.days && state.airQuality.days[id] ? state.airQuality.days[id] : null
}
