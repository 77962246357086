import { BaseApi } from '../BaseApi'
import { CitysResult, TownHallResult, CityCategoriesConfig, NotificationItem, CitysUserResult, CityItemResult } from './types'
import { ApiResponse, ReferentielFilters } from '../types'

export class CitysApi {
  public static async loadCitys(page: number, size: number, search?: string | null) {
    const textSearch = search || null

    return BaseApi.authenticatedCall()
      .url('v1/inmycity/citys')
      .query({ page, size, search: textSearch || '' })
      .get()
      .json(json => json) as Promise<CitysResult>
  }

  public static async loadCitysUser() {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/citys`)
      .get()
      .json(json => json) as Promise<CitysUserResult>
  }

  public static async loadCityUser(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/citys/${id}`)
      .get()
      .json(json => json) as Promise<CityItemResult>
  }

  public static async addCity(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/citys/${id}`)
      .post()
      .json(json => json) as Promise<ApiResponse>
  }

  public static async removeCity(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/citys/${id}`)
      .delete()
      .json(json => json) as Promise<ApiResponse>
  }

  public static async loadCitysEventsConfig() {
    return BaseApi.authenticatedCall()
      .url('v1/inmycity/user/events-config')
      .get()
      .json(json => json) as Promise<ReferentielFilters>
  }

  public static async loadTownHallsConfig() {
    return BaseApi.authenticatedCall()
      .url('v1/inmycity/user/town-halls-config')
      .get()
      .json(json => json) as Promise<ReferentielFilters>
  }

  public static async loadTown(cityId: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/town-hall-city/${cityId}`)
      .get()
      .json(json => json) as Promise<TownHallResult>
  }

  public static async loadNotificationSettings(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/city/${id}/notifications`)
      .get()
      .json(json => json) as Promise<CityCategoriesConfig>
  }

  public static async updateNotificationSettings(id: number, notify: boolean, categories: NotificationItem[]) {
    return BaseApi.authenticatedCall()
      .url(`v1/inmycity/user/city/${id}/notifications`)
      .post({ notify, categories })
      .json(json => json) as Promise<CityCategoriesConfig>
  }
}
