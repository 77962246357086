import { BaseApi } from '../BaseApi'
import { FavoritesUserItemResult, FavoriteSegment, FavoriteSelectItem, FavoriteConfig } from './types'
import { ApiResponse } from '../types'

export class FavoritesApi {
  public static async loadFavoritesCatalog(bySegment?: FavoriteSegment, page?: number, size?: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/favorites-catalog')
      .query({ page: page || '', size: size || '', segment: bySegment || '' })
      .get()
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async loadFavoritesSubThematicCatalog(id: number, page?: number, size?: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-catalog/thematic/${id}`)
      .query({ page: page || '', size: size || '' })
      .get()
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async loadFavoritesUser() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/favorites-user')
      .get()
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async addFavoritesUser(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-user/catalog/${id}`)
      .post()
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async deleteFavoritesUser(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-user/${id}`)
      .delete()
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async storeFavoritesUserId(ids: number[]) {
    return BaseApi.authenticatedCall()
      .url('/v1/mobile/favorites-user/order')
      .post({
        ids,
      })
      .json(json => json) as Promise<ApiResponse>
  }

  public static async loadFavoritesIntermediaryData(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-catalog/intermediate/${id}`)
      .get()
      .json(json => json) as Promise<{ content: FavoriteSelectItem[] }>
  }

  public static async addMultipleFavoritesUser(id: number, ids: number[]) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-user/intermediate/${id}`)
      .post(ids)
      .json(json => json) as Promise<FavoritesUserItemResult[]>
  }

  public static async loadFavoritesItem(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/favorites-user/${id}`)
      .get()
      .json(json => json) as Promise<{ content: FavoriteSelectItem[] }>
  }

  public static async storeClickFavorite(id: number) {
    return BaseApi.authenticatedCall()
      .url(`/v1/mobile/favorites-user/clicked/${id}`)
      .post()
      .json(json => json) as Promise<ApiResponse>
  }

  public static async loadFavoriteConfig() {
    return BaseApi.authenticatedCall()
      .url('/v1/mobile/favorites-user/config')
      .get()
      .json(json => json) as Promise<FavoriteConfig>
  }
}
