import { extendTheme } from 'native-base'

import variable from '../variables/platform'
import buttonTheme from './Button'

export default (variables = variable) => {
  const theme = extendTheme({
    variables,
    components: {
      Button: {
        ...buttonTheme(variables),
      },
    },
  })
  return theme
}
