import { call, put, takeEvery } from 'redux-saga/effects'
import { ThemeApi } from '../../api/Theme'
import { ThemeResult } from '../../api/Theme/types'
import { isOfflineError } from '../../services/errorManager'
import { ThemeActions } from './actions'
import { LoadThemeActionTypes } from './types'

function* loadThemeSaga() {
  try {
    const themeConfig: ThemeResult = yield call(ThemeApi.load)
    yield put(ThemeActions.storeThemeConfig(themeConfig))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

export function* loadThemeWatcher() {
  yield takeEvery(LoadThemeActionTypes.LOAD_THEME, loadThemeSaga)
}
