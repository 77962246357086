import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { AirQualityConfig } from '../../api/AirQuality/types'
import { WeatheIconerResult } from '../../api/GridPage/types'
import { themeSelector } from '../../redux/theme/selector'
import { dateToDayMonthYear, dateToDayOfWeek } from '../../services/dateFormatter'
import { iconFont } from '../../services/Icon'
import { weatherConditions } from '../../services/weather'
import { theme } from '../../theme'
import { AirQualityDay } from '../GridPageAirQuality/components/AirQualityDay.component'

interface AirQualityWeatherProps {
  airQualityConfig: AirQualityConfig
}

export const AirQualityWeather: FunctionComponent<AirQualityWeatherProps> = ({ airQualityConfig }) => {
  const { libelle, weather } = airQualityConfig
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
  const renderWeather = (weather: WeatheIconerResult) => {
    const condition = weatherConditions(weather.icone)
    if (!condition) {
      return <View />
    }

    return <Icon name={condition.icon} as={iconFont(condition.type)} style={styles.icon} color={colorText} size={8} />
  }

  const weatherIcon = weather.weather ? renderWeather(weather.weather) : null
  const day = dateToDayOfWeek(weather.dateUpdate)
  const date = dateToDayMonthYear(weather.dateUpdate)

  return (
    <View style={styles.main}>
      <Text style={[styles.title, { color: colorText }]}>{libelle}</Text>
      <View style={styles.time}>
        <Text style={[styles.date, { color: colorText }]}>{day} </Text>
        <Text style={[styles.date, { color: colorText }]}>{date}</Text>
      </View>
      <View style={styles.content}>
        {weatherIcon}
        <Text style={[styles.item, { color: colorText }]}>{Math.floor(weather.temperature)} C°</Text>
      </View>
      <AirQualityDay airQualityConfig={airQualityConfig} color={colorText} pictoStyle={styles.pictoView} infoStyle={styles.infoView} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    padding: theme.padding.x4,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    padding: theme.padding.x1,
    fontSize: theme.textSize.x3,
  },
  item: {
    padding: theme.padding.x1,
    fontSize: theme.textSize.x3,
  },
  date: {
    fontSize: theme.textSize.x2,
  },
  time: {
    flexDirection: 'row',
    marginVertical: theme.padding.x1,
  },
  icon: {
    // fontSize: theme.iconSize.x2,
    marginRight: theme.padding.x1,
  },
  pictoView: {
    fontSize: theme.iconSize.x2,
    padding: theme.padding.x1,
    alignSelf: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  infoView: {
    padding: theme.padding.x1,
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
})
