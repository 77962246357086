import { Reducer } from 'redux'

import { GridPageActionObjectTypes } from './actions'
import { GridPageActionTypes, GridPageState } from './types'

const initialGridPageState: GridPageState = {
  gridPages: {},
  carousels: {},
  actualites: {},
  weathers: {},
  rssFeed: {},
  airQuality: {},
  tide: {},
}

export const gridPageReducer: Reducer<GridPageState, GridPageActionObjectTypes> = (state = initialGridPageState, action) => {
  switch (action.type) {
    case GridPageActionTypes.STORE_GRID_PAGE:
      return {
        ...state,
        gridPages: {
          ...state.gridPages,
          [action.payload.id]: action.payload.gridPageConfig,
        },
      }
    case GridPageActionTypes.STORE_CAROUSEL:
      return {
        ...state,
        carousels: {
          ...state.carousels,
          [action.payload.id]: action.payload.carousel,
        },
      }
    case GridPageActionTypes.STORE_ACTUALITES:
      return {
        ...state,
        actualites: {
          ...state.actualites,
          [action.payload.id]: action.payload.actualitesResult,
        },
      }
    case GridPageActionTypes.STORE_WEATHER:
      return {
        ...state,
        weathers: {
          ...state.weathers,
          [action.payload.id]: action.payload.result,
        },
      }
    case GridPageActionTypes.STORE_RSS_FEED_CONFIG:
      return {
        ...state,
        rssFeed: {
          ...state.rssFeed,
          [action.payload.id]: action.payload.result,
        },
      }
    case GridPageActionTypes.STORE_GRID_AIR_QUALITY_CONFIG:
      return {
        ...state,
        airQuality: {
          ...state.airQuality,
          [action.payload.id]: action.payload.result,
        },
      }
    case GridPageActionTypes.STORE_GRID_TIDE_CONFIG:
      return {
        ...state,
        tide: {
          ...state.tide,
          [action.payload.id]: action.payload.result,
        },
      }
    default:
      return state
  }
}
