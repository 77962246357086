import { QualityValue } from '../../api/AirQuality/types'
import { i18n } from '../../lib/i18n'
import { IconType } from '../weather'

interface AirQualityIconConfig {
  icon: string
  iconType: IconType
}

export const getAirQualityIconConfig = (quality: QualityValue): AirQualityIconConfig | null => {
  if (!quality) {
    return null
  }
  switch (quality) {
    case QualityValue.POOR:
      return { icon: 'emoticon-sad-outline', iconType: 'MaterialCommunityIcons' }
    case QualityValue.BAD:
      return { icon: 'emoticon-neutral-outline', iconType: 'MaterialCommunityIcons' }
    case QualityValue.WAY:
      return { icon: 'emoticon-happy-outline', iconType: 'MaterialCommunityIcons' }
    case QualityValue.GOOD:
      return { icon: 'emoticon-outline', iconType: 'MaterialCommunityIcons' }
    default:
      return { icon: 'emoticon-angry-outline', iconType: 'MaterialCommunityIcons' }
  }
}

export const getAirQualityLabel = (quality: QualityValue) => {
  if (!quality) {
    return 'non disponible'
  }
  switch (quality) {
    case QualityValue.POOR:
      return i18n.t('airQuality.poor')
    case QualityValue.BAD:
      return i18n.t('airQuality.bad')
    case QualityValue.WAY:
      return i18n.t('airQuality.way')
    case QualityValue.GOOD:
      return i18n.t('airQuality.good')
    default:
      return i18n.t('airQuality.bad')
  }
}
