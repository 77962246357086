/* eslint react-native/no-unused-styles: 0 */
import { Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet, TouchableWithoutFeedback } from 'react-native'
import FastImage from 'react-native-fast-image'
import { CartographyItemResult } from '../../../api/Cartography/types'
import { getColor } from '../../../services/theme'
import { theme } from '../../../theme'

export const ITEM_HEIGHT = 50
const IMAGE_SIZE = 24

interface CartographyItemProps {
  item: CartographyItemResult
  isSelected: boolean
  onPress: (itemId: number) => void
}

const CartographyItem: FunctionComponent<CartographyItemProps> = ({ item, isSelected, onPress }) => {
  const [style, setStyle] = useState(styles(isSelected, getColor(item.color)))

  useEffect(() => {
    setStyle(styles(isSelected, getColor(item.color)))
  }, [isSelected])

  return (
    <TouchableWithoutFeedback onPress={() => onPress && onPress(item.id)}>
      <View style={style.container}>
        <View style={style.imageContainer}>
          <FastImage style={style.image} source={{ uri: item.image }} resizeMode={FastImage.resizeMode.cover} />
        </View>
        <View style={style.labelContainer}>
          <Text numberOfLines={2} style={style.label}>
            {item.libelle}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = (isSelected: boolean, color: string) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
      height: ITEM_HEIGHT,
      backgroundColor: isSelected ? color : theme.colors.transparent,
    },
    imageContainer: {
      backgroundColor: isSelected ? theme.colors.transparent : color,
      borderRadius: IMAGE_SIZE,
      padding: theme.padding.x1 / 2,
      margin: theme.padding.x1 / 2,
      // Image size plus margin left and right
      flexBasis: IMAGE_SIZE + theme.padding.x1,
    },
    image: {
      width: IMAGE_SIZE,
      height: IMAGE_SIZE,
      resizeMode: 'contain',
    },
    labelContainer: { flex: 2 },
    label: {
      marginLeft: theme.padding.x1,
      color: isSelected ? theme.colors.white : color,
    },
  })

export { CartographyItem }
