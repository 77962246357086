/* eslint-disable prettier/prettier */
import { isEmpty } from 'lodash'
import { View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ImageURISource, Platform, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import MapView, { Marker } from 'react-native-maps'
import { useDispatch, useSelector } from 'react-redux'
import { RealTimeMapItem } from '../../api/CartographyRealTime/type'
import { CartographyRealTimeAction } from '../../redux/cartographyRealTime/actions'
import {
  cartographyRealTimeMapItemsSelector, cartographyRealTimeMapSelector,
} from '../../redux/cartographyRealTime/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { fitToCoordinates as fitToCoords } from '../../services/cartography/cartography.service'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme'
import { CartographyLoading } from '../CartographyLoading'
import { ModalCenter } from '../ModalCenter'
import { ModalRealTime, ModalTideTime } from './components'

const DEFAULT_ALTITUDE = 2500
const DEFAULT_ZOOM = 15
const ANIMATION_DURATION = 1000

const INITIAL_REGION = {
  latitude: 46.360624,
  longitude: 2.621288,
  latitudeDelta: 12,
  longitudeDelta: 12,
}

interface CartographyRealTimeProps {
  id: number
}

export const CartographyRealTime: FunctionComponent<CartographyRealTimeProps> = ({ id }) => {
  const realTimeMap = useSelector((state: RootState) => cartographyRealTimeMapSelector(state, id))
  const mapViewRef = React.createRef<MapView>()
  const [zoneSelected, setZoneSelected] = useState<RealTimeMapItem | null>(null)
  const [isMapReady, setIsMapReady] = useState(false)

  const realTimeMapItems = useSelector((state: RootState) =>
    cartographyRealTimeMapItemsSelector(state, id),
  )
  const isLoadingConfig = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.LOAD_CARTOGRAPHY_REAL_TIME),
  )

  const isLoadingItems = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.LOAD_CARTOGRAPHY_REAL_TIME_ITEMS),
  )

  const dispatch = useDispatch()

  const renderItem = (item: RealTimeMapItem) => {
    const selected = zoneSelected === item
    // see https://github.com/react-native-community/react-native-maps/blob/master/docs/marker.md
    const anchor = { x: 0.5, y: 1 }
    return (
      <Marker
        coordinate={item.positionMap}
        anchor={anchor}
        calloutAnchor={anchor}
        calloutOffset={anchor}
        key={item.id}
        style={styles.selected}
        icon={Platform.OS === 'web' ? (item.icon as ImageURISource) : undefined}
        onPress={() => {
          trackEvent({
            category: 'Cartographie',
            actionName: 'Cartographie en temps réel',
            eventName: 'Appui sur un marker',
            value: item.title,
          })
          setZoneSelected(item)
          centerToMarker(item)
        }}
      >
        {renderIcon(selected, item.icon)}
      </Marker>
    )
  }

  /**
   *
   * @param iconUri l'uri éventuelle d'un POI
   * @param isPoiSelected bool, dit si oui ou non le POI est sélectionné
   * @return JSX.Element, soit une fastImage avec l'iconUri, soit null
   *
   */
  const renderIcon = (isPoiSelected: boolean, iconUri: string | null) => iconUri ? (
      <View style={[styles.markerImageContainer, styles.selectedMarkerImage]}>
        <FastImage
          style={isPoiSelected ? styles.selectedMarkerImage : styles.markerImage}
          source={{
            uri: iconUri,
          }}
          resizeMode={FastImage.resizeMode.contain}
        />
      </View>
    ) : null

  const checkReload = (interval: number | null) => {
    if (!interval) {
      return
    }
    const time = interval < 60 ? 60 : interval
    setInterval(() => {
      dispatch(CartographyRealTimeAction.loadRealTimeMapItems(id))
    }, time * 1000)
  }

  const renderModal = ((zoneSelected: RealTimeMapItem | null) => {
    const type = zoneSelected?.info?.type
    switch(type) {
      case 'HORAIRES_MAREES':
        return (
          <ModalTideTime
            isVisible={!!zoneSelected}
            onClose={() => setZoneSelected(null)}
            item={zoneSelected}
            onReload={checkReload}
          />
        )
      default:
        return (
          <ModalRealTime
            isVisible={!!zoneSelected}
            onClose={() => setZoneSelected(null)}
            item={zoneSelected}
            onReload={checkReload}
          />
        )
    }
  })

  const fitToCoordinates = () => {
    if (
      !mapViewRef.current ||
      !realTimeMapItems ||
      isEmpty(realTimeMapItems) ||
      !realTimeMap ||
      !realTimeMap.schedule.open ||
      !isMapReady
    ) {
      return
    }

    const coordinates = realTimeMapItems.items.map(item => item.positionMap)
    const padding = theme.padding.x4
    fitToCoords(mapViewRef, coordinates, padding)
  }

  useEffect(() => {
    dispatch(CartographyRealTimeAction.loadRealTimeMap(id))
  }, [])

  useEffect(() => {
    if (realTimeMap) {
      dispatch(CartographyRealTimeAction.loadRealTimeMapItems(id))
      if (realTimeMap && realTimeMap.partner) {
        checkReload(realTimeMap.partner.interval)
      }
    }
  }, [realTimeMap])

  useEffect(() => {
    fitToCoordinates()
  }, [realTimeMapItems])

  const renderItems = () => realTimeMapItems && !isEmpty(realTimeMapItems)
      ? realTimeMapItems.items.map(item => renderItem(item))
      : null

  const RenderModalCenter: FunctionComponent = () => {
    if (!realTimeMap || realTimeMap.schedule.open) {
      return null
    }

    return (
      <ModalCenter
        isVisible
        title="indisponible"
        contentHTML={realTimeMap.schedule.msgClosed}
      />
    )
  }

  const centerToMarker = (item: RealTimeMapItem) => {
    if (!mapViewRef.current) {
      return
    }

    mapViewRef.current.animateCamera(
      {
        center: item.positionMap,
        // iOS
        altitude: DEFAULT_ALTITUDE,
        // Android
        zoom: DEFAULT_ZOOM,
      },
      { duration: ANIMATION_DURATION },
    )
  }

  const onLayout = () => {
    setIsMapReady(true)
  }

  return (
    <View style={styles.viewContainer}>
      {isLoadingConfig || isLoadingItems ? <CartographyLoading /> : null}
      <MapView
        ref={mapViewRef}
        style={styles.viewMap}
        showsPointsOfInterest={false}
        showsUserLocation
        showsCompass
        showsTraffic={false}
        zoomEnabled
        onLayout={onLayout}
        initialRegion={INITIAL_REGION}
      >
        {renderItems()}
      </MapView>
      <RenderModalCenter />
      {renderModal(zoneSelected)}
    </View>
  )
}

const Z_INDEX = 1000

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  viewMap: {
    flex: 1,
    height: theme.deviceHeight,
    width: theme.deviceWidth,
  },
  selected: {
    zIndex: Z_INDEX,
  },
  markerImage: theme.markerImage,
  selectedMarkerImage: theme.selectedMarkerImage,
  markerImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
