import { Icon, Text, View } from 'native-base'
import React, { FC } from 'react'
import { StyleSheet } from 'react-native'
import { iconFont } from '../../../services/Icon'
import { theme } from '../../../theme'

interface ValidationItemProps {
  validated: boolean
  text: string
}
export const ValidationItem: FC<ValidationItemProps> = ({ validated, text }) => {
  return (
    <View style={styles.wrapper}>
      <Icon name={validated ? 'checkmark' : 'close'} color={validated ? theme.colors.green : theme.colors.red} as={iconFont('')} size={6} />
      <Text style={validated ? styles.validated : styles.notValidated}>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    gap: 4,
  },
  validated: {
    color: theme.colors.green,
  },
  notValidated: {
    color: theme.colors.red,
  },
})
