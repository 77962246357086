import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { ComposantItem } from '../../../api/Cantine/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { Card } from '../../Card'

const IMAGE_SIZE = 30

interface DishsProps {
  dishs: ComposantItem[]
}

export const Dishes: FunctionComponent<DishsProps> = ({ dishs }) => {
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
  const renderDishs = () =>
    dishs.map((dish, index) => (
      <View key={index} style={styles.container}>
        <View style={styles.left} />
        <View style={styles.body}>
          {dish.title && <Text style={[styles.title, { color: colorText }]}>{dish.title}</Text>}
          <Text style={styles.text}>{dish.value}</Text>
        </View>
        <View style={styles.right}>{dish.image ? <FastImage style={styles.image} source={{ uri: dish.image }} resizeMode={FastImage.resizeMode.contain} /> : null}</View>
      </View>
    ))

  return <Card style={styles.card}>{renderDishs()}</Card>
}

const styles = StyleSheet.create({
  card: {
    marginTop: theme.padding.x3,
    // flex: 1,
  },
  container: {
    paddingVertical: theme.padding.x3,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  left: {
    width: IMAGE_SIZE,
    marginHorizontal: theme.padding.x1,
  },
  right: {
    width: IMAGE_SIZE,
    marginHorizontal: theme.padding.x1,
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
  },
  body: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    color: theme.colors.silverStrong,
    textTransform: 'uppercase',
  },
  text: {
    textAlign: 'center',
  },
})
