import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { CartographyRealTimeActionTypes } from './types'
import { RealTimeMap, RealTimeMapItems } from '../../api/CartographyRealTime/type'

export const CartographyRealTimeAction = {
  loadRealTimeMap: (id: number) => createAction(CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP, { id }),
  loadRealTimeMapItems: (id: number) => createAction(CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP_ITEMS, { id }),
  storeRealTimeMap: (id: number, config: RealTimeMap) => createAction(CartographyRealTimeActionTypes.STORE_REAL_TIME_MAP, { id, config }),
  storeRealTimeMapItems: (id: number, items: RealTimeMapItems) => createAction(CartographyRealTimeActionTypes.STORE_REAL_TIME_MAP_ITEMS, { id, items }),
}

export type CartographyRealTimeActionObjectTypes = ActionsUnion<typeof CartographyRealTimeAction>
