import React, { FunctionComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { ReferentielLink } from '../../../api/Referentiels/types'
import { MasonryList } from '../../MasonryList'
import { ReferentielLinkItem } from './ReferentielLinkItem.component'

interface ReferentielLinksProps {
  links: ReferentielLink[]
}

export const ReferentielLinks: FunctionComponent<ReferentielLinksProps> = ({ links }) => {
  if (isEmpty(links)) {
    return null
  }
  return (
    <MasonryList<ReferentielLink>
      data={links}
      columns={2}
      columnHeight={70}
      columnJustify="flex-start"
      renderItem={(item: ReferentielLink) => <ReferentielLinkItem key={item.id} item={item} />}
    />
  )
}
