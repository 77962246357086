import React, { FunctionComponent } from 'react'
import { View, Text } from 'native-base'
import { StyleSheet } from 'react-native'
import { dateToDayMonthYear, dateToDayOfWeek, dateToDayMonth } from '../../../services/dateFormatter'

interface WeatherDateProps {
  date: number
  color: string
  light: boolean
  width: number
  dateShort?: boolean
}

export const WeatherDate: FunctionComponent<WeatherDateProps> = ({ date, color, light, dateShort = false }) => {
  const styles = dateStyle(light, color)

  const renderDate = () => {
    if (dateShort) {
      return dateToDayMonth(date)
    }
    return dateToDayMonthYear(date)
  }
  return (
    <View style={styles.content}>
      <Text style={styles.date}>{dateToDayOfWeek(date)}</Text>
      <Text style={styles.date}>{renderDate()}</Text>
    </View>
  )
}

const dateStyle = (modeLight: boolean, color: string) =>
  /* eslint react-native/no-unused-styles: 0 */
  StyleSheet.create({
    content: {
      display: modeLight ? 'none' : 'flex',
      flex: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
      alignSelf: 'center',
      justifyContent: 'center',
      height: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
      paddingLeft: 10,
    },
    date: {
      color,
    },
  })
