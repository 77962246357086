import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { CantineDay } from '../../components/CantineDay'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'
import { CantineActions } from '../../redux/cantine/action'
import { cantineSelector } from '../../redux/cantine/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { dateToHuge } from '../../services/dateFormatter'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme'

const Cantine: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Cantine'>> = ({ route }) => {
  const title = route.params.titre
  const { id } = route.params
  const libelleCantine = route.params.cantineLibelle

  const dispatch = useDispatch()

  const cantine = useSelector((state: RootState) => cantineSelector(state, id as number))

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_CANTINE))

  const renderContent = () => {
    if (!cantine || isLoading) {
      return <Spinner />
    }

    return (
      <>
        {cantine.ecole && libelleCantine ? <Text style={styles.title}>{cantine.ecole}</Text> : null}
        {!cantine.journees || isEmpty(cantine.journees) ? (
          <ListEmpty />
        ) : (
          <Carousel
            mode="parallax"
            data={cantine.journees}
            renderItem={journee => <CantineDay item={journee.item} />}
            width={theme.deviceWidth}
            loop={false}
            onSnapToItem={(index: number) => {
              trackEvent({
                category: 'Cantine',
                actionName: 'Cantine',
                eventName: `Changement de date`,
                value: cantine.journees ? dateToHuge(cantine.journees[index].day as number) : '',
              })
            }}
          />
        )}
      </>
    )
  }

  useEffect(() => {
    dispatch(CantineActions.loadCantine(id as number, true))
  }, [])

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <View style={styles.content}>{renderContent()}</View>
    </Page>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
    marginVertical: theme.padding.x1,
  },
})

export { Cantine }
