import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Source } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { ReferentielItemScheduleResult, ReferentielListItemResult } from '../../api/Referentiels/types'
import { Routes } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'
import { FastImageBackground } from '../FastImageBackground'
import { AgendaSchedule } from './components/AgendaSchedule.component'

import imageDefault from '../../assets/images/empty_image.jpg'

const BORDER_RADIUS = 4
const IMAGE_HEIGHT = theme.deviceHeight / 4
const IMAGE_OPACITY = 0.9

interface AgendaListItemProps {
  item: ReferentielListItemResult
  author?: boolean
  withInformations?: boolean
}

export const AgendaListItem: FunctionComponent<AgendaListItemProps> = ({ item, author = false, withInformations = true }) => {
  if (!item) {
    return null
  }
  const themeResult = useSelector(themeSelector)

  const colorText = () => {
    if (themeResult && themeResult.customAgendaInfoTextColor) {
      return { color: themeResult.customAgendaInfoTextColor }
    }
    return {}
  }
  const colorBackground = () => {
    if (themeResult && themeResult.customAgendaInfoBackgroundColor) {
      return { backgroundColor: themeResult.customAgendaInfoBackgroundColor }
    }
    return { backgroundColor: theme.colors.white }
  }
  const renderSchedule = (schedule: ReferentielItemScheduleResult | null) => {
    if (!withInformations || !schedule || (!schedule.start && !schedule.end)) {
      return <View />
    }
    return (
      <View style={[styles.opacity, colorBackground()]}>
        <AgendaSchedule schedule={schedule} colorStyle={colorText()} />
      </View>
    )
  }

  const image = (item.image && { uri: item.image }) || imageDefault

  const renderCategorie = (authorName: boolean) => {
    if (authorName && item.author) {
      if (item.categorie) {
        return `${item.author.libelle} - ${item.categorie}`
      }
      return item.author.libelle
    }
    if (item.categorie) {
      return item.categorie
    }
    return ''
  }

  const info = withInformations ? (
    <View style={[styles.opacity, styles.info, colorBackground()]}>
      {item.libelle && (
        <Text numberOfLines={1} style={colorText()}>
          {item.libelle}
        </Text>
      )}
      {(author || item.categorie) && (
        <Text numberOfLines={1} style={[styles.categorie, colorText()]}>
          {renderCategorie(author)}
        </Text>
      )}
    </View>
  ) : null

  return (
    <TouchableOpacity
      style={styles.content}
      onPress={getItemAction({
        type: 'view',
        viewName: Routes.Referentiel,
        params: { id: item.id, titre: item.libelle, image: item.image },
      })}
    >
      <FastImageBackground source={image as Source} style={styles.image} imageStyle={styles.imageBackGround} resizeMode="cover">
        <View style={styles.container}>
          {renderSchedule(item.schedule)}

          {info}
        </View>
      </FastImageBackground>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: theme.padding.x2,
    paddingVertical: theme.padding.x1,
  },
  image: {
    width: '100%',
    height: IMAGE_HEIGHT,
    justifyContent: 'space-between',
    borderRadius: BORDER_RADIUS,
  },
  imageBackGround: {
    borderRadius: BORDER_RADIUS,
  },
  container: {
    height: '100%',
    justifyContent: 'space-between',
  },
  opacity: {
    opacity: IMAGE_OPACITY,
    backgroundColor: theme.colors.white,
    borderRadius: BORDER_RADIUS,
    padding: theme.padding.x1,
    margin: theme.padding.x1,
    alignSelf: 'flex-start',
  },
  info: {
    width: '80%',
  },
  categorie: {
    color: theme.colors.silver,
    fontWeight: 'bold',
  },
})
