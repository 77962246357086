import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { NotificationApi } from '../../api/Notification/NotificationApi'
import { NotificationSettingsResult, NotificationUserItem, NotificationsResult } from '../../api/Notification/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActionNotificationObjectTypes, NotificationActions } from './action'
import { notificationsPagingSelector, tokenSelector } from './selector'
import { NotificationActionTypes, notificationDB } from './types'

const PAGE_SIZE = 40

function* sendNotificationTokenSaga(action: ActionsOfType<ActionNotificationObjectTypes, NotificationActionTypes.SEND_NOTIFICATION_TOKEN>) {
  const currentToken = yield select(tokenSelector)
  const tokenChanged = currentToken !== action.payload.token
  if (tokenChanged) {
    const enable = !!action.payload.token
    yield call(NotificationApi.sendNotificationsToken, action.payload.token)
    yield put(NotificationActions.storeNotificationEnabled(enable, action.payload.token))
  }
}

function* loadHaveNewNotificationsSaga() {
  try {
    const haveViewNotication: boolean = yield call(NotificationApi.haveViewNotification)
    yield put(NotificationActions.storeHaveNotification(!haveViewNotication))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* clearHasNewNotificationsSaga() {
  try {
    yield call(NotificationApi.postViewNotification)
    yield put(NotificationActions.storeHaveNotification(false))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* LoadNotificationsSaga(action: ActionsOfType<ActionNotificationObjectTypes, NotificationActionTypes.LOAD_NOTIFICATIONS>) {
  const paging: PagingResult<NotificationUserItem> = yield select(notificationsPagingSelector)
  let page = 0
  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }
  try {
    const notifResult: NotificationsResult = yield call(NotificationApi.load, page, PAGE_SIZE)
    if (action.payload.clear) {
      yield put(NotificationActions.storeClearNotifications(notifResult))
    } else {
      yield put(NotificationActions.storeNotifications(notifResult))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* refreshNotificationsSaga() {
  yield call(LoadNotificationsSaga, NotificationActions.loadNotifications(true))
}

function* LoadNotificationsSettingsSaga() {
  try {
    const settings: NotificationSettingsResult = yield call(NotificationApi.loadSettings)
    yield put(NotificationActions.storeNotificationSettings(settings))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* NotificationSettingsEnableSaga(action: ActionsOfType<ActionNotificationObjectTypes, NotificationActionTypes.NOTIFICATION_ALL_ENABLED>) {
  try {
    yield call(NotificationApi.notificationSettingEnabled, action.payload.enabled)
    yield LoadNotificationsSettingsSaga()
  } catch (error) {
    console.error(error)
  }
}

function* NotificationCategorieEnableSaga(action: ActionsOfType<ActionNotificationObjectTypes, NotificationActionTypes.NOTIFICATION_CATEGORIE_ENABLED>) {
  try {
    yield call(NotificationApi.notificationCategorieEnabled, action.payload.id, action.payload.enabled)
    yield LoadNotificationsSettingsSaga()
  } catch (error) {
    console.error('ERROR =', error)
  }
}

function* GetOneNotificationSaga(action: ActionsOfType<ActionNotificationObjectTypes, NotificationActionTypes.GET_ONE_NOTIFICATION>) {
  try {
    const notification: notificationDB = yield call(NotificationApi.getOneNotification, action.payload.id)
    yield put(NotificationActions.storeOneNotification(notification))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadNotificationsWatcher() {
  yield takeEvery(NotificationActionTypes.LOAD_NOTIFICATIONS, addLoader(LoadNotificationsSaga, LoaderName.LOAD_NOTIFICATIONS))
}

export function* loadNotificationsSettingsWatcher() {
  yield takeEvery(NotificationActionTypes.LOAD_NOTIFICATION_SETTINGS, addLoader(LoadNotificationsSettingsSaga, LoaderName.LOAD_NOTIFICATION_SETTINGS))
}

export function* notificationSettingsEnabledWatcher() {
  yield takeEvery(NotificationActionTypes.NOTIFICATION_ALL_ENABLED, NotificationSettingsEnableSaga)
}

export function* notificationCategorieEnabledWatcher() {
  yield takeEvery(NotificationActionTypes.NOTIFICATION_CATEGORIE_ENABLED, NotificationCategorieEnableSaga)
}

export function* sendNotificationTokenWatcher() {
  yield takeEvery(NotificationActionTypes.SEND_NOTIFICATION_TOKEN, sendNotificationTokenSaga)
}

export function* loadHaveNotificationsSagaWatcher() {
  yield takeEvery(NotificationActionTypes.LOAD_HAS_NEW_NOTIFICATION, loadHaveNewNotificationsSaga)
}

export function* updateHaveNotificationsSagaWatcher() {
  yield takeEvery(NotificationActionTypes.CLEAR_HAS_NEW_NOTIFICATION, clearHasNewNotificationsSaga)
}

export function* refreshNotificationWatcher() {
  yield takeEvery(NotificationActionTypes.REFRESH_NOTIFICATIONS, addLoader(refreshNotificationsSaga, LoaderName.REFRESH_NOTIFICATIONS))
}

export function* GetOneNotificationWatcher() {
  yield takeEvery(NotificationActionTypes.GET_ONE_NOTIFICATION, GetOneNotificationSaga)
}
