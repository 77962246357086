import { StackScreenProps } from '@react-navigation/stack'
import { Container } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { CityItemResult } from '../../api/City/types'
import { CityNotification } from '../../components/CityNotification'
import { Body, Content, Header, Left, Right, Title } from '../../components/NativeBaseLegacy'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'

export const CityEdit: FunctionComponent<StackScreenProps<ActionViewParamsType, 'cityEdit'>> = ({ route }) => {
  const city = route.params.city as CityItemResult

  return (
    <Container style={styles.container}>
      <Header>
        <Left style={styles.left} />
        <Body style={styles.body}>
          <Title>{i18n.t('notifications.notifications')}</Title>
        </Body>
        <Right style={styles.right} />
      </Header>
      <Content style={styles.content}>
        <CityNotification city={city} saveText={i18n.t('action.update')} />
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    zIndex: 1,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  right: {
    zIndex: 2,
    flex: 0,
    width: 100,
    justifyContent: 'flex-end',
  },
  content: {
    flex: 1,
  },
  container: {
    maxWidth: '100%',
  },
})
