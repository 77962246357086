import { env as envLocal } from './index.local'

export const env = () => {
  const { location } = window
  if (location.search !== '') {
    const overideEnv = location.search.replace('?env=', '')
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require, import/no-dynamic-require
    const { env: envLoaded } = require(`./index.${overideEnv}.ts`)
    return envLoaded
  }
  return envLocal
}
