/* eslint react-native/no-unused-styles: 0 */
import isEmpty from 'lodash/isEmpty'
import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { WeeklySchedulesReferentielItemResult } from '../../../api/WeeklySchedules/types'
import { i18n } from '../../../lib/i18n'
import { Routes } from '../../../navigation/Routes'
import { getItemAction } from '../../../services/itemAction'
import { theme } from '../../../theme'
import { Body, Left, Right } from '../../NativeBaseLegacy'
import { WeekReferentielImage } from './WeekReferentielImage.component'

interface WeekItemLegendProps {
  referentiels: WeeklySchedulesReferentielItemResult[]
}

export const WeekItemLegend: FunctionComponent<WeekItemLegendProps> = ({ referentiels }) => {
  if (isEmpty(referentiels)) {
    return null
  }

  const renderItem = (referentiel: WeeklySchedulesReferentielItemResult) => (
    <TouchableOpacity
      key={referentiel.id}
      style={styles.labelContainer}
      onPress={getItemAction({
        type: 'view',
        viewName: Routes.Referentiel,
        params: { id: referentiel.id, titre: referentiel.libelle, image: referentiel.image },
      })}
    >
      <Left style={styles.left}>
        <WeekReferentielImage image={referentiel.image} />
      </Left>
      <Body style={styles.body}>
        <Text style={styles.bodyText} numberOfLines={1}>
          {referentiel.libelle}
        </Text>
      </Body>
      <Right style={styles.right}>
        <Icon as={Ionicons} name="arrow-forward" size={6} />
      </Right>
    </TouchableOpacity>
  )
  return (
    <View style={styles.container}>
      <Text>{i18n.t('legend')} :</Text>
      {referentiels.map(item => renderItem(item))}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  container: {
    marginTop: theme.padding.x2,
    marginHorizontal: theme.padding.x3,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.padding.x2,
  },
  bodyText: {
    textAlign: 'left',
  },
  left: {
    marginRight: theme.padding.x1,
  },
  right: {
    marginLeft: theme.padding.x1,
  },
})
