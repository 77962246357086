import isEmpty from 'lodash/isEmpty'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { MovieItemResult } from '../../api/Movie/types'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'
import { datetimeToHuge, toHHMM } from '../../services/dateFormatter'
import { getItemAction } from '../../services/itemAction'
import { isNullOrUndefined } from '../../services/textUtil'
import { theme as defaultTheme } from '../../theme'
import { Card } from '../Card'

interface MovieCardProps {
  movie: MovieItemResult
}

const MovieCard: FunctionComponent<MovieCardProps> = ({ movie }) => {
  const theme = useSelector(themeSelector)
  const hasMeetingToday = !isEmpty(movie.meetingToday)
  const hasHourInfo = hasMeetingToday || movie.nextDay

  const primary = theme ? theme.brandPrimary : defaultTheme.colors.blue
  const warning = theme ? theme.brandWarning : defaultTheme.colors.orange

  const subTitle = [movie.categories, movie.duree].filter(info => !isNullOrUndefined(info)).join(' - ')

  const goToMovie = getItemAction({
    type: 'view',
    params: {
      id: movie.id,
      image: movie.image,
    },
    viewName: Routes.Cinema,
  })

  return (
    <Card>
      <TouchableOpacity style={styles.container} onPress={goToMovie}>
        <View style={styles.body}>
          {movie.image && (
            <View style={styles.imageContainer}>
              <FastImage source={{ uri: movie.image }} style={styles.image} resizeMode="cover" />
            </View>
          )}
          <View style={styles.textContent}>
            <Text numberOfLines={1} style={styles.title}>
              {movie.libelle}
            </Text>
            {subTitle ? (
              <Text numberOfLines={1} style={styles.categories}>
                {subTitle}
              </Text>
            ) : null}

            <Text numberOfLines={5} style={styles.description}>
              {movie.description}
            </Text>
          </View>
        </View>
        {hasHourInfo && (
          <View style={styles.hours}>
            {hasMeetingToday && (
              <>
                <Text>{i18n.t('movie.todayAt')}</Text>
                {movie.meetingToday.map(hour => (
                  <Text key={hour} style={[styles.hour, { backgroundColor: warning }]}>
                    {toHHMM(hour)}
                  </Text>
                ))}
              </>
            )}
            {movie.nextDay && (
              <>
                <Text>{i18n.t('movie.next')}</Text>
                <Text style={[styles.next, { color: primary }]}>{datetimeToHuge(movie.nextDay)}</Text>
              </>
            )}
          </View>
        )}
      </TouchableOpacity>
    </Card>
  )
}

const BODY_HEIGHT = 150
const LINE_HEIGHT = 16
const PADDING_TOP = 2
const MARGIN_TEXT = 4
const BORDER_RADIUS = 4

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: BODY_HEIGHT,
    // width: '100%',
  },
  textContent: {
    flex: 1,
    paddingHorizontal: defaultTheme.padding.x1,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginVertical: MARGIN_TEXT,
  },
  categories: {
    color: defaultTheme.colors.silver,
    marginBottom: MARGIN_TEXT,
  },
  description: {
    flex: 1,
    paddingTop: PADDING_TOP,
    lineHeight: LINE_HEIGHT,
  },
  imageContainer: {
    height: '100%',
  },
  image: {
    width: 110,
    height: '100%',
  },
  hours: { flex: 1, flexDirection: 'row', margin: MARGIN_TEXT, alignItems: 'center' },
  hour: {
    padding: MARGIN_TEXT,
    marginHorizontal: MARGIN_TEXT,
    borderRadius: BORDER_RADIUS,
    color: defaultTheme.colors.white,
  },
  next: {
    marginHorizontal: MARGIN_TEXT,
    textTransform: 'lowercase',
  },
})

export { MovieCard }
