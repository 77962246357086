import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { ReferentielInfoResult } from '../../../api/Referentiels/types'
import { BubbleAction } from '../../BubbleAction'
import { Card } from '../../Card'

interface ReferentielInfoProps {
  item: ReferentielInfoResult
}

export const ReferentielInfo: FunctionComponent<ReferentielInfoProps> = ({ item }) => {
  if (!item) {
    return null
  }
  return (
    <Card spacing column>
      <Text style={styles.text}>{item.libelle}</Text>
      {item.value && (
        <View>
          <BubbleAction
            link={{
              labelIn: item.value,
            }}
          />
        </View>
      )}
    </Card>
  )
}

const styles = StyleSheet.create({
  text: { flex: 1 },
})
