import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { WeatheIconerResult } from '../../../api/GridPage/types'
import { iconFont } from '../../../services/Icon'
import { weatherConditions } from '../../../services/weather'
import { theme } from '../../../theme'

const MIN_WIDTH = 60

interface WeatherItemProps {
  icon: WeatheIconerResult | null
  temperature: number
  color: string
  light: boolean
  width: number
}

export const WeatherItem: FunctionComponent<WeatherItemProps> = ({ icon, temperature, color, light, width }) => {
  if (!icon) {
    return <View />
  }

  const temp = width > MIN_WIDTH
  const styles = itemStyle(light, color, temp)

  const renderWeather = (weather: WeatheIconerResult) => {
    const condition = weatherConditions(weather.icone)
    if (!condition) {
      return <View />
    }

    return <Icon name={condition.icon} as={iconFont(condition.type)} style={styles.icon} color={color} size={9} />
  }

  return (
    <View style={styles.content}>
      {renderWeather(icon)}
      <Text style={styles.temperature}>{Math.floor(temperature)}°</Text>
    </View>
  )
}

const itemStyle = (modeLight: boolean, color: string, tempUsed: boolean) =>
  /* eslint react-native/no-unused-styles: 0 */
  StyleSheet.create({
    content: {
      justifyContent: modeLight ? 'center' : 'flex-end',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      height: '100%',
      overflow: 'hidden',
    },
    icon: {
      justifyContent: 'flex-end',
    },
    temperature: {
      display: tempUsed ? 'flex' : 'none',
      fontSize: theme.textSize.x4,
      lineHeight: theme.textSize.x4,
      paddingLeft: theme.padding.x1,
      color,
    },
  })
