import { StackScreenProps } from '@react-navigation/stack'
import { Spinner } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { FormApi } from '../../api/Form'
import { FormResponseResult, FormResult, FormSubmit } from '../../api/Form/types'
import { DefaultFieldValue } from '../../components/Fields/types'
import { FormBase } from '../../components/Form'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { addGDPRCheckbox, getFormDefaultValues, initDefaultValues, sanitizeFormikValues } from '../../services/form'
import { trackEvent } from '../../services/matomo/matomo.service'
import { showSuccess } from '../../services/toast'

const Formulaire: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Formulaire'>> = ({ navigation, route }) => {
  const id = route.params.id as number
  const title = route.params.titre
  const [form, setForm] = useState<FormResult | null>(null)
  const [defaultValues, setDefaultValues] = useState<DefaultFieldValue | null>(null)
  const [mapFieldIdTypes, setMapFieldIdTypes] = useState<Map<number, string | null>>(new Map())
  const [hasError, setHasError] = useState(false)

  const initializeForm = async () => {
    try {
      const formResult = await FormApi.loadForm(id)
      if (!formResult) {
        return
      }

      formResult.formModel.elements = addGDPRCheckbox(formResult.formModel.rgpdMessage, formResult.formModel.elements)
      setForm(formResult)
    } catch (error) {
      setHasError(true)
    }
  }

  const initializeDefaultValues = () => {
    if (!form) {
      return
    }

    setDefaultValues({
      ...initDefaultValues(form.formModel.elements),
      ...getFormDefaultValues(form.defaultValues),
    })
  }

  const initializeMapFieldTypeById = () => {
    if (!form) {
      return
    }
    const map = new Map<number, string | null>()
    form.formModel.elements.forEach(field => map.set(field.id, field.type))
    setMapFieldIdTypes(map)
  }

  useEffect(() => {
    initializeForm()
  }, [])

  useEffect(initializeDefaultValues, [form])
  useEffect(initializeMapFieldTypeById, [form])

  if (hasError) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <ListEmpty libelle={i18n.t('form.noForm')} />
      </Page>
    )
  }

  if (!form || !defaultValues) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <Spinner style={styles.container} />
      </Page>
    )
  }

  const handleFormResponse = (response: FormResponseResult): Map<number, string> => {
    const errors = new Map<number, string>()
    if (response.success) {
      navigation.popToTop()
      showSuccess(i18n.t('form.success'))
      return new Map()
    }
    response.errors.forEach(error => errors.set(error.id, error.message))

    return errors
  }

  const submitForm = async (values: DefaultFieldValue) => {
    try {
      const form: FormSubmit = {
        id,
        reponses: sanitizeFormikValues(values, mapFieldIdTypes),
        rgpd: true,
      }
      const response = await FormApi.submitForm(id, form)
      trackEvent({
        category: 'Formulaire',
        actionName: 'Formulaire',
        eventName: `Appui sur le bouton envoyer`,
        value: 'Envoi du formulaire',
      })
      return handleFormResponse(response)
    } catch (error) {
      console.error(error)
    }
    return new Map()
  }

  const { content, elements } = form.formModel

  return (
    <Page left={<LeftButtonBack />} title={content.titre}>
      <FormBase fields={elements} defaultValues={defaultValues} description={content.description} buttonText={content.buttomText} submit={submitForm} />
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export { Formulaire }
