import { Reducer } from 'redux'
import { MenuState, LoadMenuActionTypes } from './types'
import { MenuActionObjectTypes } from './actions'

const initialMenuState: MenuState = null

export const MenuReducer: Reducer<MenuState, MenuActionObjectTypes> = (state = initialMenuState, action) => {
  switch (action.type) {
    case LoadMenuActionTypes.STORE_MENU:
      return {
        ...state,
        ...action.payload.menu,
      }

    default:
      return state
  }
}
