import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { ActualiteItemResult } from '../../../api/GridPage/types'
import { Routes } from '../../../navigation/Routes'
import { getItemAction } from '../../../services/itemAction'
import { theme } from '../../../theme'
import { CardItem, Thumbnail } from '../../NativeBaseLegacy'

interface CardLineProps {
  item: ActualiteItemResult
  height: number
}

export const CardLineItem: FunctionComponent<CardLineProps> = props => {
  const itemStyle = cardItemStyle(props.height)
  const { item } = props

  return (
    <TouchableOpacity
      style={itemStyle.content}
      onPress={getItemAction({
        type: 'view',
        viewName: Routes.Actualite,
        params: { id: item.id, titre: item.libelle, image: item.image },
      })}
    >
      <Card mx="1" mb="1" mt="2" p="0" style={itemStyle.card}>
        <CardItem>
          <View style={itemStyle.body}>
            <View style={itemStyle.bodyContent}>
              <Text numberOfLines={2} style={itemStyle.titre}>
                {item.libelle}
              </Text>
              <Text style={itemStyle.note} numberOfLines={2}>
                {item.description}
              </Text>
            </View>
            <View style={itemStyle.bodyImage}>{item.image && <Thumbnail square large source={{ uri: item.image }} />}</View>
          </View>
        </CardItem>
      </Card>
    </TouchableOpacity>
  )
}

export const cardItemStyle = (height: number) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    note: {
      opacity: 0.4,
    },
    content: {
      height,
      width: '100%',
      paddingBottom: theme.padding.x1,
    },
    card: {
      marginTop: 0,
      height: '100%',
    },
    body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    bodyContent: { flex: 1, paddingRight: theme.padding.x1 },
    titre: {
      paddingBottom: theme.padding.x1,
    },
    bodyImage: { flex: 0 },
  })
