import { FavorieUserItem } from '../../api/Favorites/types'

export type FavoritesUserState = {
  favorites: FavorieUserItem[]
}

export enum FavoritesUserActionTypes {
  LOAD_FAVORITES_USER = 'LOAD_FAVORITES_USER',
  STORE_FAVORITES_USER = 'STORE_FAVORITES_USER',
  STORE_FAVORITE_USER = 'STORE_FAVORITE_USER',
  ADD_FAVORITES_USER = 'ADD_FAVORITES_USER',
  DELETE_FAVORITES_USER = 'DELETE_FAVORITES_USER',
  ADD_MULTIPLE_FAVORITES_USER = 'ADD_MULTIPLE_FAVORITES_USER',
  PUSH_FAVORITES_USER_IDS = 'PUSH_FAVORITES_USER_IDS',
  LOAD_FAVORITES_ITEM = 'LOAD_FAVORITES_ITEM',
  UPDATE_FAVORITE_USER = 'UPDATE_FAVORITE_USER',
}
