import { BaseApi } from '../BaseApi'
import { SignalementConfigResult, SignalementsResult, CategoriesResult } from './types'
import { urlApi } from '../url'
import { FormSubmit, FormResponseResult } from '../Form/types'

export class SignalementApi {
  public static async load(page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url('v1/signalements')
      .query({ page, size })
      .get()
      .json(json => json) as Promise<SignalementsResult>
  }

  public static async loadItem(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/signalements/${id}`)
      .get()
      .json(json => json) as Promise<SignalementsResult>
  }

  public static async loadSignalement() {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.default)
      .get()
      .json(json => json) as Promise<SignalementConfigResult>
  }

  public static async submitSignalement(form: FormSubmit) {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.submit)
      .post(form)
      .json(json => json) as Promise<FormResponseResult>
  }

  public static async loadCategories() {
    return BaseApi.authenticatedCall()
      .url(urlApi.signalement.categories)
      .get()
      .json(json => json) as Promise<CategoriesResult>
  }
}
