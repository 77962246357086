import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from '../../navigation/Routes'
import { GridPageActions } from '../../redux/gridPage/actions'
import { airQualityConfigSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { navigate } from '../../services/navigation'
import { getColor } from '../../services/theme'
import { WeatherItem } from '../GridPageWeather/components/WeatherItem.component'
import { AirQualityDay } from './components/AirQualityDay.component'

interface GridPageAirQualityProps {
  id: number
  top: number
  left: number
  width: number
  height: number
}

export const GridPageAirQuality: FunctionComponent<GridPageAirQualityProps> = props => {
  const styleAQ = styles(props)

  const gridAirQualityConfig = useSelector((state: RootState) => airQualityConfigSelector(state, props.id))
  const theme = useSelector(themeSelector)

  const isLoadingConfig = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_AIR_QUALITY))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GridPageActions.loadGridAirQualityConfig(props.id))
  }, [props.id])

  if (isLoadingConfig) {
    return (
      <View style={styleAQ.container}>
        <Spinner />
      </View>
    )
  }
  if (!gridAirQualityConfig || !gridAirQualityConfig.airQuality) {
    return (
      <View style={styleAQ.container}>
        <View style={styleAQ.containerView} />
      </View>
    )
  }

  const color = getColor(gridAirQualityConfig.textColor, theme && theme.toolbarDefaultBg)

  const { weather } = gridAirQualityConfig.airQuality
  return (
    <View style={styleAQ.container}>
      <TouchableOpacity
        style={styleAQ.containerView}
        onPress={() =>
          navigate(Routes.AirQuality, {
            id: gridAirQualityConfig && gridAirQualityConfig.airQuality ? gridAirQualityConfig.airQuality.id : null,
          })
        }
      >
        <WeatherItem temperature={weather.temperature} icon={weather.weather ? weather.weather : null} width={props.width} light color={color} />
        <AirQualityDay airQualityConfig={gridAirQualityConfig.airQuality} color={color} />
      </TouchableOpacity>
    </View>
  )
}

const styles = (props: GridPageAirQualityProps) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    container: {
      position: 'absolute',
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    },
    containerView: {
      flex: 1,
      flexDirection: 'row',
    },
  })
