import { useState } from 'react'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { isPasswordValid } from '../../services/form/formValidation'
import { navigate } from '../../services/navigation'
import { showSuccess } from '../../services/toast'
import { userAuthentication } from '../../services/userAuthentication'

export const useChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setconfirmNewPassword] = useState('')

  const isPasswordMatching = newPassword === confirmNewPassword
  const isPasswordAllowed = isPasswordValid(newPassword)

  const doUpdatePassword = async () => {
    setLoading(true)
    await userAuthentication.resetPassword(oldPassword, newPassword)
    setLoading(false)
    showSuccess(i18n.t('changePassword.successMessage'))
    navigate(Routes.HomeTabs)
  }

  return {
    oldPassword,
    newPassword,
    confirmNewPassword,
    setOldPassword,
    setNewPassword,
    setconfirmNewPassword,
    doUpdatePassword,
    loading,
    isPasswordMatching,
    isPasswordAllowed,
  }
}
