import React, { FunctionComponent } from 'react'
import { Text } from 'native-base'
import { FeedItem } from 'react-native-rss-parser'
import FastImage from 'react-native-fast-image'
import { StyleSheet } from 'react-native'
import { theme } from '../../../theme'
import { TouchableAction } from '../../TouchableAction'
import { ItemAction } from '../../../api/types'

interface GridPageRssFeedItemProps {
  item: FeedItem
  image: string | null
  action: ItemAction
}

export const GridPageRssFeedItem: FunctionComponent<GridPageRssFeedItemProps> = ({ item, image, action }) => (
  <TouchableAction style={itemStyle.content} action={action}>
    {image && <FastImage source={{ uri: image }} style={itemStyle.image} />}
    <Text numberOfLines={3} ellipsizeMode="tail" style={itemStyle.text}>
      {item.title}
    </Text>
  </TouchableAction>
)

const itemStyle = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  image: {
    width: 20,
    height: 20,
    marginRight: theme.padding.x1,
  },
  text: { flex: 1 },
})
