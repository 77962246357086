import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { FavoriteConfig } from '../../api/Favorites/types'
import { FavoritesConfigActionTypes } from './types'

export const FavoritesConfigsActions = {
  loadFavoriteConfig: () => createAction(FavoritesConfigActionTypes.LOAD_FAVORITES_CONFIG),
  storeFavoriteConfig: (conf: FavoriteConfig) => createAction(FavoritesConfigActionTypes.STORE_FAVORITES_CONFIG, conf),
}

export type FavoritesUserActionObjectTypes = ActionsUnion<typeof FavoritesConfigsActions>
