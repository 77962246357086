import { Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { theme } from '../../../theme'

interface ReportStatusProps {
  title: string | null
}

export const DisplayTitle: FunctionComponent<ReportStatusProps> = ({ title }) => {
  if (!title) return null
  return (
    <Text style={styles.title} numberOfLines={1}>
      {title}
    </Text>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: theme.textSize.x2_50,
    fontWeight: 'bold',
  },
})
