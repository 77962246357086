import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { JourneeItem } from '../../api/Cantine/types'
import { dateToHuge } from '../../services/dateFormatter'
import { theme } from '../../theme'
import { DishLabelItem, Dishes } from './components'

interface CantineDayProps {
  item: JourneeItem
}

export const CantineDay: FunctionComponent<CantineDayProps> = ({ item }) => (
  <View style={styles.content}>
    {item.day ? <Text style={styles.title}>{dateToHuge(item.day)}</Text> : null}
    <Dishes dishs={item.composants} />
    <DishLabelItem legend={item.legende} />
  </View>
)

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingTop: theme.padding.x2,
  },
  title: {
    textAlign: 'center',
  },
})
