import { Reducer } from 'redux'
import { CantineState, CantineActionTypes } from './types'
import { CantineActionObjectTypes } from './action'

const initialCantineState: CantineState = {
  items: {},
}

export const cantineReducer: Reducer<CantineState, CantineActionObjectTypes> = (state = initialCantineState, action) => {
  switch (action.type) {
    case CantineActionTypes.STORE_CANTINE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.cantineResult,
        },
      }
    default:
      return state
  }
}
