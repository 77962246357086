import { call, put, takeEvery } from 'redux-saga/effects'
import { WeatherApi } from '../../api/Weather/WeatherApi'
import { WeatherDaysResult } from '../../api/Weather/types'
import { isOfflineError } from '../../services/errorManager/errorManager.service'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { WeatherActions } from './actions'
import { WeatherActionTypes } from './types'

export function* loadWeatherDaysSaga() {
  try {
    const result: WeatherDaysResult = yield call(WeatherApi.loadWeatherDays)
    yield put(WeatherActions.storeWeatherDays(result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

export function* loadWeatherDaysWatcher() {
  yield takeEvery(WeatherActionTypes.LOAD_WEATHER_DAYS, addLoader(loadWeatherDaysSaga, LoaderName.LOAD_WEATHER_DAYS))
}
