import { Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { CloseButton } from '../../components/CloseButton'
import { TextInput } from '../../components/TextInput'
import { i18n } from '../../lib/i18n'
import { menuSelector } from '../../redux/menu/selector'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x1,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
})

export const PersonalInfo: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  // const user = useMemo(userAuthentication.getCurrentUser, [])
  const user = null
  // const [initialFirstName, initialLastName] = useMemo(() => {
  //   if (!user || !user.displayName) {
  //     return ['', '']
  //   }
  //   return user.displayName.split(' ')
  // }, [user])
  // const [userFirstName, setUserFirstName] = useState(initialFirstName)
  // const [userLastName, setUserLastName] = useState(initialLastName)

  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')

  if (!user) return null

  const logoUri = menu && menu.header && menu.header.image

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Text style={styles.title}>{i18n.t('personalInfo.title')}</Text>
          {/* <Text style={styles.subtitle}>{user.email}</Text> */}
          <TextInput
            label={i18n.t('personalInfo.firstName.label')}
            placeholder={i18n.t('personalInfo.firstName.placeholder')}
            value={userFirstName}
            onValueChanged={setUserFirstName}
            style={styles.input}
          />
          <TextInput
            label={i18n.t('personalInfo.lastName.label')}
            placeholder={i18n.t('personalInfo.lastName.placeholder')}
            value={userLastName}
            onValueChanged={setUserLastName}
            style={styles.input}
          />
        </View>
      </ScrollView>
    </View>
  )
}
