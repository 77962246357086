import { View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from '../../navigation/Routes'
import { GridPageActions } from '../../redux/gridPage/actions'
import { weatherSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { navigate } from '../../services/navigation'
import { getColor } from '../../services/theme'
import { WeatherDate } from './components/WeatherDate.component'
import { WeatherItem } from './components/WeatherItem.component'

const MIN_WIDTH = 200

interface GridPageWeatherProps {
  id: number
  top: number
  left: number
  width: number
  height: number
}

export const GridPageWeather: FunctionComponent<GridPageWeatherProps> = props => {
  const weatherStyle = weatherStyles(props)
  const dispatch = useDispatch()

  const weather = useSelector((state: RootState) => weatherSelector(state, props.id))
  const theme = useSelector(themeSelector)
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_ACTUALITES))

  const { width } = props
  const modeLight = width < MIN_WIDTH

  useEffect(() => {
    dispatch(GridPageActions.loadWeather(props.id))
  }, [props.id])

  if (!weather || isLoading) {
    return <View />
  }

  const color = getColor(weather.textColor, theme && theme.toolbarDefaultBg)

  return (
    <View style={[weatherStyle.wheatherContainer, { backgroundColor: weather.backgroundColor || 'transparent' }]}>
      <TouchableOpacity style={weatherStyle.containerView} onPress={() => navigate(Routes.Weather)}>
        <WeatherItem temperature={weather.temp} icon={weather.weather ? weather.weather : null} width={width} light={modeLight} color={color} />
        <WeatherDate date={weather.dateUpdate} light={modeLight} color={color} width={props.width} />
      </TouchableOpacity>
    </View>
  )
}

const weatherStyles = (props: GridPageWeatherProps) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    wheatherContainer: {
      position: 'absolute',
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    },
    containerView: {
      flex: 1,
      flexDirection: 'row',
    },
  })
