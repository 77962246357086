import { Linking } from 'react-native'

export const mailto = async (email: string) => {
  if (!email) {
    return
  }

  try {
    const mailto = `mailto:${email}`
    await Linking.openURL(mailto)
  } catch (error) {
    console.error(error)
  }
}
