import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { toDDMMM, toHHMM } from '../../../services/dateFormatter'

interface TimeLineItemProps {
  date: number
  height: number
  color: string
}

export const TimeLineItem: FunctionComponent<TimeLineItemProps> = props => {
  const tlStyle = timelineItemStyle(props.height, props.color)

  return (
    <View style={tlStyle.content}>
      <View>
        {props.date && (
          <>
            <Text style={tlStyle.timeLinedate}>{toDDMMM(props.date)}</Text>
            <Text style={tlStyle.timeLinedate}>{toHHMM(props.date)}</Text>
          </>
        )}
      </View>
      <View style={tlStyle.timeLineBar} />
    </View>
  )
}

export const timelineItemStyle = (height: number, mainColor: string) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    content: {
      height,
      paddingBottom: 20,
      overflow: 'hidden',
    },
    timeLinedate: { textAlign: 'center', color: mainColor },
    timeLineBar: {
      textAlign: 'center',
      width: '50%',
      borderRightWidth: 2,
      height: '100%',
      borderRightColor: mainColor,
    },
  })
