import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { CartographyActionTypes } from './types'
import { CartographyResult, MenuMarkerResult } from '../../api/Cartography/types'

export const CartographyActions = {
  loadCartography: (id: number) => createAction(CartographyActionTypes.LOAD_CARTOGRAPHY, { id }),
  loadCartographyMenuMarkers: (cartographyId: string, menuId: string) =>
    createAction(CartographyActionTypes.LOAD_CARTOGRAPHY_MENU_MARKER, {
      cartographyId,
      menuId,
    }),
  storeCartography: (id: string, cartographyResult: CartographyResult) =>
    createAction(CartographyActionTypes.STORE_CARTOGRAPHY, {
      id,
      cartography: cartographyResult,
    }),
  storeCartographyMarkerMenu: (cartographyId: string, menuId: string, menuMarker: MenuMarkerResult) =>
    createAction(CartographyActionTypes.STORE_CARTOGRAPHY_MENU_MARKER, {
      cartographyId,
      menuId,
      menuMarker,
    }),
}

export type CartographyActionObjectTypes = ActionsUnion<typeof CartographyActions>
