import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'

import { theme } from '../../../../theme'
import { FavoriteButton } from '../FavoriteButton'

interface FavoriteButtonConfigProps {
  editMode: boolean
  onAdd: () => void
  onEdit: () => void
  onQuit: () => void
  showFullAdd: boolean
}

// MARGIN_CONTENT corresponds to the horizontal margin on the sides of the screen
const MARGIN_CONTENT = 7

// ITEM_SIZE corresponds to the size of a square, apply to favorites widgets
const ITEM_SIZE = Math.floor((theme.deviceWidth - MARGIN_CONTENT * 2 - 10) / 3) - 2

export const FavoriteButtonConfig: FunctionComponent<FavoriteButtonConfigProps> = (props: FavoriteButtonConfigProps) => {
  const { editMode, onAdd, onEdit, onQuit, showFullAdd, ...rest } = props

  const displayAddButton = () => {
    if (editMode) {
      return null
    }
    return <FavoriteButton buttonType={showFullAdd ? 'fullAdd' : 'add'} onPress={() => onAdd()} />
  }

  const displayEditButton = () => {
    if (showFullAdd || editMode) {
      return null
    }
    return <FavoriteButton buttonType="edit" onPress={() => onEdit()} />
  }

  const displayQuitButton = () => {
    if (showFullAdd || !editMode) {
      return null
    }
    return <FavoriteButton buttonType="quit" onPress={() => onQuit()} />
  }

  return (
    <View style={styles.buttonWidget} {...rest}>
      {displayAddButton()}
      {displayEditButton()}
      {displayQuitButton()}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonWidget: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    // flex: 1,
  },
})
