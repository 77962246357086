import { StackScreenProps } from '@react-navigation/stack'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import { CantineEliorApi } from '../../api/CantineElior/CantineEliorApi'
import { CantineEliorItemResult } from '../../api/CantineElior/types'
import { CantineEliorDay } from '../../components/CantineElior/CantineEliorDay.component'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { theme } from '../../theme'

export const CantineElior: FunctionComponent<StackScreenProps<ActionViewParamsType, 'CantineElior'>> = ({ route }) => {
  const title = route.params.titre
  const filter = route.params.cantineElior

  const [cantines, setCantines] = useState<CantineEliorItemResult[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState(false)

  const initializeForm = async () => {
    if (!filter) {
      return
    }
    try {
      const cantineResult = await CantineEliorApi.loadCantine(filter)
      if (!cantineResult) {
        return
      }
      setCantines(cantineResult)
    } catch (error) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initializeForm()
  }, [])

  const renderContent = () => {
    if (hasError) {
      return <ListEmpty libelle={i18n.t('serviceUnavailable')} />
    }

    if (loading) {
      return <Spinner />
    }

    if (isEmpty(cantines)) {
      return <ListEmpty />
    }

    const cantinesFiltered = cantines.filter(cantine => {
      const now = DateTime.local()
      return DateTime.fromISO(cantine.Date) >= DateTime.local(now.year, now.month, now.day, 0, 0, 0, 0)
    })

    return <Carousel mode="parallax" data={cantinesFiltered} renderItem={item => <CantineEliorDay day={item.item} />} width={theme.deviceWidth} loop={false} />
  }

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <View style={styles.content}>{renderContent()}</View>
    </Page>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
})
