import I18n from 'i18n-js'
import { Select } from 'native-base'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

interface Props {
  onLanguageChange?: () => void
}

const styles = StyleSheet.create({
  picker: {
    height: 50,
    width: 100,
  },
})

export function ChangeLanguage(props: Props) {
  const [language, setLanguage] = useState(I18n.currentLocale())

  return (
    <Select
      testID="changeLanguagePicker"
      selectedValue={language}
      style={styles.picker}
      onValueChange={value => {
        value = value as string
        I18n.locale = value
        setLanguage(value)

        if (props.onLanguageChange) {
          props.onLanguageChange()
        }

        // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
        // @ts-ignore Todo: Update I18n typings
        return I18n.t.cache.clear()
      }}
    >
      <Select.Item label="FR" value="fr" />
      <Select.Item label="EN" value="en" />
    </Select>
  )
}
