import React, { FunctionComponent } from 'react'
import { Button } from 'react-native'

export const CrashTestButton: FunctionComponent = () => (
  <Button
    title="Crash the app"
    onPress={() => {
      throw new Error('Test crash')
    }}
  />
)
