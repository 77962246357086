import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { FavoritesApi } from '../../api/Favorites'
import { FavoriteSegment, FavoritesCatalogResult } from '../../api/Favorites/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { FavoritesCatalogActionObjectTypes, FavoritesCatalogActions } from './actions'
import { favoritesCatalogByNameSelector, favoritesCatalogByThematicSelector, favoritesCatalogMostUsedSelector, favoritesSubThematicCatalogSelector } from './selector'
import { FavoritesCatalogActionTypes } from './types'

const PAGE_SIZE = 20

function* loadFavoritesCatalogByMostUsedSaga() {
  let page = 0
  const catalogsResult: FavoritesCatalogResult = yield select(favoritesCatalogMostUsedSelector)
  if (catalogsResult && catalogsResult.page !== undefined) {
    page = catalogsResult.page + 1
  }
  try {
    const favoritesCatalogResult: FavoritesCatalogResult = yield call(FavoritesApi.loadFavoritesCatalog, FavoriteSegment.MOST_USED, page, PAGE_SIZE)
    yield put(FavoritesCatalogActions.storeFavoritesCatalogsByMostUsed(favoritesCatalogResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* refreshFavoriteCatalogByMostUsedSaga() {
  yield call(loadFavoritesCatalogByMostUsedSaga)
}

function* loadFavoritesCatalogByThematicSaga() {
  let page = 0
  const catalogsResult: FavoritesCatalogResult = yield select(favoritesCatalogByThematicSelector)
  if (catalogsResult && catalogsResult.page !== undefined) {
    page = catalogsResult.page + 1
  }
  try {
    const favoritesCatalogResult: FavoritesCatalogResult = yield call(FavoritesApi.loadFavoritesCatalog, FavoriteSegment.BY_THEMATIC, page, PAGE_SIZE)
    yield put(FavoritesCatalogActions.storeFavoritesCatalogsByThematic(favoritesCatalogResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* refreshFavoriteCatalogByThematicSaga() {
  yield call(loadFavoritesCatalogByThematicSaga)
}

function* loadFavoritesCatalogByNameSaga() {
  let page = 0
  const catalogsResult: FavoritesCatalogResult = yield select(favoritesCatalogByNameSelector)
  if (catalogsResult && catalogsResult.page !== undefined) {
    page = catalogsResult.page + 1
  }
  try {
    const favoritesCatalogResult: FavoritesCatalogResult = yield call(FavoritesApi.loadFavoritesCatalog, FavoriteSegment.BY_NAME, page, PAGE_SIZE)
    yield put(FavoritesCatalogActions.storeFavoritesCatalogsByName(favoritesCatalogResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* refreshFavoriteCatalogByNameSaga() {
  yield call(loadFavoritesCatalogByNameSaga)
}

function* loadFavoritesSubThematicCatalogSaga(action: ActionsOfType<FavoritesCatalogActionObjectTypes, FavoritesCatalogActionTypes.LOAD_FAVORITES_SUB_THEMATIC_CATALOG>) {
  let page = 0
  const paging: PagingResult<FavoritesCatalogResult> = yield select(favoritesSubThematicCatalogSelector, action.payload.id)
  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }
  try {
    const favoritesSubThematicCatalogResult: FavoritesCatalogResult = yield call(FavoritesApi.loadFavoritesSubThematicCatalog, action.payload.id, page, PAGE_SIZE)
    if (action.payload.clear) {
      yield put(FavoritesCatalogActions.storeClearFavoritesSubThematicCatalogs(favoritesSubThematicCatalogResult, action.payload.id))
    } else {
      yield put(FavoritesCatalogActions.storeFavoritesSubThematicCatalogs(favoritesSubThematicCatalogResult, action.payload.id))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* refreshFavoriteSubThematicCatalogSaga(action: ActionsOfType<FavoritesCatalogActionObjectTypes, FavoritesCatalogActionTypes.REFRESH_FAVORITES_SUB_THEMATIC_CATALOG>) {
  yield call(loadFavoritesSubThematicCatalogSaga, FavoritesCatalogActions.loadFavoritesSubThematicCatalogs(action.payload.id, true))
}

export function* loadFavoritesCatalogByMostUsedWatcher() {
  yield takeEvery(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_MOST_USED, addLoader(loadFavoritesCatalogByMostUsedSaga, LoaderName.LOAD_FAVORITES_CATALOG_BY_MOST_USED))
}

export function* refreshFavoriteCatalogByMostUsedWatcher() {
  yield takeEvery(
    FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_MOST_USED,
    addLoader(refreshFavoriteCatalogByMostUsedSaga, LoaderName.REFRESH_FAVORITES_CATALOG_BY_MOST_USED),
  )
}

export function* loadFavoritesCatalogByThematicWatcher() {
  yield takeEvery(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_THEMATIC, addLoader(loadFavoritesCatalogByThematicSaga, LoaderName.LOAD_FAVORITES_CATALOG_BY_THEMATIC))
}

export function* refreshFavoriteCatalogByThematicWatcher() {
  yield takeEvery(
    FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_THEMATIC,
    addLoader(refreshFavoriteCatalogByThematicSaga, LoaderName.REFRESH_FAVORITES_CATALOG_BY_THEMATIC),
  )
}

export function* loadFavoritesCatalogByNameWatcher() {
  yield takeEvery(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_NAME, addLoader(loadFavoritesCatalogByNameSaga, LoaderName.LOAD_FAVORITES_CATALOG_BY_NAME))
}

export function* refreshFavoriteCatalogByNameWatcher() {
  yield takeEvery(FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_NAME, addLoader(refreshFavoriteCatalogByNameSaga, LoaderName.REFRESH_FAVORITES_CATALOG_BY_NAME))
}

export function* loadFavoritesSubThematicCatalogWatcher() {
  yield takeEvery(FavoritesCatalogActionTypes.LOAD_FAVORITES_SUB_THEMATIC_CATALOG, addLoader(loadFavoritesSubThematicCatalogSaga, LoaderName.LOAD_FAVORITES_SUB_THEMATIC_CATALOG))
}

export function* refreshFavoriteSubThematicCatalogWatcher() {
  yield takeEvery(
    FavoritesCatalogActionTypes.REFRESH_FAVORITES_SUB_THEMATIC_CATALOG,
    addLoader(refreshFavoriteSubThematicCatalogSaga, LoaderName.REFRESH_FAVORITES_SUB_THEMATIC_CATALOG),
  )
}
