import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { IntermediatePageActionTypes } from './types'
import { IntermediatePageResult } from '../../api/GridPage/types'

export const IntermediatePageActions = {
  loadIntermediatePage: (id: number) => createAction(IntermediatePageActionTypes.LOAD_INTERMEDIATE_PAGE, { id }),
  storeIntermediatePage: (id: number, intermediatePage: IntermediatePageResult) => createAction(IntermediatePageActionTypes.STORE_INTERMEDIATE_PAGE, { id, intermediatePage }),
}

export type IntermediatePageActionObjectTypes = ActionsUnion<typeof IntermediatePageActions>
