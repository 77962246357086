import { BaseApi } from '../BaseApi/BaseApi'
import { WeatherDaysResult } from './types'

export class WeatherApi {
  public static async loadWeatherDays() {
    return BaseApi.authenticatedCall()
      .url('v1/meteo/days')
      .get()
      .json(json => json) as Promise<WeatherDaysResult>
  }
}
