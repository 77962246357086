import { BaseApi } from '../BaseApi'
import { urlApi } from '../url'
import { CantineItemResult } from './types'

export class CantineApi {
  public static async loadCantine(id: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.cantine.submit(id))
      .get()
      .json(json => json) as Promise<CantineItemResult>
  }
}
