import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { CarouselResult } from '../../api/Carousel/types'
import { ActualitesResult, GridAirQualityConfig, GridPageResult, GridTideConfig, RssFeedConfigResult, WeatherResult } from '../../api/GridPage/types'
import { GridPageActionTypes } from './types'

export const GridPageActions = {
  loadGridPage: (width: number, id?: number) => createAction(GridPageActionTypes.LOAD_GRID_PAGE, { width, id }),
  storeGridPageConfig: (gridPageConfig: GridPageResult, id: number) => createAction(GridPageActionTypes.STORE_GRID_PAGE, { id, gridPageConfig }),
  loadCarousel: (id: number) => createAction(GridPageActionTypes.LOAD_CAROUSEL, { id }),
  storeCarousel: (id: number, carousel: CarouselResult) => createAction(GridPageActionTypes.STORE_CAROUSEL, { id, carousel }),
  loadActualites: (id: number) => createAction(GridPageActionTypes.LOAD_ACTUALITES, { id }),
  storeActualites: (id: number, actualitesResult: ActualitesResult) => createAction(GridPageActionTypes.STORE_ACTUALITES, { actualitesResult, id }),
  loadWeather: (id: number) => createAction(GridPageActionTypes.LOAD_WEATHER, { id }),
  storeWeather: (id: number, result: WeatherResult) => createAction(GridPageActionTypes.STORE_WEATHER, { id, result }),
  loadRssFeedConfig: (id: number) => createAction(GridPageActionTypes.LOAD_RSS_FEED_CONFIG, { id }),
  storeRssFeedConfig: (id: number, result: RssFeedConfigResult) => createAction(GridPageActionTypes.STORE_RSS_FEED_CONFIG, { id, result }),
  loadGridAirQualityConfig: (id: number) => createAction(GridPageActionTypes.LOAD_GRID_AIR_QUALITY_CONFIG, { id }),
  storeGridAirQualityConfig: (id: number, result: GridAirQualityConfig) => createAction(GridPageActionTypes.STORE_GRID_AIR_QUALITY_CONFIG, { id, result }),
  loadGridTideConfig: (id: number) => createAction(GridPageActionTypes.LOAD_GRID_TIDE_CONFIG, { id }),
  storeGridTideConfig: (id: number, result: GridTideConfig) => createAction(GridPageActionTypes.STORE_GRID_TIDE_CONFIG, { id, result }),
}

export type GridPageActionObjectTypes = ActionsUnion<typeof GridPageActions>
