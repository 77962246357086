import _ from 'lodash'
import moment from 'moment'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { LayoutChangeEvent, StyleSheet } from 'react-native'
import { BarChart } from 'react-native-chart-kit'
import { ChartData } from 'react-native-chart-kit/dist/HelperTypes'
import { useSelector } from 'react-redux'
import { ReferencielLandFillBucketDay, ReferencielLandFillBucketDayBucket, ReferencielLandFillBucketWeek, ReferentielInfoResult } from '../../../api/Referentiels/types'
import { i18n } from '../../../lib/i18n'
import { RootState } from '../../../redux/reducers'
import { referentielLandfillSelector } from '../../../redux/referentiels/selector'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { Card } from '../../Card'

type LandfillsTypes = {
  code: ReferentielInfoResult
}

type DaysWeek = {
  key: string
  value: string
}

const ReferentielLandfillsWeek: FunctionComponent<LandfillsTypes> = ({ code }) => {
  if (!code) return null

  const landfills = useSelector((state: RootState) => referentielLandfillSelector(state))
  const themeSelect = useSelector(themeSelector)
  const [width, setWidth] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<ChartData>({ labels: [], datasets: [] })
  const [buckets, setBuckets] = useState([])

  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
    setWidth(layout.width - 30)
  }

  const chartConfig = {
    backgroundGradientFrom: theme.colors.white,
    backgroundGradientTo: theme.colors.white,
    fillShadowGradientOpacity: 1,
    fillShadowGradientToOpacity: 1,
    barPercentage: 1,
    color: () => color,
    labelColor: () => theme.colors.black,
  }

  // Select date of the day
  const indexOfTheDay = moment().isoWeekday() - 1
  const baseDayWeeks: Array<DaysWeek> = [
    {
      key: 'Lundi',
      value: i18n.t('daysShort.monday'),
    },
    {
      key: 'Mardi',
      value: i18n.t('daysShort.tuesday'),
    },
    {
      key: 'Mercredi',
      value: i18n.t('daysShort.wednesday'),
    },
    {
      key: 'Jeudi',
      value: i18n.t('daysShort.thursday'),
    },
    {
      key: 'Vendredi',
      value: i18n.t('daysShort.friday'),
    },
    {
      key: 'Samedi',
      value: i18n.t('daysShort.saturday'),
    },
    {
      key: 'Dimanche',
      value: i18n.t('daysShort.sunday'),
    },
  ]
  const startWeek = _.slice(baseDayWeeks, indexOfTheDay)
  const endWeek = _.slice(baseDayWeeks, 0, indexOfTheDay)
  const orderDayWeeks = [...startWeek, ...endWeek]

  useEffect(() => {
    if (!landfills || !landfills.days) setIsLoading(true)
    else {
      const weekBucket = _.find(landfills.week.aggregations.decheteries.buckets, ['key', code.value]) as ReferencielLandFillBucketDay

      const sortedBucket = _.sortBy(weekBucket.days.buckets, (item: ReferencielLandFillBucketDayBucket) => _.findIndex(orderDayWeeks, ['key', item.key]))

      const weekLegends = _.map(sortedBucket, bucket => (_.find(orderDayWeeks, { key: bucket.key }) as DaysWeek).value)

      const weekValues: Array<number> = _.map(sortedBucket, (bucket: ReferencielLandFillBucketWeek) =>
        bucket.isOpen.value === 1 ? bucket.totalEntree.value : 0,
      ) as unknown as Array<number>

      setBuckets(sortedBucket as [])
      setData({
        labels: weekLegends,
        datasets: [{ data: weekValues }],
      })
      setIsLoading(false)
    }
  }, [landfills])

  const displayLoading = () => <Spinner color={color} size="small" />

  const displayGraph = () => (
    <View>
      <BarChart
        style={styles.chart}
        data={data as ChartData}
        width={width}
        height={160}
        yAxisLabel=""
        yAxisSuffix=""
        fromZero
        showValuesOnTopOfBars={false}
        showBarTops={false}
        withInnerLines={false}
        withHorizontalLabels={false}
        chartConfig={chartConfig}
      />
      <View style={styles.chartClosed}>
        {_.map(buckets || [], (bucket: ReferencielLandFillBucketWeek, index) =>
          bucket.isOpen.value === 0 ? (
            <View key={index} style={styles.closed}>
              <Text style={styles.closedText}>{i18n.t('referentiel.closed')}</Text>
            </View>
          ) : (
            <View key={index} style={styles.closed} />
          ),
        )}
      </View>
    </View>
  )

  const renderContent = () => (data && buckets && !isLoading ? displayGraph() : displayLoading())

  return (
    <Card spacing onLayout={onLayout} style={[styles.chartContainer, isLoading ? styles.spinner : null]}>
      <Text style={styles.title}>{i18n.t('referentiel.thisWeek')}</Text>
      {renderContent()}
    </Card>
  )
}

const styles = StyleSheet.create({
  title: {
    width: '100%',
  },
  chartContainer: {
    padding: theme.padding.x1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  spinner: {
    alignItems: 'center',
  },
  chart: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  chartClosed: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: -6,
    bottom: 35,
    flexDirection: 'row',
    paddingLeft: 5,
  },
  closed: {
    flex: 1,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closedText: {
    transform: [{ rotate: '-90deg' }],
  },
})

export { ReferentielLandfillsWeek }
