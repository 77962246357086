import isEmpty from 'lodash/isEmpty'
import { Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { DishLegend, DishLegendItem } from '../../../api/Cantine/types'
import { theme } from '../../../theme'
import { Body, Left } from '../../NativeBaseLegacy'

const IMAGE_SIZE = 20

interface LabelItemProps {
  legend: DishLegend | null
}

const renderItemLegend = (item: DishLegendItem) => (
  <View style={styles.container}>
    <Left style={styles.left}>{item.image ? <FastImage style={styles.image} source={{ uri: item.image }} resizeMode={FastImage.resizeMode.contain} /> : null}</Left>
    <Body>
      <Text>{item.libelle}</Text>
    </Body>
  </View>
)

const DishLabelItem: FunctionComponent<LabelItemProps> = ({ legend }) => {
  const [uniqueItems, setUniqueItems] = useState<DishLegendItem[]>([])

  useEffect(() => {
    if (!legend) {
      return
    }
    const setOfItems = legend.items.reduce<DishLegendItem[]>((arr, item) => {
      if (!arr.find(i => i.libelle === item.libelle)) {
        arr.push(item)
      }
      return arr
    }, [])
    setUniqueItems(setOfItems)
  }, [legend])

  if (!legend || isEmpty(legend.items)) {
    return null
  }

  return (
    <View>
      <Text>{legend.titre}</Text>
      <FlatList data={uniqueItems} keyExtractor={(_item, index) => index.toString()} renderItem={item => renderItemLegend(item.item)} />
    </View>
  )
}

const styles = StyleSheet.create({
  left: {
    width: IMAGE_SIZE,
    marginRight: theme.padding.x1,
  },
  container: {
    flexDirection: 'row',
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
})

export { DishLabelItem }
