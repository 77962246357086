import jwtDecode from 'jwt-decode'
import merge from 'lodash/merge'
import { FetchLike, Middleware, WretcherOptions, WretcherResponse } from 'wretch'
import { storeManager } from '../lib/storeManager'
import { AuthenticationActions } from '../redux/authentication/actions'
import { apiTokenSelector, credentialsSelector } from '../redux/authentication/selectors'
import { AuthenticationApi } from './Authentication/AuthenticationApi'

export const shouldRefreshFromToken = (token: string): boolean => {
  try {
    if (!token) {
      return true
    }
    const payload: { exp: number } = jwtDecode(token)
    const clockTimestamp = Math.floor(Date.now() / 1000)
    const clockTolerance = 5

    return payload && payload.exp < clockTimestamp - clockTolerance
  } catch (error) {
    return true
  }
}

const getNewAuthToken = async (): Promise<string> => {
  const state = storeManager.store.getState()
  const authtoken = apiTokenSelector(state)
  const credentials = credentialsSelector(state)

  let newToken
  if (!credentials || !authtoken?.refreshToken || shouldRefreshFromToken(authtoken.refreshToken)) {
    newToken = await AuthenticationApi.signIn(credentials)
  } else {
    newToken = await AuthenticationApi.refreshToken({ refreshToken: authtoken?.refreshToken || '' })
  }

  storeManager.store.dispatch(AuthenticationActions.storeApiToken(newToken.accessToken, newToken.tokenType, newToken.refreshToken))
  return newToken.accessToken
}

export const getAuthToken = async () => {
  const state = storeManager.store.getState()
  const authtoken = apiTokenSelector(state)
  if (!authtoken) {
    storeManager.store.dispatch(AuthenticationActions.signInUser())
  }
  if (authtoken && authtoken.accessToken && shouldRefreshFromToken(authtoken.accessToken)) {
    return getNewAuthToken()
  }
  return authtoken && authtoken.accessToken ? authtoken.accessToken : ''
}

const getNewOpts = (options: WretcherOptions, tokenValue: string) =>
  merge(options, {
    headers: {
      Authorization: `Bearer ${tokenValue}`,
    },
  })

export const authMiddleware: Middleware =
  () =>
  (next: FetchLike) =>
  async (url: string, opts: WretcherOptions): Promise<WretcherResponse> => {
    const authToken = await getAuthToken()
    const newOpts = getNewOpts(opts, authToken)
    const response = await next(url, newOpts)

    return response
  }
