export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '200ed967-ab47-4a99-adff-5ccd55136bb6',
  ENV: 'legeCapFerret',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 106,
}
