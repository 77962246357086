import { Reducer } from 'redux'
import { ReferentielsState, ReferentielsActionTypes } from './types'
import { ReferentielsActionObjectTypes } from './actions'

export const initialReferentielsState: ReferentielsState = {
  items: {},
  pages: {},
  landfills: undefined,
}

export const referentielsReducer: Reducer<ReferentielsState, ReferentielsActionObjectTypes> = (state = initialReferentielsState, action) => {
  switch (action.type) {
    case ReferentielsActionTypes.STORE_REFERENTIEL_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.item,
        },
      }
    case ReferentielsActionTypes.STORE_REFERENTIEL_LIST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id]: {
            last: action.payload.page.last,
            page: action.payload.page.page,
            size: action.payload.page.size,
            totalElements: action.payload.page.totalElements,
            totalPages: action.payload.page.totalPages,
            content: [...(state.pages[action.payload.id] ? state.pages[action.payload.id].content : []), ...action.payload.page.content],
          },
        },
      }
    case ReferentielsActionTypes.STORE_CLEAR_REFERENTIEL_LIST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id]: action.payload.page,
        },
      }
    case ReferentielsActionTypes.STORE_REFERENTIEL_LANDFILLS:
      return {
        ...state,
        landfills: action.payload,
      }
    default:
      return state
  }
}
