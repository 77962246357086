import { useCallback, useState } from 'react'
import { userAuthentication } from '../../services/userAuthentication'

export const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const doResetPassword = useCallback(async (token, password) => {
    setLoading(true)
    try {
      await userAuthentication.sendResetPassword(token, password)
    } catch (error) {
      console.warn((error as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { doResetPassword, loading }
}
