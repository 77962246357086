import React, { FunctionComponent, ReactNode } from 'react'
import { ImageURISource, Platform, StyleSheet } from 'react-native'
import { Marker as MarkerRN } from 'react-native-maps'
import { MarkerBaseResult } from '../../api/Cartography/types'
import { theme } from '../../theme'

interface MarkerProps {
  coordinate: { latitude: number; longitude: number }
  anchor?: { x: number; y: number }
  onPress: () => void
  isSelected: boolean
  marker: MarkerBaseResult
  children: ReactNode
  icon?: string
}

/**
 * @summary In this class, we define a Marker component.
 * @description The important thing to know and understand about this class is the usage of `tracksViewChanges`.
 * `tracksViewChanges` is a prop on react-native-maps' `Marker` Component which enables update on Marker thhrough the bridge.
 * Turning it off disables all Marker update through the react-native bridge. This is a huge performance gain.
 * @see https://github.com/react-native-community/react-native-maps/blob/master/docs/marker.md
 */

export const Marker: FunctionComponent<MarkerProps> = ({ coordinate, anchor, onPress, isSelected, marker, children, icon }) => (
  <MarkerRN
    anchor={anchor}
    onPress={onPress}
    stopPropagation
    coordinate={coordinate}
    calloutAnchor={anchor}
    calloutOffset={anchor}
    key={marker.id}
    icon={Platform.OS === 'web' ? (icon as ImageURISource) : undefined}
    style={[styles.base, isSelected ? styles.selected : undefined]}
  >
    {children}
  </MarkerRN>
)

/**
 * https://github.com/react-native-community/react-native-maps/issues/282
 * https://github.com/react-native-community/react-native-maps/blob/master/example/examples/ZIndexMarkers.js
 * Minimum value to set the selected marker on top of the others
 */
const Z_INDEX = 1000

const styles = StyleSheet.create({
  base: theme.selectedMarkerImage,
  selected: {
    zIndex: Z_INDEX,
  },
})
