import isEmpty from 'lodash/isEmpty'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { SeanceItemResult } from '../../api/Movie/types'
import { i18n } from '../../lib/i18n'
import { themeSelector } from '../../redux/theme/selector'
import { toHHMM } from '../../services/dateFormatter'
import { isNullOrUndefined } from '../../services/textUtil'
import { theme as defaultTheme, theme } from '../../theme'
import { CardItem } from '../NativeBaseLegacy'

interface MovieAvailableSeanceProps {
  day: string
  seances: SeanceItemResult[]
}

const getSubtitle = (seance: SeanceItemResult): string | null => {
  const subtitles = [seance.format, seance.voix, seance.sousTitre ? i18n.t('movie.subtitles') : null].filter(a => !isNullOrUndefined(a))

  if (isEmpty(subtitles)) {
    return null
  }
  return subtitles.join(' - ')
}

const getComments = (seances: SeanceItemResult[]): string =>
  seances
    .map(seance => (seance.commentaire ? `${toHHMM(seance.dateSeance)} : ${seance.commentaire}` : null))
    .filter(comment => !isNullOrUndefined(comment))
    .join('\n')

const MovieAvailableSeance: FunctionComponent<MovieAvailableSeanceProps> = ({ day, seances }) => {
  const theme = useSelector(themeSelector)
  const primaryColor = theme ? theme.brandPrimary : defaultTheme.colors.blue
  const warning = theme ? theme.brandWarning : defaultTheme.colors.orange
  const comments = getComments(seances)

  return (
    <CardItem p="1" style={styles.cardItem}>
      <Text>{day}</Text>
      <View style={styles.container}>
        {seances.map(seance => {
          const subtitle = getSubtitle(seance)
          return (
            <View key={seance.id} style={[styles.seance, { backgroundColor: warning }]}>
              <Text style={styles.seanceText}>
                {toHHMM(seance.dateSeance)}
                {seance.commentaire ? ' *' : ''}
              </Text>
              {subtitle ? <Text style={styles.seanceText}>{subtitle}</Text> : null}
            </View>
          )
        })}
      </View>
      {comments ? <Text style={{ color: primaryColor }}>{comments}</Text> : null}
    </CardItem>
  )
}

const MARGIN_TEXT = 4
const BORDER_RADIUS = 4
const HOUR_MIN_WIDTH = 80

const styles = StyleSheet.create({
  cardItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: theme.padding.x1,
    marginHorizontal: -MARGIN_TEXT,
  },
  seance: {
    padding: MARGIN_TEXT,
    margin: MARGIN_TEXT,
    borderRadius: BORDER_RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: HOUR_MIN_WIDTH,
  },
  seanceText: {
    color: defaultTheme.colors.white,
  },
})

export { MovieAvailableSeance }
