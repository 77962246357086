export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '2b51e028-19f9-4677-bd5c-3c4d0a4d09a0',
  ENV: 'levalloisPerretAgent',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
    authentication: {
      enabled: true,
      appleProviderEnabled: false,
      facebookProviderEnabled: false,
      googleProviderEnabled: false,
    },
  },
  googleClientId: '',
  emailValidationDynamicLink: 'https://levalloisperretagent.page.link/email-confirmation',
}
