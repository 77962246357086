import { useRoute } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { Spinner } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { FlatList } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { TideDay } from '../../components/GridPageTide/components/TideDay.component'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { WeatherCurrentDay, WeatherDay } from '../../components/Weather'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { GridPageActions } from '../../redux/gridPage/actions'
import { tideConfigSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { WeatherActions } from '../../redux/weather/actions'
import { weatherDaysSelector } from '../../redux/weather/selectors'
import { theme } from '../../theme'

export const Weather: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Weather'>> = () => {
  const dispatch = useDispatch()
  const weather = useSelector((state: RootState) => weatherDaysSelector(state))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_WEATHER_DAYS))
  const themeSelect = useSelector(themeSelector)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const route = useRoute() as any

  let gridTideConfig
  if (route.params && route.params.id) {
    gridTideConfig = useSelector((state: RootState) => tideConfigSelector(state, route.params.id))
  }

  useEffect(() => {
    if (route.params && route.params.id) dispatch(GridPageActions.loadGridTideConfig(route.params.id))
  }, [route.params])

  useEffect(() => {
    dispatch(WeatherActions.loadWeatherDays())
  }, [])

  if (!weather || isLoading) {
    return (
      <Page left={<LeftButtonBack />} title={i18n.t('weather.title')}>
        <Spinner />
      </Page>
    )
  }

  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  return (
    <Page left={<LeftButtonBack />} title={i18n.t('weather.title')}>
      <WeatherCurrentDay title={weather.title} current={weather.current} />
      {gridTideConfig && <TideDay tideConfig={gridTideConfig} color={colorText} hideBorder large />}
      <FlatList
        data={weather.days}
        renderItem={({ item }) => <WeatherDay key={item.date} item={item} />}
        keyExtractor={item => item.date.toString()}
        ListEmptyComponent={<ListEmpty />}
      />
    </Page>
  )
}
