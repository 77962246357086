import { Icon, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { theme } from '../../../../theme'

interface FavoriteButtonConfigProps {
  onDelete: () => void
}
const MARGIN_CONTENT = 7
const ITEM_SIZE = Math.floor((theme.deviceWidth - MARGIN_CONTENT * 2 - 10) / 3) - 2
const TRASH_MARGIN = 8
const BACKGROUND_DRAGVIEW_COLOR = 'rgba(21, 66, 125, 0.5)'

export const FavoriteDragView: FunctionComponent<FavoriteButtonConfigProps> = (props: FavoriteButtonConfigProps) => {
  const { onDelete } = props

  return (
    <View style={styles.dragView}>
      <TouchableOpacity style={styles.trashWrapper} onPress={() => onDelete()}>
        <Icon as={FontAwesome} name="trash" color={theme.colors.white} size={6} />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  dragView: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    backgroundColor: BACKGROUND_DRAGVIEW_COLOR,
    position: 'absolute',
    elevation: 999,
    zIndex: 999,
  },
  trashWrapper: {
    position: 'absolute',
    left: TRASH_MARGIN,
    top: TRASH_MARGIN,
    zIndex: 1,
  },
})
