import { call, put, takeEvery } from '@redux-saga/core/effects'
import { FavoritesApi } from '../../api/Favorites'
import { FavoriteConfig } from '../../api/Favorites/types'
import { isOfflineError } from '../../services/errorManager'
import { FavoritesConfigsActions } from './actions'
import { FavoritesConfigActionTypes } from './types'

function* loadFavoritesConfigSaga() {
  try {
    const favoriteConfig: FavoriteConfig = yield call(FavoritesApi.loadFavoriteConfig)
    yield put(FavoritesConfigsActions.storeFavoriteConfig(favoriteConfig))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

export function* loadFavoritesConfigWatcher() {
  yield takeEvery(FavoritesConfigActionTypes.LOAD_FAVORITES_CONFIG, loadFavoritesConfigSaga)
}
