import { WeeklySchedulesItemResult } from '../../api/WeeklySchedules/types'

export type WeeklySchedulesState = {
  items: {
    [key: number]: WeeklySchedulesItemResult[]
  }
}

export enum WeeklySchedulesActionTypes {
  LOAD_WEEKLY_SCHEDULES = 'LOAD_WEEKLY_SCHEDULES',
  STORE_WEEKLY_SCHEDULES = 'STORE_WEEKLY_SCHEDULES',
}
