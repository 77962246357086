import { BaseApi } from '../BaseApi'
import { AddressResult } from './types'

export class GeolocationApi {
  public static async loadFirst(lat: number, lng: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/geolocations/first')
      .query({ lat, lng })
      .get()
      .json(json => json) as Promise<AddressResult>
  }
}
