import { StackScreenProps } from '@react-navigation/stack'
import { Spinner } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { FlatList, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { AirQualityWeather } from '../../components/AirQuality'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { WeatherDay } from '../../components/Weather'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { AirQualityActions } from '../../redux/airQuality/action'
import { AirQualityConfigSelector } from '../../redux/airQuality/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { WeatherActions } from '../../redux/weather/actions'
import { weatherDaysSelector } from '../../redux/weather/selectors'

export const AirQuality: FunctionComponent<StackScreenProps<ActionViewParamsType, 'AirQuality'>> = ({ route }) => {
  const id = route.params.id as number
  const dispatch = useDispatch()
  const weather = useSelector((state: RootState) => weatherDaysSelector(state))
  const airQualityConfig = useSelector((state: RootState) => AirQualityConfigSelector(state, id))

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_WEATHER_DAYS))

  useEffect(() => {
    dispatch(WeatherActions.loadWeatherDays())
    dispatch(AirQualityActions.loadAirQualityConfig(id))
  }, [id])

  if (!weather || !airQualityConfig || isLoading) {
    return (
      <Page left={<LeftButtonBack />} title={i18n.t('weather.title')}>
        <Spinner />
      </Page>
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={i18n.t('weather.title')}>
      <ScrollView>
        <AirQualityWeather airQualityConfig={airQualityConfig} />
        <FlatList
          data={weather.days}
          renderItem={({ item }) => <WeatherDay key={item.date} item={item} />}
          keyExtractor={item => item.date.toString()}
          ListEmptyComponent={<ListEmpty />}
        />
      </ScrollView>
    </Page>
  )
}
