import { BaseApi } from '../BaseApi'
import { FormResult, FormSubmit, FormResponseResult } from './types'
import { urlApi } from '../url'

export class FormApi {
  public static async loadForm(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/forms/${id}/default`)
      .get()
      .json(json => json) as Promise<FormResult>
  }

  public static async submitForm(id: number, form: FormSubmit) {
    return BaseApi.authenticatedCall()
      .url(urlApi.form.submit(id))
      .post(form)
      .json(json => json) as Promise<FormResponseResult>
  }
}
