import { BaseApi } from '../BaseApi'
import { ActualiteItemResult } from '../GridPage/types'
import { urlApi } from '../url'
import { NewsResult } from './types'

export class ActualitesApi {
  public static async loadActualite(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/actualites/${id}`)
      .get()
      .json(json => json) as Promise<ActualiteItemResult>
  }

  public static async loadNews(page: number, size: number, referentielId?: number | null) {
    return BaseApi.authenticatedCall()
      .url(urlApi.news.submit(page, size, referentielId))
      .get()
      .json(json => json) as Promise<NewsResult>
  }
}
