import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import AntDesign from 'react-native-vector-icons/AntDesign'
import { MenuItemResult } from '../../api/Menu/types'
import { theme } from '../../theme'
import { Body, Left, ListItem, Right } from '../NativeBaseLegacy'

export interface MenuItem extends MenuItemResult {
  onPress?: (item: MenuItem) => Promise<void>
  navigation: DrawerNavigationHelpers
}

const buildImage = (item: MenuItem) => {
  if (!item.icon) {
    return <View style={styles.image} />
  }

  return <FastImage source={{ uri: item.icon }} style={styles.image} />
}

const showSubMenu = (item: MenuItem) => {
  if (item.items.length === 0) {
    return null
  }
  return (
    <Right>
      <Icon as={AntDesign} name="rightcircleo" size={6} />
    </Right>
  )
}

const DrawerItem: FunctionComponent<MenuItem> = props => (
  <ListItem
    onPress={() => {
      props.onPress && props.onPress(props)
      props.items.length === 0 ? props.navigation.closeDrawer() : null
    }}
  >
    <Left>{buildImage(props)}</Left>
    <Body>
      <Text style={styles.name}>{props.name}</Text>
    </Body>
    <Right>{showSubMenu(props)}</Right>
  </ListItem>
)

const IMAGE_SIZE = 24

const styles = StyleSheet.create({
  name: {
    fontWeight: theme.menu.header.fontWeight,
    marginLeft: theme.padding.x2,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
})

export { DrawerItem }
