import { Reducer } from 'redux'

import { SignalementsActionTypes, SignalementsState } from './types'
import { ActionSignalementObjectTypes } from './action'

const initialSignalementsState: SignalementsState = {
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  items: {},
}

export const signalementsReducer: Reducer<SignalementsState, ActionSignalementObjectTypes> = (state = initialSignalementsState, action) => {
  switch (action.type) {
    case SignalementsActionTypes.STORE_SIGNALEMENTS:
      return {
        ...state,
        paging: {
          last: action.payload.signalementsResult.last,
          page: action.payload.signalementsResult.page,
          size: action.payload.signalementsResult.size,
          totalElements: action.payload.signalementsResult.totalElements,
          totalPages: action.payload.signalementsResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.signalementsResult.content],
        },
      }
    case SignalementsActionTypes.STORE_CLEAR_SIGNALEMENTS:
      return {
        ...state,
        paging: {
          last: action.payload.signalementsResult.last,
          page: action.payload.signalementsResult.page,
          size: action.payload.signalementsResult.size,
          totalElements: action.payload.signalementsResult.totalElements,
          totalPages: action.payload.signalementsResult.totalPages,
          content: [...action.payload.signalementsResult.content],
        },
      }
    case SignalementsActionTypes.STORE_SIGNALEMENT_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.item,
        },
      }
    default:
      return state
  }
}
