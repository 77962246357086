import { Reducer } from 'redux'
import { MovieState, MovieActionTypes } from './types'
import { MovieActionObjectTypes } from './actions'

const initialMovieState: MovieState = {
  items: {},
  pages: {},
}

export const movieReducer: Reducer<MovieState, MovieActionObjectTypes> = (state = initialMovieState, action) => {
  switch (action.type) {
    case MovieActionTypes.STORE_MOVIE_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id.toString()]: action.payload.item,
        },
      }
    case MovieActionTypes.STORE_MOVIE_LIST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id.toString()]: {
            last: action.payload.page.last,
            page: action.payload.page.page,
            size: action.payload.page.size,
            totalElements: action.payload.page.totalElements,
            totalPages: action.payload.page.totalPages,
            content: [...(state.pages[action.payload.id] ? state.pages[action.payload.id].content : []), ...action.payload.page.content],
          },
        },
      }
    case MovieActionTypes.STORE_CLEAR_MOVIE_LIST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id.toString()]: action.payload.page,
        },
      }
    default:
      return state
  }
}
