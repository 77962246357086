import React, { FunctionComponent, useEffect, useState } from 'react'
import { ImageBackground, ImageSourcePropType, StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import add_full from '../../../../assets/images/tuiles/add_full.png'
import add_light from '../../../../assets/images/tuiles/add_light.png'
import edit from '../../../../assets/images/tuiles/edit.png'
import quit from '../../../../assets/images/tuiles/quit.png'
import { FavoritesConfigsActions } from '../../../../redux/favoritesConfig/actions'
import { favoritesConfigSelector } from '../../../../redux/favoritesConfig/selector'
import { theme } from '../../../../theme'
import { RootState } from '../../../../redux/reducers'
import { apiTokenSelector } from '../../../../redux/authentication/selectors'

interface FavoriteButtonProps {
  buttonType: favoritesButtonType
  onPress: () => void
}

export type favoritesButtonType = 'fullAdd' | 'add' | 'edit' | 'quit'

export const FavoriteButton: FunctionComponent<FavoriteButtonProps> = ({ onPress, buttonType }) => {
  const dispatch = useDispatch()
  const color = theme.colors.transparent
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))
  const favConfig = useSelector(favoritesConfigSelector)

  const [sizes, setSizes] = useState<{ width: number; height: number } | null>(null)

  useEffect(() => {
    if (authtoken) dispatch(FavoritesConfigsActions.loadFavoriteConfig())
  }, [authtoken])

  const getIconFavoriteButton = (buttonType: favoritesButtonType) => {
    switch (buttonType) {
      case 'add':
        return favConfig.imgAddLight ? { uri: favConfig.imgAddLight } : add_light
      case 'edit':
        return favConfig.imgCustomize ? { uri: favConfig.imgCustomize } : edit
      case 'quit':
        return favConfig.imgValidate ? { uri: favConfig.imgValidate } : quit
      case 'fullAdd':
        return favConfig.imgAddFull ? { uri: favConfig.imgAddFull } : add_full
      default:
        return favConfig.imgAddFull ? { uri: favConfig.imgAddFull } : add_full
    }
  }

  return (
    <TouchableOpacity
      onLayout={({ nativeEvent: { layout } }) => setSizes({ width: layout.width, height: layout.height })}
      style={[styles.buttonFavoriteWrapper, { backgroundColor: color }]}
      onPress={onPress}
    >
      <ImageBackground source={getIconFavoriteButton(buttonType) as ImageSourcePropType} style={[styles.favoriteButtonImage, sizes]} resizeMode="contain" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  favoriteButtonImage: {
    flex: 1,
  },
  buttonFavoriteWrapper: {
    flex: 1,
  },
})
