import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { View } from 'native-base'
import { FormElement } from '../../api/Form/types'
import { FormBase } from '../Form'
import { theme } from '../../theme'
import { ModalBottom } from '../ModalBottom'
import { i18n } from '../../lib/i18n'
import { DefaultFieldValue } from '../Fields/types'

interface ReferentielSearchProps {
  isVisible: boolean
  fields: FormElement[]
  defaultValues: DefaultFieldValue | null
  isSearching: boolean
  onClose: () => void
  submit: (values: DefaultFieldValue) => Promise<Map<number, string>>
}

const ReferentielSearch: FunctionComponent<ReferentielSearchProps> = ({ isVisible, fields, defaultValues, isSearching, onClose, submit }) => (
  <ModalBottom show={isVisible} title={i18n.t('referentiel.search')} onClose={onClose} fullscreen>
    <View style={styles.container}>
      <FormBase fields={fields} buttonText={i18n.t('referentiel.apply')} defaultValues={defaultValues || {}} submit={submit} forceLoading={isSearching} />
    </View>
  </ModalBottom>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
})

export { ReferentielSearch }
