import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { View } from 'native-base'
import { theme } from '../../theme'

const IMAGE_SIZE = 48

interface ImageRoundedProps {
  image: string | null
  size?: number
}
export const ImageRounded: FunctionComponent<ImageRoundedProps> = ({ image, size = IMAGE_SIZE }) => {
  const style = [styles.image, { width: size, height: size, borderRadius: size / 2 }]

  if (!image) {
    return <View style={style} />
  }
  return <FastImage source={{ uri: image }} style={style} resizeMode={FastImage.resizeMode.contain} />
}

const styles = StyleSheet.create({
  image: {
    borderColor: theme.colors.silver,
    borderWidth: 0.5,
  },
})
