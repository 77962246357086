import { CurrentWeatherDay, WeatherDaysItem } from '../../api/Weather/types'

export type WeatherState = {
  title: string
  days: WeatherDaysItem[] | null
  current: CurrentWeatherDay | null
}

export enum WeatherActionTypes {
  LOAD_WEATHER_DAYS = 'LOAD_WEATHER_DAYS',
  STORE_WEATHER_DAYS = 'STORE_WEATHER_DAYS',
}
