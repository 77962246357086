import { Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { theme } from '../../../theme'

interface ReportStatusProps {
  text: string | null
}

export const DisplaySubText: FunctionComponent<ReportStatusProps> = ({ text }) => {
  if (!text) return null
  return (
    <Text style={styles.text} numberOfLines={1}>
      {text}
    </Text>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: theme.textSize.x1_75,
  },
})
