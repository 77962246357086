import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { ActualitesApi } from '../../api/Actualites/ActualitesApi'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActualitesActionObjectTypes, ActualitesActions } from './actions'
import { ActualitesActionTypes } from './types'

import { ActualiteItemResult } from '../../api/Actualites'
import { NewsResult } from '../../api/Actualites/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { actualiteSelector, newsPagingSelector } from './selector'

function* loadActualiteSaga(action: ActionsOfType<ActualitesActionObjectTypes, ActualitesActionTypes.LOAD_ACTUALITE>) {
  try {
    const actu: ActualiteItemResult = yield select(state => actualiteSelector(state, action.payload))

    if (!actu) {
      const item: ActualiteItemResult = yield call(ActualitesApi.loadActualite, action.payload)
      yield put(ActualitesActions.storeActualite(item))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadNewsSaga(action: ActionsOfType<ActualitesActionObjectTypes, ActualitesActionTypes.LOAD_NEWS>) {
  const PAGE_SIZE = 10
  const paging: PagingResult<ActualiteItemResult> = yield select(newsPagingSelector)
  let PAGE = 0
  if (!action.payload.clear && paging.page !== undefined) {
    PAGE = paging.page + 1
  }
  try {
    let newsResult: NewsResult
    if (action.payload.referentielId && action.payload.referentielId !== undefined) {
      newsResult = yield call(ActualitesApi.loadNews, PAGE, PAGE_SIZE, action.payload.referentielId)
    } else {
      newsResult = yield call(ActualitesApi.loadNews, PAGE, PAGE_SIZE)
    }

    if (action.payload.clear) {
      yield put(ActualitesActions.clearNewsState())
    }
    yield put(ActualitesActions.storeNews(newsResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
    }
  }
}

function* refreshNewsSaga() {
  yield call(loadNewsSaga, ActualitesActions.loadNews(true))
}

export function* refreshNewsWatcher() {
  yield takeEvery(ActualitesActionTypes.REFRESH_NEWS, addLoader(refreshNewsSaga, LoaderName.REFRESH_NEWS))
}

export function* loadNewsWatcher() {
  yield takeEvery(ActualitesActionTypes.LOAD_NEWS, addLoader(loadNewsSaga, LoaderName.LOAD_NEWS))
}

export function* loadActualiteWatcher() {
  yield takeEvery(ActualitesActionTypes.LOAD_ACTUALITE, addLoader(loadActualiteSaga, LoaderName.LOAD_ACTUALITE))
}
