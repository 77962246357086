import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { LoadingActionTypes } from './types'

export const LoadingActions = {
  showLoader: (loaderName: string, status = true, message = '') =>
    createAction(LoadingActionTypes.SHOW_LOADER, {
      loaderName,
      status: { ok: status, message },
    }),
  hideLoader: (loaderName: string, status = true, message = '') => createAction(LoadingActionTypes.HIDE_LOADER, { loaderName, status: { ok: status, message } }),
}

export type LoadingActionObjectTypes = ActionsUnion<typeof LoadingActions>
