import { BaseApi } from '../BaseApi/BaseApi'
import { TaskReportItem } from './types'

export class TaskReportApi {
  public static async loadTaskReport(page: number, size: number, sort_column: number) {
    const sort_column_param = sort_column === 0 ? 'CREATE_AT_DESC' : 'CREATE_AT_ASC'
    return BaseApi.authenticatedCall().url('/v1/grc/citopia/tasks').query({ page, size, sort_column: sort_column_param }).get().json()
  }

  public static async loadTaskReportItem(uuid: string) {
    return BaseApi.authenticatedCall().url(`/v1/grc/citopia/tasks/${uuid}`).get().json() as Promise<TaskReportItem>
  }

  public static async isAuthorizedGrcAccount() {
    return BaseApi.authenticatedCall()
      .errorType('json')
      .url('/v1/grc/citopia/tasks/check')
      .get()
      .error(400, () => ({ status: false, error: 400 }))
      .error(401, () => ({ status: false, error: 401 }))
      .json(json => ({ status: true, error: null, token: json.token }))
      .catch(() => ({ status: false, error: 500 }))
  }
}
