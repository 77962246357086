import { Reducer } from 'redux'
import { HomeState, HomeActionTypes } from './types'
import { HomeActionObjectTypes } from './actions'

const initialHomeState: HomeState = {
  homeComponentName: 'gridPage',
  footers: [],
  title: null,
  titleImage: null,
  promotions: {},
  cgu: null,
}

export const HomeReducer: Reducer<HomeState, HomeActionObjectTypes> = (state = initialHomeState, action) => {
  switch (action.type) {
    case HomeActionTypes.STORE_HOME_CONFIG: {
      const { homeConfig } = action.payload
      return {
        ...state,
        homeComponentName: homeConfig.homeComponentName,
        footers: homeConfig.footers,
        title: homeConfig.title,
        titleImage: homeConfig.titleImage,
        cgu: homeConfig.cgu,
      }
    }
    case HomeActionTypes.STORE_PROMOTION:
      return {
        ...state,
        promotions: {
          ...(state.promotions || {}),
          [action.payload.promotion.id.toString()]: action.payload.promotion,
        },
      }
    case HomeActionTypes.DISPLAYED_PROMOTION: {
      const promotionId = action.payload.promotionId.toString()
      const { promotions } = state
      const hasPromotionInStore = promotions && promotionId in promotions

      if (!hasPromotionInStore) {
        return state
      }

      return {
        ...state,
        promotions: {
          ...promotions,
          [promotionId]: {
            ...(promotions || {})[promotionId],
            displayed: true,
          },
        },
      }
    }
    case HomeActionTypes.CLEAR_PROMOTION: {
      const promotionId = action.payload.promotionId.toString()
      const { promotions } = state

      if (promotions && promotionId in promotions) {
        delete promotions[promotionId]
      }

      return {
        ...state,
        promotions: {
          ...(promotions || {}),
        },
      }
    }
    default:
      return state
  }
}
