import React, { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { FormElementProps } from './types'
import { FormBase, FormSelect } from '../../api/Form/types'
import { SelectField } from './SelectField.component'
import { SectorApi } from '../../api/Sector'
import { SectorItemResult } from '../../api/Sector/types'

type SectorFieldProps = FormElementProps<FormBase, number | null>

interface SectorFieldState {
  id?: number
  items: SectorItemResult[]
}

class SectorField extends PureComponent<SectorFieldProps, SectorFieldState> {
  public state: SectorFieldState = {
    id: undefined,
    items: [],
  }

  public constructor(props: SectorFieldProps) {
    super(props)
    if (props.value) {
      this.setState({
        ...this.state,
        id: props.value,
      })
    }
    this.getSectorList()
  }

  private async getSectorList() {
    const sector = await SectorApi.loadSectorList()
    if (!sector || isEmpty(sector.content)) {
      return
    }
    this.setState({
      ...this.state,
      items: sector.content,
    })
  }

  public render() {
    if (isEmpty(this.state.items)) {
      return null
    }

    const items = [...this.state.items]
    const { field } = this.props
    const fieldSelect: FormSelect = {
      ...field,
      type: 'field_select',
      items,
    }
    return <SelectField {...this.props} field={fieldSelect} />
  }
}

export { SectorField }
