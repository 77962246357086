import isEmpty from 'lodash/isEmpty'
import { Card, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { SignalementCommentResult } from '../../../api/Signalement/types'
import { i18n } from '../../../lib/i18n'
import { CardItem } from '../../NativeBaseLegacy'
import { SignalementComment } from './SignalementComment.component'

interface SignalementCommentListProps {
  comments: SignalementCommentResult[] | null
}

export const SignalementCommentList: FunctionComponent<SignalementCommentListProps> = ({ comments }) => {
  if (!comments || isEmpty(comments)) {
    return null
  }

  return (
    <Card p="0" m="1">
      <CardItem p="3">
        <Text style={styles.title}>{i18n.t('signalement.comments')} :</Text>
      </CardItem>
      {comments.map(item => (
        <SignalementComment key={item.id} item={item} />
      ))}
      <CardItem />
    </Card>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
  },
})
