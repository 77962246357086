import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { ExternalLinksApi } from '../../api/ExternalLinks/ExternalLinksApi'
import { ExternalLinksResult } from '../../api/ExternalLinks/types'
import { isOfflineError } from '../../services/errorManager/errorManager.service'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActionExternalLinksObjectTypes, ExternalLinksActions } from './actions'
import { externalLinksPagingSelector } from './selector'
import { ExternalLinksActionTypes } from './types'

const PAGE_SIZE = 20

function* loadExternalLinksSaga(action: ActionsOfType<ActionExternalLinksObjectTypes, ExternalLinksActionTypes.LOAD_EXTERNAL_LINKS>) {
  const paging: ExternalLinksResult | null = yield select(externalLinksPagingSelector, action.payload.idPage.toString())
  let page = 0
  if (!action.payload.clear && paging && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const result: ExternalLinksResult = yield call(ExternalLinksApi.loadExternalLinks, page, PAGE_SIZE, action.payload.type)
    if (action.payload.clear) {
      yield put(ExternalLinksActions.storeClearExternalLinks(result, action.payload.idPage))
    } else {
      yield put(ExternalLinksActions.storeExternalLinks(result, action.payload.idPage))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* refreshExternalLinksSaga(action: ActionsOfType<ActionExternalLinksObjectTypes, ExternalLinksActionTypes.REFRESH_EXTERNAL_LINKS>) {
  yield call(loadExternalLinksSaga, ExternalLinksActions.loadExternalLinks(true, action.payload.idPage, action.payload.type))
}

export function* loadExternalLinksWatcher() {
  yield takeEvery(ExternalLinksActionTypes.LOAD_EXTERNAL_LINKS, addLoader(loadExternalLinksSaga, LoaderName.LOAD_EXTERNAL_LINKS))
}

export function* refreshExternalLinksWatcher() {
  yield takeEvery(ExternalLinksActionTypes.REFRESH_EXTERNAL_LINKS, addLoader(refreshExternalLinksSaga, LoaderName.REFRESH_EXTERNAL_LINKS))
}
