import { Icon, Text, View } from 'native-base'
import React from 'react'
import { StyleSheet, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { theme } from '../../../theme'

interface Style {
  itemContainer: ViewStyle
  label: TextStyle
  iconContainer: ViewStyle
}

const styles = StyleSheet.create<Style>({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.padding.x2,
  },
  label: {
    marginLeft: theme.padding.x2,
  },
  iconContainer: {
    alignItems: 'center',
    width: 30,
  },
})

interface Props {
  label: string
  iconName: string
  onPress: () => void
}

export const MyAccountItem: React.FC<Props> = ({ iconName, onPress, label }) => (
  <TouchableOpacity style={styles.itemContainer} onPress={onPress}>
    <View style={styles.iconContainer}>
      <Icon as={Ionicons} name={iconName} size={6} />
    </View>
    <Text style={styles.label}>{label}</Text>
  </TouchableOpacity>
)
