import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../redux/theme/selector'
import { theme } from '../../theme'

interface ErrorsFieldsProps {
  message: string
  errors: string[]
}

export const ErrorsFields: FunctionComponent<ErrorsFieldsProps> = ({ message, errors }) => {
  const theme = useSelector(themeSelector)
  if (!theme || !message || !errors) {
    return null
  }

  return (
    <View style={[styles.container, { backgroundColor: theme.brandWarning }]}>
      <Text style={styles.message}>{message}</Text>
      {errors.map((error, index) => (
        <Text key={index} style={styles.error}>
          {error}
        </Text>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  message: { color: theme.colors.white, fontWeight: 'bold', paddingBottom: theme.padding.x1 },
  error: {
    color: theme.colors.white,
  },
  container: {
    flex: 1,
    borderRadius: theme.padding.x1,
    margin: theme.padding.x1,
    padding: theme.padding.x1,
  },
})
