import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { AuthenticationApi } from '../../api/Authentication/AuthenticationApi'
import { SignInResult, SignUpResult } from '../../api/Authentication/types'
import { env } from '../../environment'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { AuthenticationActions } from './actions'
import { apiTokenSelector, credentialsSelector } from './selectors'
import { AuthenticationActionTypes } from './types'

function* initializeUserSaga() {
  const credentials = yield select(credentialsSelector)
  if (credentials) {
    return
  }

  try {
    const { uuid, password }: SignUpResult = yield call(AuthenticationApi.signUp, env().clientUuid)
    yield put(AuthenticationActions.storeUserCredentials(uuid, password))
  } catch (err) {
    // console.error('ERROR =', err)
  }
}

export function* initializeUserWatcher() {
  yield takeEvery(AuthenticationActionTypes.INIT_AUTHENTICATION, initializeUserSaga)
}

function* signIn() {
  try {
    const apiToken = yield select(apiTokenSelector)
    if (apiToken && apiToken.accessToken) {
      return
    }

    yield initializeUserSaga()
    const credentials = yield select(credentialsSelector)
    const { accessToken, tokenType, refreshToken }: SignInResult = yield call(AuthenticationApi.signIn, credentials)
    // yield put(AuthenticationActions.signInUser(accessToken, tokenType, refreshToken))
    yield put(AuthenticationActions.storeApiToken(accessToken, tokenType, refreshToken))
  } catch (err) {
    // console.error('ERROR =', err)
    // throw err
  }
}

export function* signInUserWatcher() {
  yield takeEvery(AuthenticationActionTypes.SIGN_IN_USER, addLoader(signIn, LoaderName.SIGN_IN_USER))
}
