/* eslint-disable prettier/prettier */
import _ from 'lodash'
import { Checkbox, Radio, Text, View } from 'native-base'
import React, { PureComponent } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { FormSelect } from '../../../api/Form/types'
import { ThemeState } from '../../../redux/theme/types'
import { theme } from '../../../theme'
import { ListItem } from '../../NativeBaseLegacy'
import { LabelInput } from '../components'
import { FormElementProps } from '../types'

type CheckboxProps = FormElementProps<FormSelect, number[] | number | null> & ThemeState

class CheckboxFieldClass extends PureComponent<CheckboxProps> {
  public focus = () => {}

  public render() {
    const { field } = this.props
    switch (field.type) {
      case 'field_radio':
        return this.displayRadioButtons()
      case 'field_checkboxes':
        return this.displayCheckboxes()
      default:
        return null
    }
  }

  private displayCheckboxes() {
    const { field, value, setFieldValue } = this.props

    if (!value) {
      setFieldValue(this.elementId, [])
    }
    const valueArray = (value as Array<number | undefined> | null) || []
    const color = this.props.toolbarDefaultBg || theme.colors.silver

    return (
      <View {...this.props}>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        {field.items &&
          field.items.map(item => (
            <ListItem
              key={item.id}
              onPress={() => this.toggleValue(item.id)}
              style={styles.alignLeft}
            >
              <Text style={styles.alignLeft}>{item.libelle}</Text>
              <Checkbox
                value={item.id?.toString() as string}
                isChecked={valueArray.includes(item.id)}
                color={color}
                style={{ borderColor: color }}
                onChange={() => {
                  if (Platform.OS !== 'web') this.toggleValue(item.id)
                }}
              />
            </ListItem>
          ))}
      </View>
    )
  }

  private displayRadioButtons() {
    const { field, value, setFieldValue } = this.props

    if (!value) {
      setFieldValue(this.elementId, field.constraint.mandatory ? (_.first(field.items)?.id || '0').toString() : null)
    }

    const valueNumber =  value

    return (
      <View {...this.props}>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        <Radio.Group name={field.libelle as string} value={(valueNumber || '0').toString() as string} onChange={(item) => this.toggleValue(+item)}>
        {field.items &&
          field.items.map(item => (
            <ListItem
              style={styles.alignLeft}
              key={item.id}
              onPress={() => this.toggleValue(item.id)}
            >
                <Text style={styles.alignLeft}>{item.libelle}</Text>
                <Radio
                  value={item.id?.toString() as string}
                  isPressed={valueNumber === item.id}
                  color={theme.colors.black}
                />
            </ListItem>
          ))}
        </Radio.Group>
      </View>
    )
  }

  private toggleValue(id?: number) {
    const { field, value, setFieldValue } = this.props

    switch (field.type) {
      case 'field_checkboxes': {
        if (!value) {
          setFieldValue(this.elementId, [])
        }
        const valueArray = (value as Array<number | undefined> | null) || []
        if (valueArray.includes(id)) {
          const newValue = valueArray.filter(elementId => elementId !== id)
          setFieldValue(this.elementId, newValue)
        } else {
          setFieldValue(this.elementId, [...valueArray, id])
        }
        break
      }
      case 'field_radio': {
        if (value === id) {
          setFieldValue(this.elementId, null)
        } else {
          setFieldValue(this.elementId, id)
        }
        break
      }
      default:
        break
    }
  }

  private get elementId() {
    const { field } = this.props
    return `${field.id}`
  }
}

const styles = StyleSheet.create({
  alignLeft: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
  },
})

export { CheckboxFieldClass }
