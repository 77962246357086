/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRoute } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, createRef, useEffect, useState } from 'react'
import { Image, ImageStyle, StyleSheet, ViewStyle } from 'react-native'
import MapView from 'react-native-maps'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'react-use/lib/usePrevious'
import { MarkerResult } from '../../api/Cartography/types'
import { CartographyFooter } from '../../components/CartographyFooter'
import { CARTOGRAPHY_CARD_HEIGHT } from '../../components/CartographyFooter/components'
import { CartographyMenu, MIN_WIDTH as MENU_MIN_WIDTH } from '../../components/CartographyMenu'
import { Marker } from '../../components/Marker'
import { LeftButtonBack, Page } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'
import { CartographyActions } from '../../redux/cartography/actions'
import { cartographyMenuMarkersSelector, cartographySelector } from '../../redux/cartography/selector'
import { RootState } from '../../redux/reducers'
import { checkLocation } from '../../services/Permissions/Permissions.service'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme'

const DEFAULT_ALTITUDE = 2500
const DEFAULT_ZOOM = 15
const ANIMATION_DURATION = 1000
// Region of France
const INITIAL_REGION = {
  latitude: 46.360624,
  longitude: 2.621288,
  latitudeDelta: 12,
  longitudeDelta: 12,
}

// interface ClusterMapViewRef {
//   getMapRef: () => MapView
// }

// interface FormattedMarkers extends MarkerResult {
//   location: { latitude: number longitude: number }
// }

const Cartography: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Cartography'>> = () => {
  const dispatch = useDispatch()
  const { params } = useRoute()
  const title = (params as any).titre
  const id = (params as any).id as number
  const cartographyId = id.toString()
  const cartography = useSelector((state: RootState) => cartographySelector(state, cartographyId))
  const mapView = createRef<MapView>()

  const [selectedMenuId, setSelectedMenuId] = useState<string | null>(null)
  const [selectedMarkerId, setSelectedMarkerId] = useState<number | null>(null)
  const [isMapReady, setIsMapReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const prevIsLoading = usePrevious(isLoading)

  const markers = useSelector((state: RootState) => cartographyMenuMarkersSelector(state, { cartographyId, menuId: selectedMenuId }))

  const renderMarker = (marker: MarkerResult) => {
    const selected = selectedMarkerId === marker.id
    // see https://github.com/react-native-community/react-native-maps/blob/master/docs/marker.md
    const anchor = { x: 0.5, y: 1 }
    /**
     * We use a selectedMarkerImage with a container to set the marker size
     * as the maximum size an image can be set, then we can change the size of the image
     * when the marker is presssed or not.
     */

    return (
      <Marker
        marker={marker}
        coordinate={marker.latlng}
        onPress={() => {
          trackEvent({
            category: 'Cartographie',
            actionName: 'Cartographie',
            eventName: 'Appui sur un marker',
            value: marker.libelle,
          })
          setSelectedMarkerId(marker.id)
        }}
        icon={marker.point}
        anchor={anchor}
        key={marker.id}
        isSelected={selected}
      >
        <View style={[styles.markerImageContainer, styles.selectedMarkerImage]}>
          {marker.point && <Image style={selected ? styles.selectedMarkerImage : styles.markerImage} source={{ uri: marker.point }} resizeMode="contain" />}
        </View>
      </Marker>
    )
  }

  const initCartography = () => {
    dispatch(CartographyActions.loadCartography(id))
  }

  const fitToCoordinates = () => {
    if (!mapView.current || !markers || isEmpty(markers) || !isMapReady) {
      return
    }

    const coordinates = markers.map(marker => marker.latlng)
    const padding = theme.padding.x4
    mapView.current.fitToCoordinates(coordinates, {
      animated: true,
      edgePadding: {
        top: padding,
        left: padding,
        // Adding padding to fit with the right menu
        right: padding + MENU_MIN_WIDTH,
        bottom: padding + CARTOGRAPHY_CARD_HEIGHT,
      },
    })
  }

  const resetSelectedMarker = () => {
    setSelectedMarkerId(null)
  }

  const centerToMarker = () => {
    if (!mapView.current || !selectedMarkerId || !markers) {
      return
    }
    const marker = markers.find(m => m.id === selectedMarkerId)
    if (!marker) {
      return
    }
    mapView.current.animateCamera(
      {
        center: marker.latlng,
        // iOS
        altitude: DEFAULT_ALTITUDE,
        // Android
        zoom: DEFAULT_ZOOM,
      },
      { duration: ANIMATION_DURATION },
    )
  }

  const displayLoader = () => {
    const displayLoader = isLoading && _.isEmpty(markers) && !markers
    if (prevIsLoading !== displayLoader) setIsLoading(displayLoader)
  }

  const onLayout = () => {
    setIsMapReady(true)
  }

  useEffect(initCartography, [])
  useEffect(resetSelectedMarker, [selectedMenuId])
  useEffect(fitToCoordinates, [markers, isMapReady, isLoading])
  useEffect(centerToMarker, [selectedMarkerId])
  useEffect(displayLoader, [isLoading, markers])

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <View style={styles.viewContainer}>
        <MapView
          ref={mapView}
          initialRegion={(cartography && cartography.region) || INITIAL_REGION}
          showsPointsOfInterest={false}
          showsUserLocation={(cartography && cartography.showsUserLocation) || undefined}
          showsCompass
          showsTraffic={(cartography && cartography.showsTraffic) || undefined}
          zoomEnabled
          loadingEnabled
          scrollEnabled
          onLayout={onLayout}
          style={styles.viewContainer}
          onMapReady={checkLocation}
        >
          {isMapReady && !_.isEmpty(markers) && _.map(markers, marker => renderMarker(marker))}
        </MapView>

        {markers && isMapReady && !isEmpty(markers) && (
          <View style={styles.footer}>
            <CartographyFooter cartographyId={cartographyId} menuId={selectedMenuId} selectedMarkerId={selectedMarkerId} onSnapItem={setSelectedMarkerId} />
          </View>
        )}
        {cartography && (
          <View style={styles.menu}>
            <CartographyMenu cartographyId={cartographyId} displayLoader={setIsLoading} onMenuItemSelected={setSelectedMenuId} />
          </View>
        )}
        {isLoading && (
          <View style={styles.loaderContainer}>
            <Spinner color={theme.colors.black} />
          </View>
        )}
      </View>
    </Page>
  )
}

interface Style {
  viewContainer: ViewStyle
  menu: ViewStyle
  markerImageContainer: ViewStyle
  markerImage: ImageStyle
  selectedMarkerImage: ImageStyle
  footer: ViewStyle
  loaderContainer: ViewStyle
}

const styles = StyleSheet.create<Style>({
  viewContainer: {
    flex: 1,
  },
  menu: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
  },
  markerImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  markerImage: theme.markerImage,
  selectedMarkerImage: theme.selectedMarkerImage,
  footer: {
    position: 'absolute',
    bottom: theme.padding.x1,
    left: 0,
    right: MENU_MIN_WIDTH,
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white + theme.opacity,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export { Cartography }
