import isEmpty from 'lodash/isEmpty'
import { Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { CityItemResult } from '../../api/City/types'
import { i18n } from '../../lib/i18n'
import { addingCitySelector, citysSelectSelector } from '../../redux/city/selector'
import { themeSelector } from '../../redux/theme/selector'
import { showSuccess } from '../../services/toast'
import { theme } from '../../theme'
import { ImageRounded } from '../ImageRounded'

const SPINNER_HEIGHT = 40
interface CityItemProps {
  cityItem: CityItemResult
  onSelect?: (cityItem: CityItemResult) => void
}

export const CityItem: FunctionComponent<CityItemProps> = ({ cityItem, onSelect }) => {
  const citys = useSelector(citysSelectSelector)
  const addingSelector = useSelector(addingCitySelector)
  const isSelected = !isEmpty(Object.values(citys).filter(val => val.id === cityItem.id))

  const isAdding = addingSelector ? addingSelector === cityItem.id : false

  const themeSelec = useSelector(themeSelector)
  const primaryColor = themeSelec ? themeSelec.brandPrimary : theme.colors.blue

  const renderAdding = () => {
    if (!isAdding) {
      if (isSelected) {
        return (
          <View style={styles.selected}>
            <Icon as={Ionicons} name="heart" color={primaryColor} size={6} />
          </View>
        )
      }
      return null
    }
    return (
      <View style={styles.loading}>
        <Spinner style={styles.spinner} size="small" />
      </View>
    )
  }

  const onPressItem = () => {
    if (isSelected) {
      showSuccess(i18n.t('addCity.citySelected', { name: cityItem.title }), 'top')
    } else if (onSelect) {
      onSelect(cityItem)
    }
  }

  return (
    <TouchableOpacity onPress={() => onPressItem()}>
      <View style={styles.content}>
        <ImageRounded image={cityItem.image} />
        <View style={styles.infos}>
          <Text>{cityItem.title}</Text>
          <Text>
            {cityItem.postalCode} - {cityItem.department}
          </Text>
        </View>
        {renderAdding()}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  content: {
    padding: theme.padding.x2,
    flex: 1,
    flexDirection: 'row',
  },
  infos: {
    flex: 1,
    paddingLeft: theme.padding.x1,
    justifyContent: 'space-between',
  },
  selected: {
    flex: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    flex: 0,
    justifyContent: 'center',
  },
  spinner: {
    height: SPINNER_HEIGHT,
  },
})
