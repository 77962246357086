/* eslint-disable @typescript-eslint/no-explicit-any */
import { Linking } from 'react-native'
import { ActionPartnerApp } from '../../api/types'
import { theme } from '../../theme'
import { trackEvent } from '../matomo/matomo.service'

/**
 * Error when there is a problem opening an url/app,
 * typically when the app is not installed.
 */
const ERROR_UNSPECIFIED = 'EUNSPECIFIED'

export const partnerApp = async (action: ActionPartnerApp) => {
  const platform = theme.isIOS ? 'ios' : 'android'
  const partnerApp = action.partnerApp[platform]
  try {
    const canOpenURL = !Linking.canOpenURL(partnerApp.check)
    trackEvent({
      category: 'Application externe',
      actionName: 'Application externe',
      eventName: canOpenURL ? "Ouverture d'une app partenaire" : "Ouverture d'une application via urlScheme",
      value: canOpenURL ? action.partnerApp.libelle : partnerApp.urlScheme,
    })
    if (canOpenURL) {
      await Linking.openURL(partnerApp.urlStore)
    } else {
      await Linking.openURL(partnerApp.urlScheme)
    }
  } catch (error) {
    if (error && (error as any).code === ERROR_UNSPECIFIED) {
      await Linking.openURL(partnerApp.urlStore)
    } else {
    }
  }
}
