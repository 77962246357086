import { useNavigation } from '@react-navigation/native'
import { Container, Text } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { AuthenticationActions } from '../../redux/authentication/actions'
import { apiTokenSelector, credentialsSelector } from '../../redux/authentication/selectors'
import { menuSelector } from '../../redux/menu/selector'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  container: {
    maxWidth: '100%',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  text: {
    alignSelf: 'center',
  },
})

export const SignOut: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const credentials = useSelector(credentialsSelector)
  const apiToken = useSelector(apiTokenSelector)

  useEffect(() => {
    if (apiToken && apiToken.accessToken) {
      setTimeout(() => {
        navigation.navigate(Routes.HomeTabs as never)
      }, 1000)
    }
  }, [apiToken])

  useEffect(() => {
    if (credentials) dispatch(AuthenticationActions.logout(credentials.uuid, credentials.password))
    dispatch(AuthenticationActions.signInUser())
  }, [])

  const logoUri = menu && menu.header && menu.header.image

  return (
    <Container safeAreaBottom background={theme.colors.white} style={styles.container}>
      {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
      <Text style={styles.text}>{i18n.t('signOut.disconnecing')}</Text>
    </Container>
  )
}
