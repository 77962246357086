import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { MenuResult } from '../../api/Menu/types'
import { LoadMenuActionTypes } from './types'

export const MenuActions = {
  loadMenuConfig: () => createAction(LoadMenuActionTypes.LOAD_MENU),
  storeMenuConfig: (menu: MenuResult) => createAction(LoadMenuActionTypes.STORE_MENU, { menu }),
}

export type MenuActionObjectTypes = ActionsUnion<typeof MenuActions>
