import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { theme } from '../../theme'

interface CardInFoProps {
  title?: string
  textColor?: string
  components: string[]
  colorBackground?: string
  footer?: string
}

export const CardInFo: FunctionComponent<CardInFoProps> = ({ title, textColor, colorBackground, components, footer }) => {
  const renderCardItems = (items: string[]) => {
    const render = items.map((item: string, index: number) => (
      <View key={index}>
        <Text style={[styles.text, { color: colorText }]}>{item}</Text>
      </View>
    ))

    return render
  }

  const colorText = textColor || theme.colors.azure
  const colorBack = colorBackground || 'rgba(37, 146, 240, 0.05)'

  return (
    <View style={styles.container}>
      <View padding="4" style={{ backgroundColor: colorBack }}>
        <View style={styles.cardTitle}>
          <Text style={[{ color: colorText }, styles.titleText]}>{title}</Text>
        </View>
        {renderCardItems(components)}
        <View>
          <Text style={[styles.text, { color: colorText }]}>{footer}</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cardTitle: {
    paddingBottom: theme.padding.x2,
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: theme.textSize.x2,
  },
  text: {
    marginTop: theme.padding.x2,
    fontSize: theme.textSize.x1_75,
  },
  container: {
    marginVertical: theme.padding.x3,
    borderColor: theme.colors.silver,
    borderWidth: 1,
    borderRadius: theme.padding.x1,
  },
})
