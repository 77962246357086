import { all } from 'redux-saga/effects'
import { loadActualiteWatcher, loadNewsWatcher, refreshNewsWatcher } from './actualites/saga'
import { checkAirQualityDayWatcher, loadAirQualityWatcher } from './airQuality/saga'
import { initializeUserWatcher, signInUserWatcher } from './authentication/sagas'
import { loadCantineWatcher } from './cantine/saga'
import { loadCartographyMenuMarkerSagaWatcher, loadCartographyWatcher } from './cartography/sagas'
import { loadCartographyBeaconsWatcher } from './cartographyBeacons/sagas'
import { loadCartographyRealTimeMapItemsWatcher, loadCartographyRealTimeMapWatcher } from './cartographyRealTime/sagas'
import { loadCartographyZonesWatcher } from './cartographyZones/sagas'
import {
  addCityWatcher,
  loadCitysEventsConfigWatcher,
  loadCitysuserWatcher,
  loadCitysWatcher,
  loadTownHallsConfigWatcher,
  loadTownHallWatcher,
  refreshCitysWatcher,
  removeCityWatcher,
  updateNotificationSettingsWatcher,
} from './city/saga'
import { loadExternalLinksWatcher } from './externalLinks/saga'
import {
  loadFavoritesCatalogByMostUsedWatcher,
  loadFavoritesCatalogByNameWatcher,
  loadFavoritesCatalogByThematicWatcher,
  loadFavoritesSubThematicCatalogWatcher,
  refreshFavoriteCatalogByMostUsedWatcher,
  refreshFavoriteCatalogByNameWatcher,
  refreshFavoriteCatalogByThematicWatcher,
} from './favoritesCatalog/sagas'
import { loadFavoritesConfigWatcher } from './favoritesConfig/sagas'
import {
  addFavoritesUserWatcher,
  addMultipleFavoritesUserWatcher,
  deleteFavoritesUserWatcher,
  loadFavoritesItemWatcher,
  loadFavoritesUserWatcher,
  pushFavoritesUserIdWatcher,
} from './favoritesUser/sagas'
import { loadGrcYpokConfigWatcher } from './grcYpok/sagas'
import {
  loadCarouselWatcher,
  loadGridAirQualityWatcher,
  loadGridPageActualiteWatcher,
  loadGridRssFeedWatcher,
  loadGridTideWatcher,
  loadGridWeatherWatcher,
  loadPageWatcher,
} from './gridPage/sagas'
import { fetchDisplayedPromotionWatcher, loadHomeConfigWatcher } from './home/saga'
import { loadIntermediatePageWatcher } from './intermediatePage/sagas'
import { loadMenuWatcher } from './menu/sagas'
import { loadMovieItemWatcher, loadMovieListWatcher, refreshMovieListWatcher } from './movies/sagas'
import {
  GetOneNotificationWatcher,
  loadHaveNotificationsSagaWatcher,
  loadNotificationsSettingsWatcher,
  loadNotificationsWatcher,
  notificationCategorieEnabledWatcher,
  notificationSettingsEnabledWatcher,
  refreshNotificationWatcher,
  sendNotificationTokenWatcher,
  updateHaveNotificationsSagaWatcher,
} from './notification/sagas'
import { loadReferentielItemWatcher, loadReferentielLandfillsWatcher, loadReferentielListWatcher, refreshReferentielListWatcher } from './referentiels/sagas'
import { loadSignalemenItemtWatcher, loadSignalementsWatcher, refreshSignalementsWatcher } from './signalements/sagas'
import { isAuthorizedGrcAccountWatcher, loadTaskReportItemWatcher, loadTaskReportWatcher, refreshTaskReportWatcher } from './taskReport/sagas'
import { loadThemeWatcher } from './theme/sagas'
import { loadTideMapWatcher } from './tide/sagas'
import { loadUrgentNumbersWatcher, refreshUrgentNumbersWatcher } from './UrgentNumbers/saga'
import { loadWeatherDaysWatcher } from './weather/sagas'
import { loadWeeklySchedulesWatcher } from './WeeklySchedules/sagas'

export function* rootSaga() {
  yield all([
    initializeUserWatcher(),
    signInUserWatcher(),
    loadHomeConfigWatcher(),
    fetchDisplayedPromotionWatcher(),
    loadPageWatcher(),
    loadCarouselWatcher(),
    loadGridPageActualiteWatcher(),
    loadGridWeatherWatcher(),
    loadGridRssFeedWatcher(),
    loadGridAirQualityWatcher(),
    sendNotificationTokenWatcher(),
    loadHaveNotificationsSagaWatcher(),
    updateHaveNotificationsSagaWatcher(),
    refreshNotificationWatcher(),
    loadNotificationsWatcher(),
    loadNotificationsSettingsWatcher(),
    notificationSettingsEnabledWatcher(),
    notificationCategorieEnabledWatcher(),
    loadThemeWatcher(),
    loadMenuWatcher(),
    loadCartographyWatcher(),
    loadCartographyMenuMarkerSagaWatcher(),
    loadCartographyRealTimeMapWatcher(),
    loadCartographyRealTimeMapItemsWatcher(),
    loadIntermediatePageWatcher(),
    loadFavoritesUserWatcher(),
    addFavoritesUserWatcher(),
    deleteFavoritesUserWatcher(),
    loadFavoritesCatalogByMostUsedWatcher(),
    refreshFavoriteCatalogByMostUsedWatcher(),
    loadFavoritesCatalogByThematicWatcher(),
    refreshFavoriteCatalogByThematicWatcher(),
    loadFavoritesCatalogByNameWatcher(),
    refreshFavoriteCatalogByNameWatcher(),
    loadFavoritesSubThematicCatalogWatcher(),
    loadActualiteWatcher(),
    loadNewsWatcher(),
    refreshNewsWatcher(),
    loadReferentielItemWatcher(),
    loadReferentielListWatcher(),
    refreshReferentielListWatcher(),
    loadWeeklySchedulesWatcher(),
    loadUrgentNumbersWatcher(),
    refreshUrgentNumbersWatcher(),
    loadSignalementsWatcher(),
    refreshSignalementsWatcher(),
    loadSignalemenItemtWatcher(),
    loadCartographyZonesWatcher(),
    loadCartographyBeaconsWatcher(),
    loadCantineWatcher(),
    loadMovieItemWatcher(),
    loadMovieListWatcher(),
    refreshMovieListWatcher(),
    loadGrcYpokConfigWatcher(),
    loadCitysWatcher(),
    loadCitysuserWatcher(),
    refreshCitysWatcher(),
    addCityWatcher(),
    removeCityWatcher(),
    loadCitysEventsConfigWatcher(),
    loadTownHallsConfigWatcher(),
    loadTownHallWatcher(),
    updateNotificationSettingsWatcher(),
    loadAirQualityWatcher(),
    checkAirQualityDayWatcher(),
    loadWeatherDaysWatcher(),
    loadExternalLinksWatcher(),
    loadTaskReportWatcher(),
    refreshTaskReportWatcher(),
    loadTaskReportItemWatcher(),
    isAuthorizedGrcAccountWatcher(),
    pushFavoritesUserIdWatcher(),
    addMultipleFavoritesUserWatcher(),
    loadFavoritesItemWatcher(),
    loadFavoritesConfigWatcher(),
    GetOneNotificationWatcher(),
    loadReferentielLandfillsWatcher(),
    loadTideMapWatcher(),
    loadGridTideWatcher(),
  ])
}
