import { BaseApi } from '../BaseApi'
import { HomeConfigResult, PromotionResult } from '.'
import { ApiResponse } from '../types'

export class HomeApi {
  public static async loadHomeConfig() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/home')
      .get()
      .json(json => json) as Promise<HomeConfigResult>
  }

  public static async loadPromotion(promotionId: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/promotions/${promotionId}`)
      .get()
      .json(json => json) as Promise<PromotionResult>
  }

  public static async saveDisplayedPromotion(promotionId: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/promotions/${promotionId}`)
      .put()
      .json(json => json) as Promise<ApiResponse>
  }
}
