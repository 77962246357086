import { HomeComponentName, HomeFooterItem, PromotionResult } from '../../api/Home'

export type HomeState = {
  homeComponentName: HomeComponentName
  footers: HomeFooterItem[]
  title: string | null
  titleImage: string | null
  cgu: string | null
  promotions?: {
    [key: string]: PromotionResult
  }
}

export enum HomeActionTypes {
  LOAD_HOME_CONFIG = 'LOAD_HOME_CONFIG',
  STORE_HOME_CONFIG = 'STORE_HOME_CONFIG',
  STORE_PROMOTION = 'STORE_PROMOTION',
  FETCH_DISPLAYED_PROMOTION = 'FETCH_DISPLAYED_PROMOTION',
  DISPLAYED_PROMOTION = 'DISPLAYED_PROMOTION',
  CLEAR_PROMOTION = 'CLEAR_PROMOTION',
}
