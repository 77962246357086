import { Linking, Platform } from 'react-native'
import MapView, { LatLng } from 'react-native-maps'
import { PolyMapResult } from '../../api/CartographyBeacons/type'
import { ActionOpenMap } from '../../api/types'
import { trackEvent } from '../matomo/matomo.service'

export const openMap = (mapAction: ActionOpenMap) => {
  const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' })
  const latlng = `${mapAction.latitude},${mapAction.longitude}`
  const { label } = mapAction
  const url = Platform.select({
    ios: `${scheme}${label}@${latlng}`,
    android: `${scheme}${latlng}(${label})`,
  }) as string

  trackEvent({
    category: 'GPS',
    actionName: 'Map',
    eventName: 'Appui sur le bouton de navigation',
    value: `${label}@${latlng}`,
  })

  Linking.openURL(url)
}

export const getPolyMapResultCoordinates = (polyMaps: PolyMapResult[]): LatLng[] =>
  polyMaps.reduce<LatLng[]>((coordinates, item) => {
    if (!item.coordinates) {
      return coordinates
    }

    return [...coordinates, ...item.coordinates]
  }, [])

/**
 * Fit map with a list of coordinates
 * @param mapViewRef
 * @param coordinates
 * @param padding
 */
export const fitToCoordinates = (mapViewRef: React.RefObject<MapView>, coordinates: LatLng[], padding: number) => {
  if (!mapViewRef || !mapViewRef.current) {
    return
  }

  mapViewRef.current.fitToCoordinates(coordinates, {
    animated: true,
    edgePadding: {
      top: padding,
      left: padding,
      right: padding,
      bottom: padding,
    },
  })
}
