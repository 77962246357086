import { StackScreenProps } from '@react-navigation/stack'
import { Button, Container, Icon, Input } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useDispatch, useSelector } from 'react-redux'
import { ActionCityConfigParams, CityItemResult } from '../../api/City/types'
import { CityList } from '../../components/CityList/CityList.component'
import { Content, Header, Item, Right } from '../../components/NativeBaseLegacy'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, RoutesCityAdd } from '../../navigation/Routes'
import { CityActions } from '../../redux/city/action'
import { notificationsEnabledSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { pop } from '../../services/navigation'

export const CityAdd: FunctionComponent<StackScreenProps<ActionViewParamsType, 'cityAdd'>> = ({ navigation }) => {
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const permission = useSelector((state: RootState) => notificationsEnabledSelector(state))

  const dispatch = useDispatch()

  const addCity = (item: CityItemResult) => {
    if (item.notification && permission) {
      const action: ActionCityConfigParams = {
        city: item,
      }
      navigation.navigate({ name: RoutesCityAdd.cityAddConfig, params: action })
    } else {
      dispatch(CityActions.addCity(item))
    }
  }

  return (
    <Container style={styles.container}>
      <Header>
        <Item>
          <Icon as={Ionicons} name="ios-search" size={6} />
          <Input
            placeholder={i18n.t('addCity.searchPlaceholder')}
            onChangeText={text => setSearchValue(text)}
            returnKeyType="search"
            autoCapitalize="none"
            clearButtonMode="always"
            autoCorrect={false}
          />
          <Icon name="city" as={MaterialCommunityIcons} size={6} />
        </Item>
        <Right style={styles.leftRight}>
          <Button
            variant="ghost"
            onPress={() => {
              pop()
            }}
          >
            <Icon as={Ionicons} name="close" size={6} />
          </Button>
        </Right>
      </Header>
      <Content style={styles.content}>
        <CityList search={searchValue} onSelect={item => addCity(item)} />
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1 },
  leftRight: {
    flex: 0,
    width: 50,
  },
  container: {
    maxWidth: '100%',
  },
})
