import { ITextProps, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'

export const Label: FunctionComponent<ITextProps> = ({ style, ...props }) => <Text style={[styles.content, style]} {...props} />

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
  },
})
