import { Container, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import FastImage, { Source } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import emailValidated from '../../assets/images/email_validated.png'
import { Button } from '../../components/Button'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76
const IMAGE_HEIGHT = 200

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x3,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  image: {
    height: IMAGE_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x4,
    marginVertical: theme.padding.x4,
  },
})

export const EmailVerificationDone: FunctionComponent = () => {
  const menu = useSelector(menuSelector)

  const logoUri = menu && menu.header && menu.header.image

  return (
    <Container>
      <SafeAreaView style={styles.content}>
        {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
        <Text style={styles.title}>{i18n.t('emailVerificationDone.title')}</Text>
        <Text style={styles.subtitle}>{i18n.t('emailVerificationDone.subtitle')}</Text>
        <FastImage style={styles.image} source={emailValidated as Source} resizeMode={FastImage.resizeMode.contain} />
        <Button
          style={{ marginTop: theme.padding.x3, backgroundColor: theme.colors.green }}
          onPress={() => navigate(Routes.HomeTabs)}
          label={i18n.t('emailVerificationDone.continue')}
        />
      </SafeAreaView>
    </Container>
  )
}
