import isEmpty from 'lodash/isEmpty'
import React, { FunctionComponent, useState } from 'react'
import { MansoryItemResult } from '../../../api/Favorites/types'
import { ReferentielMediaResult } from '../../../api/Referentiels/types'
import { i18n } from '../../../lib/i18n'
import { openLink } from '../../../services/browserInApp'
import { getBubble } from '../../../services/bubble'
import { mailto } from '../../../services/mailto/mailto.services'
import { phoneCall } from '../../../services/phoneCall'
import { Card } from '../../Card'
import { MasonryList } from '../../MasonryList'

interface ReferentielMediaProps {
  phone: string | null
  email: string | null
  website: string | null
  media: ReferentielMediaResult | null
}

interface ReferentielMediaItem extends MansoryItemResult {
  component: React.ReactNode
}

export const ReferentielMedia: FunctionComponent<ReferentielMediaProps> = ({ phone, email, website, media }) => {
  const [isPlus, setPlus] = useState<boolean>(true)
  const items: ReferentielMediaItem[] = []

  const addItem = (component: React.ReactNode) => {
    if (items.length === 3 && isPlus) {
      items.push({
        id: items.length + 1,
        sizeX: 1,
        component: getBubble({
          link: {
            icon: 'more-horiz',
            iconTheme: 'MaterialIcons',
            label: i18n.t('more'),
          },
          onPress: () => {
            setPlus(false)
          },
        }),
      })
    }
    if (items.length > 3 && isPlus) {
      return
    }

    items.push({
      id: items.length + 1,
      sizeX: 1,
      component,
    })
  }

  if (phone) {
    addItem(
      getBubble({
        link: {
          icon: 'call',
          iconTheme: 'MaterialIcons',
          label: i18n.t('call'),
        },
        onPress: async () => {
          await phoneCall(phone)
        },
      }),
    )
  }

  if (email) {
    addItem(
      getBubble({
        link: {
          icon: 'send-o',
          iconTheme: 'FontAwesome',
          label: 'Email',
        },
        onPress: async () => {
          await mailto(email)
        },
      }),
    )
  }
  if (website) {
    addItem(
      getBubble({
        link: {
          labelIn: 'www',
          label: i18n.t('website'),
        },
        onPress: () => openLink(website),
      }),
    )
  }

  if (media) {
    if (media.facebook) {
      const fb = media.facebook
      addItem(
        getBubble({
          link: {
            icon: 'facebook',
            iconTheme: 'FontAwesome',
            label: 'Facebook',
          },
          onPress: () => openLink(fb),
        }),
      )
    }

    if (media.twitter) {
      const { twitter } = media
      addItem(
        getBubble({
          link: {
            icon: 'twitter',
            iconTheme: 'FontAwesome',
            label: 'Twitter',
          },
          onPress: () => openLink(twitter),
        }),
      )
    }

    if (media.instagram) {
      const { instagram } = media
      addItem(
        getBubble({
          link: {
            icon: 'instagram',
            iconTheme: 'FontAwesome',
            label: 'Instagram',
          },
          onPress: () => openLink(instagram),
        }),
      )
    }

    if (media.linkedin) {
      const { linkedin } = media
      addItem(
        getBubble({
          link: {
            icon: 'linkedin',
            iconTheme: 'FontAwesome',
            label: 'Linkedin',
          },
          onPress: () => openLink(linkedin),
        }),
      )
    }
  }

  if (isEmpty(items)) {
    return null
  }

  return (
    <Card spacing column>
      <MasonryList<ReferentielMediaItem> data={items} columns={4} columnHeight={80} columnJustify="flex-start" renderItem={(item: ReferentielMediaItem) => item.component} />
    </Card>
  )
}
