/* eslint-disable prettier/prettier */
import { Button, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { SegmentItemResult } from '../../api/types'
import { themeSelector } from '../../redux/theme/selector'
import { trackEvent } from '../../services/matomo/matomo.service'

export const WIDTH_LEFT_RIGHT = 50

interface SegmentProps {
  items: SegmentItemResult[]
  defautItem?: SegmentItemResult | null
  onPress: (item: SegmentItemResult) => void
}

export const Segment: FunctionComponent<SegmentProps> = ({ items, defautItem, onPress }) => {
  const [currentItem, setCurrentItem] = useState<SegmentItemResult | null>(null)
  if (defautItem && !currentItem) {
    setCurrentItem(defautItem)
  }

  const isActive = (item: SegmentItemResult) => !!(currentItem && currentItem.id === item.id)

  const onChangeItem = (item: SegmentItemResult) => {
    setCurrentItem(item)
    onPress(item)
  }

  const theme = useSelector(themeSelector)

  return (
    <View p="2" style={[{ backgroundColor: theme?.toolbarDefaultBg }, styles.container]}>
      <Button.Group isAttached>
        {items.map(item => (
          <Button
            py="1"
            style={[styles.border, { borderColor: theme?.segmentTextColor }]}
            key={item.id}
            variant={isActive(item) ? 'solid' : "outline"}
            background={isActive(item) ? theme?.segmentTextColor : theme?.toolbarDefaultBg}
            onPress={() => {
              onChangeItem(item)
              trackEvent({
                category: 'Segment',
                actionName: 'Filtre',
                eventName: 'Changement de filtre',
                value: item.libelle,
              })
            }}
          >
            <Text fontSize="12" color={isActive(item) ? theme?.toolbarDefaultBg : theme?.segmentTextColor}>{item.libelle}</Text>
          </Button>
        ))}
      </Button.Group>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  border: {
    borderWidth: 1,
  },
})