// @flow

import Variables from '../variables/material'
import variable from '../variables/platform'

export default (variables: Partial<typeof Variables> = variable) => ({
  variants: {
    solid: () => ({
      bg: variables.buttonPrimaryBg,
      _hover: variables.buttonPrimaryBg,
      color: variables.buttonPrimaryColor,
    }),
    outline: () => ({
      borderColor: variables.buttonPrimaryBg,
      color: variables.buttonPrimaryBg,
    }),
  },
})
