import { call, put, takeEvery } from 'redux-saga/effects'
import { GrcYpokApi } from '../../api/GrcYpok'
import { isOfflineError } from '../../services/errorManager'
import { GrcYpokActions } from './actions'
import { GrcYpokActionTypes } from './types'

function* loadConfigSaga() {
  try {
    const ypokConfig = yield call(GrcYpokApi.load)
    yield put(GrcYpokActions.storeYpokConfig(ypokConfig))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

export function* loadGrcYpokConfigWatcher() {
  yield takeEvery(GrcYpokActionTypes.LOAD_YPOK_CONFIG, loadConfigSaga)
}
