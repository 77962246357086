// @flow

import color from 'color'

import { Dimensions, PixelRatio, Platform } from 'react-native'

import { PLATFORM } from './commonColor'

const deviceHeight = Dimensions.get('window').height
const deviceWidth = Dimensions.get('window').width
const platform = Platform.OS
const platformStyle = undefined
const isIphoneX =
  platform === 'ios' &&
  (deviceHeight === 812 ||
    deviceWidth === 812 ||
    deviceHeight === 896 ||
    deviceWidth === 896 ||
    deviceHeight === 844 ||
    deviceHeight === 926)

const myTheme = data => {
  if (!data) {
    return null
  }
  return {
    platformStyle,
    platform,

    //Accordion
    headerStyle: data.headerStyle,
    iconStyle: data.iconStyle,
    contentStyle: data.contentStyle,
    expandedIconStyle: data.expandedIconStyle,
    accordionBorderColor: data.accordionBorderColor,
    accordionContentPadding: 10,
    accordionIconFontSize: 18,
    disableRow: '#a9a9a9',

    // ActionSheet
    elevation: 4,
    containerTouchableBackgroundColor: 'rgba(0,0,0,0.4)',
    innerTouchableBackgroundColor: '#fff',
    listItemHeight: 50,
    listItemBorderColor: 'transparent',
    marginHorizontal: -15,
    marginLeft: 14,
    marginTop: 15,
    minHeight: 56,
    padding: 15,
    touchableTextColor: '#757575',

    // Android
    androidRipple: true,
    androidRippleColor: data.androidRippleColor,
    androidRippleColorDark: data.androidRippleColorDark,
    btnUppercaseAndroidText: true,

    // Badge
    badgeBg: data.badgeBg,
    badgeColor: data.badgeColor,
    badgePadding: 0,

    // Button
    // buttonFontFamily: 'Bariol-Regular',
    buttonDisabledBg: data.btnDisabledBg,
    buttonPadding: 6,
    get buttonPrimaryBg() {
      return data.brandPrimary
    },
    get buttonPrimaryColor() {
      return this.inverseTextColor
    },
    get buttonInfoBg() {
      return this.brandInfo
    },
    get buttonInfoColor() {
      return this.inverseTextColor
    },
    get buttonSuccessBg() {
      return this.brandSuccess
    },
    get buttonSuccessColor() {
      return this.inverseTextColor
    },
    get buttonDangerBg() {
      return this.brandDanger
    },
    get buttonDangerColor() {
      return this.inverseTextColor
    },
    get buttonWarningBg() {
      return this.brandWarning
    },
    get buttonWarningColor() {
      return this.inverseTextColor
    },
    get buttonTextSize() {
      return this.fontSizeBase - 1
    },
    get buttonTextSizeLarge() {
      return this.fontSizeBase * 1.5
    },
    get buttonTextSizeSmall() {
      return this.fontSizeBase * 0.8
    },
    get borderRadiusLarge() {
      return this.fontSizeBase * 3.8
    },
    get iconSizeLarge() {
      return this.iconFontSize * 1.5
    },
    get iconSizeSmall() {
      return this.iconFontSize * 0.6
    },

    // Card
    cardDefaultBg: data.cardDefaultBg,
    cardBorderColor: data.cardBorderColor,
    cardBorderRadius: 2,
    cardItemPadding: platform === PLATFORM.IOS ? 10 : 12,

    // CheckBox
    CheckboxRadius: 0,
    CheckboxBorderWidth: 2,
    CheckboxPaddingLeft: 1,
    CheckboxPaddingBottom: 4,
    CheckboxIconSize: 16,
    CheckboxIconMarginTop: 1,
    CheckboxFontSize: 17,
    checkboxBgColor: data.checkboxBgColor,
    checkboxSize: 20,
    checkboxTickColor: 'white',
    checkboxDefaultColor: 'transparent',

    // Color
    brandPrimary: data.brandPrimary,
    brandInfo: data.brandInfo,
    brandSuccess: data.brandSuccess,
    brandDanger: data.brandDanger,
    brandWarning: data.brandWarning,
    brandDark: data.brandDark,
    brandLight: data.brandLight,

    //Container
    containerBgColor: data.containerBgColor,

    //Date Picker
    datePickerTextColor: data.datePickerTextColor,
    datePickerBg: 'transparent',

    // FAB
    fabWidth: 56,

    // Font
    DefaultFontSize: 16,
    // fontFamily: 'Bariol-Regular',
    fontSizeBase: 15,
    get fontSizeH1() {
      return this.fontSizeBase * 1.8
    },
    get fontSizeH2() {
      return this.fontSizeBase * 1.6
    },
    get fontSizeH3() {
      return this.fontSizeBase * 1.4
    },

    // Footer
    footerHeight: 55,
    footerDefaultBg: data.footerDefaultBg,
    footerPaddingBottom: 0,

    // FooterTab
    tabBarTextColor: data.tabBarTextColor,
    tabBarTextSize: 11,
    activeTab: data.activeTab,
    sTabBarActiveTextColor: data.sTabBarActiveTextColor,
    tabBarActiveTextColor: data.tabBarActiveTextColor,
    tabActiveBgColor: data.tabActiveBgColor,

    // Header
    toolbarBtnColor: data.toolbarBtnColor,
    toolbarDefaultBg: data.toolbarDefaultBg,
    toolbarHeight: 56,
    toolbarSearchIconSize: 23,
    toolbarInputColor: data.toolbarInputColor,
    searchBarHeight: platform === PLATFORM.IOS ? 30 : 40,
    searchBarInputHeight: platform === PLATFORM.IOS ? 30 : 50,
    toolbarBtnTextColor: data.toolbarBtnTextColor,
    toolbarDefaultBorder: data.toolbarDefaultBorder,
    iosStatusbar: 'light-content',
    get statusBarColor() {
      return color(this.toolbarDefaultBg)
        .darken(0.2)
        .hex()
    },
    get darkenHeader() {
      return color(this.tabBgColor)
        .darken(0.03)
        .hex()
    },

    // Icon
    iconFamily: 'Ionicons',
    iconFontSize: platform === 'ios' ? 30 : 28,
    iconHeaderSize: platform === 'ios' ? 33 : 24,

    // InputGroup
    inputFontSize: 17,
    inputBorderColor: data.inputBorderColor,
    inputSuccessBorderColor: data.inputSuccessBorderColor,
    inputErrorBorderColor: data.inputErrorBorderColor,
    inputHeightBase: 50,
    get inputColor() {
      return this.textColor
    },
    get inputColorPlaceholder() {
      return '#575757'
    },

    // Line Height
    buttonLineHeight: 19,
    lineHeightH1: 32,
    lineHeightH2: 27,
    lineHeightH3: 22,
    lineHeight: 24,

    // List
    listBg: data.listBg,
    listBorderColor: data.listBorderColor,
    listDividerBg: data.listDividerBg,
    listBtnUnderlayColor: data.listBtnUnderlayColor,
    listItemPadding: 12,
    listNoteColor: data.listNoteColor,
    listNoteSize: 13,
    listItemSelected: data.listItemSelected,

    // Progress Bar
    defaultProgressColor: data.defaultProgressColor,
    inverseProgressColor: data.inverseProgressColor,

    // Radio Button
    radioBtnSize: 23,
    radioSelectedColorAndroid: data.radioSelectedColorAndroid,
    radioBtnLineHeight: 24,
    radioColor: data.checkboxBgColor,

    // Segment
    segmentBackgroundColor: data.segmentBackgroundColor,
    segmentActiveBackgroundColor: data.segmentActiveBackgroundColor,
    segmentTextColor: data.segmentTextColor,
    segmentActiveTextColor: data.segmentActiveTextColor,
    segmentBorderColor: data.segmentBorderColor,
    segmentBorderColorMain: data.segmentBorderColorMain,

    // Spinner
    defaultSpinnerColor: data.defaultSpinnerColor,
    inverseSpinnerColor: data.inverseSpinnerColor,

    // Tab
    tabDefaultBg: data.tabDefaultBg,
    topTabBarTextColor: data.topTabBarTextColor,
    topTabBarActiveTextColor: data.topTabBarActiveTextColor,
    topTabBarBorderColor: data.topTabBarBorderColor,
    topTabBarActiveBorderColor: data.topTabBarActiveBorderColor,

    // Tabs
    tabBgColor: data.tabBgColor,
    tabFontSize: 15,

    // Text
    textColor: data.textColor,
    inverseTextColor: '#fff',
    noteFontSize: 14,
    get defaultTextColor() {
      return this.textColor
    },

    // Title
    // titleFontfamily: 'Bariol-Regular',
    titleFontSize: 19,
    subTitleFontSize: 14,
    subtitleColor: data.subtitleColor,
    titleFontColor: data.titleFontColor,

    // Other
    borderRadiusBase: 2,
    borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    contentPadding: 10,
    dropdownLinkColor: data.dropdownLinkColor,
    inputLineHeight: 24,
    deviceWidth,
    deviceHeight,
    isIphoneX,
    inputGroupRoundedBorderRadius: 30,

    //iPhoneX SafeArea
    Inset: {
      portrait: {
        topInset: 24,
        leftInset: 0,
        rightInset: 0,
        bottomInset: 34,
      },
      landscape: {
        topInset: 0,
        leftInset: 44,
        rightInset: 44,
        bottomInset: 21,
      },
    },
  }
}

export default myTheme
