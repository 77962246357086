import { WeatheIconerResult } from '../GridPage/types'

export interface AirQualityConfig {
  id: number
  libelle: string
  weather: WeatherItemResult
  airParif: AirParifConfig | null
}

export interface WeatherItemResult {
  temperature: number
  dateUpdate: number
  weather?: WeatheIconerResult
}

export interface AirQualityDayResult {
  quality: QualityValue
}

export interface AirParifConfig {
  url: string
  key: string
  code: string
}

export interface AirParifResult {
  [ninsee: string]: AirParifDayResult[]
}

interface AirParifDayResult {
  date: string
  no2: QualityValue
  o3: QualityValue
  pm10: QualityValue
  pm25: QualityValue
  so2: QualityValue
  indice: QualityValue
}

export enum QualityValue {
  'GOOD' = 'Bon',
  'WAY' = 'Moyen',
  'POOR' = 'Dégradé',
  'BAD' = 'Mauvais',
}
