import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Image, ImageSourcePropType, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import BACKGROUND_IMAGE from '../../../assets/images/access_denied.png'
import { i18n } from '../../../lib/i18n'
import { theme } from '../../../theme'
import { CardInFo } from '../../CardInFo'

export const TaskReportLoginErrors: FunctionComponent = () => {
  const addr = ''
  return (
    <View style={styles.container}>
      <Image style={styles.image} source={BACKGROUND_IMAGE as ImageSourcePropType} resizeMode={FastImage.resizeMode.contain} />
      <CardInFo
        title={i18n.t('taskReport.noGRCAccountTitle')}
        textColor={theme.colors.azure}
        colorBackground={theme.colors.azureLight}
        components={[i18n.t('taskReport.noGRCAccountExplain'), addr]}
        footer={i18n.t('taskReport.noGRCAccountFooter')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.padding.x2,
    marginHorizontal: theme.padding.x3,
  },
  image: {
    width: theme.deviceWidth,
    height: theme.referentielImageMaxHeight,
  },
})
