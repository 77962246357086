import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ImageSourcePropType, StyleSheet, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ReferentielInfoResult } from '../../api/Referentiels/types'
import { CardAddress } from '../../components/CardAddress'
import { HeaderScrollView } from '../../components/HeaderScrollView'
import { LeftButtonBack, LeftRoundButtonBack } from '../../components/Page/Page.component'
import { ReferentielHoraire, ReferentielInfos, ReferentielLandfillsDay, ReferentielLandfillsWeek, ReferentielLinks } from '../../components/Referentiel/components'
import { ReferentielFields } from '../../components/Referentiel/components/ReferentielFields.component'
import { ReferentielMedia } from '../../components/Referentiel/components/ReferentielMedia.component'
import { Separator } from '../../components/Separator'
import { HtmlWebView } from '../../components/WebView'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { ReferentielsActions } from '../../redux/referentiels/actions'
import { referentielItemSelector, referentielLandfillSelector } from '../../redux/referentiels/selector'
import { theme } from '../../theme'

import imageDefault from '../../assets/images/empty_image.jpg'

export const Referentiel: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Referentiel'>> = ({ route }) => {
  const id = route.params.id as number
  const { image } = route.params

  const dispatch = useDispatch()

  const referentiel = useSelector((state: RootState) => referentielItemSelector(state, id))
  const landfills = useSelector((state: RootState) => referentielLandfillSelector(state))

  const [loadLandfills, setLoadLandfills] = useState<boolean>(false)

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_ACTUALITE))

  const loadReferentiel = () => {
    dispatch(ReferentielsActions.loadReferentielItem(id))
  }
  useEffect(loadReferentiel, [])

  const loadSubDependancies = () => {
    const infos = referentiel && referentiel.infos ? referentiel.infos : undefined
    const refCond = !_.isEmpty(infos) && _.find(infos, { libelle: 'url_stats_by_day' }) && _.find(infos, { libelle: 'url_stats_by_week' }) && _.find(infos, { libelle: 'code' })
    if (refCond && _.isEmpty(landfills) && !loadLandfills) {
      setLoadLandfills(true)
      const weekUrl = (_.find(infos, { libelle: 'url_stats_by_week' }) as ReferentielInfoResult).value as string
      const daysUrl = (_.find(infos, { libelle: 'url_stats_by_day' }) as ReferentielInfoResult).value as string
      dispatch(ReferentielsActions.loadReferentielLandfills(weekUrl, daysUrl))
    } else if (!_.isEmpty(landfills)) {
      setLoadLandfills(false)
    }
  }

  useEffect(loadSubDependancies, [referentiel, landfills])

  if (!referentiel || isLoading) {
    const images = image ? [image] : []
    return (
      <HeaderScrollView images={images} imageDefault={imageDefault as ImageSourcePropType} title="" left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
        <Spinner />
      </HeaderScrollView>
    )
  }

  const libelle = referentiel ? referentiel.libelle : ''
  const images = referentiel && referentiel.image ? [referentiel.image] : []

  const excludedLandFillsFromInfos = _.compact(
    _.map(referentiel.infos, ({ libelle, ...rest }) => (libelle !== 'code' && libelle !== 'url_stats_by_day' && libelle !== 'url_stats_by_week' ? { libelle, ...rest } : null)),
  )

  return (
    <HeaderScrollView images={images} imageDefault={imageDefault as ImageSourcePropType} title={libelle} left={<LeftRoundButtonBack />} leftHeader={<LeftButtonBack />}>
      <View style={styles.content}>
        {referentiel.categorie && (
          <Text style={styles.categorie} numberOfLines={2}>
            {referentiel.categorie}
          </Text>
        )}
        <Separator />
        <ReferentielMedia phone={referentiel.telephone} email={referentiel.email} website={referentiel.siteWeb} media={referentiel.media} />
        <ReferentielHoraire horaire={referentiel.horaire} />
        <CardAddress address={referentiel.adresseFull} positionMap={referentiel.positionMap} />
        <ReferentielLinks links={referentiel.links} />
        <ReferentielInfos infos={excludedLandFillsFromInfos} />
        <ReferentielFields fields={referentiel.fields} />
        <ReferentielLandfillsDay code={_.find(referentiel.infos, ['libelle', 'code']) as ReferentielInfoResult} />
        <ReferentielLandfillsWeek code={_.find(referentiel.infos, ['libelle', 'code']) as ReferentielInfoResult} />
        <HtmlWebView content={referentiel.description} />
      </View>
    </HeaderScrollView>
  )
}

interface Style {
  content: ViewStyle
  categorie: ViewStyle
}
const styles = StyleSheet.create<Style>({
  content: {
    paddingHorizontal: theme.padding.x2,
  },
  categorie: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    fontSize: theme.textSize.x2,
    color: theme.colors.silver,
  },
})
