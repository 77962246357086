import { Card, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { EtatColorResult } from '../../../api/types'
import { i18n } from '../../../lib/i18n'
import { theme } from '../../../theme'
import { CardItem } from '../../NativeBaseLegacy'

interface SignalementItemEtatProps {
  etat: EtatColorResult | null
}

export const SignalementItemEtat: FunctionComponent<SignalementItemEtatProps> = ({ etat }) => {
  if (!etat) {
    return null
  }

  return (
    <Card mx="1" mb="1" mt="2" p="0" style={styles.content}>
      <CardItem>
        <Text>{i18n.t('signalement.state')} : </Text>
        <Text style={{ color: etat.color || theme.colors.black }}>{etat.libelle}</Text>
      </CardItem>
    </Card>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
  },
})
