import { Toast } from 'native-base'
import { i18n } from '../../lib/i18n'
import { theme } from '../../theme'

export const showDanger = (message: string = i18n.t('toast.error'), duration = 1500) => {
  Toast.show({
    title: message,
    placement: 'top',
    variant: 'solid',
    duration,
    background: theme.colors.red,
    color: theme.colors.white,
  })
}

export const showSuccess = (message: string = i18n.t('toast.success'), placement?: 'top' | 'bottom', duration = 2000) => {
  Toast.show({
    title: message,
    placement: placement || 'top',
    variant: 'solid',
    color: theme.colors.white,
    duration,
    background: theme.colors.green,
  })
}

export const showWarning = (message: string, placement?: 'top' | 'bottom', duration = 3000) => {
  Toast.show({
    title: message,
    placement: placement || 'top',
    variant: 'solid',
    duration,
    background: theme.colors.orange,
    color: theme.colors.white,
  })
}
