import React, { FunctionComponent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { FlatListPaging } from '../FlatListPaging'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { citysSelector, lastPageSelector } from '../../redux/city/selector'
import { CityActions } from '../../redux/city/action'
import { CityItem } from '../CityItem/CityItem.component'
import { CityItemResult } from '../../api/City/types'
import { Separator } from '../Separator'

interface CitysProps {
  search: string | null
  onSelect: (cityItem: CityItemResult) => void
}

export const CityList: FunctionComponent<CitysProps> = ({ search, onSelect }) => {
  const dispatch = useDispatch()
  const citys = useSelector(citysSelector)

  useEffect(() => {
    dispatch(CityActions.loadCitys(true, search))
  }, [search])

  const isLoadingCitys = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_CITYS))

  const isLastPage = useSelector(lastPageSelector)

  const onEndReached = () => {
    canLoadMore() ? dispatch(CityActions.loadCitys(false, search)) : null
  }

  const onRefresh = () => {
    dispatch(CityActions.refreshCitys())
  }

  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_CITYS))

  const isLoadingPage = () => isEmpty(citys) && isLoadingCitys && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoadingCitys && isLoadingMore()

  return (
    <FlatListPaging
      data={citys}
      renderItem={({ item }) => <CityItem cityItem={item} onSelect={onSelect} />}
      onEndReached={onEndReached}
      onRefresh={onRefresh}
      isRefreshing={isRefreshing}
      isLoadingPage={isLoadingPage()}
      isLoadingMore={isLoadingMore}
      keyExtractor={item => item.id.toString()}
      itemSeparatorComponent={() => <Separator paddingVertical={false} />}
    />
  )
}
