import _ from 'lodash'
import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Entypo from 'react-native-vector-icons/Entypo'
import { useSelector } from 'react-redux'
import { WeatherResult } from '../../api/GridPage/types'
import { everyAirQualityConfigSelector, weatherAllSelector } from '../../redux/gridPage/selector'
import { RootState } from '../../redux/reducers'
import { getColor } from '../../services/theme'
import { theme } from '../../theme'

interface iProps {
  title: string
  onPress: () => void
}

export const FavItemShopSimple: FunctionComponent<iProps> = ({ title, onPress }: iProps) => {
  const weather = useSelector((state: RootState) => weatherAllSelector(state))
  const allGridAirQualityConfig = useSelector((state: RootState) => everyAirQualityConfigSelector(state))

  const keyGridAirQualityConfig = Object.keys(allGridAirQualityConfig)[0]
  const gridAirQualityConfig = allGridAirQualityConfig[keyGridAirQualityConfig]

  const weatherColor = _.find(weather, 'textColor') as WeatherResult

  const color = getColor(weatherColor && weatherColor.textColor, gridAirQualityConfig && gridAirQualityConfig.textColor)

  return (
    <TouchableOpacity onPress={() => onPress()} style={styles.container}>
      <View style={styles.titleWrapper}>
        <Icon name="circle-with-plus" as={Entypo} style={styles.icon} size={6} />
        <Text style={[styles.title, { color }]}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  icon: {
    color: theme.colors.azure,
    marginRight: 8,
  },
  titleWrapper: {
    paddingTop: 12,
    paddingBottom: 2,
    borderBottomColor: theme.colors.silver,
    borderBottomWidth: 1,
    paddingHorizontal: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    textAlign: 'left',
    fontSize: theme.textSize.x1_75,
    fontWeight: 'bold',
    // fontFamily: theme.fontFamily,
  },
})
