import { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent } from 'react'
import { ReferentielFilters } from '../../api/types'
import { AgendaList as Agenda } from '../../components/AgendaList'
import { LeftButtonBack } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'

export const AgendaList: FunctionComponent<StackScreenProps<ActionViewParamsType, 'AgendaList'>> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.idParams as number
  const referentielFilters = route.params.referentielFilters as ReferentielFilters

  return <Agenda id={id} title={title || ''} referentielFilters={referentielFilters} left={<LeftButtonBack />} />
}
