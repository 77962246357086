import * as rssParser from 'react-native-rss-parser'
import wretch from 'wretch'

export const rssParse = async (url: string) => {
  const resp = await wretch(url)
    .get()
    .res(response => response.text())
    .catch(() => {})

  if (resp) {
    return rssParser.parse(resp)
  }
  return null
}
