import { BaseApi } from '../BaseApi'
import { TideDetails } from './types'

export class TideApi {
  public static async loadTideDetails(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/tide-schedules/${id}`)
      .get()
      .json(json => json) as Promise<TideDetails>
  }
  // public static async loadTideDetails(id: number) {
  //   return BaseApi.authenticatedCall()
  //     .url(`v1/tide-schedules/${id}`)
  //     .get()
  //     .json(json => json) as Promise<TideDetails>
  // }
}
