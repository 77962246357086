import { Button, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { TaskReportItem } from '../../../api/TaskReport/types'
import { i18n } from '../../../lib/i18n'
import { Routes } from '../../../navigation/Routes'
import { themeSelector } from '../../../redux/theme/selector'
import { navigate } from '../../../services/navigation'
import { theme } from '../../../theme'

const SIZE_TEXT = 20

interface iProps {
  task: TaskReportItem
}

export const ReportButtonProcess: FunctionComponent<iProps> = ({ task }) => {
  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  const onPress = () => {
    navigate(Routes.TaskReportItemRequest, { taskReport: task })
  }

  return (
    <View>
      <Button style={[styles.button, { backgroundColor: color }]} onPress={onPress}>
        <Text style={styles.textButton} color={theme.colors.white}>
          {i18n.t('signalement.process')}
        </Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: theme.padding.x3,
  },
  textButton: {
    fontWeight: 'bold',
    fontSize: SIZE_TEXT,
  },
})
