import isEmpty from 'lodash/isEmpty'
import { Icon } from 'native-base'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Animated, Easing, FlatList, Platform, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { CartographyItemResult } from '../../api/Cartography/types'
import { CartographyActions } from '../../redux/cartography/actions'
import { cartographyDefaultSelectedItemSelector, cartographyItemsSelector } from '../../redux/cartography/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme'
import { CartographyItem, CartographyList } from './components'

export const MIN_WIDTH = 45
const MAX_WIDTH = 260
const BACKGROUND_COLOR_IOS = 'rgba(255,255,255,0.7)'
const BACKGROUND_COLOR_ANDROID = 'rgba(255,255,255,0.8)'

interface CartographyMenuProps {
  cartographyId: string
  onMenuItemSelected: (menuId: string | null) => void
  displayLoader: (show: boolean) => void
}

const CartographyMenu: FunctionComponent<CartographyMenuProps> = ({ cartographyId, onMenuItemSelected, displayLoader }) => {
  const dispatch = useDispatch()
  const defaultMenuItem = useSelector((state: RootState) => cartographyDefaultSelectedItemSelector(state, cartographyId))
  const themeSelect = useSelector(themeSelector)
  const colorIconOpenMenu = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  const items = useSelector((state: RootState) => cartographyItemsSelector(state, cartographyId))
  const width = useRef(new Animated.Value(MIN_WIDTH)).current

  const [isHorizontalMenuOpen, setHorizontalMenuOpen] = useState(false)
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(defaultMenuItem)
  const [animatedStyle, setAnimatedStyle] = useState({ width })
  const [expandIconNameButton, setExpandIconNameButton] = useState(theme.icon.arrowLeft)

  const toggleMenu = () => {
    const toValue = isHorizontalMenuOpen ? MIN_WIDTH : MAX_WIDTH

    Animated.timing(width, {
      toValue,
      duration: 150,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start(() => {
      setHorizontalMenuOpen(!isHorizontalMenuOpen)
    })
  }

  const onPress = (item: CartographyItemResult) => (itemId: number) => {
    displayLoader(true)
    trackEvent({
      category: 'Cartographie',
      actionName: 'Cartographie',
      eventName: "Sélection d'un menu",
      value: item.libelle,
    })
    setSelectedMenuId(itemId)
  }

  useEffect(() => {
    setAnimatedStyle({
      width,
    })
  }, [width])

  useEffect(() => {
    setExpandIconNameButton(isHorizontalMenuOpen ? theme.icon.arrowRight : theme.icon.arrowLeft)
  }, [isHorizontalMenuOpen])

  useEffect(() => {
    if (isHorizontalMenuOpen || !selectedMenuId) {
      toggleMenu()
    }
    if (selectedMenuId) {
      displayLoader(true)
      dispatch(CartographyActions.loadCartographyMenuMarkers(cartographyId, selectedMenuId.toString()))
      onMenuItemSelected(selectedMenuId.toString())
    }
  }, [selectedMenuId])

  const renderItem = (item: CartographyItemResult) =>
    isEmpty(item.items) ? (
      <CartographyItem item={item} onPress={onPress(item)} isSelected={selectedMenuId === item.id} />
    ) : (
      <CartographyList item={item} onPress={onPress(item)} selectedItem={selectedMenuId} isExpandable={isHorizontalMenuOpen} />
    )

  return (
    <Animated.View style={[styles.container, animatedStyle]}>
      <SafeAreaView style={styles.container}>
        <TouchableOpacity onPress={toggleMenu} style={[styles.button]}>
          <Icon as={Ionicons} name={expandIconNameButton} color={colorIconOpenMenu} size={8} />
        </TouchableOpacity>
        <FlatList
          style={styles.list}
          data={items}
          renderItem={({ item }) => renderItem(item)}
          keyExtractor={item => item.id.toString()}
          extraData={[selectedMenuId, isHorizontalMenuOpen]}
        />
      </SafeAreaView>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Platform.OS === 'android' ? BACKGROUND_COLOR_ANDROID : BACKGROUND_COLOR_IOS,
    height: '100%',
  },
  button: {
    width: '100%',
    marginLeft: 0,
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.padding.x1,
  },
  list: {
    flex: 1,
  },
})

export { CartographyMenu }
