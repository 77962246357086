import { BaseApi } from '../BaseApi'
import { NotificationsResult, NotificationSettingsResult } from './types'

export class NotificationApi {
  public static async load(page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/notification/find?page=${page}&size=${size}`)
      .get()
      .json(json => json) as Promise<NotificationsResult>
  }

  public static async sendNotificationsToken(tokenPhone: string | null) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/token')
      .post({ token: tokenPhone })
      .json(json => json)
  }

  public static async haveViewNotification() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/view')
      .get()
      .json(json => json)
  }

  public static async postViewNotification() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/view')
      .post()
      .json(json => json)
  }

  public static async loadSettings() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/settings')
      .get()
      .json(json => json) as Promise<NotificationSettingsResult>
  }

  public static async notificationSettingEnabled(enabled: boolean) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/enabled')
      .query({ value: enabled })
      .post()
      .json(json => json)
  }

  public static async notificationCategorieEnabled(id: number, enabled: boolean) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/notification/categorie/notifier')
      .query({ id, value: enabled })
      .post()
      .json(json => json)
  }

  public static async getOneNotification(id: number | string) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/notification/${id}`)
      .get()
      .json(json => json)
  }
}
