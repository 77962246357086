import isEmpty from 'lodash/isEmpty'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { ReferentielFieldResult } from '../../../api/Referentiels/types'
import { Card } from '../../Card'

const CONTENT_IMAGE = 30
const IMAGE_SIZE = 20

interface ReferentielFielsProps {
  fields: ReferentielFieldResult[]
}

export const ReferentielFields: FunctionComponent<ReferentielFielsProps> = ({ fields }) => {
  if (isEmpty(fields)) {
    return null
  }

  const items = fields.map((item, index) => (
    <React.Fragment key={index}>
      <View style={styles.contentImage}>{item.image && <FastImage style={styles.image} source={{ uri: item.image }} resizeMode={FastImage.resizeMode.contain} />}</View>
      <Text style={styles.text}>{item.libelle}</Text>
      <Text>{item.value}</Text>
    </React.Fragment>
  ))

  return (
    <Card spacing column>
      {items}
    </Card>
  )
}

const styles = StyleSheet.create({
  text: { flex: 1 },
  contentImage: {
    width: CONTENT_IMAGE,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
})
