import { Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { Card } from '../../Card'

interface ReferentielHoraireProps {
  horaire: string | null
}

export const ReferentielHoraire: FunctionComponent<ReferentielHoraireProps> = ({ horaire }) => {
  if (!horaire) {
    return null
  }
  return (
    <Card spacing>
      <Text style={styles.text}>{horaire}</Text>
    </Card>
  )
}

const styles = StyleSheet.create({
  text: { flex: 1 },
})
