import { MovieItemResult, MovieListResult } from '../../api/Movie/types'

export type MovieState = {
  items: {
    [key: string]: MovieItemResult
  }
  pages: {
    [key: string]: MovieListResult
  }
}

export enum MovieActionTypes {
  LOAD_MOVIE_ITEM = 'LOAD_MOVIE_ITEM',
  STORE_MOVIE_ITEM = 'STORE_MOVIE_ITEM',
  LOAD_MOVIE_LIST = 'LOAD_MOVIE_LIST',
  STORE_MOVIE_LIST = 'STORE_MOVIE_LIST',
  STORE_CLEAR_MOVIE_LIST = 'STORE_CLEAR_MOVIE_LIST',
  REFRESH_MOVIE_LIST = 'REFRESH_MOVIE_LIST',
}
