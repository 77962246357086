import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Image, ImageSourcePropType, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { theme } from '../../../theme'

const BORDER_RADIUS = 20

interface ReferentielImageProps {
  image?: ImageSourcePropType
  fastImage?: string | null
  maxHeight: number
  title: string | null
}

export const ReferentielImage: FunctionComponent<ReferentielImageProps> = ({ title, image, fastImage, maxHeight }) => {
  const s = styles(maxHeight)

  const RenderImage: FunctionComponent = () => {
    if (fastImage) {
      return <FastImage style={[s.image, { height: maxHeight }]} source={{ uri: fastImage }} resizeMode="cover" />
    }
    if (image) {
      return <Image style={s.image} source={image} />
    }
    return null
  }

  const RenderTitle: FunctionComponent = () => {
    if (!title) {
      return null
    }
    return (
      <View style={s.titleView}>
        <Text style={s.title}>{title}</Text>
      </View>
    )
  }

  return (
    <View style={s.content}>
      <RenderImage />
      <RenderTitle />
    </View>
  )
}

const styles = (MaxHeight: number) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    content: {
      height: MaxHeight,
      backgroundColor: theme.colors.silver,
    },
    image: {
      width: '100%',
      height: '100%',
    },
    titleView: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
      backgroundColor: theme.colors.white,
      paddingVertical: theme.padding.x1,
    },
    title: {
      fontSize: theme.textSize.x3,
      lineHeight: theme.textSize.x3,
      textAlign: 'center',
      width: '100%',
    },
  })
