import { isEmpty } from 'lodash'
import { Button, List, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CitysApi } from '../../api/City'
import { CityItemResult, NotificationItem } from '../../api/City/types'
import { NotificationUserCategorie } from '../../api/Notification/types'
import { i18n } from '../../lib/i18n'
import { CityActions } from '../../redux/city/action'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'
import { ImageRounded } from '../ImageRounded/ImageRounded.component'
import { CityNotificationCategory } from './component/CityNotificationCategory.component'

const IMAGE_SIZE = theme.deviceHeight / 4.7

interface CityNotificationProps {
  city: CityItemResult
  saveText?: string
}

export const CityNotification: FunctionComponent<CityNotificationProps> = ({ city, saveText = i18n.t('action.add') }) => {
  const [categories, setCategories] = useState<NotificationUserCategorie[]>([])
  const [notify, setNotify] = useState<boolean>(true)
  const [items, setItems] = useState<NotificationItem[]>([])
  const isUpdating = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.SAVING_NOTIFICATION_SETTINGS))

  const dispatch = useDispatch()

  const initializeSignalement = async () => {
    try {
      if (city) {
        const config = await CitysApi.loadNotificationSettings(city.id)

        if (!config) {
          return
        }
        const items: NotificationItem[] = []
        config.categories.forEach(element => items.push({ id: element.categorie.id, value: element.notifier }))
        setCategories(config.categories)
        setItems(items)
        setNotify(config.notify || false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    initializeSignalement()
  }, [])

  const renderCategory = (item: NotificationUserCategorie) => {
    const currentItem = items.filter(element => element.id === item.categorie.id)[0]
    return (
      <CityNotificationCategory
        key={item.categorie.id}
        item={currentItem}
        enabled={notify}
        category={item.categorie}
        onChanged={value => {
          setItems([...items.filter(element => element.id !== item.categorie.id), { id: item.categorie.id, value }])
        }}
      />
    )
  }

  const RenderCategories: FunctionComponent = () => {
    if (isEmpty(categories)) {
      return <View />
    }
    return <List>{categories.map(item => renderCategory(item))}</List>
  }

  const RenderHeader: FunctionComponent = () => {
    const img = city.image ? <ImageRounded image={city.image} size={IMAGE_SIZE} /> : <View />
    return (
      <View style={styles.header}>
        {img}
        <Text style={styles.title}>{city.title}</Text>
      </View>
    )
  }

  return (
    <ScrollView>
      <RenderHeader />
      <Text style={styles.information}>{i18n.t('notifications.information')}</Text>
      <View style={styles.buttonView}>
        <Button variant="outline" onPress={() => setNotify(!notify)}>
          <Text>{notify ? i18n.t('notifications.disabledNotif') : i18n.t('notifications.enabledNotif')}</Text>
        </Button>
      </View>
      <RenderCategories />
      <Button isDisabled={isUpdating} style={styles.saveButton} onPress={() => dispatch(CityActions.updateNotificationSettings(city, notify, items))}>
        {isUpdating ? <Spinner style={styles.saveSpinner} /> : <Text style={styles.saveText}>{saveText}</Text>}
      </Button>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  header: { padding: theme.padding.x2, width: '100%', alignItems: 'center' },
  title: {
    fontSize: theme.textSize.x3,
  },
  information: {
    padding: theme.padding.x2,
    textAlign: 'center',
  },
  buttonView: {
    alignItems: 'center',
    width: '100%',
  },
  saveButton: {
    marginHorizontal: theme.padding.x2,
    marginTop: theme.padding.x2,
    marginBottom: theme.padding.x2,
  },
  saveText: { flex: 1, textAlign: 'center' },
  saveSpinner: { flex: 1 },
})
