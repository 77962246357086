/* eslint react-native/no-unused-styles: 0 */
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { CarouselConfigResult, CarouselItemResult } from '../../api/Carousel/types'
import { getItemAction } from '../../services/itemAction'
import { addOpacity } from '../../services/theme'
import { theme } from '../../theme/theme'
import { FastImageBackground } from '../FastImageBackground'

interface CarouselItemProps {
  carousel: CarouselConfigResult
  item: CarouselItemResult
}

export interface CarouselColor {
  titleBackground: string
  titleColor: string
  footerBackground: string
}

const getCarouselColor = (carousel: CarouselConfigResult) => ({
  titleColor: carousel.titreColor,
  titleBackground: carousel.titreBackground,
  footerBackground: carousel.footerBackground,
  dotsColor: carousel.dotsColor,
})

export const CarouselItem: FunctionComponent<CarouselItemProps> = props => {
  const { carousel, item } = props
  const carouselColor: CarouselColor = getCarouselColor(carousel)
  const carouselStyles = styles(carouselColor)

  const renderTitle = () => {
    if (!carousel.titre) {
      return null
    }
    return (
      <View style={carouselStyles.titleContainer}>
        <Text numberOfLines={1} style={[carouselStyles.labelText, carouselStyles.titleText]}>
          {carousel.titre}
        </Text>
      </View>
    )
  }

  return (
    <TouchableOpacity style={styles(carouselColor).itemContainer} onPress={item.action ? getItemAction(item.action) : undefined}>
      <FastImageBackground source={{ uri: item.image }} style={carouselStyles.image} resizeMode={FastImage.resizeMode.cover}>
        {renderTitle()}
        {carousel.footer && (
          <View style={carouselStyles.labelContainer}>
            <View>
              <Text numberOfLines={1} style={[carouselStyles.labelText, carouselStyles.labelTextPrimary]}>
                {item.libelle1}
              </Text>
              <Text numberOfLines={1} style={carouselStyles.labelText}>
                {item.libelle2}
              </Text>
            </View>
          </View>
        )}
      </FastImageBackground>
    </TouchableOpacity>
  )
}

const styles = (colors: CarouselColor) =>
  StyleSheet.create({
    itemContainer: {
      flex: 1,
    },
    titleContainer: {
      backgroundColor: colors.titleBackground,
      padding: theme.padding.x1,
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
      alignSelf: 'flex-start',
    },
    image: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
    labelContainer: {
      backgroundColor: addOpacity(colors.footerBackground, theme.opacity),
      color: theme.colors.white,
      paddingTop: theme.padding.x1,
      paddingHorizontal: theme.padding.x1,
      paddingBottom: theme.padding.x4,
      marginTop: 'auto',
    },
    titleText: {
      color: colors.titleColor,
    },
    labelText: {
      paddingHorizontal: theme.padding.x1,
      textAlign: 'left',
      color: theme.colors.white,
    },
    labelTextPrimary: {
      paddingTop: theme.padding.x1,
      fontWeight: 'bold',
    },
  })
