import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from '@redux-saga/core/effects'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'

import { WeeklySchedulesApi } from '../../api/WeeklySchedules'
import { WeeklySchedulesItemResult } from '../../api/WeeklySchedules/types'
import { isOfflineError } from '../../services/errorManager'
import { WeeklySchedulesActionObjectTypes, WeeklySchedulesActions } from './actions'
import { WeeklySchedulesActionTypes } from './types'

function* loadWeeklySchedulesSaga(action: ActionsOfType<WeeklySchedulesActionObjectTypes, WeeklySchedulesActionTypes.LOAD_WEEKLY_SCHEDULES>) {
  try {
    const items: WeeklySchedulesItemResult[] = yield call(WeeklySchedulesApi.load, action.payload.referentiels)
    yield put(WeeklySchedulesActions.storeWeeklySchedules(action.payload.id, items))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}
export function* loadWeeklySchedulesWatcher() {
  yield takeEvery(WeeklySchedulesActionTypes.LOAD_WEEKLY_SCHEDULES, addLoader(loadWeeklySchedulesSaga, LoaderName.LOAD_WEEKLY_SCHEDULES))
}
