import wretch from 'wretch'
import { BaseApi } from '../BaseApi'
import { GrcYpokConfigResult, GrcYpokAuthenticationResult } from './types'

export class GrcYpokApi {
  public static async load() {
    return BaseApi.authenticatedCall()
      .url('v1/grc-ypok')
      .get()
      .json(json => json) as Promise<GrcYpokConfigResult>
  }

  public static async login(urlAuthentication: string, userName: string, pwd: string) {
    return wretch(urlAuthentication)
      .post({ username: userName, password: pwd })
      .json(json => json) as Promise<GrcYpokAuthenticationResult>
  }
}
