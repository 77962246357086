import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { ReferentielLink } from '../../../api/Referentiels/types'
import { getItemAction } from '../../../services/itemAction'
import { CardItem } from '../../NativeBaseLegacy'

const CONTENT_IMAGE = 30
const IMAGE_SIZE = 20

export interface ReferentielLinkItemProps {
  item: ReferentielLink
}

export const ReferentielLinkItem: FunctionComponent<ReferentielLinkItemProps> = ({ item }) => (
  <TouchableOpacity style={styles.flex} onPress={getItemAction(item.action)}>
    <Card mx="1" mb="1" mt="2" p="0" style={styles.flex}>
      <CardItem style={styles.cardItem}>
        <View style={styles.contentImage}>{item.image && <FastImage style={styles.image} source={{ uri: item.image }} />}</View>
        <Text style={styles.flex} numberOfLines={2}>
          {item.libelle}
        </Text>
      </CardItem>
    </Card>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  cardItem: {
    flex: 1,
    alignItems: 'center',
  },
  contentImage: {
    width: CONTENT_IMAGE,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
})
