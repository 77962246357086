import { RootState } from '../reducers'

const getCartographyConfig = (state: RootState, id: string) => {
  const cartography = state.cartography && state.cartography[id]
  return (cartography && cartography.config) || null
}

export const cartographySelector = (state: RootState, id: string) => getCartographyConfig(state, id)

export const cartographyItemsSelector = (state: RootState, id: string) => {
  const config = getCartographyConfig(state, id)
  if (!config) {
    return null
  }
  return config.items
}

export const cartographyDefaultSelectedItemSelector = (state: RootState, id: string) => {
  const config = getCartographyConfig(state, id)
  if (!config) {
    return null
  }
  return config.currentItem
}

interface CartographyMarkerParams {
  cartographyId: string
  menuId: string | null
}

export const cartographyMenuMarkersSelector = (state: RootState, { cartographyId, menuId }: CartographyMarkerParams) => {
  if (!menuId) {
    return null
  }
  const cartography = state.cartography && state.cartography[cartographyId]
  if (!cartography || !cartography.markers) {
    return null
  }
  const markerIds = cartography.menuItems[menuId]
  if (!markerIds) {
    return null
  }
  return Object.values(cartography.markers).filter(marker => markerIds.includes(marker.id))
}
