/* eslint-disable prettier/prettier */
import {
  Icon,
  Select,
  View,
} from 'native-base'
import React, { PureComponent } from 'react'
import { StyleSheet } from 'react-native'
import { withNextInputAutoFocusInput } from 'react-native-formik'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { FormSelect } from '../../api/Form/types'
import { i18n } from '../../lib/i18n'
import { LabelInput } from './components'
import { FormElementProps } from './types'

class SelectFieldClass extends PureComponent<FormElementProps<FormSelect>> {
  public focus = () => {}

  public render() {
    const { field, value } = this.props

    const items = [...(field.items || [])]

    items.unshift({
      libelle: i18n.t('form.selectItem'),
    })

    return (
      <View style={styles.container}>
        <View style={styles.left}>
          <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        </View>
        <View style={styles.right}>
          <Select
            {...this.props}
            dropdownIcon={<Icon as={Ionicons} name="chevron-down" size={6} />}
            placeholder={i18n.t('form.selectItem')}
            onValueChange={itemValue => this.props.setFieldValue(this.props.name, itemValue)}
            selectedValue={value as string}
            onOpen={console.log}
          >
            {items.map(item => (
              <Select.Item
                key={`picker-item-${field.id}-${item.id}`}
                label={item.libelle}
                value={item.id?.toString() as string}
              />
            ))}
          </Select>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  left: {
    flex: 1,
    justifyContent: 'center',
  },
  right: {
    flex: 1,
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
})

export const SelectField = withNextInputAutoFocusInput(SelectFieldClass)
