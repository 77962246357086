import { Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import useCancelableTimeout from 'use-cancelable-timeout'
import { MarkerResult } from '../../../api/Cartography/types'
import { Routes } from '../../../navigation/Routes'
import { getItemAction } from '../../../services/itemAction'
import { theme } from '../../../theme'
import { FastImageBackground } from '../../FastImageBackground'

export const CARTOGRAPHY_CARD_HEIGHT = theme.deviceHeight / 3
const IMAGE_SIZE = 45
const BORDER_TOP_WIDTH = 4
const BORDER_RADIUS = 2

interface CartographyCardProps {
  item: MarkerResult
  isDisplayed?: boolean
  isClickable?: boolean
}

const CartographyCard: FunctionComponent<CartographyCardProps> = ({ item, isDisplayed = true, isClickable }) => {
  const [startAction, killAction] = useCancelableTimeout(() => {
    if (isClickable) {
      action()
    }
  }, 50)

  const action = getItemAction({
    type: 'view',
    viewName: Routes.Referentiel,
    params: { id: item.id, titre: item.libelle, image: item.image },
  })

  useEffect(() => {
    if (!isClickable) killAction()
  }, [isClickable])

  if (!isDisplayed) {
    return null
  }

  return (
    <Pressable onPressOut={startAction}>
      <View style={[styles.container, { borderTopColor: item.color }]}>
        <FastImageBackground source={{ uri: item.image || undefined }} style={styles.image} resizeMode={FastImage.resizeMode.cover}>
          {item.icon && (
            <View style={[styles.imageContainer, { backgroundColor: item.color }]}>
              <FastImage style={styles.flex} source={{ uri: item.icon }} resizeMode={FastImage.resizeMode.contain} />
            </View>
          )}
          <View pointerEvents="none" style={styles.footer}>
            <View style={styles.flex}>
              <Text numberOfLines={2} style={[styles.flex, { paddingBottom: theme.padding.x1 }]}>
                {item.libelle}
              </Text>
              <Text style={styles.note} numberOfLines={2}>
                {item.address}
              </Text>
            </View>
          </View>
        </FastImageBackground>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.white,
    height: CARTOGRAPHY_CARD_HEIGHT,
    flex: 1,
    borderTopWidth: BORDER_TOP_WIDTH,
    borderRadius: BORDER_RADIUS,
  },
  note: {
    opacity: 0.4,
  },
  flex: { flex: 1 },
  imageContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: BORDER_RADIUS,
    padding: theme.padding.x1,
    margin: theme.padding.x1,
  },
  image: {
    width: '100%',
    height: CARTOGRAPHY_CARD_HEIGHT,
    backgroundColor: theme.colors.silver,
    borderRadius: BORDER_RADIUS,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.white,
    padding: theme.padding.x1,
  },
})

export { CartographyCard }
