import { Divider, View } from 'native-base'
import React, { PureComponent } from 'react'
import { StyleSheet } from 'react-native'
import { FormDecoSpace } from '../../api/Form/types'
import { FormElementProps } from './types'

const SPACE_HEIGHT = 20
const LINE_HEIGHT = 2

class LineBreakField extends PureComponent<FormElementProps<FormDecoSpace>> {
  public render() {
    const { field } = this.props
    switch (field.type) {
      case 'deco_space':
        return <View style={styles.space} />
      case 'deco_linebreak':
        return <Divider style={styles.separator} />
      default:
        return null
    }
  }
}

const styles = StyleSheet.create({
  space: {
    height: SPACE_HEIGHT,
  },
  separator: {
    height: LINE_HEIGHT,
  },
})

export { LineBreakField }
