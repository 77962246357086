import { Reducer } from 'redux'
import { LoadingState, LoadingActionTypes } from './types'
import { LoadingActionObjectTypes } from './actions'

export const loadingReducer: Reducer<LoadingState, LoadingActionObjectTypes> = (state = {}, action) => {
  switch (action.type) {
    case LoadingActionTypes.SHOW_LOADER:
      return {
        ...state,
        [action.payload.loaderName]: { isLoading: true, status: { ...action.payload.status } },
      }
    case LoadingActionTypes.HIDE_LOADER:
      return {
        ...state,
        [action.payload.loaderName]: {
          isLoading: false,
          status: { ...action.payload.status },
        },
      }
    default:
      return state
  }
}
