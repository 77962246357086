/* eslint-disable @typescript-eslint/no-explicit-any */
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { Button, Icon, Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Image, Platform, StyleSheet } from 'react-native'
import { Edge, SafeAreaView } from 'react-native-safe-area-context'
import Ionicons from 'react-native-vector-icons/Ionicons'
import Swiper from 'react-native-web-swiper'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { HomeActions } from '../../redux/home/actions'
import { promotionSelector } from '../../redux/home/selector'
import { theme } from '../../theme'

const Promotion: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Promotion'>> = ({ navigation }) => {
  const dispatch = useDispatch()
  const promotion = useSelector(promotionSelector)
  const [active, setActive] = useState(0)
  const [isOnLastElement, setIsOnLastElement] = useState(false)
  const carousel = useRef<Swiper>()

  useEffect(() => {
    if (!promotion) {
      return
    }
    setIsOnLastElement(active === promotion.images.length - 1)
  }, [active, promotion])

  if (!promotion) {
    return <Spinner style={styles.container} />
  }

  const finishPromotion = () => {
    navigation.popToTop()
    dispatch(HomeActions.fetchDisplayedPromotion(promotion.id))
  }

  const next = () => {
    if (isOnLastElement) {
      finishPromotion()
    } else if (carousel.current) {
      carousel.current.goToNext()
    }
  }

  const nextText = isOnLastElement ? i18n.t('promotion.start') : i18n.t('promotion.continue')

  const edges: Edge[] = Platform.OS !== 'ios' ? ['bottom', 'top'] : ['bottom']

  return (
    <SafeAreaView edges={edges} style={styles.container}>
      <View style={styles.header}>
        <View style={styles.container} />
        <View style={styles.right}>
          <Button variant="ghost" onPress={finishPromotion}>
            <Icon as={Ionicons} color={theme.colors.black} name="close" size={6} />
          </Button>
        </View>
      </View>
      <View style={styles.container}>
        <Swiper
          ref={carousel as any}
          from={active}
          controlsProps={{
            prevPos: false,
            nextPos: false,
          }}
          onIndexChanged={index => setActive(index)}
        >
          {_.map(promotion.images, (item, index) => (
            <Image key={index} style={styles.image} source={{ uri: item }} resizeMode="contain" />
          ))}
        </Swiper>
        <View style={styles.buttonContainer}>
          <Button style={styles.button} onPress={next}>
            {nextText}
          </Button>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
  },
  right: {
    // flex: 1,
    width: 50,
  },
  image: {
    flex: 1,
    width: theme.deviceWidth,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  button: {
    width: theme.deviceWidth / 2,
    margin: theme.padding.x1,
  },
})

export { Promotion }
