import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { TextInput } from '../../components/TextInput'
import { WarningBox } from '../../components/WarningBox'
import { i18n } from '../../lib/i18n'
import { menuSelector } from '../../redux/menu/selector'
import { theme } from '../../theme'
import { useChangePassword } from './useChangePassword'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x1,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
})

export const ChangePassword: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  const { newPassword, oldPassword, confirmNewPassword, setNewPassword, setconfirmNewPassword, setOldPassword, doUpdatePassword, loading, isPasswordAllowed, isPasswordMatching } =
    useChangePassword()

  const logoUri = menu && menu.header && menu.header.image

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Text style={styles.title}>{i18n.t('changePassword.title')}</Text>
          <TextInput
            label={i18n.t('changePassword.oldPassword.label')}
            autoCapitalize="none"
            secureTextEntry
            placeholder=""
            value={oldPassword}
            onValueChanged={setOldPassword}
            style={styles.input}
          />
          <TextInput
            label={i18n.t('changePassword.newPassword.label')}
            autoCapitalize="none"
            secureTextEntry
            placeholder=""
            value={newPassword}
            onValueChanged={setNewPassword}
            style={styles.input}
          />
          <TextInput
            label={i18n.t('changePassword.confirmNewPassword.label')}
            autoCapitalize="none"
            secureTextEntry
            placeholder=""
            value={confirmNewPassword}
            onValueChanged={setconfirmNewPassword}
            style={styles.input}
          />
          {!isPasswordAllowed && (
            <WarningBox
              style={{ marginBottom: theme.padding.x2 }}
              title={i18n.t('changePassword.warning.passwordNotValid.title')}
              content={i18n.t('changePassword.warning.passwordNotValid.content')}
            />
          )}
          {!isPasswordMatching && (
            <WarningBox
              style={{ marginBottom: theme.padding.x2 }}
              title={i18n.t('changePassword.warning.passwordNotMatching.title')}
              content={i18n.t('changePassword.warning.passwordNotMatching.content')}
            />
          )}
          <Button
            onPress={doUpdatePassword}
            label={i18n.t('changePassword.confirmChangePassword')}
            style={{ backgroundColor: theme.colors.lightBlue }}
            loading={loading}
            disabled={!isPasswordAllowed || !isPasswordMatching}
          />
        </View>
      </ScrollView>
    </View>
  )
}
