import { RealTimeMapItems, RealTimeMap } from '../../api/CartographyRealTime/type'

export type RealTimeMapState = {
  configs: {
    [id: string]: RealTimeMap | null
  }
  items: {
    [id: string]: RealTimeMapItems | null
  }
}

export enum CartographyRealTimeActionTypes {
  LOAD_REAL_TIME_MAP = 'LOAD_REAL_TIME_MAP',
  LOAD_REAL_TIME_MAP_ITEMS = 'LOAD_REAL_TIME_MAP_ITEMS',
  STORE_REAL_TIME_MAP = 'STORE_REAL_TIME_MAP',
  STORE_REAL_TIME_MAP_ITEMS = 'STORE_REAL_TIME_MAP_ITEMS',
}
