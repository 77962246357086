import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { Image, ImageSourcePropType, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { ItemAction } from '../../api/types'
import { themeSelector } from '../../redux/theme/selector'
import { theme } from '../../theme'
import { TouchableAction } from '../TouchableAction'
import { ItemListMap } from './components/ItemListMap.component'

import DEFAULT_IMAGE from '../../assets/images/camera_picture.png'
import { Card } from '../Card'

const DEFAULT_SIZE = 100
const BORDER_RADIUS = 2
const BAR_WIDTH = 4

interface ItemDefaultProps {
  title: string
  subTitle?: string | null
  address?: string | null
  description?: string | null
  image?: string | null
  tagText?: string | null
  tagColor?: string | null
  barColor?: string | null
  action?: ItemAction
}

export const ItemDefault: FunctionComponent<ItemDefaultProps> = ({ title, subTitle, address, description, image, tagText, tagColor, barColor, action }) => {
  const themeData = useSelector(themeSelector)
  const colorTitle = (themeData && themeData.brandPrimary) || theme.colors.silver

  const renderImage = () => {
    return image ? (
      <Image source={{ uri: image }} resizeMode={FastImage.resizeMode.cover} style={styles.image} />
    ) : (
      <Image source={DEFAULT_IMAGE as ImageSourcePropType} style={styles.image} />
    )
  }

  const renderTag = () => {
    if (!tagText) {
      return null
    }
    const backgroundColor = tagColor || theme.colors.silverStrong
    return (
      <View style={[styles.contentTag, { backgroundColor }]}>
        <Text style={styles.tagText}>{tagText}</Text>
      </View>
    )
  }

  const renderDescription = () => {
    if (!address && description) {
      return <Text numberOfLines={3}>{description}</Text>
    }
  }

  return (
    <TouchableAction action={action}>
      <Card style={styles.card}>
        <View style={styles.content}>
          <View style={styles.contentImage}>
            <View style={styles.imageZone}>{renderImage()}</View>
            {renderTag()}
          </View>
          <View style={styles.contentInfo}>
            <Text style={[styles.title, { color: colorTitle }]} numberOfLines={1}>
              {title}
            </Text>
            <Text style={styles.subTitle}>{subTitle}</Text>
            <ItemListMap address={address} />
            {renderDescription()}
          </View>
          {barColor && <View style={[styles.barLine, { backgroundColor: barColor }]} />}
        </View>
      </Card>
    </TouchableAction>
  )
}

export const getTextColor = (textColor?: string) => (textColor ? { color: textColor } : null)

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    minHeight: DEFAULT_SIZE,
  },
  card: {
    marginHorizontal: theme.padding.x1,
    marginTop: theme.padding.x1,
  },
  contentImage: {
    width: DEFAULT_SIZE,
  },
  contentInfo: {
    flex: 1,
    paddingLeft: theme.padding.x1,
    paddingBottom: theme.padding.x1,
    paddingRight: theme.padding.x1,
    alignItems: 'flex-start',
    height: '100%',
  },
  imageZone: {
    width: '100%',
    height: '100%',
  },
  title: { fontWeight: '400' },
  subTitle: {
    paddingBottom: theme.padding.x1,
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
  },
  contentTag: {
    position: 'absolute',
    bottom: theme.padding.x1,
    left: theme.padding.x1,
    right: theme.padding.x1,
    alignItems: 'center',
    borderRadius: BORDER_RADIUS,
  },
  tagText: {
    color: theme.colors.white,
  },
  barLine: {
    height: '100%',
    width: BAR_WIDTH,
    borderTopRightRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
  },
})
