import { CommonActions, NavigationContainerRef, ParamListBase, StackActions } from '@react-navigation/native'
import _ from 'lodash'
import queryString from 'query-string'
import { Platform } from 'react-native'
import { ActionView } from '../../api/types'
import { Routes } from '../../navigation/Routes'

import { getItemAction } from '../itemAction'
import { trackScreen, trackScreenProps } from '../matomo/matomo.service'
// import { handleEmailVerification } from '../userAuthentication'

let navigator: NavigationContainerRef<ParamListBase>

export const setTopLevelNavigator = (navigatorRef: NavigationContainerRef<ParamListBase> | null) => {
  if (!navigatorRef) {
    return
  }

  navigator = navigatorRef
}

export const navigate = (routeName: string, params?: {}) => {
  if (!navigator) {
    return
  }

  navigator.dispatch(
    CommonActions.navigate({
      name: routeName,
      params,
    }),
  )
}

export const pop = () => {
  if (!navigator) {
    return
  }

  navigator.dispatch(StackActions.pop())
}

export const deepLinkNavigate = ({ url }: { url: string }) => {
  const appUrl = queryString.parseUrl(url)
  const routeName = appUrl.url.split('/').pop()
  // const params = queryString.parse(url)

  // if (params.mode === 'verifyEmail' && !!params.oobCode && typeof params.oobCode === 'string') {
  //   handleEmailVerification(params.oobCode)
  //   return
  // }

  if (!routeName) {
    return
  }

  if (!Object.values(Routes).includes(routeName as Routes)) return

  const action: ActionView = {
    type: 'view',
    viewName: routeName,
    params: appUrl.query,
  }

  const navigateAction = getItemAction(action)
  navigateAction()
}

export const navigatePush = (routeName: string, params?: {}) => {
  if (!navigator) {
    return
  }

  const pushAction = StackActions.push(routeName, params)
  navigator.dispatch(pushAction)
}

export const goBack = () => {
  if (!navigator) {
    return
  }
  navigator.dispatch(CommonActions.goBack())
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractDatas = (routes: any): Array<trackScreenProps> => {
  const current = _.find(routes, route => route.state)
  if (routes && current && current.state) return extractDatas(current.state.routes)
  return _.slice(routes, 1, routes.length + 1)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackStateChange = (e: any) => {
  const datas = extractDatas(e.data.state.routes)
  if (!_.isEmpty(datas)) trackScreen(datas)
}

export const getParamsUrl = (param: string) => {
  if (Platform.OS !== 'web') return ''
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(param) || ''
}
