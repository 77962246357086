/* eslint react-native/no-unused-styles: 0 */
import { Button, Icon, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { FlatList, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { CartographyItemResult } from '../../../api/Cartography/types'
import { getColor } from '../../../services/theme'
import { theme } from '../../../theme'
import { CartographyItem } from './CartographyItem.component'

const BUTTON_WIDTH = 80

interface CartographyListProps {
  item: CartographyItemResult
  selectedItem: number | null
  onPress: (itemId: number) => void
  isExpandable: boolean
}

const CartographyList: FunctionComponent<CartographyListProps> = ({ item, selectedItem, onPress, isExpandable }) => {
  const [selected, setSelected] = useState(selectedItem === item.id)
  const [isVerticalMenuOpen, setisVerticalMenuOpen] = useState(false)
  const [arrowIcon, setArrowIcon] = useState(theme.icon.arrowDown)
  const [style, setStyle] = useState(styles(selected, getColor(item.color)))

  useEffect(() => {
    setSelected(selectedItem === item.id)
  }, [selectedItem])

  useEffect(() => {
    setArrowIcon(isVerticalMenuOpen ? theme.icon.arrowUp : theme.icon.arrowDown)
  }, [isVerticalMenuOpen])

  useEffect(() => {
    setStyle(styles(selected, getColor(item.color)))
  }, [selected])

  const isSelected = (itemId: number) => selectedItem === itemId

  const renderArrowIcon = () =>
    isExpandable ? (
      <View style={style.buttonContainer}>
        <Button variant="ghost" style={style.button} onPress={() => setisVerticalMenuOpen(!isVerticalMenuOpen)}>
          <Icon as={Ionicons} name={arrowIcon} color={selected ? theme.colors.white : item.color} size={6} />
        </Button>
      </View>
    ) : null

  return (
    <View>
      <TouchableWithoutFeedback style={style.itemContainer} onPress={() => onPress && onPress(item.id)}>
        <View style={style.container}>
          <CartographyItem item={item} isSelected={isSelected(item.id)} onPress={onPress} />
          {renderArrowIcon()}
        </View>
      </TouchableWithoutFeedback>
      {isVerticalMenuOpen && (
        <FlatList
          style={style.list}
          data={item.items}
          renderItem={({ item }) => <CartographyItem item={item} onPress={onPress} isSelected={isSelected(item.id)} />}
          keyExtractor={item => item.id.toString()}
          extraData={selectedItem}
        />
      )}
    </View>
  )
}

const styles = (selected: boolean, color: string) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: selected ? color : theme.colors.transparent,
    },
    itemContainer: {
      flex: 1,
    },
    buttonContainer: {
      width: BUTTON_WIDTH,
      alignSelf: 'flex-end',
    },
    button: {
      alignSelf: 'flex-end',
    },
    list: {
      flex: 1,
    },
  })

export { CartographyList }
