import { Button, Input, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { Alert, StyleSheet } from 'react-native'
import { i18n } from '../../lib/i18n'
import { theme } from '../../theme'

interface LoginProps {
  userName?: string
  password?: string
  error?: string | null
  onPress: (userName: string, pwd: string) => void
}

export const Login: FunctionComponent<LoginProps> = ({ userName, password, error, onPress }) => {
  const [loginValue, setLoginValue] = useState<string>(userName || '')
  const [pwdValue, setPwdValue] = useState<string>(password || '')

  const onPressSubmit: Function = () => {
    if (!loginValue || !loginValue) {
      Alert.alert('Utilisateur et mot de passe obligatoire')
      return
    }
    onPress(loginValue.trim(), pwdValue.trim())
  }

  const renderError: Function = () => {
    if (!error) {
      return
    }
    return <Text style={styles.errorText}>{error}</Text>
  }

  return (
    <View>
      <Text>{i18n.t('login.userName')}</Text>
      <Input value={loginValue} onChangeText={text => setLoginValue(text)} textContentType="username" />

      <Text>{i18n.t('login.password')}</Text>
      <Input secureTextEntry value={pwdValue} onChangeText={text => setPwdValue(text)} textContentType="password" />

      {renderError()}
      <Button style={{ marginTop: theme.padding.x2 }} onPress={() => onPressSubmit()}>
        <Text style={styles.buttonText}>{i18n.t('grcYpok.login')}</Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    width: '100%',
    textAlign: 'center',
    color: theme.colors.white,
  },
  errorText: {
    color: theme.colors.red,
    paddingVertical: theme.padding.x2,
  },
})
