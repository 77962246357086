import { View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'

export const Content: FunctionComponent<IViewProps> = ({ style, ...props }) => <View style={[styles.content, style]} {...props} />

const styles = StyleSheet.create({
  content: {
    // flex: 1,
    // width: '100%',
  },
})
