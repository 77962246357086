import { View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableWithoutFeedback, TouchableWithoutFeedbackProps } from 'react-native'

export const ListItem: FunctionComponent<TouchableWithoutFeedbackProps & IViewProps> = ({ onPress, ...props }) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <View px="4" py="4" style={styles.touchable} {...props} />
  </TouchableWithoutFeedback>
)

const styles = StyleSheet.create({
  touchable: {
    flex: 1,
    flexDirection: 'row',
  },
})
