import isEmpty from 'lodash/isEmpty'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import { FlatList, Image, ImageSourcePropType, RefreshControl, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { FavoriteSelectItem } from '../../api/Favorites/types'
import check from '../../assets/images/favorites/check.png'
import plusListe from '../../assets/images/favorites/plus_liste.png'
import { i18n } from '../../lib/i18n'
import { theme } from '../../theme'

export interface ListWithSelectionProps {
  data: FavoriteSelectItem[]
  onConfirm: (selectedData: number[]) => void
  isLoadingPage?: boolean
  isRefreshing: boolean
  onRefresh: () => void
  keyExtractor: (item: FavoriteSelectItem, index: number) => string
  flatListStyle?: ViewStyle
}

export const ListWithSelection: FunctionComponent<ListWithSelectionProps> = (props: PropsWithChildren<ListWithSelectionProps>) => {
  const { data, onConfirm, isLoadingPage, isRefreshing, onRefresh, keyExtractor, flatListStyle } = props

  const [selectedData, setSelectedData] = useState<number[]>([])

  const displayDescription = (item: FavoriteSelectItem): JSX.Element | null => {
    const { description } = item
    if (!description || description === '') {
      return null
    }
    return <Text style={styles.description}>{description}</Text>
  }

  const displayButtonAdd = (item: FavoriteSelectItem) =>
    isItemOnSelectedData(item.id) ? (
      <Image style={styles.image} source={check as ImageSourcePropType} resizeMode="contain" />
    ) : (
      <Image style={styles.image} source={plusListe as ImageSourcePropType} resizeMode="contain" />
    )

  const isItemOnSelectedData = (id: number) => selectedData.find((i: number) => i === id)

  const onPressItem = (item: FavoriteSelectItem) => {
    if (isItemOnSelectedData(item.id)) {
      removeItemOnSelectedData(item.id)
      return
    }
    addItemOnSelectedData(item.id)
  }

  const removeItemOnSelectedData = (id: number) => {
    const newData = selectedData.filter((i: number) => i !== id)
    setSelectedData(newData)
  }

  const addItemOnSelectedData = (id: number) => {
    setSelectedData([...selectedData, id])
  }

  const renderItem = ({ item }: { item: FavoriteSelectItem }) => {
    const { title } = item
    return (
      <TouchableOpacity style={styles.item} onPress={() => onPressItem(item)}>
        <View style={styles.addButton}>{displayButtonAdd(item)}</View>
        <View style={styles.content}>
          <Text style={styles.title}>{title}</Text>
          {displayDescription(item)}
        </View>
      </TouchableOpacity>
    )
  }

  const displayRefreshControl = () => <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />

  if (isLoadingPage && isEmpty(data)) {
    return <Spinner />
  }
  return (
    <View style={styles.listWithSelection}>
      <FlatList keyExtractor={keyExtractor} data={data} renderItem={renderItem} refreshControl={displayRefreshControl()} style={flatListStyle} />
      <TouchableOpacity style={styles.buttonWrapper} onPress={() => onConfirm(selectedData)}>
        <Text style={styles.buttonText}>{i18n.t('favorites.multiChoice.add')}</Text>
      </TouchableOpacity>
    </View>
  )
}

export const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: 0,
    marginBottom: theme.padding.x4,
    paddingBottom: theme.padding.x1,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.silver,
  },
  content: {
    flex: 1,
    margin: 0,
  },
  addButton: {
    marginRight: theme.padding.x2,
  },
  title: {
    fontSize: theme.textSize.x1_75,
    color: theme.colors.black,
    fontWeight: '600',
    marginBottom: theme.padding.x1,
  },
  description: {
    fontSize: theme.textSize.x1_25,
    color: theme.colors.silverStrong,
  },
  listWithSelection: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: theme.padding.x3,
  },
  buttonWrapper: {
    backgroundColor: theme.colors.lightBlue,
    height: 40,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.padding.x6,
    paddingHorizontal: theme.padding.x3,
  },
  buttonText: {
    textAlign: 'center',
    color: theme.colors.white,
    fontSize: theme.textSize.x1_50,
  },
  image: {
    width: theme.iconSize.x1,
    height: theme.iconSize.x1,
  },
})
