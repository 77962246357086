import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { ReferentielsApi } from '../../api/Referentiels'
import { ReferencielLandFill, ReferentielItemResult, ReferentielListResult } from '../../api/Referentiels/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { RootState } from '../reducers'
import { ReferentielsActionObjectTypes, ReferentielsActions } from './actions'
import { referentielPagingListSelector } from './selector'
import { ReferentielsActionTypes } from './types'

const PAGE_SIZE = 40

export const getStore = (state: RootState) => state.referentiels

function* loadReferentielItemSaga(action: ActionsOfType<ReferentielsActionObjectTypes, ReferentielsActionTypes.LOAD_REFERENTIEL_ITEM>) {
  try {
    const item: ReferentielItemResult = yield call(ReferentielsApi.loadReferentielItem, action.payload)
    yield put(ReferentielsActions.storeReferentielItem(action.payload, item))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadReferentielListSaga(action: ActionsOfType<ReferentielsActionObjectTypes, ReferentielsActionTypes.LOAD_REFERENTIEL_LIST>) {
  const paging: PagingResult<ReferentielListResult> = yield select(referentielPagingListSelector, action.payload.id)
  let page = 0

  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const result: ReferentielListResult = yield call(ReferentielsApi.loadReferentielList, page, PAGE_SIZE, action.payload.filters, action.payload.query)
    if (action.payload.clear) {
      yield put(ReferentielsActions.storeClearReferentielList(action.payload.id, result))
    } else {
      yield put(ReferentielsActions.storeReferentielList(action.payload.id, result))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* refreshReferentielListSaga(action: ActionsOfType<ReferentielsActionObjectTypes, ReferentielsActionTypes.REFRESH_REFERENTIEL_LIST>) {
  yield call(loadReferentielListSaga, ReferentielsActions.loadReferentielList(true, action.payload.id, action.payload.filters, action.payload.query))
}

function* loadReferentielLandfillsSaga(action: ActionsOfType<ReferentielsActionObjectTypes, ReferentielsActionTypes.LOAD_REFERENTIEL_LANDFILLS>) {
  try {
    const weekData: ReferencielLandFill = yield call(ReferentielsApi.loadReferentielSubDependancies, action.payload.weekUrl)
    const daysData: ReferencielLandFill = yield call(ReferentielsApi.loadReferentielSubDependancies, action.payload.daysUrl)
    yield put(ReferentielsActions.storeReferentielLandfills(weekData, daysData))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadReferentielItemWatcher() {
  yield takeEvery(ReferentielsActionTypes.LOAD_REFERENTIEL_ITEM, addLoader(loadReferentielItemSaga, LoaderName.LOAD_REFERENTIEL))
}

export function* loadReferentielListWatcher() {
  yield takeEvery(ReferentielsActionTypes.LOAD_REFERENTIEL_LIST, addLoader(loadReferentielListSaga, LoaderName.LOAD_REFERENTIEL_LIST))
}

export function* refreshReferentielListWatcher() {
  yield takeEvery(ReferentielsActionTypes.REFRESH_REFERENTIEL_LIST, addLoader(refreshReferentielListSaga, LoaderName.REFRESH_REFERENTIEL_LIST))
}

export function* loadReferentielLandfillsWatcher() {
  yield takeEvery(ReferentielsActionTypes.LOAD_REFERENTIEL_LANDFILLS, loadReferentielLandfillsSaga)
}
