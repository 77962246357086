import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { TaskReportApi } from '../../api/TaskReport'
import { TaskReportItem, TaskReportListResult } from '../../api/TaskReport/types'
import { PagingResult } from '../../api/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { ActionTaskReportObjectTypes, TaskReportActions } from './action'
import { taskReportPagingSelector } from './selector'
import { TaskReportActionTypes } from './types'

const PAGE_SIZE = 10

function* LoadTaskReportSaga(action: ActionsOfType<ActionTaskReportObjectTypes, TaskReportActionTypes.LOAD_TASKREPORT>) {
  const paging: PagingResult<TaskReportListResult> = yield select(taskReportPagingSelector)
  let page = 0

  if (!action.payload.clear && paging.page !== undefined) {
    page = paging.page + 1
  }

  if (action.payload.clear) {
    yield put(
      TaskReportActions.storeClearTaskReport({
        content: [],
        last: undefined,
        size: undefined,
        page: undefined,
        totalElements: undefined,
        totalPages: undefined,
      }),
    )
  }
  try {
    const taskReportResult: TaskReportListResult = yield call(TaskReportApi.loadTaskReport, page, PAGE_SIZE, action.payload.sort_column)

    if (action.payload.clear) {
      yield put(TaskReportActions.storeClearTaskReport(taskReportResult))
    } else {
      yield put(TaskReportActions.storeTaskReport(taskReportResult))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* refreshTaskReportSaga(action: ActionsOfType<ActionTaskReportObjectTypes, TaskReportActionTypes.REFRESH_TASKREPORT>) {
  yield call(LoadTaskReportSaga, TaskReportActions.loadTaskReport(true, action.payload.sort_column))
}

function* loadReferentielItemSaga(action: ActionsOfType<ActionTaskReportObjectTypes, TaskReportActionTypes.LOAD_TASKREPORT_ITEM>) {
  try {
    const item: TaskReportItem = yield call(TaskReportApi.loadTaskReportItem, action.payload.id)
    yield put(TaskReportActions.storeClearTaskReportItem(action.payload.id, item))
  } catch (error) {
    console.log(error)
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* isAuthorizedGrcAccountSaga() {
  try {
    const result = yield call(TaskReportApi.isAuthorizedGrcAccount)
    yield put(
      TaskReportActions.storeIsAuthorizedGrc({
        grcAccount: result.status,
        grcAccountError: result.error,
        token: result.token,
      }),
    )
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* isAuthorizedGrcAccountWatcher() {
  yield takeEvery(TaskReportActionTypes.LOAD_IS_AUTHORIZED_GRC, addLoader(isAuthorizedGrcAccountSaga, LoaderName.LOAD_IS_AUTHORIZED_GRC))
}

export function* loadTaskReportWatcher() {
  yield takeEvery(TaskReportActionTypes.LOAD_TASKREPORT, addLoader(LoadTaskReportSaga, LoaderName.LOAD_TASKREPORT))
}

export function* refreshTaskReportWatcher() {
  yield takeEvery(TaskReportActionTypes.REFRESH_TASKREPORT, addLoader(refreshTaskReportSaga, LoaderName.REFRESH_TASKREPORT))
}

export function* loadTaskReportItemWatcher() {
  yield takeEvery(TaskReportActionTypes.LOAD_TASKREPORT_ITEM, addLoader(loadReferentielItemSaga, LoaderName.LOAD_TASKREPORT_ITEM))
}
