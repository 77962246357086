import { PagingResult, Latlng } from '../types'

export type MovieListResult = PagingResult<MovieItemResult>

export interface MovieItemResult {
  id: number
  libelle: string
  description: string | null
  image: string | null
  duree: string
  categories: string | null
  nextDay: number | null
  meetingToday: number[]
  imageBackdrop: string | null
  dateSortie: string | null
  directeur: string
  acteurs: string
  notePresse: number
  noteSpectateur: number
  minAge: number
  seances: SeanceItemResult[] | null
  address: string | null
  positionMap: Latlng | null
}

export interface SeanceItemResult {
  id: number
  voix: string | null
  dateSeance: number
  sousTitre: boolean
  format: string | null
  commentaire: string
}

export enum MovieInTheater {
  Now,
  Coming,
}
