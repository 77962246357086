import { BaseApi } from '../BaseApi'
import { CartographyBeaconResult } from './type'

export class CartographyBeaconApi {
  public static async loadBeacon(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/map/beacon/${id}`)
      .get()
      .json(json => json) as Promise<CartographyBeaconResult>
  }
}
