export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '7c2744c4-cc4d-46ea-9615-b93bd92903e8',
  ENV: 'nanteuilLesMeaux',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 44,
}
