/* eslint-disable prettier/prettier */
import { StatusBar, useTheme, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StatusBarStyle, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../../redux/theme/selector'

type HeaderProps = {
  androidStatusBarColor?: string
  transparent?: boolean
  translucent?: boolean
  iosBarStyle?: string
  safeAreaTop?: boolean
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}

export const Header: FunctionComponent<HeaderProps> = ({ androidStatusBarColor, style, transparent, translucent, iosBarStyle, children, safeAreaTop = true }) => {
  const { variables } = useTheme()
  const theme = useSelector(themeSelector)

  const {platformStyle} = variables

  return (
    <SafeAreaView edges={safeAreaTop ? ['top'] : []} style={[style, { backgroundColor: transparent ? undefined : theme?.toolbarDefaultBg }]}>
      <StatusBar
        backgroundColor={
          androidStatusBarColor || variables.statusBarColor
        }
        barStyle={
          (iosBarStyle || (platformStyle === 'material'
              ? 'light-content'
              : variables.iosStatusbar)) as StatusBarStyle
        }
        translucent={transparent ? true : translucent}
      />
      <View px="1" py="0.25" style={styles.header}>
        {children}
      </View>
    </SafeAreaView>
  )
}


const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
  },
})