import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { CartographyZonesActionTypes } from './types'
import { CartographyZoneResult } from '../../api/CartographyZoneApi.ts/type'

export const CartographyZonesActions = {
  loadCartographyZones: (zoneId: number) => createAction(CartographyZonesActionTypes.LOAD_CARTOGRAPHY_ZONES, { zoneId }),
  storeCartographyZones: (zoneId: string, config: CartographyZoneResult) => createAction(CartographyZonesActionTypes.STORE_CARTOGRAPHY_ZONES, { zoneId, config }),
}

export type CartographyZonesActionObjectTypes = ActionsUnion<typeof CartographyZonesActions>
