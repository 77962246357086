import { ExternalLinksResult } from '../../api/ExternalLinks/types'

export type ExternalLinksState = {
  pages: {
    [key: string]: ExternalLinksResult
  }
}

export enum ExternalLinksActionTypes {
  LOAD_EXTERNAL_LINKS = 'LOAD_EXTERNAL_LINKS',
  STORE_EXTERNAL_LINKS = 'STORE_EXTERNAL_LINKS',
  REFRESH_EXTERNAL_LINKS = 'REFRESH_EXTERNAL_LINKS',
  STORE_CLEAR_EXTERNAL_LINKS = 'STORE_ClEAR_EXTERNAL_LINKS',
}
