import { Reducer } from 'redux'
import { AirQualityState, AirQualityActionTypes } from './types'
import { AirQualityActionObjectTypes } from './action'

const initialState: AirQualityState = {
  configs: {},
  days: {},
}

export const airQualityReducer: Reducer<AirQualityState, AirQualityActionObjectTypes> = (state = initialState, action) => {
  switch (action.type) {
    case AirQualityActionTypes.STORE_AIR_QUALITY_CONFIG:
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.payload.id]: action.payload.airQualityConfig,
        },
      }
    case AirQualityActionTypes.STORE_AIR_QUALITY_DAY:
      return {
        ...state,
        days: {
          ...state.days,
          [action.payload.id]: action.payload.airQualityDayResult,
        },
      }
    default:
      return state
  }
}
