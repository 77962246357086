import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ImageBackground, ImageBackgroundProps, StyleProp } from 'react-native'
import { FastImageProps, ImageStyle } from 'react-native-fast-image'

interface FastImageBackgroundProps extends FastImageProps {
  style?: StyleProp<ImageStyle>
  imageStyle?: ImageStyle
}

export const FastImageBackground: FunctionComponent<FastImageBackgroundProps> = props => {
  const { children, style, imageStyle, source, resizeMode } = props
  return (
    <View style={style}>
      <ImageBackground
        source={source as ImageBackgroundProps}
        // eslint-disable-next-line react-native/no-inline-styles
        style={[{ flex: 1 }, imageStyle]}
        resizeMode={resizeMode}
      >
        {children}
      </ImageBackground>
    </View>
  )
}
