import { RootState } from '../reducers'
import { initialReferentielsState } from './reducers'

export const referentielItemSelector = (state: RootState, id: number) => state.referentiels.items[id]

export const referentielPagingListSelector = (state: RootState, page: number) => state.referentiels.pages[page]

export const referentielListSelector = (state: RootState, page: number) => (state.referentiels.pages[page] ? state.referentiels.pages[page].content : [])

export const lastPageReferentielListSelector = (state: RootState, page: number) => (state.referentiels.pages[page] ? state.referentiels.pages[page].last : true)

export const referentielLandfillSelector = (state: RootState) => state.referentiels.landfills || initialReferentielsState.landfills
