import { Reducer } from 'redux'
import { CityState, CityActionTypes } from './types'
import { CityActionObjectTypes } from './action'
import { CityItemResult, CitysUserResult } from '../../api/City/types'

const initialCityState: CityState = {
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  selected: {},
  eventsConfig: null,
  townHall: null,
  addingCity: null,
  townHallsConfig: null,
}

/**
 * Select item without id
 * @param state State
 * @param idToExclude id to exclude
 */
const getCitysSelectFilter = (state: CityState, idToExclude: number) => {
  const selectedItems = Object.values(state.selected).filter(val => val.id !== idToExclude)

  const selected = selectedItems.reduce((selectedItems: { [key: string]: CityItemResult }, item) => {
    if (!(item.id in selectedItems)) {
      selectedItems[item.id.toString()] = item
    }
    return selectedItems
  }, {})

  return { ...selected }
}

const mapCitysUser = (citysUser: CitysUserResult) => {
  const selectedItems = citysUser.citys
  const selected = selectedItems.reduce((selectedItems: { [key: string]: CityItemResult }, item) => {
    if (!(item.id in selectedItems)) {
      selectedItems[item.id.toString()] = item
    }
    return selectedItems
  }, {})

  return { ...selected }
}

export const cityReducer: Reducer<CityState, CityActionObjectTypes> = (state = initialCityState, action) => {
  switch (action.type) {
    case CityActionTypes.STORE_CITYS:
      return {
        ...state,
        paging: {
          last: action.payload.cityResult.last,
          page: action.payload.cityResult.page,
          size: action.payload.cityResult.size,
          totalElements: action.payload.cityResult.totalElements,
          totalPages: action.payload.cityResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.cityResult.content],
        },
      }
    case CityActionTypes.CLEAR_CITYS:
      return {
        ...state,
        paging: {
          content: [],
          last: undefined,
          size: undefined,
          page: undefined,
          totalElements: undefined,
          totalPages: undefined,
        },
      }
    case CityActionTypes.STORE_CITY:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.city.id]: action.payload.city,
        },
      }
    case CityActionTypes.STORE_CITYS_USER:
      return {
        ...state,
        selected: {
          ...mapCitysUser(action.payload.cityUserResult),
        },
      }
    case CityActionTypes.REMOVE_STORE_CITY:
      return {
        ...state,
        selected: { ...getCitysSelectFilter(state, action.payload.city.id) },
      }
    case CityActionTypes.STORE_CITYS_EVENTS_CONFIG:
      return {
        ...state,
        eventsConfig: action.payload.citysEventConfig,
      }
    case CityActionTypes.STORE_TOWN_HALLS_CONFIG:
      return {
        ...state,
        townHallsConfig: action.payload.townHallsConfig,
      }
    case CityActionTypes.STORE_TOWN_HALL:
      return {
        ...state,
        townHall: action.payload.townId,
      }
    case CityActionTypes.STORE_ADDING_CITY:
      return {
        ...state,
        addingCity: action.payload.city,
      }

    default:
      return state
  }
}
