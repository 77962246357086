/* eslint-disable prettier/prettier */
import _ from 'lodash'
import { Button, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Modal from 'react-native-modal'
import { useSelector } from 'react-redux'
import { MapMessage, RealTimeMapItem } from '../../../api/CartographyRealTime/type'
import { i18n } from '../../../lib/i18n'
import { themeSelector } from '../../../redux/theme/selector'
import { getItemAction } from '../../../services/itemAction'
import { theme as themeBase } from '../../../theme'

interface ModalRealTimeProps {
  item: RealTimeMapItem | null
  onClose: () => void
  isVisible: boolean
  onReload: (interval: number | null) => void
}

const BORDER_RADIUS = 15
const MIN_HEIGHT = 130
const IMAGE_SIZE = 20

export const ModalTideTime: FunctionComponent<ModalRealTimeProps> = ({
  item,
  isVisible,
  onClose,
}) => {
  if (!item) {
    return null
  }
  const theme = useSelector(themeSelector)

  const renderTideLigne = ((tideLigne: MapMessage) => (
      <View style={styles.tideIconText}>
        {tideLigne.iconInfo ? (
          <FastImage
            source={{ uri: tideLigne.iconInfo }}
            style={styles.image}
          />
        ) : null}
        <Text style={{ color: tideLigne.color }}>{tideLigne.message}</Text>
      </View>
    ))

  const renderCurrentAttendance = (item: RealTimeMapItem) => {
    if (item.info) {
      const firstTide = item.info.messages[0]
      const lastTide = item.info.messages.slice(1)
      return (
        <View style={styles.viewTide}>
          <View style={styles.tideIconText}>
            <Text style={[styles.tideTimeNow, { color: theme?.brandPrimary  }]}>{i18n.t('tideTime.now')} </Text>
            <Text style={{ color: firstTide.color }}>{firstTide.message}</Text>
          </View>
          <View style={styles.ligne} />
          {_.map(lastTide, element => renderTideLigne(element))}
          <Text style={styles.theoretical} numberOfLines={2}>{i18n.t('tideTime.theoretical')}</Text>
        </View>
      )
    } 
      return null
    
  }

  const renderConsulterButton = (item: RealTimeMapItem) => {
    if (item.info && item.info.action) {
      const itemAction = item.info.action
      if (itemAction === null) {
        return null
      }

      const bgColor = theme ? theme.brandPrimary : 'blue'
      return (
        <View style={styles.viewButton}>
          <Button
            style={[styles.button, { backgroundColor: bgColor }]}
            onPress={getItemAction(itemAction)}
          >
            <Text color={themeBase.colors.white}>{i18n.t('notifications.moreInfo')}</Text>
          </Button>
        </View>
      )
    } 
      return null
    
  }

  return (
    <Modal
      isVisible={isVisible}
      style={styles.bottomModal}
      swipeDirection={['down']}
      hasBackdrop={false}
      coverScreen={false}
      onSwipeComplete={onClose}
    >
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {item.title}
          </Text>
        </View>
        {renderCurrentAttendance(item)}
        {renderConsulterButton(item)}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  content: {
    padding: themeBase.padding.x6,
    minHeight: MIN_HEIGHT,
    backgroundColor: themeBase.colors.white,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    borderTopColor: themeBase.colors.silver,
    borderLeftColor: themeBase.colors.silver,
    borderRightColor: themeBase.colors.silver,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleContainer: {
    textAlign: 'left',
    marginRight: themeBase.padding.x1,
    marginBottom: themeBase.padding.x1,
  },
  viewTide: {
  },
  viewButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: { flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: themeBase.padding.x3 },
  ligne: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderWidth: 1,
    borderColor: themeBase.colors.silver,
    marginBottom: themeBase.padding.x2,
  },
  image: { 
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    marginRight: themeBase.padding.x2,
  },
  title: {
    fontWeight: 'bold', 
    fontSize: themeBase.textSize.x3,
    lineHeight: themeBase.textSize.x3,
  },
  theoretical: {
    fontStyle: 'italic',
    fontSize: themeBase.textSize.x1_25,
    marginTop: themeBase.padding.x2,
  },
  tideTimeNow: { fontWeight: 'bold', marginRight: themeBase.padding.x2 },
  tideIconText: { flexDirection: 'row', marginBottom: themeBase.padding.x2 },
})
