import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18n } from '../lib/i18n'
import { AuthenticationActions } from '../redux/authentication/actions'
import { showDanger } from '../services/toast'
import { ERROR_CODES, userAuthentication } from '../services/userAuthentication'

interface SignInError {
  status: number
  code: string
  message: string
}

const handleSignInError = (error: SignInError) => {
  console.warn(error.message)
  if ([ERROR_CODES.APPLE_USER_CANCELED, ERROR_CODES.GOOGLE_USER_CANCELED].includes(error.code)) {
    return
  }
  if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found' || error.status === 401) {
    showDanger(i18n.t('signIn.wrongCredentials'), 3000)
  }
  if (error.code === 'auth/account-exists-with-different-credential') {
    showDanger(i18n.t('signIn.accountExistWithExistingCredential'), 3000)
  } else {
    console.error(error)
  }
}

export const useSignIn = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const doSignIn = useCallback(async (email, password, code?: string | null) => {
    setLoading(true)
    try {
      let token
      if (!code) token = await userAuthentication.signIn(email, password)
      else token = await userAuthentication.signInWith2fa(email, password, code)
      if (token.code === 'ERRAUTH002') {
        dispatch(AuthenticationActions.set2fa(true))
      } else {
        dispatch(AuthenticationActions.set2fa(false))
        dispatch(AuthenticationActions.storeIsConnected(!!token))
        dispatch(AuthenticationActions.storeApiToken(token.accessToken, token.tokenType, token.refreshToken))
        return true
      }
    } catch (error) {
      handleSignInError(error as SignInError)
    } finally {
      setLoading(false)
    }
  }, [])

  return { doSignIn, loading }
}
