import { DateTime } from 'luxon'
import { i18n } from '../../lib/i18n'

export type grcInterval = 'primary' | 'warning' | 'danger'

export const getGrcInterval = (target: number): grcInterval => {
  const date = DateTime.local().setLocale(i18n.locale)
  const dateNow = DateTime.fromMillis(target).setLocale(i18n.locale)

  const duration = dateNow.diff(date, ['days', 'hours', 'minutes'])

  if (duration.days > 1 && duration.hours >= 0 && duration.minutes >= 0) {
    return 'primary'
  }
  if (duration.days === 1 || (duration.days === 0 && duration.hours >= 12)) {
    return 'warning'
  }
  return 'danger'
}
