import React, { FunctionComponent } from 'react'
import { SafeAreaView, StyleSheet, ViewStyle } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { Page, LeftButtonBack } from '../../components/Page'
import { News } from '../../components/News/News.component'
import { ActionViewParamsType } from '../../navigation/Routes'

export const Actualites: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Actualites'>> = ({ route }) => {
  const title = route.params.titre
  const { referentielId } = route.params

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <SafeAreaView style={styles.safeArea}>
        <News referentielId={referentielId} />
      </SafeAreaView>
    </Page>
  )
}

interface Style {
  safeArea: ViewStyle
}

const styles = StyleSheet.create<Style>({
  safeArea: {
    flex: 1,
  },
})
