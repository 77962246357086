import React, { FunctionComponent } from 'react'
import { View, Text } from 'native-base'
import { StyleSheet } from 'react-native'
import { i18n } from '../../lib/i18n'
import { theme } from '../../theme'

interface ListEmptyProps {
  libelle?: string
  textColor?: string
}

export const ListEmpty: FunctionComponent<ListEmptyProps> = ({ libelle, textColor: colorText }) => (
  <View style={listEmptyStyles.content}>
    <Text style={[getTextColor(colorText), listEmptyStyles.text]}>{libelle || i18n.t('noData')}</Text>
  </View>
)

export const getTextColor = (textColor?: string) => (textColor ? { color: textColor } : null)

const listEmptyStyles = StyleSheet.create({
  content: {
    flex: 1,
    marginVertical: theme.padding.x1,
  },
  text: {
    textAlign: 'center',
  },
})
