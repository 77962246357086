import { RootState } from '../reducers'

export const notificationsEnabledSelector = (state: RootState) => state.notifications.notificationEnabled

export const tokenSelector = (state: RootState) => (state.notifications && state.notifications.token ? state.notifications.token : null)

export const hasNewNotificationSelector = (state: RootState) => state.notifications.hasNewNotification

export const hasNewNotificationFirebaseSelector = (state: RootState) => state.notifications.hasNewNotificationFirebase

export const notificationsPagingSelector = (state: RootState) => state.notifications.paging

export const notificationsSelector = (state: RootState) => (state.notifications ? state.notifications.paging.content : [])

export const currrentNotificationsSelector = (state: RootState) => state.notifications.currentNotification

export const lastPageSelector = (state: RootState) => (state.notifications.paging ? state.notifications.paging.last : null)

export const notificationSettingsSelector = (state: RootState) => state.notifications.settings
