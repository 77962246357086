import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { FeedItem } from 'react-native-rss-parser'
import { openLink } from '../../services/browserInApp'
import { dateTimefromRFC2822ToHuge } from '../../services/dateFormatter'
import { capitalize } from '../../services/textUtil'
import { theme } from '../../theme'
import { Html } from '../Html'
import { CardItem } from '../NativeBaseLegacy'

const FONT_SIZE = 16

interface RssFeedItemProps {
  feed: FeedItem
  linkEnabled: boolean
}

const renderFeedDescription = (item: FeedItem, linkEnabled: boolean, fontFamily: string) => {
  if (item.content) {
    return (
      <CardItem>
        <Html content={item.content} baseFontStyle={{ fontSize: FONT_SIZE, fontFamily }} />
      </CardItem>
    )
  }
  if (item.description) {
    const feedLink = feedLinkEnabled(item, linkEnabled)
    const itemRender = () => (
      <CardItem p="3">
        <Html content={item.description} baseFontStyle={{ fontSize: FONT_SIZE, fontFamily }} />
      </CardItem>
    )
    return feedLink ? <TouchableOpacity onPress={() => onPress(item, linkEnabled)}>{itemRender()}</TouchableOpacity> : itemRender()
  }
  return null
}

const feedLinkEnabled = (feed: FeedItem, linkEnabled: boolean) => feed.links.length > 0 && linkEnabled

const onPress = (feed: FeedItem, linkEnabled: boolean) => {
  if (feedLinkEnabled(feed, linkEnabled)) {
    openLink(feed.links[0].url)
  }
}

export const RssFeedItem: FunctionComponent<RssFeedItemProps> = ({ feed, linkEnabled }) => {
  const feedLink = feedLinkEnabled(feed, linkEnabled)

  const renderItem = () => (
    <CardItem p="3">
      <View>
        <Text style={style.title}>{feed.title}</Text>
        <Text style={[style.note, style.date]}>{capitalize(dateTimefromRFC2822ToHuge(feed.published))}</Text>
      </View>
    </CardItem>
  )

  return (
    <Card m="1" p="0">
      {feedLink ? <TouchableOpacity onPress={() => onPress(feed, linkEnabled)}>{renderItem()}</TouchableOpacity> : renderItem()}
      {renderFeedDescription(feed, linkEnabled, '')}
    </Card>
  )
}

const style = StyleSheet.create({
  title: {
    fontSize: 18,
  },
  note: {
    opacity: 0.4,
  },
  date: {
    paddingTop: theme.padding.x1,
    flex: 1,
  },
})
