import React, { FunctionComponent } from 'react'
import { View } from 'native-base'
import { StyleSheet } from 'react-native'
import { ActualiteItemResult } from '../../api/Actualites'
import { NewsItemTimeLine } from './components/NewsItemTimeLine.component'
import { NewsItemTimeCard } from './components/NewsItemTimeCard.component'

const HEIGHT = 120

interface NewsItemProps {
  actuality: ActualiteItemResult
  author?: boolean
}

export const NewsItemTime: FunctionComponent<NewsItemProps> = ({ actuality, author = false }) => (
  <View>
    <View style={styles.lines}>
      <NewsItemTimeLine date={actuality.date} height={HEIGHT} />
      <NewsItemTimeCard item={actuality} height={HEIGHT} author={author} />
    </View>
  </View>
)

const styles = StyleSheet.create({
  lines: {
    flex: 1,
    flexDirection: 'row',
  },
})
