import { View } from 'native-base'
import React, { PropsWithChildren } from 'react'
import { Dimensions, StyleProp, ViewStyle } from 'react-native'
import { MansoryItemResult } from '../../../api/Favorites/types'

const { width: widthWindow } = Dimensions.get('window')

export interface MasonryItemProps<T extends MansoryItemResult> {
  columnSize: number
  columnHeight?: number
  item: T
  renderItem: (item: T, width: number | string, height: number) => React.ReactNode
}

const getColumnSize = (prop: MansoryItemResult) => {
  if (!prop.sizeX) {
    return 1
  }
  return prop.sizeX
}

export function MansoryItem<T extends MansoryItemResult>({ columnSize, columnHeight, item, renderItem }: PropsWithChildren<MasonryItemProps<T>>) {
  const height = columnHeight || widthWindow / columnSize
  const width = `${(100 / columnSize) * getColumnSize(item)}%`

  return <View style={{ width, height } as StyleProp<ViewStyle>}>{renderItem(item, width, height)}</View>
}
