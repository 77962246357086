import { call, put } from 'redux-saga/effects'

import { LoadingActions } from './actions'
import { LoaderName } from './types'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const addLoader = (saga: (...args: any[]) => any, loaderName: LoaderName) =>
  function* (...args: any[]): any {
    let err: string | undefined
    try {
      yield put(LoadingActions.showLoader(loaderName))
      const result = yield call(saga, ...args)

      return result
    } catch (error) {
      err = error as any
    } finally {
      yield put(LoadingActions.hideLoader(loaderName, !err, err))
    }
  }
