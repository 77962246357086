import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { ReferentielFilters, ActionView } from '../../api/types'
import { ReferentielsActions } from '../../redux/referentiels/actions'
import { referentielListSelector, lastPageReferentielListSelector } from '../../redux/referentiels/selector'
import { RootState } from '../../redux/reducers'
import { LoaderName } from '../../redux/loading/types'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { FlatListPaging } from '../FlatListPaging'
import { ItemDefault } from '../ItemDefault/ItemDefault.component'
import { Routes } from '../../navigation/Routes'
import { ReferentielListItemResult } from '../../api/Referentiels/types'
import { ItemSimple } from '../ItemSimple'
import { FieldValueRequest } from '../../api/Form/types'

interface ReferentielListProps {
  id: number
  referentielFilters: ReferentielFilters
  fieldValues?: FieldValueRequest[] | null
  onLoaded?: () => void
}

export const ReferentielList: FunctionComponent<ReferentielListProps> = ({ id, referentielFilters, fieldValues, onLoaded }) => {
  const dispatch = useDispatch()

  const referentielResult = useSelector((state: RootState) => referentielListSelector(state, id))
  const isLoadingReferentiels = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_REFERENTIEL_LIST))
  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_REFERENTIEL_LIST))
  const isLastPage = useSelector((state: RootState) => lastPageReferentielListSelector(state, id))

  useEffect(() => {
    dispatch(ReferentielsActions.loadReferentielList(true, id, referentielFilters, fieldValues))
  }, [fieldValues])

  const isLoadingPage = isEmpty(referentielResult) && isLoadingReferentiels && !isRefreshing
  useEffect(() => {
    if (isLoadingReferentiels && !isRefreshing && onLoaded !== undefined) {
      onLoaded()
    }
  }, [isLoadingReferentiels, isRefreshing])

  const isLoadingMore = () => !isLastPage && !isRefreshing
  const canLoadMore = () => !isLoadingReferentiels && isLoadingMore()

  const onRefresh = () => {
    dispatch(ReferentielsActions.refreshReferentielList(id, referentielFilters, fieldValues))
  }

  const onEndReached = () => {
    canLoadMore() ? dispatch(ReferentielsActions.loadReferentielList(false, id, referentielFilters)) : null
  }

  const renderItem = (item: ReferentielListItemResult) => {
    const action: ActionView = {
      type: 'view',
      viewName: Routes.Referentiel,
      params: { id: item.id, titre: item.libelle, image: item.image },
    }

    if (referentielFilters.referentielRowEnum && referentielFilters.referentielRowEnum === 1) {
      return <ItemSimple title={item.libelle} subTitle={item.categorie} address={item.adresse} description={item.description} action={action} />
    }

    return (
      <ItemDefault
        title={item.libelle}
        subTitle={item.categorie}
        image={item.image}
        address={item.adresse}
        description={item.description}
        tagText={item.etat && item.etat.libelle}
        tagColor={item.etat && item.etat.color}
        action={action}
      />
    )
  }

  return (
    <FlatListPaging
      data={referentielResult}
      renderItem={({ item }) => renderItem(item)}
      onEndReached={onEndReached}
      onRefresh={onRefresh}
      isRefreshing={isRefreshing}
      isLoadingPage={isLoadingPage}
      isLoadingMore={isLoadingMore}
      keyExtractor={item => item.id.toString()}
    />
  )
}
