import { Button, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { Image, ImageSourcePropType, SafeAreaView, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import antenna from '../../assets/images/antenna.png'
import { i18n } from '../../lib/i18n'
import { AuthenticationActions } from '../../redux/authentication/actions'
import { theme } from '../../theme'

const BootAppFailed: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const retry = () => {
    setIsLoading(true)
    dispatch(AuthenticationActions.signInUser())
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={antenna as ImageSourcePropType} resizeMode="contain" />
      </View>
      <View style={styles.textContainer}>
        <Text style={[styles.textTitle, styles.text]}>{i18n.t('offline.title')}</Text>
        <Text style={styles.text}>{i18n.t('offline.line1')}</Text>
        <Text style={styles.text}>{i18n.t('offline.line2')}</Text>
        <Button style={styles.button} variant="outline" onPress={retry}>
          {isLoading ? <Spinner /> : <Text style={styles.buttonText}>{i18n.t('offline.retry')}</Text>}
        </Button>
      </View>
    </SafeAreaView>
  )
}

const BUTTON_WIDTH = 150

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  image: {
    margin: theme.padding.x1,
  },
  textTitle: {
    fontWeight: 'bold',
  },
  button: {
    width: BUTTON_WIDTH,
    alignSelf: 'center',
  },
  buttonText: {
    flex: 1,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    margin: theme.padding.x1,
  },
})

export { BootAppFailed }
