import { StackScreenProps } from '@react-navigation/stack'
import { Spinner, Text } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { Feed, FeedItem } from 'react-native-rss-parser'
import { ListEmpty } from '../../components/ListEmpty'
import { Content } from '../../components/NativeBaseLegacy'
import { LeftButtonBack, Page } from '../../components/Page'
import { RssFeedItem } from '../../components/RssFeedItem'
import { ActionViewParamsType } from '../../navigation/Routes'
import { rssParse } from '../../services/rss/rss.service'
import { theme } from '../../theme'

const RssFeed: FunctionComponent<StackScreenProps<ActionViewParamsType, 'RssFeed'>> = ({ route }) => {
  const title = route.params.titre
  const rssParams = route.params.rss
  const [rssFeed, setRssFeed] = useState<Feed | null>(null)

  const renderTitle = () => {
    if (!rssFeed || !rssFeed.title) {
      return title
    }
    return rssFeed.title
  }

  if (!rssParams) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <Content>
          <Text>No data</Text>
        </Content>
      </Page>
    )
  }
  useEffect(() => {
    const loadRssFeed = async () => {
      if (rssParams && !rssFeed) {
        const rss = await rssParse(rssParams.link)
        setRssFeed(rss)
      }
    }
    loadRssFeed()
  }, [rssParams && rssParams.link])

  const renderFeeds = () => {
    if (!rssFeed) {
      return <Spinner />
    }
    return (
      <FlatList<FeedItem>
        style={style.list}
        data={rssFeed.items}
        keyExtractor={item => item.id}
        ListEmptyComponent={<ListEmpty />}
        renderItem={({ item }) => <RssFeedItem key={item.id} feed={item} linkEnabled={rssParams && rssParams.linkEnabled} />}
      />
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={renderTitle()}>
      <Content>{renderFeeds()}</Content>
    </Page>
  )
}

const style = StyleSheet.create({
  list: {
    padding: theme.padding.x2,
  },
})

export { RssFeed }
