/* eslint react-native/no-unused-styles: 0 */
import React, { FunctionComponent } from 'react'
import { View } from 'native-base'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { MenuHeaderResult } from '../../api/Menu/types'
import { theme } from '../../theme'

const DrawerHeader: FunctionComponent<MenuHeaderResult> = props => {
  const headerStyles = styles(props.backgroundColor)

  return (
    <View style={headerStyles.headerContainer}>
      {props.image && <FastImage style={headerStyles.image} source={{ uri: props.image }} resizeMode={FastImage.resizeMode.contain} />}
    </View>
  )
}

const styles = (color: string | null) =>
  StyleSheet.create({
    headerContainer: {
      height: theme.menu.header.imageHeight,
      backgroundColor: color || theme.colors.white,
    },
    image: {
      flex: 1,
    },
  })

export { DrawerHeader }
