import { Checkbox, Container, ScrollView, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { Body, ListItem } from '../../components/NativeBaseLegacy'
import { TextInput } from '../../components/TextInput'
import { i18n } from '../../lib/i18n'
import { homeCguSelector } from '../../redux/home/selector'
import { menuSelector } from '../../redux/menu/selector'
import { openLink } from '../../services/browserInApp'
import { goBack } from '../../services/navigation'
import { theme } from '../../theme'
import { useSignUp } from './useSignUp'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    marginBottom: theme.padding.x1,
  },
  subtitle: {
    color: theme.colors.silverStrong,
    marginBottom: theme.padding.x4,
  },
  cguText: {
    color: theme.colors.black,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  spacer: {
    flex: 1,
  },
  bottomText: {
    alignSelf: 'center',
  },
  cguContainer: {
    marginBottom: theme.padding.x2,
    flexDirection: 'row',
  },
  container: {
    maxWidth: '100%',
    flex: 1,
  },
  scrollview: {
    width: '100%',
    padding: theme.padding.x3,
  },
})

export const SignUp: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  const cgu = useSelector(homeCguSelector)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [cguAccepted, setCguAccepted] = useState(false)
  const { doSignUp, loading } = useSignUp()

  const logoUri = menu && menu.header && menu.header.image

  const openCgu = () => {
    if (cgu) {
      openLink(cgu)
    }
  }

  return (
    <Container safeAreaBottom background={theme.colors.white} style={styles.container}>
      <ScrollView style={styles.scrollview}>
        <View>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Text style={styles.title}>{i18n.t('signUp.welcome')}</Text>
          <Text style={styles.subtitle}>{i18n.t('signUp.subtitle')}</Text>
          <TextInput
            label={i18n.t('signUp.email.label')}
            placeholder={i18n.t('signUp.email.placeholder')}
            value={email}
            autoCapitalize="none"
            onValueChanged={text => setEmail(text)}
            style={styles.input}
          />
          <TextInput
            label={i18n.t('signUp.password.label')}
            placeholder={i18n.t('signUp.password.placeholder')}
            value={password}
            autoCapitalize="none"
            secureTextEntry
            onValueChanged={text => setPassword(text)}
            style={styles.input}
          />
          <ListItem pl="0" style={styles.cguContainer} onPress={() => setCguAccepted(!cguAccepted)}>
            <Checkbox mr="4" isChecked={cguAccepted} color={theme.colors.lightBlue} value={cguAccepted.toString()} onChange={() => setCguAccepted(!cguAccepted)} />
            <Body>
              <Text style={styles.cguText}>
                {i18n.t('signUp.acceptCgu.part1')}{' '}
                <Text onPress={() => openCgu()} style={[styles.cguText, { color: theme.colors.lightBlue }]}>
                  {i18n.t('signUp.acceptCgu.part2')}
                </Text>{' '}
                {i18n.t('signUp.acceptCgu.part3')}
              </Text>
            </Body>
          </ListItem>
          <Button
            onPress={() => doSignUp(email)}
            label={i18n.t('signUp.connectWithEmail')}
            style={{ backgroundColor: theme.colors.lightBlue, marginBottom: theme.padding.x2 }}
            iconName="mail"
            loading={loading}
            disabled={!cguAccepted}
          />
          <View style={styles.spacer} />
          <Text style={styles.bottomText}>
            {i18n.t('signUp.alreadyHaveAccount')}{' '}
            <Text style={{ color: theme.colors.lightBlue }} onPress={goBack}>
              {i18n.t('signUp.connect')}
            </Text>
          </Text>
        </View>
      </ScrollView>
    </Container>
  )
}
