/* eslint-disable no-lone-blocks */
import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CatalogFavoritesItem, FavoritesCatalogResult, FavoriteSegment, FavoriteSegmentLabel } from '../../api/Favorites/types'
import { SegmentItemResult } from '../../api/types'
import { FavItemShop } from '../../components/FavItemShop'
import { FavItemShopSimple } from '../../components/FavItemShopSimple'
import { FlatListPaging } from '../../components/FlatListPaging'
import { LeftButtonBack } from '../../components/Page'
import { PageSegment } from '../../components/PageSegment'
import { ActionViewParamsType } from '../../navigation/Routes'
import { FavoritesCatalogActions } from '../../redux/favoritesCatalog/actions'
import { favoritesCatalogByNameSelector, favoritesCatalogByThematicSelector, favoritesCatalogMostUsedSelector } from '../../redux/favoritesCatalog/selector'
import { gridPageHomeSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { processFavoritesCatalog } from '../../services/favoritesShop'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme'

const windowWidth = theme.deviceWidth - 16
const windowWidthWithoutBorder = theme.deviceWidth

export const FavoriesShop: FunctionComponent<StackScreenProps<ActionViewParamsType, 'FavoriesShop'>> = () => {
  const [currentSegment, setCurrentSegment] = useState<SegmentItemResult | null>(null)
  const [favoritesSegments, setFavoritesSegments] = useState<SegmentItemResult[]>([])
  const dispatch = useDispatch()

  const [firstUpdate, setFirstUpdate] = useState<boolean>(false)
  const favoritesMostUsed = useSelector(favoritesCatalogMostUsedSelector)
  const favoritesByThematic = useSelector(favoritesCatalogByThematicSelector)
  const favoritesByName = useSelector(favoritesCatalogByNameSelector)
  const isLoadingByMostUsed = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_FAVORITES_CATALOG_BY_MOST_USED))
  const isLoadingByThematic = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_FAVORITES_CATALOG_BY_THEMATIC))
  const isLoadingByName = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_FAVORITES_CATALOG_BY_NAME))
  const isRefreshingByMostUsed = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_FAVORITES_CATALOG_BY_MOST_USED))

  const isRefreshingByThematic = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_FAVORITES_CATALOG_BY_THEMATIC))

  const isRefreshingByName = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_FAVORITES_CATALOG_BY_NAME))

  const isAddingFavorites = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.ADD_FAVORITES_USER))

  const gridPage = useSelector((state: RootState) => gridPageHomeSelector(state))

  useEffect(() => {
    if (!firstUpdate) {
      caseClearFavoriteBySegment(FavoriteSegment.MOST_USED)
      caseClearFavoriteBySegment(FavoriteSegment.BY_THEMATIC)
      caseClearFavoriteBySegment(FavoriteSegment.BY_NAME)
    }
  }, [])

  useEffect(() => {
    addFavoriteSegments()
  }, [favoritesByThematic])

  useEffect(() => {
    addCurrentSegment()
  }, [favoritesSegments])

  useEffect(() => {
    getFavoriteCurrentSegments()
  }, [currentSegment])

  const addCurrentSegment = () => {
    if (isEmpty(favoritesSegments) || currentSegment) {
      return
    }
    setCurrentSegment(favoritesSegments[0])
  }

  const getFavoriteCurrentSegments = () => {
    if (!currentSegment || firstUpdate) {
      return
    }
    const favorites: FavoritesCatalogResult | undefined = caseGetFavoritesBySegment(currentSegment.id)
    if (isFavoritesContentEmpty(favorites) || !firstUpdate) {
      setFirstUpdate(true)
      caseLoadFavoritesBySegment(currentSegment.id)
    }
  }

  const isFavoritesContentEmpty = (favorites: FavoritesCatalogResult | undefined) => favorites && favorites.content && favorites.content.length === 0

  const addFavoriteSegments = () => {
    if (firstUpdate) {
      return
    }
    if (isByThematicNotDefined()) {
      caseLoadFavoritesBySegment(FavoriteSegment.BY_THEMATIC)
      return
    }
    if (isByThematicEmpty()) {
      setFavoritesSegments([
        {
          id: FavoriteSegment.MOST_USED,
          libelle: FavoriteSegmentLabel.MOST_USED,
        },
        {
          id: FavoriteSegment.BY_NAME,
          libelle: FavoriteSegmentLabel.BY_NAME,
        },
      ])
      return
    }
    setFavoritesSegments([
      {
        id: FavoriteSegment.MOST_USED,
        libelle: FavoriteSegmentLabel.MOST_USED,
      },
      {
        id: FavoriteSegment.BY_THEMATIC,
        libelle: FavoriteSegmentLabel.BY_THEMATIC,
      },
      {
        id: FavoriteSegment.BY_NAME,
        libelle: FavoriteSegmentLabel.BY_NAME,
      },
    ])
  }

  const isByThematicNotDefined = () => {
    const byThematic = caseGetFavoritesBySegment(FavoriteSegment.BY_THEMATIC)
    return byThematic && byThematic.totalElements === undefined
  }

  const isByThematicEmpty = () => {
    const byThematic = caseGetFavoritesBySegment(FavoriteSegment.BY_THEMATIC)
    return byThematic && byThematic.totalElements === 0
  }

  const caseGetFavoritesBySegment = (segment: FavoriteSegment): FavoritesCatalogResult | undefined => {
    switch (segment) {
      case FavoriteSegment.MOST_USED:
        return favoritesMostUsed
      case FavoriteSegment.BY_THEMATIC:
        return favoritesByThematic
      case FavoriteSegment.BY_NAME:
        return favoritesByName
      default:
        return favoritesMostUsed
    }
  }

  const caseLoadFavoritesBySegment = (segment: FavoriteSegment): void => {
    switch (segment) {
      case FavoriteSegment.MOST_USED:
        {
          dispatch(FavoritesCatalogActions.loadFavoritesCatalogsByMostUsed())
        }
        break
      case FavoriteSegment.BY_THEMATIC:
        {
          dispatch(FavoritesCatalogActions.loadFavoritesCatalogsByThematic())
        }
        break
      case FavoriteSegment.BY_NAME:
        {
          dispatch(FavoritesCatalogActions.loadFavoritesCatalogsByName())
        }
        break
      default: {
        dispatch(FavoritesCatalogActions.loadFavoritesCatalogsByMostUsed())
      }
    }
  }

  const caseRefreshFavoritesBySegment = (segment: FavoriteSegment): void => {
    switch (segment) {
      case FavoriteSegment.MOST_USED:
        {
          dispatch(FavoritesCatalogActions.refreshFavoriteCatalogByMostUsed(segment))
        }
        break
      case FavoriteSegment.BY_THEMATIC:
        {
          dispatch(FavoritesCatalogActions.refreshFavoriteCatalogByThematic(segment))
        }
        break
      case FavoriteSegment.BY_NAME:
        {
          dispatch(FavoritesCatalogActions.refreshFavoriteCatalogByName(segment))
        }
        break
      default: {
        dispatch(FavoritesCatalogActions.refreshFavoriteCatalogByMostUsed(segment))
      }
    }
  }

  const caseClearFavoriteBySegment = (segment: FavoriteSegment): void => {
    switch (segment) {
      case FavoriteSegment.MOST_USED:
        {
          dispatch(FavoritesCatalogActions.clearFavoriteByMostUsedCatalog())
        }
        break
      case FavoriteSegment.BY_THEMATIC:
        {
          dispatch(FavoritesCatalogActions.clearFavoriteByThematicCatalog())
        }
        break
      case FavoriteSegment.BY_NAME:
        {
          dispatch(FavoritesCatalogActions.clearFavoriteByNameCatalog())
        }
        break
      default: {
        dispatch(FavoritesCatalogActions.clearFavoriteByMostUsedCatalog())
      }
    }
  }

  const getFavoritesContentBySegment = (segment: FavoriteSegment): CatalogFavoritesItem[] => {
    if (!firstUpdate) {
      return []
    }
    const favorites = caseGetFavoritesBySegment(segment)
    if (!favorites) {
      return []
    }
    return favorites.content
  }

  const onSegmentPress = (segment: SegmentItemResult) => {
    if (currentSegment && currentSegment.id === segment.id) {
      return
    }
    setCurrentSegment(segment)
    caseClearFavoriteBySegment(segment.id)
    caseRefreshFavoritesBySegment(segment.id)
  }

  const getIsLastPage = (segment: FavoriteSegment): boolean => {
    const favorites = caseGetFavoritesBySegment(segment)
    if (!favorites || typeof favorites.last === 'undefined') {
      return true
    }
    return favorites.last
  }

  const isRefreshing = isRefreshingByMostUsed || isRefreshingByThematic || isRefreshingByName
  const isLoading = isLoadingByMostUsed || isLoadingByThematic || isLoadingByName

  const isLoadingPage = () => {
    if (!currentSegment) {
      return false
    }
    const favorites = caseGetFavoritesBySegment(currentSegment.id)
    if (!favorites) {
      return false
    }
    return favorites.totalElements === undefined && isLoading && !isRefreshing
  }

  const isLoadingMore = () => !!currentSegment && !getIsLastPage(currentSegment.id) && !isRefreshing

  const canLoadMore = () => !isLoading && isLoadingMore()

  const onRefresh = () => {
    if (!currentSegment) {
      return
    }
    caseClearFavoriteBySegment(currentSegment.id)
    caseRefreshFavoritesBySegment(currentSegment.id)
  }

  const onLoadMore = () => {
    if (!currentSegment || !canLoadMore()) {
      return
    }
    caseLoadFavoritesBySegment(currentSegment.id)
  }

  const onEndReached = () => {
    if (!currentSegment) {
      return
    }
    const favorites: FavoritesCatalogResult | undefined = caseGetFavoritesBySegment(currentSegment.id)
    if (!favorites) {
      return
    }
    onLoadMore()
  }

  const onPressFavItem = (item: CatalogFavoritesItem) => {
    if (isAddingFavorites) {
      return
    }
    trackEvent({
      category: 'Favoris',
      actionName: 'Favoris',
      eventName: 'Appuis sur un favoris',
      value: item.title,
    })
    processFavoritesCatalog(item)
  }

  const renderItem = (item: CatalogFavoritesItem) => {
    if (!currentSegment) {
      return null
    }
    if (currentSegment.id === FavoriteSegment.BY_NAME) {
      return <FavItemShopSimple title={item.title} onPress={() => onPressFavItem(item)} />
    }
    return (
      <View key={item.id} style={styles.favItemWrapper}>
        <FavItemShop key={item.id} item={item} onPress={() => onPressFavItem(item)} />
      </View>
    )
  }

  const displayNumColumns = (): number => {
    if (!currentSegment) {
      return 1
    }
    if (currentSegment.id === FavoriteSegment.MOST_USED || currentSegment.id === FavoriteSegment.BY_THEMATIC) {
      return 3
    }
    if (currentSegment.id === FavoriteSegment.BY_NAME) {
      return 1
    }
    return 3
  }

  const backgroundColor = (gridPage && gridPage.bg && gridPage.bg.color) || theme.colors.transparent

  return (
    <PageSegment
      left={<LeftButtonBack />}
      title="CATALOGUE DE FAVORIS"
      style={{ backgroundColor }}
      currentSegment={currentSegment}
      segments={favoritesSegments}
      onSegmentPress={onSegmentPress}
    >
      {!isLoading && !isRefreshing && currentSegment ? (
        <FlatListPaging
          keyFlatlist={currentSegment.id.toString()}
          data={getFavoritesContentBySegment(currentSegment.id)}
          renderItem={({ item }) => renderItem(item)}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingPage={isLoadingPage()}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.id.toString()}
          columnWrapperStyle={displayNumColumns() > 1 ? { paddingRight: theme.padding.x1 } : undefined}
          numColumns={displayNumColumns()}
        />
      ) : (
        <Spinner />
      )}
    </PageSegment>
  )
}

const styles = StyleSheet.create({
  favItemWrapper: {
    width: windowWidth / 3 - theme.padding.x1,
    marginLeft: theme.padding.x1,
    height: windowWidthWithoutBorder / 3,
  },
})
