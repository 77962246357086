import { Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Alert, ScrollView, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { Content } from '../../components/NativeBaseLegacy'
import { TextInput } from '../../components/TextInput'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

export const MyAccountConfirmDeletion: FunctionComponent = () => {
  const menu = useSelector(menuSelector)
  const [inputText, setInputText] = useState('')
  const [loading, setLoading] = useState(false)
  const [isHide, setIsHide] = useState(true)

  const logoUri = menu && menu.header && menu.header.image

  const handleAccountDeletion = async () => {
    try {
      setLoading(true)
      // await userAuthentication.delete()
      navigate(Routes.MyAccountWasDeleted)
    } catch (error) {
      setLoading(false)
      Alert.alert(i18n.t('myAccount.myAccountConfirmDeletion.alert'))
    }
  }

  useEffect(() => {
    inputText === i18n.t('myAccount.myAccountConfirmDeletion.keyword') ? setIsHide(false) : setIsHide(true)
  }, [inputText])

  const onValueChanged = (text: string) => {
    setInputText(text)
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Content style={styles.content}>
            <Text style={styles.subtitle}>{i18n.t('myAccount.myAccountDeletion.subtitle')}</Text>
            <Text style={styles.title}>{i18n.t('myAccount.myAccountDeletion.title')}</Text>
            <View style={styles.informations}>
              <Text style={styles.informationTitle}>{i18n.t('myAccount.myAccountConfirmDeletion.parameters.title')}</Text>
              <Text style={styles.informationText}>{i18n.t('myAccount.myAccountConfirmDeletion.parameters.question')}</Text>
              <Text style={styles.warning}>- {i18n.t('myAccount.myAccountConfirmDeletion.parameters.firstInfo')}</Text>
              <Text style={styles.warning}>- {i18n.t('myAccount.myAccountConfirmDeletion.parameters.secondInfo')}</Text>
            </View>
            <TextInput
              label={i18n.t('myAccount.myAccountConfirmDeletion.confirm')}
              placeholder=""
              value={inputText}
              onValueChanged={onValueChanged}
              style={styles.input}
              styleLabel={styles.label}
            />
            <Button
              onPress={handleAccountDeletion}
              iconName="trash"
              iconType="FontAwesome5"
              loading={loading}
              label={i18n.t('myAccount.myAccountDeletion.title')}
              style={styles.button}
              disabled={isHide}
            />
          </Content>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  content: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  input: {
    marginBottom: theme.padding.x3,
  },
  label: {
    color: theme.colors.indianRed,
    fontWeight: 'bold',
  },
  title: {
    fontSize: theme.iconSize.x2,
    lineHeight: theme.iconSize.x2,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.colors.darkGrayishBlue,
    marginVertical: theme.padding.x1,
    fontSize: theme.iconSize.x1,
    fontWeight: 'bold',
  },
  informations: {
    borderColor: theme.colors.darkPink,
    borderWidth: 1,
    backgroundColor: theme.colors.lightPink,
    padding: theme.padding.x2,
    marginBottom: theme.padding.x4,
    marginTop: theme.padding.x4,
  },
  informationTitle: {
    color: theme.colors.darkRed,
    fontWeight: 'bold',
    fontSize: theme.iconSize.x1,
    paddingBottom: theme.padding.x2,
  },
  informationText: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.indianRed,
    paddingBottom: theme.padding.x2,
  },
  warning: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.indianRed,
  },
  button: {
    backgroundColor: theme.colors.originalRed,
    borderRadius: 0,
  },
})
