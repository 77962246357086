import { RootState } from '../reducers'

export const gridPageSelector = (state: RootState, id: number) => state.gridPageConfig.gridPages[id]

export const gridPageHomeSelector = (state: RootState) => {
  // On boucle sur toutes les gridPage
  // on retourne la première gridPage correpsondant à la page d'accueil
  // voir pour remplacer le true

  for (let i = 0; Object.keys(state.gridPageConfig.gridPages).length; i++) {
    if (state.gridPageConfig.gridPages[i] && state.gridPageConfig.gridPages[i].titre === 'Accueil') {
      return state.gridPageConfig.gridPages[i]
    }
  }
  return null
}

export const carouselSelector = (state: RootState, id: number) => state.gridPageConfig.carousels[id]

export const actualiteSelector = (state: RootState, id: number) => state.gridPageConfig.actualites[id]

export const weatherSelector = (state: RootState, id: number) => state.gridPageConfig.weathers[id]

export const weatherAllSelector = (state: RootState) => state.gridPageConfig.weathers

export const rssConfigSelector = (state: RootState, id: number) => (state.gridPageConfig.rssFeed && state.gridPageConfig.rssFeed[id] ? state.gridPageConfig.rssFeed[id] : null)

export const airQualityConfigSelector = (state: RootState, id: number) =>
  state.gridPageConfig.airQuality && state.gridPageConfig.airQuality[id] ? state.gridPageConfig.airQuality[id] : null

export const tideConfigSelector = (state: RootState, id: number) => (state.gridPageConfig.tide && state.gridPageConfig.tide[id] ? state.gridPageConfig.tide[id] : null)

export const everyAirQualityConfigSelector = (state: RootState) => state.gridPageConfig.airQuality
