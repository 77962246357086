import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Modal from 'react-native-modal'
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5'
import { useSelector } from 'react-redux'
import { RealTimeMapItem } from '../../../api/CartographyRealTime/type'
import { i18n } from '../../../lib/i18n'
import { themeSelector } from '../../../redux/theme/selector'
import { intervalForHumans } from '../../../services/dateFormatter'
import { getItemAction } from '../../../services/itemAction/itemAction.service'
import { theme } from '../../../theme'

interface ModalRealTimeProps {
  item: RealTimeMapItem | null
  onClose: () => void
  isVisible: boolean
  onReload: (interval: number | null) => void
}

const BORDER_RADIUS = 15
const IMAGE_SIZE = 35

export const ModalRealTime: FunctionComponent<ModalRealTimeProps> = ({ item, isVisible, onClose }) => {
  if (!item) {
    return null
  }
  const theme = useSelector(themeSelector)

  const renderCurrentAttendance = (item: RealTimeMapItem) => {
    if (item.info) {
      const image = item.info.iconInfo ? <FastImage source={{ uri: item.info.iconInfo }} resizeMode={FastImage.resizeMode.contain} style={styles.image} /> : null

      const color = item.info.color ? item.info.color : theme ? theme.brandPrimary : 'blue'
      return (
        <View style={styles.inlineField}>
          {image}
          <View style={styles.textAdress}>
            <Text style={{ color }}>{item.info.message}</Text>
            <Text style={styles.subtitle}>{i18n.t('date.updated') + intervalForHumans(item.info.date)}</Text>
          </View>
        </View>
      )
    }
    return null
  }

  // const renderModalHeight = (item: RealTimeMapItem) => {
  //   if (!item.info) {
  //     return 0.1
  //   } else {
  //     return item.info.action === null ? 0.25 : 0.4
  //   }
  // }

  const renderAdressField = (item: RealTimeMapItem) => {
    if (item.info) {
      return (
        <View style={styles.inlineField}>
          <View style={styles.icon}>
            <Icon as={FontAwesome5} name="map-marker-alt" size={6} />
          </View>
          <View style={styles.textAdress}>
            <Text>{item.info.address}</Text>
          </View>
        </View>
      )
    }
    return null
  }

  const renderConsulterButton = (item: RealTimeMapItem) => {
    if (item.info && item.info.action) {
      const itemAction = item.info.action
      if (itemAction === null) {
        return null
      }

      const bgColor = theme ? theme.brandPrimary : 'blue'
      return (
        <View style={styles.viewButton}>
          <Button style={[styles.button, { backgroundColor: bgColor }]} onPress={getItemAction(itemAction)}>
            <Text style={styles.buttonText}>{i18n.t('view')}</Text>
          </Button>
        </View>
      )
    }
    return null
  }

  return (
    <Modal isVisible={isVisible} style={styles.bottomModal} swipeDirection={['down']} hasBackdrop={false} coverScreen={false} onSwipeComplete={onClose}>
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {item.title}
          </Text>
        </View>
        {renderAdressField(item)}
        {renderCurrentAttendance(item)}
        {renderConsulterButton(item)}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  content: {
    padding: theme.padding.x6,
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    borderTopColor: theme.colors.silver,
    borderLeftColor: theme.colors.silver,
    borderRightColor: theme.colors.silver,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexDirection: 'column',
  },
  titleContainer: {
    textAlign: 'left',
    marginBottom: theme.padding.x1,
  },
  inlineField: {
    flexDirection: 'row',
    marginBottom: theme.padding.x1,
  },
  viewButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: theme.padding.x2,
  },
  icon: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.padding.x2,
    // height: IMAGE_SIZE,
    width: IMAGE_SIZE,
  },
  image: { height: IMAGE_SIZE, width: IMAGE_SIZE, marginRight: theme.padding.x2 },
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: theme.colors.white,
  },
  textAdress: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.textSize.x3,
    lineHeight: theme.textSize.x3,
  },
  subtitle: { color: theme.colors.silverStrong },
})
