import _ from 'lodash'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Feed } from 'react-native-rss-parser'
import Swiper from 'react-native-web-swiper'
import { useDispatch, useSelector } from 'react-redux'
import { ItemAction } from '../../api/types'
import { i18n } from '../../lib/i18n'
import { GridPageActions } from '../../redux/gridPage/actions'
import { rssConfigSelector } from '../../redux/gridPage/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { rssParse } from '../../services/rss/rss.service'
import { GridPageRssFeedItem } from './components/GridPageRssItem.component'

interface GridPageRssFeedProps {
  id: number
  top: number
  left: number
  width: number
  height: number
}

export const GridPageRssFeed: FunctionComponent<GridPageRssFeedProps> = props => {
  const [rssFeed, setRssFeed] = useState<Feed | null>(null)
  const [rssLoad, setRssLoad] = useState<boolean>(false)
  const rssStyle = rssFeedStyles(props)

  const dispatch = useDispatch()

  const rssFeedConfig = useSelector((state: RootState) => rssConfigSelector(state, props.id))
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_GRID_RSS_FEED_CONFIG))

  useEffect(() => {
    dispatch(GridPageActions.loadRssFeedConfig(props.id))
  }, [props.id])

  useEffect(() => {
    const loadRssFeed = async () => {
      if (rssFeedConfig && !rssFeed) {
        const rss = await rssParse(rssFeedConfig.rssFeed)
        setRssFeed(rss)
        setRssLoad(true)
      }
    }
    loadRssFeed()
  }, [rssFeedConfig && rssFeedConfig.rssFeed])

  if (!rssFeedConfig || isLoading || !rssFeed) {
    if (!rssFeed && rssLoad) {
      return (
        <View style={rssStyle.wheatherContainer}>
          <View style={rssStyle.containerView}>
            <Text>{i18n.t('notAvailable')}</Text>
          </View>
        </View>
      )
    }
    return (
      <View style={rssStyle.wheatherContainer}>
        <View style={rssStyle.containerView}>
          <Spinner />
        </View>
      </View>
    )
  }

  const action: ItemAction = {
    type: 'view',
    viewName: 'RssFeed',
    params: {
      titre: 'Actualité',
      rss: { link: rssFeedConfig.rssFeed, linkEnabled: rssFeedConfig.activeLink },
    },
  }

  return (
    <View style={rssStyle.wheatherContainer}>
      <View style={rssStyle.containerView}>
        <Swiper
          loop
          timeout={7}
          controlsProps={{
            prevPos: false,
            nextPos: false,
          }}
          controlsEnabled={false}
        >
          {_.map(rssFeed.items, (item, index) => (
            <GridPageRssFeedItem key={index} item={item} image={rssFeedConfig.image} action={action} />
          ))}
        </Swiper>
      </View>
    </View>
  )
}

const rssFeedStyles = (props: GridPageRssFeedProps) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    wheatherContainer: {
      position: 'absolute',
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    },
    containerView: {
      flex: 1,
      flexDirection: 'row',
      overflow: 'hidden',
    },
  })
