import { BaseApi } from '../BaseApi'
import { RealTimeMap, RealTimeMapItems } from './type'

export class CartographyRealTimeApi {
  public static async loadRealTimeMap(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/realtimemaps/${id}`)
      .get()
      .json(json => json) as Promise<RealTimeMap>
  }

  public static async loadRealTimeMapItems(id: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/realtimemaps/${id}/items`)
      .get()
      .json(json => json) as Promise<RealTimeMapItems>
  }
}
