import React, { FunctionComponent, useEffect } from 'react'
import { Spinner } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { townHallSelector } from '../../redux/city/selector'
import { CityActions } from '../../redux/city/action'
import { Referentiel } from '../Referentiel'

interface TownHallProps {
  cityId: number
}
export const TownHall: FunctionComponent<TownHallProps> = ({ cityId }) => {
  const dispatch = useDispatch()
  const townHall = useSelector((state: RootState) => townHallSelector(state))

  useEffect(() => {
    dispatch(CityActions.loadTownHall(cityId))
  }, [])

  if (!townHall) {
    return <Spinner />
  }

  return <Referentiel id={townHall} />
}
