import { RootState } from '../reducers'

export const reportProcessSelector = (state: RootState) => (state.reportProcess ? state.reportProcess.paging.content : [])

export const lastPageSelector = (state: RootState) => (state.reportProcess.paging ? state.reportProcess.paging.last : null)

export const taskReportPagingSelector = (state: RootState) => state.reportProcess.paging

export const TaskReportItemSelector = (state: RootState, id: string) => state.reportProcess.items[id]

export const grcIsAuthorizedSelector = (state: RootState) => state.reportProcess.grcIsAuth

export const grcTokenSelector = (state: RootState) => state.reportProcess.grcIsAuth.token
