import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'
import { Page, LeftButtonBack } from '../../components/Page'
import { CartographyBeaconsActions } from '../../redux/cartographyBeacons/actions'
import { CartographyBeacon } from '../../components/CartographyBeacon/CartographyBeacon.component'
import { ActionViewParamsType } from '../../navigation/Routes'

export const CartographieBeacon: FunctionComponent<StackScreenProps<ActionViewParamsType, 'CartographieBeacons'>> = ({ route }) => {
  const title = route.params.titre as string
  const id = route.params.id as number

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(CartographyBeaconsActions.loadCartographyBeacons(id))
  }, [])

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <CartographyBeacon id={id} />
    </Page>
  )
}
