import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from 'native-base'
import { useSelector } from 'react-redux'
import { toDDMMM, toHHMM } from '../../../services/dateFormatter'
import { theme as defaultTheme } from '../../../theme'
import { themeSelector } from '../../../redux/theme/selector'

const WIDTH = 60
interface NewsItemTimeLineProps {
  date: number
  height: number
  width?: number
}

export const NewsItemTimeLine: FunctionComponent<NewsItemTimeLineProps> = ({ date, height, width = WIDTH }) => {
  const theme = useSelector(themeSelector)
  const primaryColor = theme ? theme.brandPrimary : defaultTheme.colors.blue

  const styles = timelineItemStyle(height, width, primaryColor)
  return (
    <View style={styles.content}>
      <View>
        <Text style={styles.timeLinedate}>{toDDMMM(date)}</Text>
        <Text style={styles.timeLinedate}>{toHHMM(date)}</Text>
      </View>
      <View style={styles.timeLineBar} />
    </View>
  )
}

export const timelineItemStyle = (height: number, width: number, color: string) =>
  /* eslint react-native/no-unused-styles: */
  StyleSheet.create({
    content: {
      height: '100%',
      width,
      paddingBottom: 20,
      overflow: 'hidden',
    },
    timeLinedate: { textAlign: 'center', color },
    timeLineBar: {
      textAlign: 'center',
      width: '50%',
      borderRightWidth: 2,
      height: '100%',
      borderRightColor: color,
    },
  })
