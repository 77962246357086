import { BaseApi } from '../BaseApi'
import { CartographyResult, MenuMarkerResult } from './types'

export class MapApi {
  public static async load(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/cartographie/referentiel/load')
      .query({ id })
      .get()
      .json(json => json) as Promise<CartographyResult>
  }

  public static async loadMenuMarker(cartographyId: string, menuId: string) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/cartographie/referentiel/markers')
      .query({ id: cartographyId, menu: menuId })
      .get()
      .json(json => json) as Promise<MenuMarkerResult>
  }
}
