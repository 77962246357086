import { Switch, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { NotificationItem } from '../../../api/City/types'
import { Categorie } from '../../../api/Notification/types'
import { Body, ListItem, Right } from '../../NativeBaseLegacy'

interface CityNotificationCategoryProps {
  enabled: boolean
  item: NotificationItem | null
  category: Categorie
  onChanged: (value: boolean) => void
}
export const CityNotificationCategory: FunctionComponent<CityNotificationCategoryProps> = ({ enabled, category, item, onChanged }) => {
  const Render: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => (
    <ListItem>
      <Body>
        <Text>{category.libelle}</Text>
      </Body>
      <Right>{children}</Right>
    </ListItem>
  )

  if (!enabled) {
    return (
      <Render>
        <Switch value={false} disabled />
      </Render>
    )
  }

  if (!item) {
    return <Render />
  }
  return (
    <Render>
      <Switch value={item.value} onValueChange={enabled => onChanged(enabled)} />
    </Render>
  )
}
