import React, { FunctionComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { View } from 'native-base'
import { ReferentielInfoResult } from '../../../api/Referentiels/types'
import { ReferentielInfo } from './ReferentielInfo.component'

interface ReferentielInfosProps {
  infos: ReferentielInfoResult[]
}

export const ReferentielInfos: FunctionComponent<ReferentielInfosProps> = ({ infos }) => {
  if (isEmpty(infos)) {
    return null
  }
  return (
    <View>
      {infos.map((item, index) => (
        <ReferentielInfo key={index} item={item} />
      ))}
    </View>
  )
}
