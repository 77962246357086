/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import { i18n } from '../../lib/i18n'
import { showDanger } from '../../services/toast'
import { userAuthentication } from '../../services/userAuthentication'

export const useSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const doSignUp = useCallback(async email => {
    setLoading(true)
    try {
      await userAuthentication.signUp(email)
    } catch (error) {
      console.warn((error as Error).message)
      if ((error as any).code === 'auth/email-already-in-use') {
        showDanger(i18n.t('signUp.error.emailAlreadyUsed'))
      } else {
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return { doSignUp, loading }
}
