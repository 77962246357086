import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import { middlewares, sagaMiddleware } from './middlewares'

import { getParamsUrl } from '../services/navigation'
import { rootReducer } from './reducers'
import { rootSaga } from './sagas'

const saveSubsetBlacklistFilterAuthentication = createBlacklistFilter('referentiels', ['landfills'])

// // Since the 2FA update, we use the new store to avoid having API call errors
// const buildNumber = DeviceInfo.getBuildNumber()
// const useNewStore = +buildNumber >= 4010000 || env().ENV === 'local' || env().ENV === 'staging'
// const storeName = useNewStore ? 'rootAC2' : 'root'

export const persistConfig = {
  key: `${Platform.OS === 'web' ? getParamsUrl('env') : ''}root`,
  storage: AsyncStorage,
  whitelist: [
    'authentication',
    'theme',
    'menu',
    'gridPageConfig',
    'notifications',
    'favoritesUser',
    'home',
    'referentiels',
    'urgentNumbers',
    'signalements',
    'cartographyZones',
    'news',
    'cantine',
    'intermediatePage',
    'movie',
    'grcYpok',
    'city',
    'airQuality',
  ],
  transforms: [saveSubsetBlacklistFilterAuthentication],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
