import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { MapApi } from '../../api/Cartography'
import { CartographyResult } from '../../api/Cartography/types'
import { RootState } from '../reducers'
import { CartographyActionObjectTypes, CartographyActions } from './actions'
import { cartographyMenuMarkersSelector } from './selector'
import { CartographyActionTypes } from './types'

function* loadCartographySaga(action: ActionsOfType<CartographyActionObjectTypes, CartographyActionTypes.LOAD_CARTOGRAPHY>) {
  try {
    const mapResult: CartographyResult = yield call(MapApi.load, action.payload.id)
    yield put(CartographyActions.storeCartography(action.payload.id.toString(), mapResult))
  } catch (error) {
    console.error('ERROR =', error)
  }
}

/**
 * Fetch marker with the cartography id and menu id,
 * this fetch is made only once when the cartography state
 * is not set yet for the menu id.
 * @param action Cartography id and menu id to be fetched
 */
function* loadCartographyMenuMarkerSaga(action: ActionsOfType<CartographyActionObjectTypes, CartographyActionTypes.LOAD_CARTOGRAPHY_MENU_MARKER>) {
  try {
    const stateMenuMarker = yield select((state: RootState) =>
      cartographyMenuMarkersSelector(state, {
        cartographyId: action.payload.cartographyId,
        menuId: action.payload.menuId,
      }),
    )
    if (stateMenuMarker) {
      return
    }

    const menuMarker = yield call(MapApi.loadMenuMarker, action.payload.cartographyId, action.payload.menuId)
    yield put(CartographyActions.storeCartographyMarkerMenu(action.payload.cartographyId, action.payload.menuId, menuMarker))
  } catch (error) {
    console.error('ERROR =', error)
  }
}

export function* loadCartographyWatcher() {
  yield takeEvery(CartographyActionTypes.LOAD_CARTOGRAPHY, loadCartographySaga)
}

export function* loadCartographyMenuMarkerSagaWatcher() {
  yield takeEvery(CartographyActionTypes.LOAD_CARTOGRAPHY_MENU_MARKER, loadCartographyMenuMarkerSaga)
}
