import { isEmpty } from '@martin_hotell/rex-tils'
import { Spinner } from 'native-base'
import React, { PureComponent } from 'react'
import { StyleSheet } from 'react-native'
import { FormBase, FormSelect } from '../../api/Form/types'
import { SectorApi } from '../../api/Sector'
import { dateToDayMonthYear } from '../../services/dateFormatter'
import { SelectField } from './SelectField.component'
import { FormElementProps } from './types'

type SectorDayFieldProps = FormElementProps<FormBase, number | null>

interface SectorDayFieldState {
  sectorId: number | null
  fetching: boolean
  items: Array<{
    id: number
    libelle: string
  }>
}

class SectorDayField extends PureComponent<SectorDayFieldProps, SectorDayFieldState> {
  private sectorId: number | null = null

  public state: SectorDayFieldState = {
    sectorId: null,
    fetching: false,
    items: [],
  }

  public constructor(props: SectorDayFieldProps) {
    super(props)
    this.initSectorId()
  }

  public componentDidUpdate() {
    if (!this.props.sectorId) {
      return
    }

    if (this.sectorId !== this.props.sectorId) {
      this.sectorId = this.props.sectorId
      this.getDayList()
      this.resetValue()
    }
  }

  private initSectorId() {
    const { sectorId } = this.props

    if (!sectorId) {
      return
    }
    this.sectorId = sectorId
    this.getDayList()
  }

  private async getDayList() {
    if (!this.sectorId) {
      return
    }
    this.setState({
      ...this.state,
      fetching: true,
    })
    try {
      const sectorDays = await SectorApi.loadSectorDayList(this.sectorId)
      if (sectorDays && sectorDays.content) {
        this.setState({
          ...this.state,
          items: sectorDays.content.map(item => ({
            id: item.id,
            libelle: dateToDayMonthYear(item.jour),
          })),
        })
      }
    } catch (error) {
      console.error({ error })
    } finally {
      this.setState({
        ...this.state,
        fetching: false,
      })
    }
  }

  private resetValue() {
    const { setFieldValue } = this.props
    setFieldValue(this.elementId, null)
  }

  private get elementId() {
    return `${this.props.field.id}`
  }

  public render() {
    if (this.state.fetching) {
      return <Spinner style={styles.spinner} />
    }

    if (isEmpty(this.state.items)) {
      return null
    }

    const items = [...this.state.items]
    const { field } = this.props
    const fieldSelect: FormSelect = {
      ...field,
      type: 'field_select',
      items,
    }
    return <SelectField {...this.props} field={fieldSelect} />
  }
}

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
  },
})

export { SectorDayField }
