import { ItemAction } from '../../api/types'
import { modalsRoute } from '../../navigation/Routes'
import { openLink } from '../browserInApp'
import { openMap } from '../cartography'
import { phoneCall } from '../phoneCall'

import { navigate, navigatePush } from '../navigation'
import { partnerApp } from '../partnerApp'

export const getItemAction = (action: ItemAction): (() => Promise<void> | void) => {
  switch (action.type) {
    case 'view':
      if (action.viewName === 'LienExterneWebView') {
        return () => {
          if (!action.params.link) {
            return
          }
          openLink(action.params.link)
        }
      }
      if (modalsRoute.includes(action.viewName)) {
        return () => {
          navigate(action.viewName, action.params)
        }
      }
      return () => {
        navigatePush(action.viewName, action.params)
      }
    case 'call':
      return async () => {
        await phoneCall(action.phone)
      }
    case 'map':
      return () => {
        openMap(action)
      }
    case 'externalLink':
      return () => {
        openLink(action.link)
      }
    case 'partnerapp':
      return async () => {
        await partnerApp(action)
      }
    default:
      return () => {}
  }
}
