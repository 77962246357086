import { BaseApi } from '../BaseApi'
import { MenuResult } from './types'

export class MenuApi {
  public static async load() {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/menu/load')
      .get()
      .json(json => json) as Promise<MenuResult>
  }
}
