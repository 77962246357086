import createSagaMiddleware from 'redux-saga'

export const sagaMiddleware = createSagaMiddleware()

const middlewaresTmp = [sagaMiddleware]

// if (__DEV__) {
//   /* eslint @typescript-eslint/no-var-requires: "off" */
//   const createDebugger = require('redux-flipper').default
//   middlewaresTmp.push(createDebugger())
// }

export const middlewares = middlewaresTmp
