import { BaseApi } from '../BaseApi'
import { WeeklySchedulesItemResult } from './types'

export class WeeklySchedulesApi {
  public static async load(ids: number[]) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/horairesemaine')
      .query({ ids })
      .get()
      .json(json => json) as Promise<WeeklySchedulesItemResult[]>
  }
}
