import { Reducer } from 'redux'
import { CartographyBeaconsState, CartographyBeaconsActionTypes } from './types'
import { CartographyBeaconsActionObjectTypes } from './actions'

const initialCartographyBeaconsState: CartographyBeaconsState = {}

export const cartographyBeaconsReducer: Reducer<CartographyBeaconsState, CartographyBeaconsActionObjectTypes> = (state = initialCartographyBeaconsState, action) => {
  switch (action.type) {
    case CartographyBeaconsActionTypes.STORE_CARTOGRAPHY_BEACONS:
      return {
        ...state,
        [action.payload.id]: action.payload.config,
      }
    default:
      return { ...state }
  }
}
