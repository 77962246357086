import { AppState } from 'react-native'

export class FavoritesTimerService {
  schedule = 0

  timer: NodeJS.Timeout | null = null

  constructor(schedule: number) {
    this.schedule = schedule
    this.handleAppState()
  }

  private handleAppState = () => {
    AppState.addEventListener('change', nextAppState => {
      if (nextAppState === 'background' || nextAppState === 'inactive') {
        this.stopTimer()
      }
    })
  }

  private hasSchedule = (): boolean => this.schedule > 0

  public startTimer = (callApi: () => void) => {
    if (!this.hasSchedule()) {
      return
    }
    this.timer = setInterval(() => {
      callApi()
    }, this.schedule * 1000)
  }

  public stopTimer = () => {
    if (!this.timer) {
      return
    }
    clearInterval(this.timer)
  }
}
