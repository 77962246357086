import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { CityActionTypes } from './types'

import { CitysApi } from '../../api/City/CitysApi'
import { CitysResult, CitysUserResult, TownHallResult } from '../../api/City/types'
import { ApiResponse } from '../../api/types'
import { i18n } from '../../lib/i18n'
import { isOfflineError } from '../../services/errorManager'
import { pop } from '../../services/navigation'
import { showDanger, showSuccess, showWarning } from '../../services/toast'
import { CityActionObjectTypes, CityActions } from './action'
import { citysPagingSelector } from './selector'

function* loadCitysSaga(action: ActionsOfType<CityActionObjectTypes, CityActionTypes.LOAD_CITYS>) {
  const PAGE_SIZE = 10
  const paging: CitysResult = yield select(citysPagingSelector)
  let PAGE = 0
  if (!action.payload.clear && paging.page !== undefined) {
    PAGE = paging.page + 1
  }
  try {
    const newsResult: CitysResult = yield call(CitysApi.loadCitys, PAGE, PAGE_SIZE, action.payload.search)

    if (action.payload.clear) {
      yield put(CityActions.clearCitysState())
    }
    yield put(CityActions.storeCitys(newsResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}
function* loadCitysUserSaga() {
  try {
    const citysUserResult: CitysUserResult = yield call(CitysApi.loadCitysUser)
    yield put(CityActions.storeCitysUser(citysUserResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* addCitySaga(action: ActionsOfType<CityActionObjectTypes, CityActionTypes.ADD_CITY>) {
  try {
    yield put(CityActions.storeAddingCity(action.payload.city.id))

    const result: ApiResponse = yield call(CitysApi.addCity, action.payload.city.id)

    if (!result.success) {
      showDanger(result.message)
    } else {
      yield put(CityActions.storeCity(action.payload.city))
      pop()
      showSuccess(i18n.t('addCity.successAdd'), 'bottom')
    }
    yield loadCitysEventsConfigSaga()
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  } finally {
    yield put(CityActions.storeAddingCity(null))
  }
}

function* removeCitySaga(action: ActionsOfType<CityActionObjectTypes, CityActionTypes.ADD_CITY>) {
  try {
    const result: ApiResponse = yield call(CitysApi.removeCity, action.payload.city.id)
    if (!result.success) {
      showDanger(result.message)
    } else {
      yield put(CityActions.removeStoreCity(action.payload.city))
    }
    yield loadCitysEventsConfigSaga()
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadCitysEventsConfigSaga() {
  try {
    const result = yield call(CitysApi.loadCitysEventsConfig)

    yield put(CityActions.storeCitysEventsConfig(result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadTownHallsConfigSaga() {
  try {
    const result = yield call(CitysApi.loadTownHallsConfig)

    yield put(CityActions.storeTownHallsConfig(result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}
function* loadTownHallSaga(action: ActionsOfType<CityActionObjectTypes, CityActionTypes.LOAD_TOWN_HALL>) {
  try {
    const result: TownHallResult = yield call(CitysApi.loadTown, action.payload.id)
    yield put(CityActions.storeTownHall(result.id))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}
function* updateNotificationSettingsSaga(action: ActionsOfType<CityActionObjectTypes, CityActionTypes.UPDATE_NOTIFICATION_SETTINGS>) {
  try {
    const result: ApiResponse = yield call(CitysApi.updateNotificationSettings, action.payload.city.id, action.payload.notify, action.payload.categories)
    if (result.success) {
      const cityResult = yield call(CitysApi.loadCityUser, action.payload.city.id)
      yield put(CityActions.storeCity(cityResult))
      pop()
    } else {
      showWarning(result.message, 'bottom')
    }
    yield loadCitysEventsConfigSaga()
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* refreshCitysSaga() {
  yield call(loadCitysSaga, CityActions.loadCitys(true, null))
}

export function* refreshCitysWatcher() {
  yield takeEvery(CityActionTypes.REFRESH_CITYS, addLoader(refreshCitysSaga, LoaderName.REFRESH_NEWS))
}

export function* loadCitysWatcher() {
  yield takeEvery(CityActionTypes.LOAD_CITYS, addLoader(loadCitysSaga, LoaderName.LOAD_CITYS))
}

export function* loadCitysuserWatcher() {
  yield takeEvery(CityActionTypes.LOAD_CITYS_USER, addLoader(loadCitysUserSaga, LoaderName.LOAD_CITYS_USER))
}

export function* addCityWatcher() {
  yield takeEvery(CityActionTypes.ADD_CITY, addCitySaga)
}
export function* removeCityWatcher() {
  yield takeEvery(CityActionTypes.REMOVE_CITY, removeCitySaga)
}

export function* loadCitysEventsConfigWatcher() {
  yield takeEvery(CityActionTypes.LOAD_CITYS_EVENTS_CONFIG, loadCitysEventsConfigSaga)
}
export function* loadTownHallsConfigWatcher() {
  yield takeEvery(CityActionTypes.LOAD_TOWN_HALLS_CONFIG, loadTownHallsConfigSaga)
}

export function* loadTownHallWatcher() {
  yield takeEvery(CityActionTypes.LOAD_TOWN_HALL, loadTownHallSaga)
}
export function* updateNotificationSettingsWatcher() {
  yield takeEvery(CityActionTypes.UPDATE_NOTIFICATION_SETTINGS, addLoader(updateNotificationSettingsSaga, LoaderName.SAVING_NOTIFICATION_SETTINGS))
}
