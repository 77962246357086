import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { FavoritesCatalogActionTypes } from './types'
import { FavoritesCatalogResult, FavoriteSegment } from '../../api/Favorites/types'

export const FavoritesCatalogActions = {
  loadFavoritesCatalogsByMostUsed: () => createAction(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_MOST_USED),
  storeFavoritesCatalogsByMostUsed: (favoritesCatalogResult: FavoritesCatalogResult) =>
    createAction(FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_MOST_USED, {
      favoritesCatalogResult,
    }),
  refreshFavoriteCatalogByMostUsed: (bySegment: FavoriteSegment) => createAction(FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_MOST_USED, { bySegment }),
  clearFavoriteByMostUsedCatalog: () => createAction(FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_MOST_USED),

  loadFavoritesCatalogsByThematic: () => createAction(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_THEMATIC),
  storeFavoritesCatalogsByThematic: (favoritesCatalogResult: FavoritesCatalogResult) =>
    createAction(FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_THEMATIC, {
      favoritesCatalogResult,
    }),
  refreshFavoriteCatalogByThematic: (bySegment: FavoriteSegment) => createAction(FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_THEMATIC, { bySegment }),
  clearFavoriteByThematicCatalog: () => createAction(FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_THEMATIC),

  loadFavoritesCatalogsByName: () => createAction(FavoritesCatalogActionTypes.LOAD_FAVORITES_CATALOG_BY_NAME),
  storeFavoritesCatalogsByName: (favoritesCatalogResult: FavoritesCatalogResult) =>
    createAction(FavoritesCatalogActionTypes.STORE_FAVORITES_CATALOG_BY_NAME, {
      favoritesCatalogResult,
    }),
  refreshFavoriteCatalogByName: (bySegment: FavoriteSegment) => createAction(FavoritesCatalogActionTypes.REFRESH_FAVORITES_CATALOG_BY_NAME, { bySegment }),
  clearFavoriteByNameCatalog: () => createAction(FavoritesCatalogActionTypes.CLEAR_FAVORITES_CATALOG_BY_NAME),

  loadFavoritesSubThematicCatalogs: (id: number, clear: boolean) =>
    createAction(FavoritesCatalogActionTypes.LOAD_FAVORITES_SUB_THEMATIC_CATALOG, {
      id,
      clear,
    }),
  storeFavoritesSubThematicCatalogs: (favoritesSubThematicCatalogResult: FavoritesCatalogResult, id: number) =>
    createAction(FavoritesCatalogActionTypes.STORE_FAVORITES_SUB_THEMATIC_CATALOG, {
      favoritesSubThematicCatalogResult,
      id,
    }),
  storeClearFavoritesSubThematicCatalogs: (favoritesSubThematicCatalogResult: FavoritesCatalogResult, id: number) =>
    createAction(FavoritesCatalogActionTypes.STORE_CLEAR_FAVORITES_SUB_THEMATIC_CATALOG, {
      favoritesSubThematicCatalogResult,
      id,
    }),
  refreshFavoriteSubThematicCatalog: (id: number, page?: number) => createAction(FavoritesCatalogActionTypes.REFRESH_FAVORITES_SUB_THEMATIC_CATALOG, { id, page }),
  clearFavoriteSubThematicCatalog: () => createAction(FavoritesCatalogActionTypes.CLEAR_FAVORITES_SUB_THEMATIC_CATALOG),
}

export type FavoritesCatalogActionObjectTypes = ActionsUnion<typeof FavoritesCatalogActions>
