export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '230e7896-46c7-41e4-b71f-2618248387ce',
  ENV: 'graveline',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr',
  matomoSiteId: 7,
}
