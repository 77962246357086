import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { EtatColorResult } from '../../../api/types'
import { theme } from '../../../theme'

interface ReportStatusProps {
  state: EtatColorResult | null
}

const TEXT_SIZE = 15

export const ReportStatus: FunctionComponent<ReportStatusProps> = ({ state }) => {
  if (!state) {
    return null
  }

  const backgroundColor = state.color ? state.color : 'red'

  return (
    <View style={[styles.row, { backgroundColor }]}>
      <Text style={styles.text}>{state.libelle.toUpperCase()}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: theme.padding.x2,
    paddingVertical: theme.padding.x05,
    marginVertical: theme.padding.x1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: theme.colors.black,
    fontSize: TEXT_SIZE,
    fontWeight: 'bold',
  },
})
