import { Pressable, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { TaskReportHisto, TaskReportSubmission } from '../../../api/TaskReport/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { ReportDetailsTab } from './ReportDetailsTab.component'
import { ReportHistoryTab } from './ReportHistoryTab.component'

interface ReportStatusProps {
  history: TaskReportHisto[] | null
  submissions: TaskReportSubmission[] | null
}

export const ReportTabs: FunctionComponent<ReportStatusProps> = ({ history, submissions }) => {
  if (!history) {
    return null
  }

  const [activeTab, setActiveTab] = useState<'history' | 'submissions'>('submissions')

  const themeSelect = useSelector(themeSelector)
  const colorPrimary = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  return (
    <SafeAreaView edges={['bottom']} style={styles.row}>
      <View style={[styles.tabs, { backgroundColor: colorPrimary }]}>
        <Pressable
          style={[styles.tabStyle, activeTab === 'submissions' ? styles.activeTab : null, activeTab === 'submissions' ? { borderBottomColor: colorPrimary } : null]}
          onPress={() => setActiveTab('submissions')}
        >
          <Text style={styles.tabText}>Détails de la demande</Text>
        </Pressable>
        <Pressable
          style={[styles.tabStyle, activeTab === 'history' ? styles.activeTab : null, activeTab === 'history' ? { borderBottomColor: colorPrimary } : null]}
          onPress={() => setActiveTab('history')}
        >
          <Text style={styles.tabText}>Historique</Text>
        </Pressable>
      </View>
      <View>
        {activeTab === 'submissions' && <ReportDetailsTab submissions={submissions} />}
        {activeTab === 'history' && <ReportHistoryTab history={history} />}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flex: 1,
  },
  tabs: {
    flexDirection: 'row',
    flex: 1,
  },
  tabText: {
    color: theme.colors.silverStrong,
  },
  tabStyle: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.white,
    backgroundColor: theme.colors.white,
    flex: 1,
    alignItems: 'center',
    paddingVertical: theme.padding.x2,
  },
  activeTab: {
    backgroundColor: theme.colors.white,
    color: theme.colors.green,
    fontWeight: 'bold',
  },
})
