import { Reducer } from 'redux'
import { FavoritesConfigState, FavoritesConfigActionTypes } from './types'
import { FavoritesUserActionObjectTypes } from './actions'

const initialFavoritesUserState: FavoritesConfigState = {
  favoritesConfig: {},
}

export const favoritesConfigReducer: Reducer<FavoritesConfigState, FavoritesUserActionObjectTypes> = (state = initialFavoritesUserState, action): FavoritesConfigState => {
  switch (action.type) {
    case FavoritesConfigActionTypes.STORE_FAVORITES_CONFIG:
      return {
        ...state,
        favoritesConfig: action.payload,
      }
    default:
      return state
  }
}
