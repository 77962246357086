import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { StyleSheet, SafeAreaView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { Page, LeftButtonBack } from '../../components/Page'
import { ExternalLinksSelector, lastPageSelector } from '../../redux/externalLinks/selector'
import { RootState } from '../../redux/reducers'
import { ExternalLinksActions } from '../../redux/externalLinks/actions'
import { ExternalLinksItemDisplay } from '../../components/ExternalLinksItem/index'
import { theme } from '../../theme'
import { FlatListPaging } from '../../components/FlatListPaging'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { ActionViewParamsType } from '../../navigation/Routes'

export const ExternalLinks: FunctionComponent<StackScreenProps<ActionViewParamsType, 'ExternalLinks'>> = ({ route }) => {
  const title = route.params.titre
  const idParam = route.params.idParams as number
  const type = route.params.type as string

  const dispatch = useDispatch()
  const externalLinks = useSelector((state: RootState) => ExternalLinksSelector(state, idParam.toString()))

  useEffect(() => {
    dispatch(ExternalLinksActions.loadExternalLinks(true, idParam, type))
  }, [])

  const isLoadingExternalLinks = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_EXTERNAL_LINKS))

  const isLastPage = useSelector((state: RootState) => lastPageSelector(state, idParam.toString()))

  const onEndReached = () => {
    canLoadMore() ? dispatch(ExternalLinksActions.loadExternalLinks(false, idParam, type)) : null
  }

  const onRefresh = () => {
    dispatch(ExternalLinksActions.refreshExternalLinks(idParam, type))
  }

  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_EXTERNAL_LINKS))

  const isLoadingPage = () => isEmpty(externalLinks) && isLoadingExternalLinks && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoadingExternalLinks && isLoadingMore()

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <SafeAreaView style={styles.safeArea}>
        <FlatListPaging
          data={externalLinks}
          renderItem={({ item }) => <ExternalLinksItemDisplay key={item.id} externalLink={item} />}
          isLoadingPage={isLoadingPage()}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.id.toString()}
          flatListStyle={{ padding: theme.padding.x1 }}
        />
      </SafeAreaView>
    </Page>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
})
