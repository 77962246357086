/* eslint-disable prettier/prettier */
import moment from 'moment'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { Path, Svg } from 'react-native-svg'
import { GridTideConfig } from '../../../api/GridPage/types'
import { i18n } from '../../../lib/i18n'
import { theme } from '../../../theme'

interface TideDayProps {
  tideConfig: GridTideConfig | null
  color: string
  pictoStyle?: ViewStyle
  infoStyle?: ViewStyle
  hideBorder?: boolean
  large?: boolean
}
export const TideDay: FunctionComponent<TideDayProps> = ({ tideConfig, color, hideBorder, large }) => {
  const styles = stylesTide(color, large)

  const TideDown = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" aria-hidden="true" className="iconify iconify--whh" viewBox="0 0 1024 1024" {...props}>
      <Path
        fill="currentColor"
        d="M896 591q-53-18-118-15t-125.5 20.5-130 39-132 40.5T258 701.5 128 696q-54-11-91-35.5T0 609q0-29 38-43t90-4q43 19 103 16.5T352 558t133-39.5 139-41 139-26 133 5.5q54 11 91 35.5t37 51.5q0 30-39.5 46t-88.5 1zM536 312q-10 8-24 8t-23-8L327 166q-17-16 8-38h113V32q0-13 9.5-22.5T480 0h64q13 0 22.5 9.5T576 32v96h113q25 23 9 38zM128 882q43 19 103 16t121-20.5 133-39 139-41 139-26 133 5.5q54 11 91 35.5t37 51.5q0 30-39.5 46t-88.5 1q-53-18-118-15t-125.5 20.5-130 39-132 40.5-132.5 25.5-130-5.5q-54-11-91-35.5T0 928t38-42 90-4z"
      />
    </Svg>
  )

  const TideUp = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="1.01em" height="1em" aria-hidden="true" className="iconify iconify--whh" viewBox="0 0 1024 1023" {...props}>
      <Path
        fill="currentColor"
        d="M896 590q-53-18-118-15t-125.5 20.5-130 39-132 40.5T258 700.5 128 695q-54-11-91-35.5T0 608q0-29 38-43t90-4q43 19 103 16t121-20.5 133-39 139-41 139-26 133 5.5q54 11 91 35.5t37 51.5q0 30-39.5 46t-88.5 1zM576 192v95q0 14-9.5 23t-22.5 9h-64q-13 0-22.5-9t-9.5-23v-95H335q-25-23-8-38L489 8q9-8 23-8t24 8l162 146q16 15-9 38H576zM128 881q43 19 103 16t121-20.5 133-39 139-41 139-26 133 5.5q54 11 91 35.5t37 51.5q0 30-39.5 46t-88.5 1q-53-18-118-15t-125.5 20.5-130 39-132 40.5-132.5 25.5-130-5.5q-54-11-91-35.5T0 927t38-42 90-4z"
      />
    </Svg>
  )

  const Up = (
    <View style={styles.pageTideIcon}>
      <TideUp color={color} width="100%" height="100%" />
    </View>
  )

  const Down = (
    <View style={styles.pageTideIcon}>
      <TideDown color={color} width="100%" height="100%" />
    </View>
  )

  return (
    <View style={[styles.content, hideBorder ? styles.single : null]}>
      {tideConfig?.weatherHorairesMareesPort.nextTide ? (
        <>
          {tideConfig?.weatherHorairesMareesPort.nextTide.state === 0 ? Down : Up}
          <View>
            <Text style={[styles.text]}>
              {moment(tideConfig?.weatherHorairesMareesPort.nextTide.datetime).format('HH:mm').replace(':', 'h')} - {tideConfig?.weatherHorairesMareesPort.nextTide.height}{' '}
              {i18n.t('tide.unit')}
            </Text>
            <Text style={[styles.text]}>
              {i18n.t('tide.coeffAbbreviation')} {tideConfig?.weatherHorairesMareesPort.nextTide.coefficient || '--'}
            </Text>
          </View>
        </>
      ) : (
        <Text style={[styles.text]}>---</Text>
      )}
    </View>
  )
}

const stylesTide = (color: string, large = false) =>
  /* eslint react-native/no-unused-styles: 0 */
  StyleSheet.create({
    single: {
      borderRightWidth: 0,
      flex: 1,
    },
    content: {
      flex: 2,
      alignItems: 'center',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      overflow: 'hidden',
      borderRightWidth: 1,
      borderColor: color,
      marginRight: theme.padding.x1,
    },
    text: {
      color,
    },
    pageTideIcon: {
      width: large ? 27 : 24,
      height: large ? 30 : 24,
      marginRight: theme.padding.x3,
    },
  })
