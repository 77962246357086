import { Button, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { StyleSheet } from 'react-native'
import Modal from 'react-native-modal'
import { i18n } from '../../lib/i18n'
import { theme } from '../../theme'
import { HtmlWebView } from '../WebView'

interface ModalCenterProps {
  title: string
  contentHTML: string
  isVisible: boolean
}

export const ModalCenter: FunctionComponent<ModalCenterProps> = ({ title, contentHTML, isVisible }) => {
  const [visibility, setvisibility] = useState(isVisible)
  const onPressed = () => {
    setvisibility(false)
  }

  return (
    <Modal isVisible={visibility}>
      <View style={styles.noData}>
        <Text style={styles.title}>{title}</Text>
        <HtmlWebView content={contentHTML} />
        <Button variant="ghost" style={styles.button} onPress={() => onPressed()}>
          <Text style={styles.title}>{i18n.t('action.cancel')}</Text>
        </Button>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  noData: {
    borderRadius: 5,
    height: theme.deviceHeight / 3,
    backgroundColor: theme.colors.white,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    paddingVertical: theme.padding.x1,
    color: theme.colors.silver,
  },
  button: {
    borderRadius: 5,
  },
})
