/* eslint-disable prettier/prettier */
import { DrawerScreenProps } from '@react-navigation/drawer'
import { isEmpty } from 'lodash'
import { Button, Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import AntDesign from 'react-native-vector-icons/AntDesign'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { CityItemResult } from '../../api/City/types'
import { Content } from '../../components/NativeBaseLegacy'
import { Page } from '../../components/Page'
import { ReferentielList } from '../../components/ReferentielList'
import { TownHall } from '../../components/TownHall'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, RoutesCityAdd } from '../../navigation/Routes'
import { CityActions } from '../../redux/city/action'
import { citysSelectSelector, townHallsConfigSelector } from '../../redux/city/selector'
import { homeTitleImageSelector, homeTitleSelector } from '../../redux/home/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'

export const MyTownHalls: FunctionComponent<DrawerScreenProps<ActionViewParamsType, 'MyTown'>> = ({
  navigation,
}) => {
  const citys = useSelector(citysSelectSelector)
  const title = useSelector(homeTitleSelector)
  const titleHome = title || i18n.t('home.title')
  const titleImage = useSelector(homeTitleImageSelector)
  const theme = useSelector(themeSelector)

  const townHallsConfig = useSelector((state: RootState) => townHallsConfigSelector(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isEmpty(citys)) {
      const citysList: CityItemResult[] = Object.values(citys)
      if (citysList.length > 1) {
        dispatch(CityActions.loadTownHallsConfig())
      }
    }
  }, [citys])

  const HomePage: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => (
      <Page
        left={
          <Button variant="ghost" onPress={navigation.toggleDrawer}>
            <Icon as={Ionicons} name="menu" color={theme?.titleFontColor} size={6} />
          </Button>
        }
        title={titleHome}
        titleImage={titleImage}
      >
        <Content style={styles.content}>
          {children}
        </Content>
      </Page>
    )

  if (isEmpty(citys)) {
    return (
      <HomePage>
        <View style={styles.contentAdd}>
          <Button
            colorScheme="default"
            onPress={() => navigation.navigate({ name: RoutesCityAdd.cityAdd, params: {} })}
          >
            <Icon name="pluscircleo" as={AntDesign} size={6} />
            <Text>{i18n.t('addCity.title')}</Text>
          </Button>
        </View>
      </HomePage>
    )
  }

  const citysList: CityItemResult[] = Object.values(citys)

  if (citysList.length === 1) {
    const cityId = citysList[0].id
    return (
      <HomePage key={cityId}>
        <TownHall cityId={cityId} />
      </HomePage>
    )
  }

  if (!townHallsConfig) {
    return (
      <HomePage>
        <Spinner />
      </HomePage>
    )
  }

  return (
    <HomePage>
      <ReferentielList key={citysList.length} id={-10} referentielFilters={townHallsConfig} />
    </HomePage>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1 },
  contentAdd: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
