import { Spinner, Switch, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationUserCategorie } from '../../api/Notification/types'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { NotificationActions } from '../../redux/notification/action'
import { notificationSettingsSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { trackEvent } from '../../services/matomo/matomo.service'
import { theme } from '../../theme/theme'

export const NotificationSettings: FunctionComponent = () => {
  const dispatch = useDispatch()
  const settings = useSelector(notificationSettingsSelector)
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_NOTIFICATION_SETTINGS))

  useEffect(() => {
    dispatch(NotificationActions.loadNotificationsSettings())
  }, [])

  const allEnabled = (
    <View style={styles.line} p="2">
      <Text>{i18n.t('notifications.categorieAll')}</Text>
      <Switch
        value={settings.enabled}
        colorScheme="primary"
        onValueChange={(value: boolean) => {
          dispatch(NotificationActions.notificationSettingsEnabled(value))
          trackEvent({
            category: 'Notifications',
            actionName: 'Notifications',
            eventName: `Modification paramètre`,
            value: `${i18n.t('notifications.categorieAll')}: ${value ? 'Oui' : 'Non'}`,
          })
        }}
      />
    </View>
  )

  const renderCategory = (item: NotificationUserCategorie) => (
    <View style={styles.line} key={item.id} p="2">
      <Text>{item.categorie.libelle}</Text>
      <Switch
        value={item.notifier}
        colorScheme="primary"
        onValueChange={(value: boolean) => {
          dispatch(NotificationActions.notificationCategorieEnabled(item.id, value))
          trackEvent({
            category: 'Notifications',
            actionName: 'Notifications',
            eventName: `Modification d'un paramètre`,
            value: `${item.categorie.libelle}: ${value ? 'Oui' : 'Non'}`,
          })
        }}
      />
    </View>
  )

  const RenderCategories: FunctionComponent = () => (settings.enabled ? <>{settings.categories.map(renderCategory)}</> : <View />)

  const RenderContent: FunctionComponent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <View>
        <Text style={styles.message}>{settings.message}</Text>
        {allEnabled}
        <RenderCategories />
      </View>
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={i18n.t('notifications.settingTitle')}>
      <RenderContent />
    </Page>
  )
}

const styles = StyleSheet.create({
  line: {
    borderColor: theme.colors.paleGrey,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  message: {
    textAlign: 'center',
    width: '100%',
    padding: theme.padding.x2,
  },
})
