import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import FastImage, { Source } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import deleteAccount from '../../assets/images/delete_account.png'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

export const MyAccountDeletion: FunctionComponent = () => {
  const menu = useSelector(menuSelector)

  const logoUri = menu && menu.header && menu.header.image

  const handleAccountDeletion = () => {
    navigate(Routes.MyAccountConfirmDeletion)
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          <CloseButton style={styles.close} />
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <View style={styles.content}>
            <Text style={styles.subtitle}>{i18n.t('myAccount.myAccountDeletion.subtitle')}</Text>
            <Text style={styles.title}>{i18n.t('myAccount.myAccountDeletion.title')}</Text>
            <FastImage style={styles.image} source={deleteAccount as Source} resizeMode={FastImage.resizeMode.contain} />
            <View style={styles.informations}>
              <Text style={styles.informationTitle}>{i18n.t('myAccount.myAccountDeletion.parameters.title')}</Text>
              <Text style={styles.informationText}>{i18n.t('myAccount.myAccountDeletion.parameters.information')}</Text>
              <Text style={styles.warning}>{i18n.t('myAccount.myAccountDeletion.parameters.warning')}</Text>
            </View>
            <Button onPress={handleAccountDeletion} iconName="trash" iconType="FontAwesome5" label={i18n.t('myAccount.myAccountDeletion.title')} style={styles.button} />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  close: {
    padding: theme.padding.x3,
  },
  content: {
    padding: theme.padding.x3,
  },
  title: {
    fontSize: theme.iconSize.x2,
    lineHeight: theme.iconSize.x2,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.colors.darkGrayishBlue,
    marginVertical: theme.padding.x1,
    fontSize: theme.iconSize.x1,
    fontWeight: 'bold',
  },
  image: {
    height: 150,
    width: '100%',
    marginTop: theme.padding.x4,
  },
  informations: {
    borderColor: theme.colors.darkPink,
    borderWidth: 1,
    backgroundColor: theme.colors.lightPink,
    padding: theme.padding.x2,
    marginBottom: theme.padding.x4,
    marginTop: theme.padding.x4,
  },
  informationTitle: {
    color: theme.colors.darkRed,
    fontWeight: 'bold',
    fontSize: theme.iconSize.x1,
    paddingBottom: theme.padding.x2,
  },
  informationText: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.indianRed,
    paddingBottom: theme.padding.x2,
  },
  warning: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.indianRed,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: theme.colors.originalRed,
    borderRadius: 0,
  },
})
