/* eslint-disable prettier/prettier */
/* eslint-disable react-native/no-inline-styles */
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import moment from 'moment'
import { Pressable, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Dimensions, FlatList, Image, ImageSourcePropType, Platform, ScrollView, StyleSheet, ViewStyle } from 'react-native'
import {
  LineChart,
} from "react-native-chart-kit"
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { Coefficients, TideDetails } from '../../api/Tide/types'
import { LeftButtonBack, Page } from '../../components/Page/Page.component'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { TideAction } from '../../redux/tide/actions'
import { tideSelector } from '../../redux/tide/selector'
import { theme } from '../../theme'

import Next from '../../assets/images/next.png'
import Previous from '../../assets/images/previous.png'
import TideDown1 from '../../assets/images/tide-down-1.png'
import TideUp1 from '../../assets/images/tide-up-1.png'
import TideUp2 from '../../assets/images/tide-up-2.png'

interface PageData {
  key: string
  data: Coefficients
  id: string
}

export const HorairesMarees: FunctionComponent<
  StackScreenProps<ActionViewParamsType, 'HorairesMarees'>
  > = ({ route }) => {
    const title = route.params.titre as string
    const id = route.params.id as number

    const scrollRef = useRef<FlatList<PageData>>(null)

    const dispatch = useDispatch()
    const [list, setList] = useState<Array<PageData>>([])
    const tide = useSelector(tideSelector) as TideDetails

    useEffect(() => {
      dispatch(TideAction.loadTideDetails(id))
      return () => {
        dispatch(TideAction.storeTideDetails(undefined))
      }
    }, [])

    useEffect(() => {
      const rawDatas = _.groupBy(tide?.coefficients || [], ({ datetime }) => moment(datetime).format('YYYYMMDD'))
      const newDatas = _.map(rawDatas, (data, key) => ({ key, data, id: _.first(data)?.datetime.toString() })) as unknown as Array<PageData>
      setList(_.sortBy(newDatas, ['key']) )
    }, [tide])

    const currentRender = () => tide ? (
      <View style={styles.current}>
        <Image source={(tide.lastState === 0 ? TideDown1 : TideUp1) as ImageSourcePropType} style={styles.currentTideIcon} />
        <View>
          <Text style={styles.currentHeight}>{tide.lastWaterHeight} {i18n.t('tide.unit')}</Text>
          <Text style={styles.currentCoef}>{i18n.t('tide.coeffAbbreviation')} {tide.lastCoef || '--'}</Text>
        </View>
      </View>
    ) : null

    const scrollTo = (index: number) => {
      if (index >= 0 && index < list.length)
        scrollRef?.current?.scrollToIndex({ index })
    }

    const renderTideLigne = (tideLigne: Coefficients) => (
        <View style={styles.tideIconText}>
          <Image source={(tideLigne.state === 0 ? TideDown1 : TideUp2) as ImageSourcePropType} style={styles.pageTideIcon} />
          <Text style={[styles.lineText, { color: tideLigne.state === 0 ? theme.colors.white : theme.tide.primary }]}>
            {moment(tideLigne.datetime).format('HH:mm').replace(':','h')}
          </Text>
          <Text style={[styles.lineText, { color: tideLigne.state === 0 ? theme.colors.white : theme.tide.primary }]}>
            {tideLigne.height} {i18n.t('tide.unit')}
          </Text>
          <Text style={[styles.lineText, { color: tideLigne.state === 0 ? theme.colors.white : theme.tide.primary }]}>
            {i18n.t('tide.coeffAbbreviation')} {tideLigne.coefficient || '--'}
          </Text>
        </View>
      )

    const slideRender = useCallback(({ key, data }: PageData, index: number) => {
      const date = moment().format('YYYYMMDD') === key ? i18n.t('tide.today') : moment(key, 'YYYYMMDD').format('DD/MM/YYYY')
      return (
        <View style={styles.page}>
          <ScrollView style={styles.scrollView}>
            <View style={styles.pageHeader}>
              <Pressable onPress={() => scrollTo(index - 1)} style={{ opacity: index === 0 ? 0.5 : 1 }}>
                <Image source={Previous as ImageSourcePropType} />
              </Pressable>
              <Text style={styles.pageTitle}>{date}</Text>
              <Pressable onPress={() => scrollTo(index + 1)}>
                <Image source={Next as ImageSourcePropType} style={{ opacity: index === list.length -1 ? 0.5 : 1 }} />
              </Pressable>
            </View>
            {_.map(data, element => renderTideLigne(element as unknown as Coefficients))}
          </ScrollView>
        </View>
      )
    }, [list])

    const data = {
      labels: [],
      datasets: [{ data: _.map(tide?.waterHeights || [], ({ height }) => height) }],
    }

    const pointToHide = (t: TideDetails): Array<number> => {
      const result = _.map(t?.waterHeights || [], ({ datetime }, index) => {
        if (!moment(datetime).isBetween(moment(), moment().add(30, 'minutes'))) return index
        return null
      }) as Array<number>
      return _.filter(result, r => !_.isUndefined(r))
    }

    let height = 180
    let marginBottom = -29
    if (Dimensions.get('window').height <= 700) {
      height = 120
      marginBottom = -14
    }

    return (
      <Page left={<LeftButtonBack />} title={i18n.t('tide.title')}>
        <View style={styles.topView}>
          <Text style={styles.title}>{title}</Text>
          {!_.isEmpty(list) ? currentRender() : <Spinner color="white" />}
        </View>
        <View style={styles.graph}>
          {!_.isEmpty(tide) && <LineChart
            data={data}
            width={Dimensions.get('window').width + 73}
            height={height}
            style={[styles.graphCheat, { marginBottom }] as ViewStyle}
            yAxisInterval={0}
            yLabelsOffset={0}
            withVerticalLabels={false}
            withHorizontalLabels={false}
            withInnerLines={false}
            withOuterLines={false}
            hidePointsAtIndex={pointToHide(tide)}
            chartConfig={{
              backgroundColor: theme.tide.primary,
              backgroundGradientFrom: theme.tide.primary,
              backgroundGradientTo: theme.tide.primary,
              fillShadowGradientFromOpacity: 1,
              fillShadowGradientToOpacity: 1,
              color: () => theme.tide.secondary,
              propsForDots: {
                r: "6",
                strokeWidth: "0",
                stroke: theme.colors.white,
                fill: theme.colors.white,
              },
            }}
          />}
        </View>
        <SafeAreaView edges={['bottom']} style={styles.bottomView}>
          <View style={styles.separator} />
          {!_.isEmpty(list) ? <FlatList
            ref={scrollRef}
            data={list}
            extraData={list}
            pagingEnabled={Platform.OS !== 'web'}
            horizontal
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item: PageData) => item.id}
            renderItem={({ item, index }) => slideRender(item as PageData, index)}
          /> : <Spinner color="white" />}
          <Text style={styles.theoretical} numberOfLines={2}>{i18n.t('tideTime.theoretical')}</Text>
        </SafeAreaView>
      </Page>
    )
  }


const styles = StyleSheet.create({
  topView: {
    backgroundColor: theme.tide.primary,
    paddingVertical: theme.padding.x4,
    alignItems: 'center',
  },
  bottomView: {
    flex: 1,
    paddingTop: 0, 
    backgroundColor: theme.tide.secondary,
  },
  title: {
    color: theme.colors.white,
    fontSize: theme.textSize.x4,
    lineHeight: theme.textSize.x4,
    fontWeight: 'bold',
    marginBottom: theme.padding.x2,
  },
  current: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  currentTideIcon: {
    width: 36,
    height: 36,
    marginRight: theme.padding.x4,
  },
  currentHeight: {
    color: theme.colors.white,
    fontSize: theme.textSize.x3,
    lineHeight: theme.textSize.x4,
    fontWeight: 'bold',
  },
  currentCoef: {
    color: theme.colors.white,
    fontSize: theme.textSize.x3,
    lineHeight: theme.textSize.x4,
    fontWeight: 'bold',
  },
  separator: {
    marginHorizontal: theme.padding.x6,
    marginVertical: theme.padding.x4,
    borderBottomWidth: 1,
    borderColor: theme.colors.white,
  },
  scrollView: {
    flex: 1,
  },
  page: {
    flex: 1,
    width: Dimensions.get("window").width,
    paddingHorizontal: theme.padding.x6,
  },
  pageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.padding.x2,
  },
  pageTitle: {
    color: theme.colors.white,
    fontWeight: 'bold',
    fontSize: 20,
  },
  theoretical: {
    fontStyle: 'italic',
    marginTop: theme.padding.x2,
    color: theme.colors.white,
    paddingHorizontal: theme.padding.x6,
    textAlign: 'center',
  },
  tideIconText: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.padding.x2,
  },
  pageTideIcon: {
    width: 24,
    height: 24,
    marginRight: theme.padding.x3,
  },
  lineText: {
    fontSize: theme.textSize.x2,
    fontWeight: 'bold',
    flex: 1,
  },
  graph: {
    backgroundColor: theme.tide.primary,
  },
  graphCheat: {
    marginLeft: -64,
    marginRight: -40,
    marginTop: -15,
  },
})
