/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Spinner, TextArea, View } from 'native-base'
import React, { PureComponent } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { withNextInputAutoFocusInput } from 'react-native-formik'
import Geolocation from 'react-native-geolocation-service'
import { FormAddress } from '../../api/Form/types'
import { AddressResult } from '../../api/Geolocation/types'
import { i18n } from '../../lib/i18n'
import { checkLocation } from '../../services/Permissions/Permissions.service'
import { findAddress } from '../../services/geolocation'
import { theme } from '../../theme'
import { Item } from '../NativeBaseLegacy'
import { LabelInput } from './components'
import { AddressValue, FormElementProps } from './types'

const initialAddressValue: AddressValue = {
  adresse: '',
  codePostal: '',
  ville: '',
}
interface AddressFieldState {
  loading: boolean
  geolocated: boolean
}

type AddressFieldClassProps = FormElementProps<FormAddress, AddressValue>

class AddressFieldClass extends PureComponent<AddressFieldClassProps, AddressFieldState> {
  private addressInput: typeof Input | null = null

  private cityInput: typeof Input | null = null

  public state: AddressFieldState = {
    loading: false,
    geolocated: false,
  }

  getAddress = async (lat: number, lng: number) => {
    const adr: AddressResult | null = await findAddress(lat, lng)

    if (adr) {
      const { field } = this.props
      if (field.constraint.detailAddress) {
        if (adr.address) {
          this.setValue('adresse', adr.address)
        }
        if (adr.postalCode) {
          this.setValue('codePostal', adr.postalCode)
        }
        if (adr.city) {
          this.setValue('ville', adr.city)
        }
      } else if (adr.formattedAddress) {
        this.setValue('adresse', adr.formattedAddress)
      }
    }
  }

  async componentDidMount() {
    const allowdLocation = await checkLocation()
    if (allowdLocation) {
      this.setState({
        ...this.state,
        loading: true,
      })

      await this.geolocate()

      if (Platform.OS === 'android') {
        /**
         * If we have twice address on form, the function `Geolocation.getCurrentPosition` work only one time ont Android.
         * With this timeout we force to hide spinner on change state `loading` and try to load again geolocation
         */
        setTimeout(() => {
          if (!this.state.geolocated) {
            this.setState({
              ...this.state,
              loading: false,
            })
            this.geolocate()
          }
        }, 3000)
      }
    }
  }

  geolocate = () => {
    Geolocation.getCurrentPosition(
      position => {
        this.getAddress(position.coords.latitude, position.coords.longitude)
        this.setState({
          ...this.state,
          loading: false,
          geolocated: true,
        })
      },
      () => {
        this.setState({
          ...this.state,
          loading: false,
        })
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
    )
  }

  public focus = () => {
    if (!this.addressInput) {
      return
    }
    // https://github.com/GeekyAnts/NativeBase/issues/1429#issuecomment-350228398
    const root = (this.addressInput as any)._root
    if (!root) {
      return
    }
    root.focus()
  }

  private focusCity = () => {
    if (!this.cityInput) {
      return
    }
    const root = (this.cityInput as any)._root
    if (!root) {
      return
    }
    root.focus()
  }

  private renderAddressField() {
    const { field, handleBlur } = this.props
    const height = field.constraint.height || theme.textSize.x2
    const numberOfLines = height / theme.textSize.x2
    const address = this.getAddressFieldValue('adresse')

    return (
      <View>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <TextArea
            autoCompleteType
            accessibilityValue={undefined}
            onAccessibilityEscape={undefined}
            focusable={undefined}
            onTextInput={undefined}
            passwordRules={undefined}
            rejectResponderTermination={undefined}
            importantForAutofill={undefined}
            showSoftInputOnFocus={undefined}
            {...this.props}
            ref={(input: any) => (this.addressInput = input)}
            totalLines={numberOfLines}
            scrollEnabled={false}
            onChangeText={(text: any) => this.setValue('adresse', text)}
            onBlur={handleBlur(this.elementId)}
            returnKeyType="default"
            onSubmitEditing={() => {}}
            value={address}
          />
        )}
      </View>
    )
  }

  private renderZipAndCityFields() {
    const { field, returnKeyType, handleBlur } = this.props
    if (!field.constraint.detailAddress) {
      return null
    }
    const zipCode = this.getAddressFieldValue('codePostal')
    const city = this.getAddressFieldValue('ville')

    return (
      <>
        <View style={styles.input}>
          <LabelInput text={i18n.t('form.zipCode')} required={field.constraint.mandatory} />
          <Item>
            <Input {...this.props} returnKeyType="next" onChangeText={text => this.setValue('codePostal', text)} onSubmitEditing={this.focusCity} value={zipCode} />
          </Item>
        </View>
        <View style={styles.input}>
          <LabelInput text={i18n.t('form.city')} required={field.constraint.mandatory} />
          <Item>
            <Input
              {...this.props}
              ref={(input: any) => (this.cityInput = input)}
              returnKeyType={returnKeyType}
              onChangeText={text => this.setValue('ville', text)}
              onBlur={handleBlur(this.elementId)}
              value={city}
            />
          </Item>
        </View>
      </>
    )
  }

  private get elementId() {
    return `${this.props.field.id}`
  }

  private setValue(key: keyof AddressValue, newAddress: string): void {
    const { value, setFieldValue } = this.props
    setFieldValue(this.elementId, {
      ...(value || initialAddressValue),
      [key]: newAddress,
    })
  }

  private getAddressFieldValue(key: keyof AddressValue): string {
    const { value, setFieldValue } = this.props
    if (!value) {
      setFieldValue(this.elementId, initialAddressValue)
      return initialAddressValue[key] || ''
    }
    return value[key] || ''
  }

  public render() {
    return (
      <>
        {this.renderAddressField()}
        {this.renderZipAndCityFields()}
      </>
    )
  }
}

const styles = StyleSheet.create({
  input: {
    marginVertical: theme.padding.x1,
  },
})

export const AddressField = withNextInputAutoFocusInput(AddressFieldClass)
