import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { View } from 'native-base'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel'
import { useSelector } from 'react-redux'
import { MarkerResult } from '../../api/Cartography/types'
import { cartographyMenuMarkersSelector } from '../../redux/cartography/selector'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'
import { MIN_WIDTH as MENU_MIN_WIDTH } from '../CartographyMenu'
import { CartographyCard } from './components'

const SLIDER_WIDTH = theme.deviceWidth - MENU_MIN_WIDTH
const DISPLAY_CARD_OFFSET = 3
const FIRST_ITEM_DISPLAYED = 0

interface RenderItemResult {
  item: MarkerResult
  index: number
}

interface CartographyFooterProps {
  cartographyId: string
  menuId: string | null
  selectedMarkerId: number | null
  onSnapItem: (id: number) => void
}

const CartographyFooter: FunctionComponent<CartographyFooterProps> = ({ cartographyId, menuId, selectedMarkerId, onSnapItem }) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(FIRST_ITEM_DISPLAYED)
  const [isClickable, setIsClickable] = useState<boolean>(true)
  const carousel = useRef<ICarouselInstance>()
  const markers = useSelector((state: RootState) => cartographyMenuMarkersSelector(state, { cartographyId, menuId }))

  const isCardDisplayed = (index: number): boolean => index >= carouselIndex - DISPLAY_CARD_OFFSET && index <= carouselIndex + DISPLAY_CARD_OFFSET

  const renderItem = useCallback(
    ({ item, index }: RenderItemResult) => (
      <View style={styles.card}>
        <CartographyCard item={item} key={item.id} isDisplayed={isCardDisplayed(index)} isClickable={isClickable} />
      </View>
    ),
    [isClickable],
  )

  const onSnapToItem = (index: number): void => {
    if (!markers || index === carouselIndex) {
      return
    }
    const marker = markers[index]
    const markerId = marker && marker.id
    if (markerId) {
      setCarouselIndex(index)
      onSnapItem(markerId)
    }
  }

  const snapToItem = (animate = true) => {
    if (carousel.current) {
      carousel.current.scrollTo({ index: carouselIndex, animated: animate })
    }
  }

  const resetCarousel = () => {
    const hasMarkers = markers && !isEmpty(markers)
    if (hasMarkers) {
      setCarouselIndex(FIRST_ITEM_DISPLAYED)
    }
  }

  const snapToSelectedItem = () => {
    if (!carousel.current || !markers) {
      return
    }
    const index = markers.findIndex(marker => marker.id === selectedMarkerId)
    if (index >= 0 && index !== carouselIndex) {
      setCarouselIndex(index)
    }
  }

  useEffect(resetCarousel, [menuId])
  useEffect(snapToSelectedItem, [selectedMarkerId])
  useEffect(snapToItem, [carouselIndex])

  if (!menuId || !markers) {
    return null
  }

  /**
   * Carousel ref type does not accept a useRef but works fine
   * so we add the any type to make it work and remove the TypeScript error.
   */
  return (
    <View style={styles.content}>
      <Carousel
        ref={carousel as any} // eslint-disable-line @typescript-eslint/no-explicit-any
        mode="parallax"
        data={markers || []}
        renderItem={renderItem}
        snapEnabled
        pagingEnabled
        loop={false}
        width={SLIDER_WIDTH}
        onSnapToItem={onSnapToItem}
        onProgressChange={(offsetProgress, absoluteProgress) => {
          if (!_.isInteger(absoluteProgress)) {
            setIsClickable(false)
          } else {
            setTimeout(() => setIsClickable(true), 1000)
          }
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    height: Platform.OS === 'web' ? theme.padding.x6 * 4.5 : theme.padding.x6 * 6,
  },
  card: {
    flex: 1,
    paddingHorizontal: theme.padding.x1,
  },
})

export { CartographyFooter }
