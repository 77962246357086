import { Button, Icon, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { ItemAction } from '../../api/types'
import { themeSelector } from '../../redux/theme/selector'
import { iconFont } from '../../services/Icon'
import { IconType } from '../../services/weather'
import { theme as defaultTheme } from '../../theme'

export const BUBBLE_FONT_SIZE = 14

export interface BubbleActionProps {
  link: {
    label?: string
    labelIn?: string
    image?: string
    icon?: string
    iconTheme?: IconType
    action?: ItemAction
  }
  onPress?: () => void
}

const BubbleAction: FunctionComponent<BubbleActionProps> = ({ link, onPress }) => {
  const theme = useSelector(themeSelector)
  const color = theme ? theme.brandPrimary : defaultTheme.bubble.color

  const getIcon = () => {
    if (link.image) {
      return <FastImage source={{ uri: link.image }} resizeMode={FastImage.resizeMode.contain} style={styles.iconImage} />
    }
    if (link.icon) {
      return <Icon as={iconFont(link.iconTheme || '')} name={link.icon} style={styles.icon} color={color} size={6} />
    }
    if (link.labelIn) {
      return <Text style={[styles.iconText, { color }]}>{link.labelIn}</Text>
    }
    return null
  }

  return (
    <TouchableOpacity style={styles.touchable} onPress={onPress}>
      <Button style={[styles.button, { borderColor: color }]} onPress={onPress}>
        {getIcon()}
      </Button>
      {link.label && <Text style={styles.text}>{link.label}</Text>}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  touchable: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: BUBBLE_FONT_SIZE,
  },
  button: {
    backgroundColor: defaultTheme.colors.transparent,
    borderWidth: 1,
    width: defaultTheme.bubble.size,
    height: defaultTheme.bubble.size,
    borderRadius: defaultTheme.bubble.size / 2,
    marginVertical: defaultTheme.padding.x1 / 2,
    marginHorizontal: defaultTheme.padding.x1,
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 0,
  },
  icon: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'center',
  },
  iconText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  iconImage: {
    width: defaultTheme.bubble.size / 2,
    height: defaultTheme.bubble.size / 2,
  },
})

export { BubbleAction }
