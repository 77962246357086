import { Card, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { CatalogFavoritesItem, ExtraData, FavoriteFooter, FavoriteHeader } from '../../api/Favorites/types'
import { theme } from '../../theme'
import { FastImageBackground } from '../FastImageBackground'

interface iProps {
  item: CatalogFavoritesItem
  onPress: () => void
  buttonActivated?: boolean
}

const displayFontColor = (color: string | null) => {
  if (!color) {
    return {}
  }
  return {
    color,
  }
}

const displayBackgroundColor = (backgroundColor: string | null) => {
  if (!backgroundColor) {
    return {}
  }
  return {
    backgroundColor,
  }
}

const displayIcon = (iconURI?: string) => {
  if (!iconURI) {
    return null
  }
  return null // Afficher l'icône
}

const displayDataFavorite = (footer: FavoriteFooter) => {
  if (footer.data.length === 0) {
    return null
  }
  const style = [styles.element, displayBackgroundColor(footer.backgroundColor), displayFontColor(footer.fontColor)]
  if (footer.data.length === 1) {
    return (
      <View>
        {displayIcon(footer.data[0].iconURI)}
        <Text style={style}>{footer.data[0].title}</Text>
      </View>
    )
  }
  return footer.data.map((item: ExtraData, index: number) => {
    if (index !== 0) {
      return (
        <View key={item.title} style={[styles.marginExtraElementOnLeft]}>
          {displayIcon(item.iconURI)}
          <Text style={style}>{item.title}</Text>
        </View>
      )
    }
    return (
      <View key={item.title}>
        {displayIcon(item.iconURI)}
        <Text style={style}>{item.title}</Text>
      </View>
    )
  })
}

const getHeaderStyle = (header: FavoriteHeader | null) => {
  if (!header) {
    return [styles.title]
  }
  return [styles.title, displayBackgroundColor(header.backgroundColor), displayFontColor(header.fontColor)]
}

const displayHeader = (header: FavoriteHeader | null) => {
  if (!header) {
    return <Text style={getHeaderStyle(header)} />
  }
  return <Text style={getHeaderStyle(header)}>{header.title}</Text>
}

const displayFooter = (footer: FavoriteFooter | null) => {
  if (!footer) {
    return null
  }
  return <View style={styles.footerData}>{displayDataFavorite(footer)}</View>
}

export const FavItemShop: FunctionComponent<iProps> = ({ item, onPress, buttonActivated }: iProps) => {
  const buttonState = buttonActivated === undefined ? true : buttonActivated
  return (
    <Card mx="1" mb="1" mt="2" p="0" style={styles.card} key={item.id}>
      <TouchableOpacity disabled={!buttonState} onPress={() => onPress()} style={styles.cardItem}>
        <FastImageBackground source={{ uri: item.image }} style={styles.fastImage} resizeMode="cover">
          <View style={styles.contentWrapper}>
            {displayHeader(item.header)}
            {displayFooter(item.footer)}
          </View>
        </FastImageBackground>
      </TouchableOpacity>
    </Card>
  )
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    transparent: true,
    backgroundColor: theme.colors.transparent,
    borderColor: theme.colors.transparent,
    shadowOpacity: 0,
  },
  cardItem: {
    backgroundColor: theme.colors.transparent,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingTop: 5,
  },
  title: {
    textAlign: 'center',
    fontSize: theme.textSize.x1_75,
    fontWeight: 'bold',
    // fontFamily: theme.fontFamily,
    paddingHorizontal: 4,
    overflow: 'hidden',
    borderRadius: 5,
  },
  footerData: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 0,
  },
  fastImage: {
    flex: 1,
  },
  element: {
    fontSize: 10,
    // fontFamily: theme.fontFamily,
    textAlign: 'center',
    borderRadius: 5,
    marginLeft: 0,
    marginBottom: 8,
    paddingVertical: 2,
    paddingHorizontal: 6,
    overflow: 'hidden',
  },
  marginExtraElementOnLeft: {
    marginLeft: 4,
  },
})
