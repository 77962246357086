import { StackScreenProps } from '@react-navigation/stack'
import { Spinner } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LeftButtonBack, Page } from '../../components/Page/Page.component'
import { TaskReportItemComponent } from '../../components/TaskReportItem/components/ReportTaskItem.component'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { TaskReportActions } from '../../redux/taskReport/action'
import { TaskReportItemSelector } from '../../redux/taskReport/selector'

export const TaskReportItem: FunctionComponent<StackScreenProps<ActionViewParamsType, 'TaskReportItem'>> = ({ route }) => {
  const dispatch = useDispatch()
  const item = route.params.taskReport

  useEffect(() => {
    dispatch(TaskReportActions.loadTaskReportItem(item.uuid))
  }, [])

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_TASKREPORT_ITEM))

  const reportProcess = useSelector((state: RootState) => TaskReportItemSelector(state, item.uuid))

  if (!reportProcess || isLoading) {
    return (
      <Page left={<LeftButtonBack />} title={i18n.t('taskReport.defaultPageTitle')}>
        <Spinner />
      </Page>
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={reportProcess.task ? reportProcess.task : i18n.t('taskReport.defaultPageTitle')}>
      <TaskReportItemComponent taskReport={reportProcess} />
    </Page>
  )
}
