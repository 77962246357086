import { GrcAccountAuthorization, TaskReportItem, TaskReportListResult } from '../../api/TaskReport/types'

export type TaskReportState = {
  paging: TaskReportListResult
  items: {
    [key: string]: TaskReportItem
  }
  grcIsAuth: GrcAccountAuthorization
}

export enum TaskReportActionTypes {
  LOAD_TASKREPORT = 'LOAD_TASKREPORT',
  STORE_CLEAR_TASK_REPORT = 'STORE_CLEAR_TASK_REPORT',
  STORE_TASKREPORT = 'STORE_TASKREPORT',
  REFRESH_TASKREPORT = 'REFRESH_TASKREPORT',
  STORE_TASKREPORT_ITEM = 'STORE_TASKREPORT_ITEM',
  LOAD_TASKREPORT_ITEM = 'LOAD_TASKREPORT_ITEM',
  LOAD_IS_AUTHORIZED_GRC = 'LOAD_IS_AUTHORIZED_GRC',
  STORE_IS_AUTHORIZED_GRC = 'STORE_IS_AUTHORIZED_GRC',
}
