import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { themeSelector } from '../../../redux/theme/selector'
import { toDDMMYYYY } from '../../../services/dateFormatter'
import { getGrcInterval } from '../../../services/grcCitopia'
import { theme } from '../../../theme'

interface ReportStatusProps {
  date: number | null
}

const BORDER_RADIUS = 20

export const ReportDate: FunctionComponent<ReportStatusProps> = ({ date }) => {
  if (!date) {
    return null
  }
  const themeSelect = useSelector(themeSelector)

  const codeExpr = getGrcInterval(date)

  let colorBg = theme.colors.silver
  switch (codeExpr) {
    case 'primary':
      colorBg = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
      break
    case 'warning':
      colorBg = (themeSelect && themeSelect.brandWarning) || theme.colors.orange
      break
    case 'danger':
      colorBg = (themeSelect && themeSelect.brandDanger) || theme.colors.originalRed
      break
    default:
      break
  }

  return (
    <View style={[styles.roundedView, { backgroundColor: colorBg }, styles.row]}>
      <Icon as={Ionicons} style={styles.icon} color={theme.colors.white} name="time-outline" size={6} />
      <Text style={styles.textDate}>{toDDMMYYYY(date)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.padding.x1,
    paddingVertical: theme.padding.x05,
  },
  textDate: {
    color: theme.colors.white,
    fontSize: theme.textSize.x1_75,
  },
  roundedView: {
    borderRadius: BORDER_RADIUS,
    paddingHorizontal: theme.padding.x1,
  },
})
