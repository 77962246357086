import { CatalogFavoritesItem } from '../../api/Favorites/types'
import { navigate } from '../navigation'
import { Routes } from '../../navigation/Routes'
import { storeManager } from '../../lib/storeManager'
import { FavoritesUsersActions } from '../../redux/favoritesUser/actions'

const processThematic = (item: CatalogFavoritesItem) => {
  navigate(Routes.FavoriesSubThematic, { id: item.id, title: item.title })
}

const processDefault = (item: CatalogFavoritesItem) => {
  storeManager.store.dispatch(FavoritesUsersActions.addFavoritesUser(item.id))
}

const processMultipleChoice = (item: CatalogFavoritesItem) => {
  navigate(Routes.FavoriesMultipleChoice, { id: item.id, title: item.title })
}

export const processFavoritesCatalog = (item: CatalogFavoritesItem) => {
  switch (item.type) {
    case 'thematic':
      processThematic(item)
      break
    case 'default':
      processDefault(item)
      break
    case 'intermediate':
      processMultipleChoice(item)
      break
    default:
  }
}
