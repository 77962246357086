import { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { Html } from '../../components/Html'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'

export const CGU: FunctionComponent<StackScreenProps<ActionViewParamsType, 'CGU'>> = ({ route }) => {
  const title = route.params.titre
  const text = route.params.libelle

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <ScrollView>{text ? <Html content={text as string} /> : <Text style={styles.label}>{i18n.t('CGU.noCGU')}</Text>}</ScrollView>
    </Page>
  )
}

const styles = StyleSheet.create({
  label: {
    textAlign: 'center',
    marginTop: 20,
  },
})
