import { Linking } from 'react-native'
import { getTrackingStatus, requestTrackingPermission } from 'react-native-tracking-transparency'
import { trackEvent } from '../matomo/matomo.service'

export const openLink = async (url: string) => {
  const schemes = ['http://', 'https://']
  const hasScheme = url && schemes.some(scheme => url.startsWith(scheme))
  if (!hasScheme) {
    return
  }

  trackEvent({
    category: 'Lien',
    actionName: "Ouverture d'un lien",
    eventName: 'Lien externe',
    value: url,
  })

  try {
    const trackingStatus = await getTrackingStatus()
    if (trackingStatus === 'not-determined') {
      await requestTrackingPermission()
    }

    Linking.openURL(url)
  } catch (error) {
    console.error(error)
  }
}
