import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { DisplayImageText, DisplaySubText } from '..'
import { TaskReportListItemResult } from '../../../api/TaskReport/types'
import { intervalForHumans } from '../../../services/dateFormatter'
import { theme } from '../../../theme'
import { ReportDate } from '../../TaskReportItem/components'

interface ReportStatusProps {
  task: TaskReportListItemResult | null
}

export const DisplayTextInfos: FunctionComponent<ReportStatusProps> = ({ task }) => {
  if (!task) return null
  return (
    <View style={styles.container}>
      <Text style={styles.title} numberOfLines={1}>
        {task.title}
      </Text>

      <Text numberOfLines={1}>{task.description}</Text>
      <View style={[styles.children]}>
        <DisplayImageText state={task.state} />
        <DisplaySubText text={null} />
      </View>
      <View style={[styles.children]}>
        <DisplaySubText text={intervalForHumans(task.date, true)} />
        <ReportDate date={null} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.padding.x1,
  },
  title: { fontWeight: 'bold' },
  children: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.padding.x05,
  },
})
