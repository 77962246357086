import _ from 'lodash'
import moment from 'moment'
import { Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { LayoutChangeEvent, StyleSheet } from 'react-native'
import { BarChart } from 'react-native-chart-kit'
import { ChartData } from 'react-native-chart-kit/dist/HelperTypes'
import { useSelector } from 'react-redux'
import {
  ReferencielLandFillBucketDay,
  ReferencielLandFillBucketDays,
  ReferencielLandFillBucketHours,
  ReferencielLandFillBucketWeek,
  ReferentielInfoResult,
} from '../../../api/Referentiels/types'
import { i18n } from '../../../lib/i18n'
import { RootState } from '../../../redux/reducers'
import { referentielLandfillSelector } from '../../../redux/referentiels/selector'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { Card } from '../../Card'

type LandfillsTypes = {
  code: ReferentielInfoResult
}

type DaysHours = {
  key: number
  value: string
}

const ReferentielLandfillsDay: FunctionComponent<LandfillsTypes> = ({ code }) => {
  if (!code) return null

  const landfills = useSelector((state: RootState) => referentielLandfillSelector(state))
  const themeSelect = useSelector(themeSelector)
  const [width, setWidth] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<ChartData>({ labels: [], datasets: [] })
  const [buckets, setBuckets] = useState([])

  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue

  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
    setWidth(layout.width - 30)
  }

  const chartConfig = {
    backgroundGradientFrom: theme.colors.white,
    backgroundGradientTo: theme.colors.white,
    fillShadowGradientOpacity: 1,
    fillShadowGradientToOpacity: 1,
    barPercentage: 0.5,
    color: () => color,
    labelColor: () => theme.colors.black,
  }

  const orderDayHours: Array<DaysHours> = [
    {
      key: 1,
      value: 'Lundi',
    },
    {
      key: 2,
      value: 'Mardi',
    },
    {
      key: 3,
      value: 'Mercredi',
    },
    {
      key: 4,
      value: 'Jeudi',
    },
    {
      key: 5,
      value: 'Vendredi',
    },
    {
      key: 6,
      value: 'Samedi',
    },
    {
      key: 7,
      value: 'Samedi',
    },
  ]

  useEffect(() => {
    if (!landfills || !landfills.days) setIsLoading(true)
    else {
      const daysBucket = _.find(landfills.days.aggregations.decheteries.buckets, ['key', code.value]) as ReferencielLandFillBucketDay

      const currentDay = _.find(orderDayHours, { key: moment().isoWeekday() }) as DaysHours

      const currentHours = _.find(daysBucket.days.buckets, {
        key: currentDay.value,
      }) as ReferencielLandFillBucketHours

      const currentHoursFiltered = _.filter(currentHours.hours.buckets, bucket => bucket.key >= 9 && bucket.key <= 19)

      const hoursLegends = _.map(currentHoursFiltered, ({ key }) => moment(key, 'H').format('HH'))

      const hoursValues: Array<number> = _.map(currentHoursFiltered, (bucket: ReferencielLandFillBucketDays) =>
        bucket.isOpen.value === 1 ? bucket.avgEntree.value : 0,
      ) as unknown as Array<number>

      setBuckets(currentHoursFiltered as [])
      setData({
        labels: hoursLegends,
        datasets: [{ data: hoursValues }],
      })
      setIsLoading(false)
    }
  }, [landfills])

  const displayLoading = () => <Spinner color={color} size="small" />

  const displayGraph = () => (
    <View>
      <BarChart
        style={styles.chart}
        data={data as ChartData}
        width={width}
        height={160}
        yAxisLabel=""
        yAxisSuffix=""
        fromZero
        showValuesOnTopOfBars={false}
        showBarTops={false}
        withInnerLines={false}
        withHorizontalLabels={false}
        chartConfig={chartConfig}
      />
      <View style={styles.chartClosed}>
        {_.map(buckets || [], (bucket: ReferencielLandFillBucketWeek, index) =>
          bucket.isOpen.value === 0 ? (
            <View key={index} style={styles.closed}>
              <Text numberOfLines={1} style={styles.closedText}>
                {i18n.t('referentiel.closed')}
              </Text>
            </View>
          ) : (
            <View key={index} style={styles.closed} />
          ),
        )}
      </View>
    </View>
  )

  const renderContent = () => (data && buckets && !isLoading ? displayGraph() : displayLoading())

  return (
    <Card spacing onLayout={onLayout} style={[styles.chartContainer, isLoading ? styles.spinner : null]}>
      <Text style={styles.title}>{i18n.t('referentiel.today')}</Text>
      {renderContent()}
    </Card>
  )
}

const styles = StyleSheet.create({
  title: {
    width: '100%',
  },
  chartContainer: {
    padding: theme.padding.x1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  spinner: {
    alignItems: 'center',
  },
  chart: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  chartClosed: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: -6,
    bottom: 23,
    flexDirection: 'row',
    paddingLeft: 5,
  },
  closed: {
    flex: 1,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closedText: {
    transform: [{ rotate: '-90deg' }],
    width: 50,
    marginBottom: 16,
    marginRight: 10,
  },
})

export { ReferentielLandfillsDay }
