import { View } from 'native-base'
import React, { PropsWithChildren } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { MansoryItemResult } from '../../api/Favorites/types'
import { MansoryItem } from './components/MasonryItem.component'
import { MasonryLastItem } from './components/MasonryLastItem.component'

export type columnJustify = 'center' | 'flex-start'

interface MasonryListProps<T extends MansoryItemResult> {
  columns: number
  columnHeight?: number
  columnJustify?: columnJustify
  data: T[]
  renderItem: (item: T, width: number | string, height: number) => React.ReactNode
  renderLastItem?: () => React.ReactNode
}

export function MasonryList<T extends MansoryItemResult>({ columns, columnHeight, columnJustify, data, renderItem, renderLastItem }: PropsWithChildren<MasonryListProps<T>>) {
  const styles = mansoryStyles(columnJustify)
  return (
    <ScrollView>
      <View style={styles.container}>
        {data.map(item => (
          <MansoryItem columnSize={columns} item={item} renderItem={renderItem} key={item.id} columnHeight={columnHeight} />
        ))}
        {renderLastItem && <MasonryLastItem columnSize={columns} renderLastItem={renderLastItem} columnHeight={columnHeight} />}
      </View>
    </ScrollView>
  )
}

const mansoryStyles = (justifyContent?: columnJustify) =>
  /* eslint react-native/no-unused-styles: 0 */
  StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: justifyContent || 'center',
    },
  })
