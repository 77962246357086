import { ReferencielLandFill, ReferentielItemResult, ReferentielListResult } from '../../api/Referentiels/types'

export type Landfill = {
  week: ReferencielLandFill
  days: ReferencielLandFill
}

export type ReferentielsState = {
  items: {
    [key: number]: ReferentielItemResult
  }
  pages: {
    [key: string]: ReferentielListResult
  }
  landfills: Landfill | undefined
}

export enum ReferentielsActionTypes {
  LOAD_REFERENTIEL_ITEM = 'LOAD_REFERENTIEL_ITEM',
  STORE_REFERENTIEL_ITEM = 'STORE_REFERENTIEL_ITEM',
  LOAD_REFERENTIEL_LIST = 'LOAD_REFERENTIEL_LIST',
  STORE_REFERENTIEL_LIST = 'STORE_REFERENTIEL_LIST',
  STORE_CLEAR_REFERENTIEL_LIST = 'STORE_CLEAR_REFERENTIEL_LIST',
  REFRESH_REFERENTIEL_LIST = 'REFRESH_REFERENTIEL_LIST',
  LOAD_REFERENTIEL_LANDFILLS = 'LOAD_REFERENTIEL_LANDFILLS',
  STORE_REFERENTIEL_LANDFILLS = 'STORE_REFERENTIEL_LANDFILLS',
}
