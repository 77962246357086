import { Text, View } from 'native-base'
import React, { FC, useRef, useState } from 'react'
import { StyleSheet, TextInput, TouchableOpacity } from 'react-native'
import { theme } from '../../theme'

const CODE_LENGTH = 6

interface CodeInputProps {
  currentCode: string
  setCurrentCode: (code: string) => void
}

export const OTPInput: FC<CodeInputProps> = ({ currentCode, setCurrentCode }) => {
  const [containerIsFocused, setContainerIsFocused] = useState(false)

  const codeDigitsArray = new Array(CODE_LENGTH).fill(undefined)

  const ref = useRef<TextInput>(null)

  const handleOnPress = () => {
    setContainerIsFocused(true)
    if (ref.current) {
      ref.current.focus()
    }
  }

  const handleOnBlur = () => {
    setContainerIsFocused(false)
  }

  const handleTextChange = (code: string) => {
    setCurrentCode(code)
  }

  const toDigitInput = (_value: number, idx: number) => {
    const emptyInputChar = ''
    const digit = currentCode[idx] || emptyInputChar

    const isCurrentDigit = idx === currentCode.length
    const isLastDigit = idx === CODE_LENGTH - 1
    const isCodeFull = currentCode.length === CODE_LENGTH

    const isFocused = isCurrentDigit || (isLastDigit && isCodeFull)

    const containerStyle = containerIsFocused && isFocused ? { ...style.inputContainer, ...style.inputContainerFocused } : style.inputContainer

    return (
      <View key={idx} style={containerStyle}>
        {digit ? (
          <Text style={style.inputText} pt={4}>
            {digit}
          </Text>
        ) : (
          <Text style={style.inputPlaceHolderText} pt={4}>
            0
          </Text>
        )}
      </View>
    )
  }

  return (
    <View style={style.container}>
      <TouchableOpacity style={style.inputsContainer} activeOpacity={1} onPressIn={handleOnPress}>
        {codeDigitsArray.map(toDigitInput)}
      </TouchableOpacity>
      <TextInput
        ref={ref}
        value={currentCode}
        onChangeText={handleTextChange}
        onSubmitEditing={handleOnBlur}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        maxLength={CODE_LENGTH}
        pointerEvents="none"
        style={style.hiddenCodeInput}
      />
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: theme.padding.x3,
  },
  inputsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: 50,
    height: 50,
    borderColor: theme.colors.silver,
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainerFocused: {
    borderColor: theme.colors.blue,
  },
  inputText: {
    fontSize: 36,

    color: theme.colors.blue,
  },
  inputPlaceHolderText: {
    fontSize: 36,
    color: theme.colors.silver,
  },
  hiddenCodeInput: {
    zIndex: -1,
    position: 'absolute',
    opacity: 0,
  },
})
