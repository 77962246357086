import { ScrollView, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { WeeklySchedulesItemResult } from '../../api/WeeklySchedules/types'
import { WeekItem } from './components/WeekItem.component'
import { WeekItemLegend } from './components/WeekItemLegend.component'

interface WeeklyScheduleItemProps {
  item: WeeklySchedulesItemResult
}

export const WeeklyScheduleItem: FunctionComponent<WeeklyScheduleItemProps> = ({ item }) => {
  const safeArea = useSafeAreaInsets()
  return (
    <ScrollView style={styles.content} mb={safeArea.bottom} showsVerticalScrollIndicator={false}>
      <Text style={styles.title}>{item.libelle}</Text>
      <View px={1}>
        <WeekItem item={item} />
      </View>
      <WeekItemLegend referentiels={item.referentiels} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
})
