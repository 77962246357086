import { Reducer } from 'redux'
import { TideActionObjectTypes } from './actions'
import { TideActionTypes, TideState } from './types'

export const initialTideState: TideState = {
  tide: undefined,
}

export const TideReducer: Reducer<TideState, TideActionObjectTypes> = (state = initialTideState, action) => {
  switch (action.type) {
    case TideActionTypes.STORE_TIDE_DETAILS:
      return {
        ...state,
        tide: action.payload.tide,
      }

    default:
      return state
  }
}
