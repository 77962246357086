import React, { PureComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { FormDeco } from '../../api/Form/types'
import { isNullOrUndefined } from '../../services/textUtil'
import { Html } from '../Html'
import { Label } from '../NativeBaseLegacy'
import { FormElementProps } from './types'

const IMAGE_HEIGHT = 200

class LabelField extends PureComponent<FormElementProps<FormDeco>> {
  public render() {
    const { field } = this.props
    return (
      <>
        {!isNullOrUndefined(field.libelle) && <Label>{field.libelle}</Label>}
        {!isNullOrUndefined(field.image) && <FastImage style={styles.image} source={{ uri: field.image as string }} resizeMode={FastImage.resizeMode.contain} />}
        {!isNullOrUndefined(field.content) && <Html content={field.content as string} />}
      </>
    )
  }
}

const styles = StyleSheet.create({
  image: {
    height: IMAGE_HEIGHT,
    width: '100%',
  },
})

export { LabelField }
