export interface AuthenticationState {
  credentials?: {
    uuid: string
    password: string
  }
  apiToken?: {
    accessToken: string
    tokenType: string
    refreshToken: string
  }
  set2fa: boolean
  isConnected: boolean
}

export enum AuthenticationActionTypes {
  INIT_AUTHENTICATION = 'INIT_AUTHENTICATION',
  STORE_USER_CREDENTIALS = 'STORE_USER_CREDENTIALS',
  SIGN_IN_USER = 'SIGN_IN_USER',
  STORE_API_TOKEN = 'STORE_API_TOKEN',
  STORE_TOKEN_SET_2FA = 'STORE_TOKEN_SET_2FA',
  LOGOUT = 'LOGOUT',
  IS_CONNECTED = 'IS_CONNECTED',
}
