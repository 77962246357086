export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '21fd8a62-87df-477e-b2ec-c5cf52899dc1',
  ENV: 'boulogneBillancourt',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: true,
  },
  matomoUrlBase: 'https://analytics.tools.citopia.fr',
  matomoSiteId: 5,
}
