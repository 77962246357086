import { isEmpty } from '@martin_hotell/rex-tils'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { CantineEliorNormeResult, CantineEliorPrestationResult } from '../../../api/CantineElior/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { Card } from '../../Card'

export interface CantineNormesProps {
  presta: CantineEliorPrestationResult
}

export const CantineNormes: FunctionComponent<CantineNormesProps> = ({ presta }) => {
  const normes: CantineEliorNormeResult[] = []

  presta.FamillePlats.map(famille =>
    famille.Plats.map(plat =>
      // eslint-disable-next-line array-callback-return
      plat.NormeQualites.map(norme => {
        if (isEmpty(normes.filter(n => n.Code === norme.Code))) {
          normes.push(norme)
        }
      }),
    ),
  )

  if (isEmpty(normes)) {
    return null
  }

  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  const cardItems = normes.map(object => (
    <View key={object.Code}>
      <View style={styles.norme}>
        <Text style={{ color: colorText }}>{object.Code}</Text>
        <Text> : {object.Libelle}</Text>
      </View>
    </View>
  ))
  return (
    <View style={styles.content}>
      <Text style={[styles.title, { color: colorText }]}>Légende</Text>
      <Card style={styles.card}>{cardItems}</Card>
    </View>
  )
}

const styles = StyleSheet.create({
  content: { paddingVertical: theme.padding.x1 },
  title: { fontSize: theme.textSize.x2 + theme.textSize.x1 / 3, marginBottom: theme.padding.x1 },
  norme: { flexDirection: 'row' },
  card: { padding: theme.padding.x2 },
})
