import { Reducer } from 'redux'

import { TaskReportActionTypes, TaskReportState } from './types'
import { ActionTaskReportObjectTypes } from './action'

const initialTaskReportState: TaskReportState = {
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
  items: {},
  grcIsAuth: {
    grcAccount: false,
    grcAccountError: null,
    token: undefined,
  },
}

export const taskReportReducer: Reducer<TaskReportState, ActionTaskReportObjectTypes> = (state = initialTaskReportState, action) => {
  switch (action.type) {
    case TaskReportActionTypes.STORE_TASKREPORT:
      return {
        ...state,
        paging: {
          last: action.payload.taskReportResult.last,
          page: action.payload.taskReportResult.page,
          size: action.payload.taskReportResult.size,
          totalElements: action.payload.taskReportResult.totalElements,
          totalPages: action.payload.taskReportResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.taskReportResult.content],
        },
      }
    case TaskReportActionTypes.STORE_CLEAR_TASK_REPORT:
      return {
        ...state,
        paging: {
          ...action.payload.taskReportResult,
        },
      }
    case TaskReportActionTypes.STORE_TASKREPORT_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.item,
        },
      }
    case TaskReportActionTypes.STORE_IS_AUTHORIZED_GRC:
      return {
        ...state,
        grcIsAuth: {
          ...action.payload.grcAccountAuthorization,
        },
      }
    default:
      return state
  }
}
