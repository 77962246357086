import React, { FunctionComponent } from 'react'
import { Text } from 'native-base'
import { StyleSheet, TouchableOpacity } from 'react-native'
import FastImage from 'react-native-fast-image'
import { IntermediatePageItemResult } from '../../api/GridPage/types'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'

const ICON_SIZE = 50

interface PageItemProps {
  item: IntermediatePageItemResult
}

const IntermediatePageItem: FunctionComponent<PageItemProps> = ({ item }) => (
  <TouchableOpacity onPress={item.action ? getItemAction(item.action) : undefined} style={styles.container}>
    {item.icone && <FastImage source={{ uri: item.icone }} style={styles.image} resizeMode={FastImage.resizeMode.contain} />}
    <Text style={styles.text}>{item.titre}</Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    margin: theme.padding.x1,
    padding: theme.padding.x1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
  },
  image: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  text: { textAlign: 'center', color: theme.colors.black },
})

export { IntermediatePageItem }
