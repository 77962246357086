import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from 'redux-saga/effects'
import { CantineApi } from '../../api/Cantine'
import { CantineItemResult } from '../../api/Cantine/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { CantineActionObjectTypes, CantineActions } from './action'
import { CantineActionTypes } from './types'

function* loadCantineSaga(action: ActionsOfType<CantineActionObjectTypes, CantineActionTypes.LOAD_CANTINE>) {
  try {
    const cantineResult: CantineItemResult = yield call(CantineApi.loadCantine, action.payload.id)
    yield put(CantineActions.storeCantine(action.payload.id, cantineResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadCantineWatcher() {
  yield takeEvery(CantineActionTypes.LOAD_CANTINE, addLoader(loadCantineSaga, LoaderName.LOAD_CANTINE))
}
