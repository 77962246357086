import { Icon, Pressable, Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ActivityIndicator, Image, ImageSourcePropType, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { iconFont } from '../../services/Icon'
import { IconType } from '../../services/weather'
import { theme } from '../../theme'

interface Props {
  onPress: () => void
  iconName?: string
  iconType?: IconType
  iconSize?: number
  image?: ImageSourcePropType
  label: string
  style?: ViewStyle
  textStyle?: TextStyle
  loading?: boolean
  disabled?: boolean
}

export const Button: FunctionComponent<Props> = ({ onPress, label, iconName, iconType, image, style, textStyle, loading, disabled }) => (
  <Pressable py={3} onPress={onPress} style={[styles.button, style, disabled && styles.disabled]} isDisabled={disabled}>
    {!!image && <Image style={styles.buttonImage} source={image} resizeMode="contain" />}
    {!!iconName && <Icon name={iconName} style={styles.buttonIcon} as={iconFont(iconType || '')} color={theme.colors.white} size={6} />}
    {loading ? <ActivityIndicator color={theme.colors.white} /> : <Text style={[styles.label, textStyle]}>{label}</Text>}
  </Pressable>
)

const styles = StyleSheet.create({
  button: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    position: 'absolute',
    fontSize: theme.iconSize.x1,
    marginLeft: theme.padding.x2,
    left: 0,
  },
  buttonImage: {
    position: 'absolute',
    left: 0,
    width: 25,
    height: 25,
    marginLeft: theme.padding.x2,
  },
  label: {
    color: theme.colors.white,
    fontWeight: 'bold',
  },
  disabled: {
    opacity: 0.5,
  },
})
