import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { CloseButton } from '../../components/CloseButton'
import { Content } from '../../components/NativeBaseLegacy'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { theme } from '../../theme'

const LOGO_HEIGHT = 76

export const MyAccountWasDeleted: FunctionComponent = () => {
  const menu = useSelector(menuSelector)

  const logoUri = menu && menu.header && menu.header.image
  const handleAccountDeletion = () => {
    navigate(Routes.HomeTabs)
  }
  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
          <CloseButton />
          <Content style={styles.content}>
            <Text style={styles.subtitle}>{i18n.t('myAccount.myAccountDeletion.subtitle')}</Text>
            <Text style={styles.title}>{i18n.t('myAccount.myAccountDeletion.title')}</Text>
            <View style={styles.informations}>
              <Text style={styles.informationTitle}>{i18n.t('myAccount.myAccountWasDeleted.parameters.title')}</Text>
              <Text style={styles.informationText}>{i18n.t('myAccount.myAccountWasDeleted.parameters.informations')}</Text>
              <Text style={styles.warning}>{i18n.t('myAccount.myAccountWasDeleted.parameters.message')}</Text>
            </View>
            <Button onPress={handleAccountDeletion} iconName="home" iconType="FontAwesome" label={i18n.t('myAccount.myAccountWasDeleted.confirm')} style={styles.button} />
          </Content>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  content: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: theme.iconSize.x2,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.colors.darkGrayishBlue,
    marginVertical: theme.padding.x1,
    fontSize: theme.iconSize.x1,
    fontWeight: 'bold',
  },
  informations: {
    borderColor: theme.colors.deepSkyBlue,
    borderWidth: 1,
    backgroundColor: theme.colors.lightSkyBlue,
    padding: theme.padding.x2,
    marginBottom: theme.padding.x4,
    marginTop: theme.padding.x4,
  },
  informationTitle: {
    color: theme.colors.deepSkyBlue,
    fontWeight: 'bold',
    fontSize: theme.iconSize.x1,
    paddingBottom: theme.padding.x3,
  },
  informationText: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.deepSkyBlue,
    paddingBottom: theme.padding.x1,
  },
  warning: {
    fontSize: theme.iconSize.x1,
    color: theme.colors.deepSkyBlue,
  },
  button: {
    backgroundColor: theme.colors.deepSkyBlue,
    borderRadius: 0,
  },
})
