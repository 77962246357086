/* eslint-disable prettier/prettier */
// import PushNotificationIOS from '@react-native-community/push-notification-ios'
import { useRoute } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import _, { isEmpty } from 'lodash'
import { Button, Icon } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { FlexStyle, Platform, SafeAreaView, StyleSheet, ViewStyle } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationUserItem } from '../../api/Notification/types'
import { FlatListPaging } from '../../components/FlatListPaging'
import { NotificationItem } from '../../components/NotificationItem/NotificationItem'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, Routes, RoutesHome } from '../../navigation/Routes'
import { homeFootersSelector } from '../../redux/home/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { NotificationActions } from '../../redux/notification/action'
import { lastPageSelector, notificationsSelector } from '../../redux/notification/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'

interface Style {
  content: FlexStyle
  itemStyle: ViewStyle
  iconStyle: ViewStyle
}

export const Notifications: FunctionComponent<
  StackScreenProps<ActionViewParamsType, 'Notifications'>
> = (props, navigation) => {
  const dispatch = useDispatch()
  const { params } = useRoute() as { params: { id?: string } }
  const notifications = useSelector(notificationsSelector)
  const homeFooters = useSelector(homeFootersSelector)

  let timer: ReturnType<typeof setTimeout>

  useEffect(() => {
    const target = _.find(
      notifications,
      ({ notification }: NotificationUserItem) => notification.id === +(params.id || -1),
    )
    timer = setTimeout(() => goToPage(target), 400)
    return () => clearTimeout(timer)
  }, [notifications])

  useEffect(() => {
    dispatch(NotificationActions.loadNotifications(true))
    dispatch(NotificationActions.clearHasNewNotification())
    if (Platform.OS === 'ios') {
      // PushNotificationIOS.removeAllDeliveredNotifications()
      // PushNotificationIOS.setApplicationIconBadgeNumber(0)
    }
  }, [])

  const isLoadingNotifications = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.LOAD_NOTIFICATIONS),
  )

  const isLastPage = useSelector(lastPageSelector)

  const goToPage = (target: NotificationUserItem | undefined) => {
    if (
      target &&
      params.id &&
      +(params.id || -1) === target.notification.id &&
      target.notification.action
    ) {
      getItemAction(target.notification.action)()
    }
  }

  const onRefresh = () => {
    dispatch(NotificationActions.refreshNotifications())
  }

  const onEndReached = () => {
    canLoadMore() ? dispatch(NotificationActions.loadNotifications(false)) : null
  }

  const isRefreshing = useSelector((state: RootState) =>
    isLoadingSelector(state, LoaderName.REFRESH_NOTIFICATIONS),
  )

  const isLoadingPage = () => isLoadingNotifications && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoadingNotifications && isLoadingMore()

  function RenderSettings() {
    const theme = useSelector(themeSelector)
    const haveFooterCitysEvents =
      !isEmpty(homeFooters) &&
      homeFooters.filter((footer) => footer.route === RoutesHome.CitysEvents)

    if (haveFooterCitysEvents) {
      return null
    }
    return (
      <Button
        variant="ghost"
        onPress={() => props.navigation.navigate({ name: Routes.NotificationSettings, params: {} })}
      >
        <Icon as={Ionicons} name="settings" style={styles.iconStyle} color={theme?.titleFontColor} size={6} />
      </Button>
    )
  }

  return (
    <Page
      title={i18n.t('notifications.notifications')}
      left={<LeftButtonBack />}
      right={<RenderSettings />}
    >
      <SafeAreaView style={styles.content}>
        <FlatListPaging
          data={notifications}
          renderItem={({ item }) => (
            <NotificationItem {...props} item={item} navigation={navigation} />
          )}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingPage={isLoadingPage()}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.id.toString()}
          libelleEmptyList={i18n.t('notifications.messageEmpty')}
          itemStyle={styles.itemStyle}
        />
      </SafeAreaView>
    </Page>
  )
}

const styles = StyleSheet.create<Style>({
  content: {
    flex: 1,
    marginTop: theme.padding.x2,
  },
  itemStyle: {
    paddingHorizontal: theme.padding.x2,
  },
  iconStyle: {
    width: 30,
  },
})
