import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { ExternalLinksResult } from '../../api/ExternalLinks/types'
import { ExternalLinksActionTypes } from './types'

export const ExternalLinksActions = {
  loadExternalLinks: (clear: boolean, idPage: number, type?: string) => createAction(ExternalLinksActionTypes.LOAD_EXTERNAL_LINKS, { clear, type, idPage }),
  storeExternalLinks: (result: ExternalLinksResult, idPage: number) => createAction(ExternalLinksActionTypes.STORE_EXTERNAL_LINKS, { result, idPage }),
  storeClearExternalLinks: (result: ExternalLinksResult, idPage: number) => createAction(ExternalLinksActionTypes.STORE_CLEAR_EXTERNAL_LINKS, { result, idPage }),
  refreshExternalLinks: (idPage: number, type?: string) => createAction(ExternalLinksActionTypes.REFRESH_EXTERNAL_LINKS, { idPage, type }),
}

export type ActionExternalLinksObjectTypes = ActionsUnion<typeof ExternalLinksActions>
