import { Icon, Input, View } from 'native-base'
import React, { useState } from 'react'
import { TextInputProps, TextStyle, ViewStyle } from 'react-native'
import { iconFont } from '../../services/Icon'
import { removeEndingSpace } from '../../services/textUtil'
import { theme } from '../../theme'
import { Item, Label } from '../NativeBaseLegacy'

interface DisabledProps extends TextInputProps {
  isDisabled: true
  onValueChanged?: (value: string) => void
}

interface EnabledProps extends TextInputProps {
  isDisabled?: false
  onValueChanged: (value: string) => void
}

type Props = (DisabledProps | EnabledProps) & {
  label: string
  styleLabel?: TextStyle
  placeholder: string
  value: string
  style: ViewStyle
  isMail?: boolean
  secureTextEntry?: boolean
}

export const TextInput: React.FC<Props> = ({
  label,
  styleLabel,
  placeholder,
  value,
  onValueChanged,
  style,
  isMail = false,
  isDisabled,
  secureTextEntry,
  ...otherTextInputProps
}) => {
  const [isSecureTextEntry, setIsSecureTextEntry] = useState(secureTextEntry)
  const input = isMail ? removeEndingSpace(value) : value

  const handleChangeText = (text: string) => {
    if (onValueChanged) {
      onValueChanged(text)
    }
  }

  const displayPassword = () => {
    setIsSecureTextEntry(!isSecureTextEntry)
  }

  return (
    <Item style={style}>
      <Label style={styleLabel || styles.label}>{label}</Label>
      <View style={styles.inputWrapper}>
        <Input
          isDisabled={isDisabled}
          secureTextEntry={isSecureTextEntry}
          pt="4"
          placeholder={placeholder}
          variant="underlined"
          placeholderTextColor={theme.colors.silver}
          value={input}
          onChangeText={handleChangeText}
          style={styles.input}
          {...otherTextInputProps}
        />
        {secureTextEntry && (
          <Icon name={isSecureTextEntry ? 'eye-outline' : 'eye-off-outline'} color={theme.colors.black} as={iconFont('')} size={6} style={styles.icon} onPress={displayPassword} />
        )}
      </View>
    </Item>
  )
}

interface Style {
  label: TextStyle
  input: TextStyle
  inputWrapper: ViewStyle
  icon: ViewStyle
}

const styles: Style = {
  label: {
    color: theme.colors.black,
  },
  input: {
    fontSize: 16,
    paddingRight: 40,
  },
  inputWrapper: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: 0,
    bottom: 8,
  },
}
