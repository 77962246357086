import React, { FunctionComponent } from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { Page, LeftButtonBack } from '../../components/Page'
import { CartographyRealTime as RealTimeMap } from '../../components/CartographyRealTime'
import { ActionViewParamsType } from '../../navigation/Routes'

export const CartographyRealTime: FunctionComponent<StackScreenProps<ActionViewParamsType, 'CartographyRealTime'>> = ({ route }) => {
  const title = route.params.titre as string
  const id = route.params.id as number
  return (
    <Page left={<LeftButtonBack />} title={title}>
      <RealTimeMap id={id} />
    </Page>
  )
}
