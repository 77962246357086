import { CartographyBeaconResult } from '../../api/CartographyBeacons/type'

export type CartographyBeaconsState = {
  [id: string]: CartographyBeaconResult | null
}

export enum CartographyBeaconsActionTypes {
  LOAD_CARTOGRAPHY_BEACONS = 'LOAD_CARTOGRAPHY_BEACONS',
  STORE_CARTOGRAPHY_BEACONS = 'STORE_CARTOGRAPHY_BEACONS',
}
