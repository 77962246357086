import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from 'redux-saga/effects'
import { CartographyZoneApi } from '../../api/CartographyZoneApi.ts'
import { CartographyZoneResult } from '../../api/CartographyZoneApi.ts/type'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { CartographyZonesActionObjectTypes, CartographyZonesActions } from './actions'
import { CartographyZonesActionTypes } from './types'

function* loadCartographyZonesSaga(action: ActionsOfType<CartographyZonesActionObjectTypes, CartographyZonesActionTypes.LOAD_CARTOGRAPHY_ZONES>) {
  try {
    const cartographyZonesResult: CartographyZoneResult = yield call(CartographyZoneApi.loadZones, action.payload.zoneId)
    yield put(CartographyZonesActions.storeCartographyZones(cartographyZonesResult.id.toString(), cartographyZonesResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadCartographyZonesWatcher() {
  yield takeEvery(CartographyZonesActionTypes.LOAD_CARTOGRAPHY_ZONES, addLoader(loadCartographyZonesSaga, LoaderName.LOAD_CARTOGRAPHY_ZONES))
}
