import { CitysResult, CityItemResult } from '../../api/City/types'
import { ReferentielFilters } from '../../api/types'

export type CityState = {
  paging: CitysResult
  selected: {
    [key: string]: CityItemResult
  }
  eventsConfig: ReferentielFilters | null
  townHall: number | null
  townHallsConfig: ReferentielFilters | null
  addingCity: number | null
}

export enum CityActionTypes {
  LOAD_CITYS = 'LOAD_CITYS',
  STORE_CITYS = 'STORE_CITYS',
  LOAD_CITYS_USER = 'LOAD_CITYS_USER',
  STORE_CITYS_USER = 'STORE_CITYS_USER',
  REFRESH_CITYS = 'REFRESH_CITYS',
  CLEAR_CITYS = 'CLEAR_CITYS',
  ADD_CITY = 'ADD_CITY',
  STORE_CITY = 'STORE_CITY',
  REMOVE_CITY = 'REMOVE_CITY',
  REMOVE_STORE_CITY = 'REMOVE_STORE_CITY',
  LOAD_CITYS_EVENTS_CONFIG = 'LOAD_CITYS_EVENTS_CONFIG',
  STORE_CITYS_EVENTS_CONFIG = 'STORE_CITYS_EVENTS_CONFIG',
  LOAD_TOWN_HALLS_CONFIG = 'LOAD_TOWN_HALLS_CONFIG',
  STORE_TOWN_HALLS_CONFIG = 'STORE_TOWN_HALLS_CONFIG',
  LOAD_TOWN_HALL = 'LOAD_TOWN_HALL',
  STORE_TOWN_HALL = 'STORE_TOWN_HALL',
  STORE_ADDING_CITY = 'STORE_ADDING_CITY',
  UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS',
}
