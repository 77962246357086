import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { TideDetails } from '../../api/Tide/types'
import { TideActionTypes } from './types'

export const TideAction = {
  loadTideDetails: (id: number) => createAction(TideActionTypes.LOAD_TIDE_DETAILS, { id }),
  storeTideDetails: (tide: TideDetails | undefined) => createAction(TideActionTypes.STORE_TIDE_DETAILS, { tide }),
}

export type TideActionObjectTypes = ActionsUnion<typeof TideAction>
