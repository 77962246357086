import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { UrgentNumbersActionTypes } from './types'
import { UrgentNumbersResult } from '../../api/UrgentNumbers/types'

export const UrgentNumbersAction = {
  loadUrgentNumbers: (clear: boolean) => createAction(UrgentNumbersActionTypes.LOAD_URGENTNUMBERS, { clear }),
  storeUrgentNumbers: (numbersResult: UrgentNumbersResult) => createAction(UrgentNumbersActionTypes.STORE_URGENTNUMBERS, { numbersResult }),
  clearUrgentNumbersState: () => createAction(UrgentNumbersActionTypes.CLEAR_URGENTNUMBERS),
  refreshUrgentNumbers: () => createAction(UrgentNumbersActionTypes.REFRESH_URGENTNUMBERS),
}

export type ActionUrgentNumbersObjectTypes = ActionsUnion<typeof UrgentNumbersAction>
