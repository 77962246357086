import { Reducer } from 'redux'
import { ThemeState, LoadThemeActionTypes } from './types'
import { ThemeActionObjectTypes } from './actions'

const initialThemeState: ThemeState = null

export const themeReducer: Reducer<ThemeState, ThemeActionObjectTypes> = (state = initialThemeState, action) => {
  switch (action.type) {
    case LoadThemeActionTypes.STORE_THEME:
      return {
        ...state,
        ...action.payload.theme,
      }

    default:
      return state
  }
}
