export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '78345123-cf0a-4dd8-96f6-7e57ffae4458',
  ENV: 'neuillySurSeineStaging',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
  },
}
