import { PermissionsAndroid, Platform } from 'react-native'
import Geolocation from 'react-native-geolocation-service'
import { i18n } from '../../lib/i18n'

export const checkLocation = async () => {
  if (Platform.OS === 'ios') {
    let granted = false
    await Geolocation.requestAuthorization('whenInUse').then(permission => {
      if (permission === 'disabled' || permission === 'denied') {
        granted = false
      } else {
        granted = true
      }
    })
    return granted
  }

  if (Platform.OS === 'android') {
    const granted = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION)

    if (!granted) {
      try {
        const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, {
          title: i18n.t('permission.titleFineLocation'),
          message: i18n.t('permission.messageFineLocation'),
          buttonNegative: i18n.t('permission.cancel'),
          buttonPositive: i18n.t('permission.ok'),
        })
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          return true
        }
        return false
      } catch (err) {
        console.warn(err)
        return false
      }
    }
  }
  return true
}
