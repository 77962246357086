import { Text } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Latlng } from '../../api/types'
import { getItemAction } from '../../services/itemAction'
import { BubbleAction } from '../BubbleAction'
import { Card } from '../Card/Card.component'
import { Right } from '../NativeBaseLegacy'

const WIDTH_RIGHT = 70

interface CardAddressProps {
  address: string | null
  positionMap: Latlng | null
}

export const CardAddress: FunctionComponent<CardAddressProps> = ({ address, positionMap }) => {
  if (!address) {
    return null
  }

  if (positionMap) {
    const goToMap = getItemAction({
      type: 'map',
      label: address,
      ...positionMap,
    })

    return (
      <TouchableOpacity onPress={goToMap}>
        <Card spacing column>
          <Text style={styles.text}>{address}</Text>
          <Right style={styles.right}>
            <BubbleAction
              link={{
                icon: 'map-marker',
                iconTheme: 'FontAwesome',
              }}
              onPress={goToMap}
            />
          </Right>
        </Card>
      </TouchableOpacity>
    )
  }

  return (
    <Card spacing>
      <Text style={styles.text}>{address}</Text>
    </Card>
  )
}

const styles = StyleSheet.create({
  text: { flex: 1 },
  right: { width: WIDTH_RIGHT, textAlign: 'center' },
})
