import { ActionCityConfigParams, TaskReportNavigationParams, TaskReportRequestNavigationParams } from '../api/City/types'
import { ActionViewParams } from '../api/types'

export enum Routes {
  HomeTabs = 'HomeTabs',
  Login = 'Login',
  Notifications = 'Notifications',
  NotificationSettings = 'NotificationSettings',
  CheatCodes = 'CheatCodes',
  Gridpage = 'Gridpage',
  IntermediatePage = 'IntermediatePage',
  ReferentielList = 'ReferentielList',
  Referentiel = 'Referentiel',
  HorairesMarees = 'HorairesMarees',
  AgendaList = 'AgendaList',
  Actualite = 'Actualite',
  Actualites = 'Actualites',
  Cartography = 'Cartographie',
  CGU = 'CGU',
  Cantine = 'Cantine',
  CantineElior = 'CantineElior',
  CartographieZone = 'CartographieZone',
  CartographieBeacons = 'CartographieBeacons',
  CartographyRealTime = 'realTimeMap',
  Cinema = 'Cinema',
  Formulaire = 'Formulaire',
  UrgentNumbers = 'UrgentNumbers',
  WeeklySchedules = 'WeeklySchedules',
  SignalementList = 'SignalementList',
  Signalement = 'Signalement',
  SignalementItem = 'SignalementItem',
  ExternalLinks = 'ExternalLinks',
  CinemaSeance = 'CinemaSceance',
  GrcYpok = 'GrcYpok',
  RssFeed = 'RssFeed',
  MyCitys = 'MyCitys',
  Weather = 'Weather',
  AirQuality = 'AirQuality',
  TaskReportList = 'TaskReportList',
  TaskReportItem = 'TaskReportItem',
  TaskReportItemRequest = 'TaskReportItemRequest',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  SignOut = 'SignOut',
  MyAccount = 'MyAccount',
  MyAccountDeletion = 'MyAccountDeletion',
  MyAccountConfirmDeletion = 'MyAccountConfirmDeletion',
  MyAccountWasDeleted = 'MyAccountWasDeleted',
  ResetPassword = 'ResetPassword',
  ResetPasswordSendEmail = 'ResetPasswordSendEmail',
  ResetPasswordVerification = 'ResetPasswordVerification',
  PersonalInfo = 'PersonalInfo',
  ChangePassword = 'ChangePassword',
  EmailVerificationSent = 'EmailVerificationSent',
  EmailVerificationDone = 'EmailVerificationDone',
  FavoriesShop = 'FavoriesShop',
  FavoriesSubThematic = 'FavoriesSubThematic',
  FavoriesMultipleChoice = 'FavoriesMultipleChoice',
}

export enum RoutesHome {
  Home = 'HOME',
  Favorites = 'Favorites',
  CitysEvents = 'CitysEvents',
  MyTown = 'MyTown',
}

export enum RoutesCityAdd {
  cityAdd = 'cityAdd',
  cityAddConfig = 'cityAddConfig',
}
export enum RoutesCityConfig {
  cityEdit = 'cityEdit',
}

export enum RoutesPromotion {
  Promotion = 'Promotion',
}

export const modalsRoute: string[] = [RoutesCityAdd.cityAdd, RoutesPromotion.Promotion]

export type ActionViewParamsType = {
  // Routes
  App: ActionViewParams
  HomeTabs: ActionViewParams
  Login: ActionViewParams
  Notifications: ActionViewParams
  NotificationSettings: ActionViewParams
  CheatCodes: ActionViewParams
  Gridpage: ActionViewParams
  IntermediatePage: ActionViewParams
  ReferentielList: ActionViewParams
  Referentiel: ActionViewParams
  HorairesMarees: ActionViewParams
  AgendaList: ActionViewParams
  Actualite: ActionViewParams
  Actualites: ActionViewParams
  Cartography: ActionViewParams
  CGU: ActionViewParams
  Cantine: ActionViewParams
  CantineElior: ActionViewParams
  CartographieZone: ActionViewParams
  CartographieBeacons: ActionViewParams
  CartographyRealTime: ActionViewParams
  Cinema: ActionViewParams
  Formulaire: ActionViewParams
  UrgentNumbers: ActionViewParams
  WeeklySchedules: ActionViewParams
  SignalementList: ActionViewParams
  Signalement: ActionViewParams
  SignalementItem: ActionViewParams
  ExternalLinks: ActionViewParams
  CinemaSeance: ActionViewParams
  GrcYpok: ActionViewParams
  RssFeed: ActionViewParams
  MyCitys: ActionViewParams
  Weather: ActionViewParams
  AirQuality: ActionViewParams
  TaskReportList: ActionViewParams
  // RoutesHome
  Home: ActionViewParams
  Favorites: ActionViewParams
  CitysEvents: ActionViewParams
  MyTown: ActionViewParams
  // RoutesCityAdd
  cityAdd: ActionCityConfigParams
  cityAddConfig: ActionCityConfigParams
  // RoutesCityConfig
  cityEdit: ActionCityConfigParams
  // RoutesPromotion
  Promotion: ActionViewParams
  SignIn: ActionViewParams
  SignUp: ActionViewParams
  MyAccount: ActionViewParams
  MyAccountDeletion: ActionViewParams
  MyAccountConfirmDeletion: ActionViewParams
  MyAccountWasDeleted: ActionViewParams
  ResetPassword: ActionViewParams & { token: string; email: string; code: string; isDoubleAuthentication: boolean }
  ResetPasswordSendEmail: ActionViewParams
  ResetPasswordVerification: ActionViewParams & { token: string; email: string }
  PersonalInfo: ActionViewParams
  TaskReportItem: TaskReportNavigationParams
  TaskReportOtherErrors: ActionViewParams
  TaskReportItemRequest: TaskReportRequestNavigationParams
  EmailVerificationSent: ActionViewParams & { email?: string }
  EmailVerificationDone: ActionViewParams & { oobCode?: string }
  FavoriesShop: ActionViewParams & { onAdd: () => void }
  FavoriesSubThematic: ActionViewParams & { title: string; id: number }
  FavoriesMultipleChoice: ActionViewParams & { title: string; id: number }
}
