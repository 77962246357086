import React, { FunctionComponent, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { StackScreenProps } from '@react-navigation/stack'
import { View } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import { Dimensions, StyleSheet } from 'react-native'
import { Page, LeftButtonBack } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'
import { FlatListPaging } from '../../components/FlatListPaging'
import { theme } from '../../theme'
import { CatalogFavoritesItem } from '../../api/Favorites/types'
import { FavItemShop } from '../../components/FavItemShop'
import { favoritesSubThematicCatalogSelector } from '../../redux/favoritesCatalog/selector'
import { RootState } from '../../redux/reducers'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { FavoritesCatalogActions } from '../../redux/favoritesCatalog/actions'
import { processFavoritesCatalog } from '../../services/favoritesShop'
import { gridPageHomeSelector } from '../../redux/gridPage/selector'

const windowWidth = Dimensions.get('window').width - 16
const windowWidthWithoutBorder = Dimensions.get('window').width

export const FavoriesSubThematic: FunctionComponent<StackScreenProps<ActionViewParamsType, 'FavoriesSubThematic'>> = ({ route }) => {
  const title = route.params.title as string
  const id = route.params.id as number
  const favorites = useSelector((state: RootState) => favoritesSubThematicCatalogSelector(state, id))
  const isLoadingReferentiels = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_FAVORITES_SUB_THEMATIC_CATALOG))
  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_FAVORITES_SUB_THEMATIC_CATALOG))

  useEffect(() => {
    dispatch(FavoritesCatalogActions.loadFavoritesSubThematicCatalogs(id, true))
  }, [])

  const dispatch = useDispatch()

  const renderItem = (item: CatalogFavoritesItem) => (
    <View key={item.id} style={styles.favItemWrapper}>
      <FavItemShop key={item.id} item={item} onPress={() => processFavoritesCatalog(item)} />
    </View>
  )

  const getIsLastPage = (): boolean => {
    if (!favorites || typeof favorites.last === 'undefined') {
      return true
    }
    return favorites.last
  }

  const isLoadingMore = () => !getIsLastPage() && !isRefreshing

  const canLoadMore = () => !isLoadingReferentiels && isLoadingMore()

  const onLoadMore = () => {
    if (!canLoadMore()) {
      return
    }
    dispatch(FavoritesCatalogActions.loadFavoritesSubThematicCatalogs(id, false))
  }

  const onEndReached = () => {
    if (!favorites) {
      return
    }
    onLoadMore()
  }

  const getFavoritesSubThematicContent = (): CatalogFavoritesItem[] => {
    if (!favorites || !favorites.content) {
      return []
    }
    return favorites.content
  }

  const onRefresh = () => {
    dispatch(FavoritesCatalogActions.loadFavoritesSubThematicCatalogs(id, true))
  }

  const isLoadingPage = () => isEmpty(getFavoritesSubThematicContent()) && isLoadingReferentiels && !isRefreshing

  const gridPage = useSelector((state: RootState) => gridPageHomeSelector(state))

  const backgroundColor = (gridPage && gridPage.bg && gridPage.bg.color) || theme.colors.transparent

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <FlatListPaging
        data={getFavoritesSubThematicContent()}
        renderItem={({ item }) => renderItem(item)}
        onEndReached={onEndReached}
        onRefresh={onRefresh}
        isRefreshing={isRefreshing}
        isLoadingPage={isLoadingPage()}
        isLoadingMore={isLoadingMore}
        keyExtractor={item => item.id.toString()}
        flatListStyle={{ paddingHorizontal: theme.padding.x1, backgroundColor }}
        numColumns={3}
      />
    </Page>
  )
}

const styles = StyleSheet.create({
  favItemWrapper: {
    width: windowWidth / 3 - 6,
    marginRight: theme.padding.x1,
    height: windowWidthWithoutBorder / 3,
  },
})
