import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { CantineActionTypes } from './types'
import { CantineItemResult } from '../../api/Cantine/types'

export const CantineActions = {
  loadCantine: (id: number, clear: boolean) => createAction(CantineActionTypes.LOAD_CANTINE, { id, clear }),
  storeCantine: (id: number, cantineResult: CantineItemResult) => createAction(CantineActionTypes.STORE_CANTINE, { id, cantineResult }),
}

export type CantineActionObjectTypes = ActionsUnion<typeof CantineActions>
