import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { View } from 'native-base'
import { useSelector } from 'react-redux'
import FastImage from 'react-native-fast-image'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'

const IMAGE_SIZE = 25
const SPACE = 5
const SPACE_VERTICAL = 2
const BORDER_RADIUS = 15

interface WeekReferentielImageProps {
  image: string | null
}

export const WeekReferentielImage: FunctionComponent<WeekReferentielImageProps> = ({ image }) => {
  const themeSelect = useSelector(themeSelector)
  const color = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  if (image) {
    return (
      <View style={[styles.logoView, { backgroundColor: color }]}>
        <FastImage style={styles.logoImage} source={{ uri: image }} />
      </View>
    )
  }
  return <View style={[styles.logoView, { backgroundColor: color }]} />
}

const styles = StyleSheet.create({
  logoView: {
    marginHorizontal: SPACE,
    paddingHorizontal: SPACE,
    marginVertical: SPACE_VERTICAL,
    borderRadius: BORDER_RADIUS,
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
  },
  logoImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
})
