import { StackScreenProps } from '@react-navigation/stack'
import { View } from 'native-base'
import React from 'react'
import { ActionViewParamsType, Routes } from '../../navigation/Routes'
import { ChangeLanguage } from './components/ChangeLanguage'
import { CrashTestButton } from './components/CrashTestButton'

export function CheatCodes(props: StackScreenProps<ActionViewParamsType, 'CheatCodes'>) {
  return (
    <View>
      <CrashTestButton />
      <ChangeLanguage
        onLanguageChange={() => {
          props.navigation.reset({
            routes: [{ name: Routes.HomeTabs }],
          })
        }}
      />
    </View>
  )
}
