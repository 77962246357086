import { Reducer } from 'redux'
import { WeeklySchedulesState, WeeklySchedulesActionTypes } from './types'
import { WeeklySchedulesActionObjectTypes } from './actions'

const initialWeeklySchedulesState: WeeklySchedulesState = {
  items: {},
}

export const weeklySchedulesReducer: Reducer<WeeklySchedulesState, WeeklySchedulesActionObjectTypes> = (state = initialWeeklySchedulesState, action) => {
  switch (action.type) {
    case WeeklySchedulesActionTypes.STORE_WEEKLY_SCHEDULES:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.items,
        },
      }
    default:
      return state
  }
}
