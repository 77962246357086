import { isEmpty } from 'lodash'
import { View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Dimensions, StyleSheet, ViewStyle } from 'react-native'
import MapView, { Polygon } from 'react-native-maps'
import { useSelector } from 'react-redux'
import { Sector } from '../../api/CartographyZoneApi.ts/type'
import { ItemAction } from '../../api/types'
import { cartographyZonesByIdSelector } from '../../redux/cartographyZones/selector'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { fitToCoordinates, getPolyMapResultCoordinates } from '../../services/cartography'
import { trackEvent } from '../../services/matomo/matomo.service'
import { checkLocation } from '../../services/Permissions/Permissions.service'
import { theme } from '../../theme'
import { CartographyLoading } from '../CartographyLoading'
import { ModalBottomSimple } from '../ModalBottomSimple'

const INITIAL_REGION = {
  latitude: 46.360624,
  longitude: 2.621288,
  latitudeDelta: 12,
  longitudeDelta: 12,
}

interface CartographyZonesMapProps {
  zoneId: number
}

export const CartographyZonesMap: FunctionComponent<CartographyZonesMapProps> = ({ zoneId }) => {
  const mapViewRef = React.createRef<MapView>()
  const [isMapReady, setIsMapReady] = useState(false)
  const [zoneSelected, setZoneSelected] = useState<{ libelle?: string; itemAction?: ItemAction }>({})

  const cartographyZoneConfig = useSelector((state: RootState) => cartographyZonesByIdSelector(state, zoneId.toString()))

  useEffect(() => fitToPolygons(), [cartographyZoneConfig, isMapReady])

  const shouldDisplayModal = () => {
    if (!zoneSelected.libelle) {
      return false
    }
    if (!zoneSelected.itemAction) {
      return false
    }
    return true
  }

  const fitToPolygons = () => {
    if (!mapViewRef.current || !cartographyZoneConfig || !cartographyZoneConfig.polygons || isEmpty(cartographyZoneConfig.polygons) || !isMapReady) {
      return
    }

    fitToCoordinates(mapViewRef, getPolyMapResultCoordinates(cartographyZoneConfig.polygons), theme.padding.x4)
  }

  const fitToPolygonsSelected = (polygon: Sector) => {
    if (!isMapReady || !polygon.coordinates) {
      return
    }
    fitToCoordinates(mapViewRef, polygon.coordinates, theme.padding.x2)
  }

  const onPressPolygon = (polygon: Sector) => {
    if (!polygon.action || !polygon.libelle) {
      return
    }
    trackEvent({
      category: 'Cartographie',
      actionName: 'Cartographie de zone',
      eventName: 'Appui sur une zone',
      value: polygon.libelle,
    })
    setZoneSelected({ libelle: polygon.libelle, itemAction: polygon.action })
    fitToPolygonsSelected(polygon)
  }

  const renderZonePolygons = () => {
    if (!cartographyZoneConfig || !cartographyZoneConfig.polygons) {
      return null
    }

    return cartographyZoneConfig.polygons.map((polygon, index) => {
      if (!polygon.coordinates || !polygon.fillColor || !polygon.strokeColor || !polygon.strokeWidth) {
        return null
      }

      return (
        <Polygon
          key={polygon.code || index}
          coordinates={polygon.coordinates}
          fillColor={polygon.fillColor}
          strokeColor={polygon.strokeColor}
          strokeWidth={polygon.strokeWidth}
          onPress={() => onPressPolygon(polygon)}
          tappable
        />
      )
    })
  }

  const isLoadingZones = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_CARTOGRAPHY_ZONES))

  const displayLoader = () => <CartographyLoading />

  const onLayout = () => {
    setIsMapReady(true)
  }

  return (
    <View style={styles.viewContainer}>
      {isLoadingZones ? displayLoader() : null}
      <MapView
        ref={mapViewRef}
        style={styles.viewMap}
        showsPointsOfInterest={false}
        showsUserLocation
        showsCompass
        showsTraffic={false}
        zoomEnabled
        onLayout={onLayout}
        onMapReady={checkLocation}
        initialRegion={INITIAL_REGION}
      >
        {isMapReady && renderZonePolygons()}
      </MapView>

      <ModalBottomSimple itemAction={zoneSelected.itemAction} title={zoneSelected.libelle} onClose={() => setZoneSelected({})} isVisible={shouldDisplayModal()} />
    </View>
  )
}

const HEIGTH_MAP = Dimensions.get('window').height

const WIDTH_MAP = Dimensions.get('window').width

const styles = StyleSheet.create<Style>({
  viewContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  viewMap: {
    flex: 1,
    height: HEIGTH_MAP,
    width: WIDTH_MAP,
  },
})

interface Style {
  viewContainer: ViewStyle
  viewMap: ViewStyle
}
