import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'

import { useSelector } from 'react-redux'
import { WeatheIconerResult } from '../../../api/GridPage/types'
import { WeatherDaysItem } from '../../../api/Weather/types'
import { themeSelector } from '../../../redux/theme/selector'
import { iconFont } from '../../../services/Icon'
import { dateToDayOfWeek } from '../../../services/dateFormatter'
import { weatherConditions } from '../../../services/weather'
import { theme } from '../../../theme'

interface WeatherDayProps {
  item: WeatherDaysItem
}

export const WeatherDay: FunctionComponent<WeatherDayProps> = ({ item }) => {
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.blue
  const renderWeather = (weather: WeatheIconerResult) => {
    const condition = weatherConditions(weather.icone)
    if (!condition) {
      return <View />
    }

    return <Icon name={condition.icon} as={iconFont(condition.type)} style={styles.icon} size={9} color={colorText} />
  }

  const date = dateToDayOfWeek(item.date)
  const intTempMin = Math.floor(item.tempMin)
  const intTempMax = Math.floor(item.tempMax)

  return (
    <View style={styles.content}>
      <Text style={[styles.item, { color: colorText }]}>{date}</Text>
      {renderWeather(item.weather)}
      <Text style={[styles.item, { color: colorText }]}>
        {intTempMin} / {intTempMax} °
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    padding: theme.padding.x2,
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
  },
  item: {
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    fontSize: theme.iconSize.x2,
    alignItems: 'center',
    textAlign: 'center',
    flex: 2,
  },
})
