import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { Image, ImageBackground, ImageSourcePropType, Modal, SafeAreaView, StyleSheet } from 'react-native'
import ImageViewer from 'react-native-image-zoom-viewer'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { theme } from '../../../theme'
import { CloseButton } from '../../CloseButton'
import { Body, Header, Left } from '../../NativeBaseLegacy'

const BORDER_RADIUS = 20
const MARGIN_ICON = 30
interface HeaderImageProps {
  image?: ImageSourcePropType
  fastImage?: string
  maxHeight: number
  displayBottom: boolean
  left?: React.ReactNode
}

export const HeaderImage: FunctionComponent<HeaderImageProps> = ({ fastImage, image, maxHeight, displayBottom, left }) => {
  const [showModal, setShowModal] = useState(false)

  const displayModal = (imageUri: string | undefined) =>
    !imageUri ? null : (
      <Modal visible={showModal} transparent={false}>
        <SafeAreaView style={styles.imageViewer}>
          <ImageViewer
            renderHeader={() => <CloseButton style={styles.backIcon} callBack={() => setShowModal(false)} iconColor={theme.colors.white} />}
            style={styles.imageViewer}
            imageUrls={[{ url: imageUri, props: {} }]}
            onCancel={() => setShowModal(false)}
            enableSwipeDown
            renderIndicator={(currentIndex, allSize) =>
              currentIndex && currentIndex > 1 ? (
                <Text style={styles.indicator}>
                  {currentIndex} / {allSize}
                </Text>
              ) : (
                <View />
              )
            }
          />
        </SafeAreaView>
      </Modal>
    )
  return (
    <View style={styles.content}>
      <Header style={theme.isAndroid ? styles.header : {}} transparent>
        <Left style={styles.left}>{left}</Left>
        <Body style={styles.body} />
      </Header>
      {image && <Image style={[styles.image, { height: maxHeight }]} source={image} />}
      {fastImage && (
        <ImageBackground style={[styles.image, { height: maxHeight }]} source={{ uri: fastImage }} resizeMode="cover">
          <View style={styles.iconImage}>
            <Icon color={theme.colors.lightGray} as={FontAwesome} name="arrows-alt" onPress={() => setShowModal(true)} size={6} />
          </View>
        </ImageBackground>
      )}

      {displayBottom && <View style={styles.renderForegroundBottom} />}
      {displayModal(fastImage)}
    </View>
  )
}

const styles = StyleSheet.create({
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    zIndex: 1,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    height: '100%',
    backgroundColor: theme.colors.silver,
  },
  header: {
    paddingTop: 0,
  },
  image: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    alignSelf: 'stretch',
    zIndex: -1,
    opacity: 0.9,
  },
  renderForegroundBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.white,
  },
  iconImage: { flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', margin: MARGIN_ICON },
  imageViewer: {
    backgroundColor: theme.colors.black,
    flex: 1,
  },
  backIcon: {
    marginRight: 10,
    marginTop: 10,
    padding: 10,
    marginBottom: -54,
    zIndex: 3,
    alignSelf: 'flex-end',
  },
  indicator: {
    color: theme.colors.white,
    position: 'absolute',
    alignSelf: 'center',
    top: 20,
    zIndex: 2,
  },
})
