/* eslint-disable prettier/prettier */
import { isEmpty } from 'lodash'
import { View } from 'native-base'
import React, { FunctionComponent, ReactNode } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { SegmentItemResult } from '../../api/types'
import { themeSelector } from '../../redux/theme/selector'
import { theme } from '../../theme'
import { Body, Header, Left, Right, Title } from '../NativeBaseLegacy'
import { Segment } from '../Segment/Segment.component'

interface PageSegmentProps {
  title: string
  titleImage?: string | null
  left?: React.ReactNode
  right?: ReactNode
  currentSegment: SegmentItemResult | null
  segments: SegmentItemResult[] | null
  onSegmentPress: (segment: SegmentItemResult) => void
  style?: ViewStyle
  children?: React.ReactNode
}

const PageSegment: FunctionComponent<PageSegmentProps> = ({
  title,
  titleImage,
  left,
  right,
  currentSegment,
  segments,
  onSegmentPress,
  children,
  style,
}) => {
  const theme = useSelector(themeSelector)
  const renderTitle = () => {
    if (titleImage) {
      return (
        <FastImage
          style={styles.image}
          source={{ uri: titleImage }}
          resizeMode={FastImage.resizeMode.contain}
        />
      )
    }

    return <Title containerStyle={styles.title} style={[styles.titleText, { color: theme?.titleFontColor }]}>{title}</Title>
  }

  return (
    <View style={[styles.container, style]}>
      <Header translucent={false}>
        <Left style={styles.left}>{left || undefined}</Left>
        <Body style={styles.body}>{renderTitle()}</Body>
        <Right style={styles.right}>{right || undefined}</Right>
      </Header>
      {segments && !isEmpty(segments) && (
        <Segment items={segments} defautItem={currentSegment} onPress={onSegmentPress} />
      )}
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: theme.deviceHeight,
  },
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    zIndex: 1,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  right: {
    zIndex: 2,
    flex: 0,
    width: 100,
    justifyContent: 'flex-end',
  },
  title: {
  },
  titleText: {
    fontSize: theme.header.titleSize,

  },
  image: {
    height: '100%',
    width: '100%',
    marginBottom: theme.padding.x1,
  },
})

export { PageSegment }
