import _ from 'lodash'
import { Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useState } from 'react'
import { Alert, PermissionsAndroid, Platform, StyleSheet, TouchableOpacity } from 'react-native'
import { Asset, Callback, CameraOptions, ImagePickerResponse, launchCamera, launchImageLibrary } from 'react-native-image-picker'
import { PERMISSIONS, RESULTS, request } from 'react-native-permissions'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'

import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { i18n } from '../../lib/i18n'
import { themeSelector } from '../../redux/theme/selector'
import { showDanger } from '../../services/toast'
import { theme as themeBase } from '../../theme'
import { ImageValue } from './types'

const BORDER_RADIUS = 4
const COLOR_DANGER = 'red'
const IMAGE_QUALITY = 0.2
const ERROR_DISPLAY_DURATION = 10000

interface ImageUploadProps {
  id: number
  onAdd: (item: ImageValue) => void
  onRemove: (id: number) => void
  onLoad: (id: number) => void
  onEndLoad: (id: number) => void
}

const prefixBase64 = (base64: string, type = 'image/jpeg') => `data:${type};base64,${base64}`

const ImageUpload: FunctionComponent<ImageUploadProps> = ({ id, onAdd, onRemove, onLoad, onEndLoad }) => {
  const theme = useSelector(themeSelector)

  const [image, setImage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const optionsLaunch: CameraOptions = {
    mediaType: 'photo',
    quality: IMAGE_QUALITY,
    includeBase64: true,
    saveToPhotos: false,
  }

  const pikerResponse: Callback = (response: ImagePickerResponse) => {
    try {
      if (response.didCancel) {
        setLoading(false)
        onEndLoad(id)
      }
      if (response.errorCode) {
        showDanger(i18n.t('authorization.letPhoneAccess'), ERROR_DISPLAY_DURATION)
      } else if (response.assets) {
        const { base64 } = _.first(response.assets) as Asset
        const { type } = _.first(response.assets) as Asset
        if (base64 === undefined) {
          showDanger(i18n.t('imageUpload.error'), ERROR_DISPLAY_DURATION)
        } else {
          const data = prefixBase64(base64 as string, type)
          setImage(data)
          onAdd({ id, data })
        }
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
    onEndLoad(id)
  }

  const callLaunchCamera = async () => {
    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA, {
        title: i18n.t('imageUpload.title'),
        message: i18n.t('permission.messageCameraAccess'),
        buttonNeutral: i18n.t('permission.askMeLater'),
        buttonNegative: i18n.t('permission.cancel'),
        buttonPositive: i18n.t('permission.ok'),
      })
      granted
        ? launchCamera(optionsLaunch, pikerResponse)
        : () => {
            setLoading(false)
            onEndLoad(id)
          }
    } else if (Platform.OS === 'ios') {
      launchCamera(optionsLaunch, pikerResponse)
    }
  }

  const callLaunchImageLibrary = async () => {
    if (Platform.OS === 'ios') {
      request(PERMISSIONS.IOS.PHOTO_LIBRARY)
        .then(result => {
          if (result === RESULTS.GRANTED || result === RESULTS.LIMITED) {
            launchImageLibrary(optionsLaunch, pikerResponse)
          } else {
            showDanger(i18n.t('authorization.letPhoneAccess'), ERROR_DISPLAY_DURATION)
            setLoading(false)
            onEndLoad(id)
          }
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          onEndLoad(id)
        })
    } else {
      launchImageLibrary(optionsLaunch, pikerResponse)
    }
  }

  const showPicker = () => {
    onLoad(id)
    if (Platform.OS === 'web') {
      callLaunchImageLibrary()
      onEndLoad(id)
    } else {
      setLoading(true)
      Alert.alert(i18n.t('imageUpload.title'), '', [
        {
          text: i18n.t('imageUpload.takePhoto'),
          onPress: () => callLaunchCamera(),
          style: 'default',
        },
        {
          text: i18n.t('imageUpload.chooseFromLibrary'),
          onPress: () => callLaunchImageLibrary(),
          style: 'default',
        },
        {
          text: i18n.t('imageUpload.cancel'),
          onPress: () => {
            setLoading(false)
            onEndLoad(id)
          },
          style: 'cancel',
        },
      ])
    }
  }

  const removeImage = () => {
    setImage(null)
    onRemove(id)
  }

  if (image) {
    const iconColor = theme ? theme.brandDanger : COLOR_DANGER
    return (
      <View style={styles.content}>
        <FastImage source={{ uri: image }} style={styles.image} />
        <TouchableOpacity style={styles.buttonRemove} onPress={() => removeImage()}>
          <Icon name="cancel" as={MaterialIcons} color={iconColor} size={6} />
        </TouchableOpacity>
      </View>
    )
  }

  if (loading) {
    return (
      <TouchableOpacity style={styles.content} onPress={() => showPicker()}>
        <View style={styles.addComponent}>
          <Spinner />
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity style={styles.content} onPress={() => showPicker()}>
      <View style={styles.addComponent}>
        <Icon style={styles.addComponentIcon} color={themeBase.colors.silver} name="picture-o" as={FontAwesome} size={8} />
        <Text>{i18n.t('add')}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    height: 110,
    marginRight: themeBase.padding.x1,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: BORDER_RADIUS,
  },
  addComponent: {
    flex: 1,
    borderColor: themeBase.colors.silver,
    borderRadius: BORDER_RADIUS,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addComponentIcon: {
    width: '100%',
    textAlign: 'center',
  },
  buttonRemove: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

export { ImageUpload }
