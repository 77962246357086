import { CartographyZoneResult } from '../../api/CartographyZoneApi.ts/type'

export type CartographyZonesState = {
  [cartographyZonesId: string]: CartographyZoneResult | null
}

export enum CartographyZonesActionTypes {
  LOAD_CARTOGRAPHY_ZONES = 'LOAD_CARTOGRAPHY_ZONES',
  STORE_CARTOGRAPHY_ZONES = 'STORE_CARTOGRAPHY_ZONES',
}
