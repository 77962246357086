import { Button, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { ExternalLinksItem } from '../../api/ExternalLinks/types'
import { i18n } from '../../lib/i18n'
import { openLink } from '../../services/browserInApp'
import { theme } from '../../theme'

interface ExternalLinksItemDisplayProps {
  externalLink: ExternalLinksItem
}

export const ExternalLinksItemDisplay: FunctionComponent<ExternalLinksItemDisplayProps> = ({ externalLink }) => (
  <View style={styles.itemContainer}>
    <View style={styles.leftElt}>
      <Text>{externalLink.title}</Text>
      <Text style={styles.note} numberOfLines={1}>
        {externalLink.description}
      </Text>
    </View>
    <View style={styles.rightElt}>
      <Button variant="ghost" onPress={() => openLink(externalLink.link)}>
        <Text>{i18n.t('view')}</Text>
      </Button>
    </View>
  </View>
)

const styles = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flex: 3,
    flexDirection: 'row',
    padding: theme.padding.x1,
  },
  note: {
    opacity: 0.4,
  },
  leftElt: {
    display: 'flex',
    flex: 2,
    paddingBottom: theme.padding.x1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderBottomColor: theme.colors.silver,
    borderBottomWidth: 0.5,
  },
  rightElt: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: theme.padding.x1,
    borderBottomColor: theme.colors.silver,
    borderBottomWidth: 0.5,
  },
})
