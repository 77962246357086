import { ActionsOfType } from '@martin_hotell/rex-tils'
import isEmpty from 'lodash/isEmpty'
import { call, put, takeEvery } from 'redux-saga/effects'
import { AirQualityApi } from '../../api/AirQuality'
import { AirParifResult, AirQualityConfig } from '../../api/AirQuality/types'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { AirQualityActionObjectTypes, AirQualityActions } from './action'
import { AirQualityActionTypes } from './types'

function* loadAirQualitySaga(action: ActionsOfType<AirQualityActionObjectTypes, AirQualityActionTypes.LOAD_AIR_QUALITY_CONFIG>) {
  try {
    const aqResult: AirQualityConfig = yield call(AirQualityApi.loadAirQuality, action.payload.id)
    yield put(AirQualityActions.storeAirQualityConfig(action.payload.id, aqResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* checkAirQualityDaySaga(action: ActionsOfType<AirQualityActionObjectTypes, AirQualityActionTypes.CHECK_AIR_QUALITY_DAY>) {
  try {
    if (action.payload.airQualityConfig.airParif) {
      const apResult: AirParifResult = yield call(AirQualityApi.loadAirParif, action.payload.airQualityConfig.airParif)
      const dayApResult = apResult[Object.keys(apResult)[0]]

      const todayApResult = dayApResult.filter(item => new Date(item.date).toDateString() === new Date().toDateString())[0]

      if (isEmpty(apResult)) {
        yield put(AirQualityActions.storeAirQualityDay(action.payload.airQualityConfig.id, null))
      } else {
        yield put(
          AirQualityActions.storeAirQualityDay(action.payload.airQualityConfig.id, {
            quality: todayApResult.indice,
          }),
        )
      }
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadAirQualityWatcher() {
  yield takeEvery(AirQualityActionTypes.LOAD_AIR_QUALITY_CONFIG, addLoader(loadAirQualitySaga, LoaderName.LOAD_AIR_QUALITY))
}

export function* checkAirQualityDayWatcher() {
  yield takeEvery(AirQualityActionTypes.CHECK_AIR_QUALITY_DAY, addLoader(checkAirQualityDaySaga, LoaderName.CHECK_AIR_QUALITY))
}
