import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
// import { Button, Icon, Text } from 'native-base'
import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
// import { HomeFooterItem } from '../../api/Home'
import { homeFootersSelector } from '../../redux/home/selector'
// import { homeFooterConfig, HomeFooterConfig } from '../../services/home/home.service'
// import { iconFont } from '../../services/Icon'

export const HomeTabBar: FunctionComponent<BottomTabBarProps> = () => {
  const footers = useSelector(homeFootersSelector)

  if (!footers || footers.length === 0) {
    return null
  }

  // const IconRender = (item: HomeFooterItem, conf: HomeFooterConfig) => {
  //   if (item.icon && item.iconType) {
  //     return <Icon name={item.icon} as={iconFont(item.iconType)} size={6} />
  //   }
  //   return <Icon name={conf.icon} as={iconFont(conf.iconType)} size={6} />
  // }

  // const renderButton = (item: HomeFooterItem) => {
  //   const conf = homeFooterConfig(item.route)

  //   if (!conf) {
  //     return null
  //   }

  //   return (
  //     <Button
  //       key={item.id}
  //       isFocused={props.state.index === item.id}
  //       onPress={() => props.navigation.navigate({ name: conf.page, params: {} })}
  //     >
  //       {IconRender(item, conf)}
  //       <Text>{item.name}</Text>
  //     </Button>
  //   )
  // }

  return null
  // return (
  //   <Footer>
  //     <FooterTab>{footers.map((item: HomeFooterItem) => renderButton(item))}</FooterTab>
  //   </Footer>
  // )
}
