import { ITextProps, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

interface TitleProps extends ITextProps {
  containerStyle?: StyleProp<ViewStyle>
}

export const Title: FunctionComponent<TitleProps> = ({ containerStyle, ...props }) => (
  <View style={containerStyle}>
    <Text numberOfLines={1} {...props} />
  </View>
)
