/* eslint-disable prettier/prettier */
import { DrawerScreenProps } from '@react-navigation/drawer'
import isEmpty from 'lodash/isEmpty'
import { Button, Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import AntDesign from 'react-native-vector-icons/AntDesign'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { CityItemResult } from '../../api/City/types'
import { AgendaComponentItem, AgendaList } from '../../components/AgendaList/AgendaList.component'
import { Content } from '../../components/NativeBaseLegacy'
import { Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, RoutesCityAdd } from '../../navigation/Routes'
import { citysEventsConfigSelector, citysSelectSelector } from '../../redux/city/selector'
import { homeTitleImageSelector, homeTitleSelector } from '../../redux/home/selector'
import { themeSelector } from '../../redux/theme/selector'


export const MyCitysEvents: FunctionComponent<
  DrawerScreenProps<ActionViewParamsType, 'CitysEvents'>
> = ({ navigation }) => {
  const eventsConfig = useSelector(citysEventsConfigSelector)
  const citys = useSelector(citysSelectSelector)
  const title = useSelector(homeTitleSelector)
  const titleHome = title || i18n.t('home.title')
  const titleImage = useSelector(homeTitleImageSelector)

  const theme = useSelector(themeSelector)

  if (isEmpty(citys) || !eventsConfig) {
    return (
      <Page
        left={
          <Button variant="ghost" onPress={navigation.toggleDrawer}>
            <Icon as={Ionicons} name="menu" />
          </Button>
        }
        title={titleHome}
        titleImage={titleImage}
      >
        <Content style={styles.content}>
          <View style={styles.contentAdd}>
            <Button
              onPress={() => navigation.navigate({ name: RoutesCityAdd.cityAdd, params: {} })}
            >
              <Icon name="pluscircleo" as={AntDesign} color={theme?.titleFontColor} size={6} />
              <Text>{i18n.t('addCity.title')}</Text>
            </Button>
          </View>
        </Content>
      </Page>
    )
  }

  const citysList: CityItemResult[] = Object.values(citys)

  const newsComponentItem = (): AgendaComponentItem => {
    if (citysList.length === 1) {
      return 'default'
    }
    return 'withCustomer'
  }

  return (
    <AgendaList
      key={citysList.length}
      title={titleHome}
      titleImage={titleImage}
      left={
        <Button variant="ghost" onPress={navigation.toggleDrawer}>
          <Icon as={Ionicons} name="menu" size={6} />
        </Button>
      }
      id={-1}
      referentielFilters={eventsConfig}
      componentItem={newsComponentItem()}
    />
  )
}

const styles = StyleSheet.create({
  content: { flex: 1 },
  contentAdd: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
