import wretch from 'wretch'
import { env } from '../../environment'
import { authMiddleware } from '../authMiddleware'

export class BaseApi {
  public static baseCall() {
    return wretch(env().baseUrl)
  }

  public static authenticatedCall() {
    return wretch(env().baseUrl).headers({ clientuuid: env().clientUuid }).middlewares([authMiddleware()])
  }

  public static publicCall() {
    return wretch()
  }
}
