export const capitalize = (text: string): string => {
  if (!text) {
    return ''
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isNullOrUndefined = (text?: string | null): boolean => text === null || text === undefined

/**
 * retourne le texte en retirant les espace au début et à la fin
 * */
export const removeEndingSpace = (text: string | null): string | undefined => (text ? text.trim() : undefined)
