import { Text, View } from 'native-base'
import React from 'react'
import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { theme } from '../../theme'

interface Styles {
  container: ViewStyle
  contentTitle: TextStyle
  contentText: TextStyle
}

const styles: Styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 3,
    backgroundColor: theme.colors.lightBrown,
    padding: theme.padding.x2,
    borderColor: theme.colors.mediumBrown,
  },
  contentTitle: {
    color: theme.colors.darkBrown,
    fontWeight: 'bold',
    marginBottom: theme.padding.x1,
  },
  contentText: {
    color: theme.colors.darkBrown,
  },
})

interface Props {
  title: string
  content: string
  style?: ViewStyle
}

export const WarningBox: React.FC<Props> = ({ title, content, style }) => (
  <View style={[styles.container, style]}>
    <Text style={styles.contentTitle}>{title}</Text>
    <Text style={styles.contentText}>{content}</Text>
  </View>
)
