import { Reducer } from 'redux'
import { CartographyZonesState, CartographyZonesActionTypes } from './types'
import { CartographyZonesActionObjectTypes } from './actions'

const initialCartographyZonesState: CartographyZonesState = {}

export const cartographyZonesReducer: Reducer<CartographyZonesState, CartographyZonesActionObjectTypes> = (state = initialCartographyZonesState, action) => {
  switch (action.type) {
    case CartographyZonesActionTypes.STORE_CARTOGRAPHY_ZONES:
      return {
        ...state,
        [action.payload.zoneId]: action.payload.config,
      }
    default:
      return { ...state }
  }
}
