import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { CantineEliorFamilleResult } from '../../../api/CantineElior/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'
import { CantinePlat } from './CantinePlat.component'

export interface CantineFamilleProps {
  famille: CantineEliorFamilleResult
}

export const CantineFamille: FunctionComponent<CantineFamilleProps> = ({ famille }) => {
  const plats = famille.Plats.map((object, index) => <CantinePlat key={index} plat={object} />)

  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  return (
    <View style={styles.body}>
      {famille.Libelle ? <Text style={[styles.title, { color: colorText }]}>{famille.Libelle}</Text> : null}
      {plats}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {},
  title: {
    color: theme.colors.silverStrong,
    textAlign: 'center',
  },
})
