/* eslint-disable prettier/prettier */
import { ScrollView, Text, View } from 'native-base'
import React, { Fragment, FunctionComponent } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import Modal from 'react-native-modal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { CategoriesItemResult } from '../../api/Signalement/types'
import { themeSelector } from '../../redux/theme/selector'
import { ThemeState } from '../../redux/theme/types'
import { theme as defaultTheme } from '../../theme'
import { ListItem } from '../NativeBaseLegacy'
import { LeftButtonBack } from "../Page"
import { PageSegment } from '../PageSegment'

const IMAGE_SIZE = 30
const MARGIN_CATEGORY = 24

interface CategoryListProps {
  show: boolean
  selectedSubCategoryId: number | null
  title: string | null
  onBack: () => void
  onSelect: (id: number) => void
  categories: CategoriesItemResult[]
}

const renderCategoryHeader = (category: CategoriesItemResult) => {
  const categoryId = category.id.toString()
  return (
    <ListItem key={`cat-${categoryId}`} style={styles.listItem}>
      <View style={styles.categoryContainer}>
        {category.image !== null && (
           <View style={styles.image}>
              <FastImage
                style={styles.image}
                source={{ uri: category.image }}
                resizeMode={FastImage.resizeMode.contain}
              />
            </View>
          )}
        <Text style={styles.categoryText}>{category.libelle}</Text>
      </View>
    </ListItem>
  )
}

const renderCategory = (
  category: CategoriesItemResult,
  onSelect: CategoryListProps['onSelect'],
  selectedValue: CategoryListProps['selectedSubCategoryId'],
  theme: ThemeState,
) => {
  const categoryId = category.id.toString()
  return (
    <Fragment key={categoryId}>
      {renderCategoryHeader(category)}
      {category.sousCategories.map(subCategory => (
        <ListItem
          key={`subcat-${subCategory.id.toString()}`}
          onPress={() => onSelect(subCategory.id)}
          testID={subCategory.id.toString()}
          style={styles.item}
        >
          <Text style={{ color: subCategory.id === selectedValue ? theme?.headerStyle : undefined }}>{subCategory.libelle}</Text>
        </ListItem>
      ))}
    </Fragment>
  )
}

export const CategoryList: FunctionComponent<CategoryListProps> = ({
  show,
  selectedSubCategoryId: selectedValue,
  title,
  categories,
  onBack,
  onSelect,
}) => {
  const safeArea = useSafeAreaInsets()
  const theme = useSelector(themeSelector)
  return (
    <Modal isVisible={show} style={[styles.modal, { backgroundColor: theme?.toolbarDefaultBg }]}>
      <SafeAreaView>
        <PageSegment style={{ flexBasis: defaultTheme.deviceHeight - safeArea.top - safeArea.bottom  }} left={<LeftButtonBack action={onBack} />} title={title || ''} currentSegment={null} segments={null} onSegmentPress={() => {}}>
          <ScrollView style={styles.scrollview}>
            <View>
              {categories.map(category => renderCategory(category, onSelect, selectedValue, theme))}
            </View>
          </ScrollView>
        </PageSegment>
      </SafeAreaView>
    </Modal>
  )}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    height: defaultTheme.deviceHeight,
    width: defaultTheme.deviceWidth,
    justifyContent: 'flex-start',
  },
  scrollview: {
    backgroundColor: defaultTheme.colors.white,
  },
  title: {
    fontSize: defaultTheme.header.titleSize,
  },
  listItem: {
    paddingLeft: MARGIN_CATEGORY,
  },
  categoryText: { flex: 1, fontWeight: 'bold' },
  categoryContainer: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    marginRight: 12,
  },
  item: {
    paddingLeft: MARGIN_CATEGORY  * 2,
  },
})
