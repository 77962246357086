import { Button, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import Modal from 'react-native-modal'
import { ItemAction } from '../../api/types'
import { i18n } from '../../lib/i18n'
import { getItemAction } from '../../services/itemAction'
import { theme } from '../../theme'

export interface ModalBottomSimpleProps {
  title?: string
  itemAction?: ItemAction
  onClose: () => void
  isVisible: boolean
  subtitle?: string
  caption?: string
  subtitleColor?: string
}

const BORDER_RADIUS = 15
const MIN_HEIGHT = 130
const LINE_DOWN_WIDTH = 50
const LINE_DOWN__HEIGHT = 8
const LINE_VIEW_HEIGHT = 13

export const ModalBottomSimple: FunctionComponent<ModalBottomSimpleProps> = ({ title, itemAction, onClose, isVisible, subtitle, subtitleColor, caption }) => {
  const buttonPressed = () => {
    if (!itemAction) {
      return
    }
    return getItemAction(itemAction)
  }

  return (
    <Modal isVisible={isVisible} style={styles.bottomModal} swipeDirection={['down']} hasBackdrop={false} coverScreen={false} onSwipeComplete={onClose}>
      <View style={styles.emptyCollapse}>
        <View style={styles.emptyCollapseLine} />
      </View>

      <View style={styles.content}>
        <View style={styles.infos}>
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          {subtitle ? (
            <Text style={styles.subtitle} color={subtitleColor} numberOfLines={1}>
              {subtitle}
            </Text>
          ) : null}
          {caption ? <Text style={styles.caption}>{caption}</Text> : null}
        </View>
        <Button style={styles.button} variant="outline" onPress={buttonPressed()}>
          <Text>{i18n.t('zones.consult')}</Text>
        </Button>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCollapse: {
    width: '100%',
    height: LINE_VIEW_HEIGHT,
    alignItems: 'center',
  },
  emptyCollapseLine: {
    height: LINE_DOWN__HEIGHT,
    width: LINE_DOWN_WIDTH,
    backgroundColor: theme.colors.white,
    borderRadius: BORDER_RADIUS,
    borderWidth: 1,
    borderColor: theme.colors.silver,
  },
  content: {
    padding: theme.padding.x2,
    minHeight: MIN_HEIGHT,
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    borderTopColor: theme.colors.silver,
    borderLeftColor: theme.colors.silver,
    borderRightColor: theme.colors.silver,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'left',
    fontSize: theme.textSize.x3,
    alignItems: 'center',
    lineHeight: theme.textSize.x3,
    marginRight: theme.padding.x1,
  },
  infos: {
    width: '75%',
  },
  subtitle: {
    textAlign: 'left',
    alignItems: 'center',
    lineHeight: theme.textSize.x3,
    marginRight: theme.padding.x1,
  },
  caption: {
    textAlign: 'left',
    alignItems: 'center',
    lineHeight: theme.textSize.x2,
    marginRight: theme.padding.x1,
  },
})
