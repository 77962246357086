/* eslint-disable prettier/prettier */
import { StackScreenProps } from '@react-navigation/stack'
import { Button, Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { UserCredentials } from 'react-native-keychain'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useDispatch, useSelector } from 'react-redux'
import { LeftButtonBack, Page } from '../../components/Page'
import { Separator } from '../../components/Separator'
import { Login } from '../../components/login'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { GrcYpokActions } from '../../redux/grcYpok/actions'
import { grcYpokConfigSelector } from '../../redux/grcYpok/selector'
import { RootState } from '../../redux/reducers'
import { themeSelector } from '../../redux/theme/selector'
import { openLink } from '../../services/browserInApp'
import { isOfflineError } from '../../services/errorManager'
import {
  loadYpokUser,
  resetYpokUser, storeYpokUser, ypokSignin,
} from '../../services/grcYpok/grcYpok.service'
import { theme } from '../../theme'

const GrcYpok: FunctionComponent<StackScreenProps<ActionViewParamsType, 'GrcYpok'>> = ({
  route,
}) => {
  const title = route.params.titre

  const [errorLogin, setErrorLogin] = useState<string | null>('')
  const [ypokUser, setYpokUser] = useState<UserCredentials | null>(null)
  const [userLoad, setUserLoad] = useState<boolean>(false)

  const themeStore = useSelector(themeSelector)

  const ypokConfig = useSelector((state: RootState) => grcYpokConfigSelector(state))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GrcYpokActions.loadYpokConfig())
  }, [])

  useEffect(() => {
    async function anyNameFunction() {
      const user = await loadYpokUser()
      if (user) {
        setYpokUser(user)
      }
      setUserLoad(true)
    }
    anyNameFunction()
  }, [userLoad])

  const renderImage: Function = () => {
    if (!ypokConfig || !ypokConfig.image) {
      return null
    }
    return (
      <FastImage
        style={styles.image}
        source={{ uri: ypokConfig.image }}
        resizeMode={FastImage.resizeMode.contain}
      />
    )
  }

  if (!ypokConfig || !userLoad) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <View>
          {renderImage()}
          <Spinner />
        </View>
      </Page>
    )
  }

  const renderDescription: Function = (description: string | null) => {
    if (!description) {
      return null
    }
    return <Text>{description}</Text>
  }

  const renderForgot: Function = () => {
    const url = ypokConfig && ypokConfig.urlForgot ? ypokConfig.urlForgot : null
    if (!url) {
      return null
    }
    return (
      <Button variant="ghost" onPress={() => openLink(url)}>
        <Text style={[styles.buttonText, styles.textUnderLine]}>{i18n.t('grcYpok.forgot')}</Text>
      </Button>
    )
  }

  const renderError: Function = () => {
    if (!errorLogin) {
      return null
    }
    return <Text style={styles.errorText}>{errorLogin}</Text>
  }

  const submitLogin = async (userName: string, pwd: string) => {
    try {
      const url = ypokConfig && ypokConfig.authentication ? ypokConfig.authentication : null

      if (!url) {
        setErrorLogin(i18n.t('grcYpok.serviceBeingProcessed'))
        return
      }
      const response = await ypokSignin(userName, pwd, url)
      if (!response || !response.status) {
        setErrorLogin('response.errors.form')
      } else if (response.status === 'error') {
        setErrorLogin(response.errors.form)
      } else if (response.status === 'success') {
        openLink(response.url)
        await storeYpokUser(userName, pwd)
        setErrorLogin(null)
        setUserLoad(false)
      }
    } catch (error) {
      setErrorLogin(i18n.t('grcYpok.tryLater'))
    }
  }

  const openUserUrl = async (user: UserCredentials) => {
    try {
      const url = ypokConfig && ypokConfig.authentication ? ypokConfig.authentication : null

      if (!url) {
        setErrorLogin('Service en cours de maintenance')
        return
      }
      const response = await ypokSignin(user.username, user.password, url)
      if (!response || !response.status) {
        setErrorLogin('response.errors.form')
      } else if (response.status === 'error') {
        setErrorLogin(response.errors.form)
        logout()
      } else if (response.status === 'success') {
        openLink(response.url)
        setErrorLogin(null)
      }
    } catch (error) {
      if (isOfflineError(error as Error)) {
        setErrorLogin(i18n.t('offline.line2'))
      } else {
        setErrorLogin('Accès au service indisponible. Veuillez-ressayez ultérieurement')
      }
    }
  }
  const logout: Function = () => {
    resetYpokUser()
    setYpokUser(null)
    setUserLoad(false)
  }

  if (ypokUser) {
    return (
      <Page
        left={<LeftButtonBack />}
        title={title}
        right={
          <Button variant="ghost" onPress={() => logout()}>
            <Icon name="logout" as={MaterialCommunityIcons} color={themeStore?.titleFontColor} size={6} />
          </Button>
        }
      >
        <View style={styles.container}>
          {renderImage(ypokConfig.image)}
          <View style={styles.welcomeMessage}>
            <Text>{i18n.t('grcYpok.yourLoggedAs')}</Text>
            <Text style={styles.textBold}> {ypokUser.username}</Text>
          </View>
          <Button onPress={() => openUserUrl(ypokUser)}>
            <Text style={[styles.buttonText]}>{i18n.t('grcYpok.open')}</Text>
          </Button>
          {renderError()}
        </View>
      </Page>
    )
  }

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <View style={styles.container}>
        {renderImage(ypokConfig.image)}
        {renderDescription(ypokConfig.description)}
        <Login onPress={(userName, pwd) => submitLogin(userName, pwd)} error={errorLogin} />
        {renderForgot()}
        <Separator />
        <Button variant="ghost" onPress={() => openLink(ypokConfig.url || '')}>
          <Text style={[styles.buttonText, styles.textUnderLine]}>
            {i18n.t('grcYpok.accessAnonymous')}
          </Text>
        </Button>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: theme.padding.x2,
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
    height: 200,
  },
  buttonText: {
    width: '100%',
    textAlign: 'center',
  },
  textUnderLine: { textDecorationLine: 'underline' },
  errorText: {
    color: theme.colors.red,
    paddingVertical: theme.padding.x2,
  },
  welcomeMessage: {
    paddingVertical: theme.padding.x2,
    flexDirection: 'row',
  },
  textBold: { fontWeight: 'bold' },
})

export { GrcYpok }
