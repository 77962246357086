import { DateTime } from 'luxon'
import moment from 'moment'
import { Input } from 'native-base'
import React, { createElement, PureComponent } from 'react'
import { Platform, Pressable, TouchableOpacity } from 'react-native'
import { FormBase } from '../../api/Form/types'
import { dateToDayMonthYear } from '../../services/dateFormatter'
import { DatePicker } from '../DatePicker'
import { LabelInput } from './components'
import { FormElementProps } from './types'

class DateField extends PureComponent<FormElementProps<FormBase, number>, { show: boolean }> {
  public state = {
    show: false,
  }

  public focus = () => {}

  public render() {
    const { field, value, setFieldValue } = this.props
    const dateValue = (value && DateTime.fromMillis(value).toJSDate()) || undefined
    const defaultDate = DateTime.fromJSDate(new Date()).toJSDate()

    const inputValue = (value && dateToDayMonthYear(value)) || ''
    const InputDate =
      Platform.OS === 'web' ? (
        createElement('input', {
          type: 'date',
          style: { paddingLeft: 12, paddingRight: 12, paddingTop: 16, paddingBottom: 16, borderColor: '#d4d4d4', borderWidth: 1, borderStyle: 'solid', borderRadius: 4 },
          value: moment(inputValue).format('YYYY-MM-DD'),
          onChange: e => {
            const date = new Date(e.target.value)
            setFieldValue(this.elementId, date.getTime())
          },
        })
      ) : (
        <Input pointerEvents="none" editable={false} value={inputValue} />
      )

    return (
      <TouchableOpacity onPress={this.show}>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        <Pressable onPress={this.show}>{InputDate}</Pressable>
        <DatePicker
          show={this.state.show}
          value={dateValue as Date}
          defaultValue={defaultDate}
          validate={(date?: Date) => {
            this.hide()
            if (date) {
              const newDate = DateTime.fromJSDate(new Date(date)).toMillis()
              setFieldValue(this.elementId, newDate)
            }
          }}
        />
      </TouchableOpacity>
    )
  }

  private show = () => {
    this.setState({ show: true })
  }

  private hide = () => {
    this.setState({ show: false })
  }

  private get elementId() {
    const { field } = this.props
    return `${field.id}`
  }
}

export { DateField }
