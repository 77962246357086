import { NativeBaseProvider } from 'native-base'
import React, { ComponentType, FunctionComponent, useEffect } from 'react'
import RNBootSplash from 'react-native-bootsplash'
import { getTrackingStatus, requestTrackingPermission } from 'react-native-tracking-transparency'
import { useDispatch, useSelector } from 'react-redux'
import { ModalNotif } from '../components/ModalNotif'
import getTheme from '../native-base-theme/components'
import dynamicTheme from '../native-base-theme/variables/dynamicTheme'
import { BootAppFailed } from '../pages/BootAppFailed'
import { AuthenticationActions } from '../redux/authentication/actions'
import { apiTokenSelector } from '../redux/authentication/selectors'
import { HomeActions } from '../redux/home/actions'
import { loadingStatusSelector } from '../redux/loading/selectors'
import { LoaderName } from '../redux/loading/types'
import { MenuActions } from '../redux/menu/actions'
import { RootState } from '../redux/reducers'
import { ThemeActions } from '../redux/theme/actions'
import { themeSelector } from '../redux/theme/selector'

export const withBootApp = (Component: ComponentType): ComponentType => {
  const bootAppHOC: FunctionComponent<{}> = () => {
    const dispatch = useDispatch()
    const apiToken = useSelector(apiTokenSelector)
    const theme = useSelector(themeSelector)
    const signInloadingStatus = useSelector((state: RootState) => loadingStatusSelector(state, LoaderName.SIGN_IN_USER))

    useEffect(() => {
      dispatch(AuthenticationActions.signInUser())
      requestTracking()
    }, [])

    useEffect(() => {
      if (!apiToken) {
        return
      }
      dispatch(ThemeActions.loadThemeConfig())
      dispatch(HomeActions.loadHomeConfig())
      dispatch(MenuActions.loadMenuConfig())
    }, [apiToken])

    if (signInloadingStatus && !signInloadingStatus.status.ok) {
      RNBootSplash.hide()
      return <BootAppFailed />
    }

    const requestTracking = async () => {
      const trackingStatus = await getTrackingStatus()
      if (trackingStatus === 'not-determined') {
        await requestTrackingPermission()
      }
    }

    if (!theme) {
      return null
    }

    return (
      <NativeBaseProvider theme={getTheme(dynamicTheme(theme) || {}) as never}>
        <Component />
        <ModalNotif />
      </NativeBaseProvider>
    )
  }

  return bootAppHOC
}
