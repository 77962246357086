import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { CloseButton } from '../../components/CloseButton'
import { i18n } from '../../lib/i18n'
import { Routes } from '../../navigation/Routes'
import { menuSelector } from '../../redux/menu/selector'
import { navigate } from '../../services/navigation'
import { useUserAuthentication } from '../../services/userAuthentication/index'
import { theme } from '../../theme'
import { MyAccountItem } from './components/MyAccountItem.component'

const LOGO_HEIGHT = 76

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: theme.padding.x3,
  },
  title: {
    fontSize: 30,
    lineHeight: 30,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.colors.black,
    marginVertical: theme.padding.x4,
    fontWeight: 'bold',
  },
  logo: {
    height: LOGO_HEIGHT,
    width: '100%',
    marginBottom: theme.padding.x2,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
})

export const MyAccount: FunctionComponent = () => {
  const menu = useSelector(menuSelector)

  const { signOut } = useUserAuthentication()

  const logoUri = menu && menu.header && menu.header.image

  // const handleAccountDeletion = () => {
  //   navigate(Routes.MyAccountDeletion)
  // }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {!!logoUri && <FastImage style={styles.logo} source={{ uri: logoUri }} resizeMode={FastImage.resizeMode.contain} />}
        <CloseButton />
        <Text style={styles.title}>{i18n.t('myAccount.title')}</Text>
        <Text style={styles.subtitle}>{i18n.t('myAccount.parameters.title').toUpperCase()}</Text>
        {/* <MyAccountItem
          iconName="person"
          onPress={() => {
            navigate(Routes.PersonalInfo)
          }}
          label={i18n.t('myAccount.parameters.personalInfo')}
        /> */}
        <MyAccountItem
          iconName="key"
          onPress={() => {
            navigate(Routes.ChangePassword)
          }}
          label={i18n.t('myAccount.parameters.changePassword')}
        />
        {/* <MyAccountItem iconName="close" onPress={handleAccountDeletion} label={i18n.t('myAccount.parameters.deleteAccount')} /> */}
        <MyAccountItem
          iconName="log-out"
          onPress={async () => {
            signOut()
          }}
          label={i18n.t('myAccount.parameters.logout')}
        />
        {/* <Text style={styles.subtitle}>{i18n.t('myAccount.preferences.title').toUpperCase()}</Text>
        <MyAccountItem iconName="notifications" onPress={() => {}} label={i18n.t('myAccount.preferences.notifications')} /> */}
      </View>
    </View>
  )
}
