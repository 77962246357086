import { ActualiteItemResult } from '../../api/Actualites'
import { NewsResult } from '../../api/Actualites/types'

export type ActualitesState = {
  [key: number]: ActualiteItemResult
}

export type NewsState = {
  paging: NewsResult
}

export enum ActualitesActionTypes {
  LOAD_ACTUALITE = 'LOAD_ACTUALITE',
  LOAD_NEWS = 'LOAD_NEWS',
  STORE_NEWS = 'STORE_NEWS',
  STORE_ACTUALITE = 'STORE_ACTUALITE',
  REFRESH_NEWS = 'REFRESH_NEWS',
  CLEAR_NEWS = 'CLEAR_NEWS',
}
