import React, { FunctionComponent } from 'react'
import { View, Text } from 'native-base'
import { StyleSheet, TextStyle } from 'react-native'
import { ReferentielItemScheduleResult } from '../../../api/Referentiels/types'
import { dateToHuge, dateToDayOfMonth, dateToMonthShort } from '../../../services/dateFormatter'
import { capitalize } from '../../../services/textUtil'
import { theme } from '../../../theme'
import { i18n } from '../../../lib/i18n'

interface AgendaScheduleProps {
  schedule: ReferentielItemScheduleResult | null
  colorStyle: TextStyle
}

export const AgendaSchedule: FunctionComponent<AgendaScheduleProps> = ({ schedule, colorStyle }) => {
  if (!schedule || (!schedule.start && !schedule.end)) {
    return null
  }

  if (schedule.start) {
    if (!schedule.end || schedule.end === schedule.start) {
      const date = capitalize(dateToHuge(schedule.start))
      return (
        <View>
          <Text style={colorStyle}>{date}</Text>
        </View>
      )
    }
  }
  const startDay = schedule.start ? capitalize(dateToDayOfMonth(schedule.start)) : ''
  const startMonth = schedule.start ? capitalize(dateToMonthShort(schedule.start)) : ''
  const endDay = schedule.end ? capitalize(dateToDayOfMonth(schedule.end)) : ''
  const endMonth = schedule.end ? capitalize(dateToMonthShort(schedule.end)) : ''

  const renderText = (value: string) => <Text style={[styles.bold, colorStyle]}>{value}</Text>

  return (
    <View style={styles.content}>
      <View style={styles.day}>
        {renderText(startDay)}
        <Text style={[styles.bold, colorStyle]}>{startMonth}</Text>
      </View>
      <Text style={colorStyle}>{i18n.t('agenda.at')}</Text>
      <View style={styles.day}>
        {renderText(endDay)}
        <Text style={[styles.bold, colorStyle]}>{endMonth}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  day: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: theme.padding.x1,
  },
  bold: {
    fontWeight: 'bold',
  },
})
