import { ItemAction, PagingResult } from '../types'

export interface FavoritesUserItemResult extends MansoryItemResult {
  id: number
  ordre: number
  libelle: string
  libelleIcone: string | null
  info: string
  image: string
  color: FavoritesColor
  actif: boolean
  poolInterval: number | null
  action: ItemAction
}

export interface MansoryItemResult {
  id: number
  sizeX?: number | null
}

export interface FavoritesColor {
  backgroundColor: string
  borderColor: string
}

export interface FavoriteCommonItem {
  id: number
  title: string
  header: FavoriteHeader | null
  image: string
  footer: FavoriteFooter | null
}

export interface FavoriteHeader {
  backgroundColor: string | null
  fontColor: string | null
  title: string
}

export interface FavoriteFooter {
  backgroundColor: string
  fontColor: string
  data: ExtraData[]
}

export interface CatalogFavoritesItem extends FavoriteCommonItem {
  type: CatalogType
}

export interface FavorieUserItem extends FavoriteCommonItem {
  type: CatalogUserType
  action: ItemAction | null
  schedule: number | null
}

export interface ExtraData {
  iconURI?: string
  title: string
}

export interface FavorieUserItemAdd {
  id: number
  color: string
  isAddButton: boolean
}

export type CatalogType = 'default' | 'thematic' | 'intermediate' | 'garbageCollection'

export type CatalogUserType = 'default' | 'thematic' | 'agenda' | 'urgentCall' | 'schoolFood' | 'cinema' | 'velib' | 'parking' | 'garbageCollection'

export type FavoritesCatalogResult = PagingResult<CatalogFavoritesItem>

export interface CatalogFavoritesElement {
  fontColor: string
  backgroundColor: string
  data: string[]
}

export interface FavoriteSelectItem {
  id: number
  title: string
  description: string
}

export interface FavoriteConfig {
  imgAddFull?: string
  imgValidate?: string
  imgAddLight?: string
  imgCustomize?: string
}

export enum FavoriteSegment {
  MOST_USED,
  BY_THEMATIC,
  BY_NAME,
}

export enum FavoriteSegmentLabel {
  MOST_USED = 'Les plus utilisés',
  BY_THEMATIC = 'Par thématique',
  BY_NAME = 'Par nom',
}
