import React, { FunctionComponent } from 'react'

import { Text, View } from 'native-base'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { CantineEliorPlatResult } from '../../../api/CantineElior/types'
import { themeSelector } from '../../../redux/theme/selector'
import { theme } from '../../../theme'

interface CantinePlatProps {
  plat: CantineEliorPlatResult
}
export const CantinePlat: FunctionComponent<CantinePlatProps> = ({ plat }) => {
  const themeSelect = useSelector(themeSelector)
  const colorText = (themeSelect && themeSelect.brandPrimary) || theme.colors.silver

  const normes = plat.NormeQualites.map((object, index) => (
    <Text key={index} style={[styles.note, { color: colorText }]}>
      {object.Code}
    </Text>
  ))

  return (
    <View style={styles.content}>
      <Text style={styles.plat}>
        {plat.Libelle} {normes}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  note: {
    opacity: 0.4,
  },
  content: { flex: 1, alignItems: 'center' },
  plat: { textAlign: 'center' },
})
