import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { AuthenticationActionTypes } from './types'

export const AuthenticationActions = {
  initializeAuhtentication: (uuid?: string) => createAction(AuthenticationActionTypes.INIT_AUTHENTICATION, { uuid }),
  storeUserCredentials: (uuid: string, password: string) => createAction(AuthenticationActionTypes.STORE_USER_CREDENTIALS, { uuid, password }),
  signInUser: () => createAction(AuthenticationActionTypes.SIGN_IN_USER),
  storeApiToken: (accessToken: string, tokenType: string, refreshToken: string) =>
    createAction(AuthenticationActionTypes.STORE_API_TOKEN, { accessToken, tokenType, refreshToken }),
  set2fa: (set2fa: boolean) => createAction(AuthenticationActionTypes.STORE_TOKEN_SET_2FA, { set2fa }),
  logout: (uuid: string, password: string) => createAction(AuthenticationActionTypes.LOGOUT, { uuid, password }),
  storeIsConnected: (isConnected: boolean) => createAction(AuthenticationActionTypes.IS_CONNECTED, { isConnected }),
}

export type AuthenticationActionObjectTypes = ActionsUnion<typeof AuthenticationActions>
