import { Linking, Platform } from 'react-native'
import { trackEvent } from '../matomo/matomo.service'

export const phoneCall = async (phone: string) => {
  if (!phone) {
    return
  }

  try {
    const phoneNumber = Platform.OS === 'android' ? `tel:${phone}` : `telprompt:${phone}`
    await Linking.openURL(phoneNumber)
    await trackEvent({
      category: 'Téléphone',
      actionName: 'Appel',
      eventName: "Appui sur le bouton d'appel",
      value: phone,
    })
  } catch (error) {}
}
