import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { GrcYpokActionTypes } from './types'
import { GrcYpokConfigResult } from '../../api/GrcYpok/types'

export const GrcYpokActions = {
  loadYpokConfig: () => createAction(GrcYpokActionTypes.LOAD_YPOK_CONFIG),
  storeYpokConfig: (config: GrcYpokConfigResult) => createAction(GrcYpokActionTypes.STORE_YPOK_CONFIG, { config }),
}

export type GrcYpokActionObjectTypes = ActionsUnion<typeof GrcYpokActions>
