import { Card, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import Geolocation from 'react-native-geolocation-service'
import { AddressResult } from '../../api/Geolocation/types'
import { i18n } from '../../lib/i18n'
import { findAddress } from '../../services/geolocation'
import { checkLocation } from '../../services/Permissions/Permissions.service'
import { theme } from '../../theme'
import { Body } from '../NativeBaseLegacy'

interface Coords {
  lat: number
  lng: number
}

const NUMBER_OF_DECIMAL_TO_DISPLAY = 5

const LAT_AND_LNG_INITIAL = 0

interface GpsPositionCardProps {
  isRefreshing: boolean
}

export const GpsPositionCard: FunctionComponent<GpsPositionCardProps> = ({ isRefreshing }) => {
  const [currentAddress, setCurrentAddress] = useState<AddressResult | null>()

  const [currentCoords, setCurrentCoords] = useState<Coords>({
    lat: LAT_AND_LNG_INITIAL,
    lng: LAT_AND_LNG_INITIAL,
  })

  const [IsRefreshingState, setIsRefreshingState] = useState<boolean>()
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)
  const [hasErrorLocation, setHasErrorLocation] = useState<boolean>(false)

  const getAddress = async (lat: number, lng: number) => {
    const addr: AddressResult | null = await findAddress(lat, lng)
    setCurrentAddress(addr)
    setIsRefreshingState(false)
  }

  const smallerCoords = (aCoord: number) => aCoord.toFixed(NUMBER_OF_DECIMAL_TO_DISPLAY)

  const getGeolocation = () => {
    setIsRefreshingState(true)
    setHasErrorLocation(false)
    Geolocation.getCurrentPosition(
      position => {
        setCurrentCoords({ lat: position.coords.latitude, lng: position.coords.longitude })
        getAddress(position.coords.latitude, position.coords.longitude)
      },
      () => {
        setHasErrorLocation(true)
        return null
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
    )
  }

  useEffect(() => {
    checkLocation().then(
      hasPermission => {
        setHasPermission(hasPermission)
        if (hasPermission) {
          getGeolocation()
        }
      },
      () => {
        setHasPermission(false)
      },
    )
  }, [])

  useEffect(() => {
    if (isRefreshing) {
      getGeolocation()
    }
  }, [isRefreshing])

  const renderDisplayPosition = () => {
    const coords = `${i18n.t('gpsCard.gps')}${smallerCoords(currentCoords.lat)}, ${smallerCoords(currentCoords.lng)}`

    return (
      <View>
        {currentAddress ? <Text style={styles.text}>{currentAddress.formattedAddress}</Text> : null}
        <Text style={styles.text}>{coords}</Text>
      </View>
    )
  }

  const renderPositionCard = () => {
    if (hasErrorLocation) {
      return <Text style={styles.textred}>{i18n.t('gpsCard.errorLocation')}</Text>
    }
    if (hasPermission === null) {
      return <Spinner />
    }
    if (hasPermission) {
      return IsRefreshingState ? <Spinner /> : renderDisplayPosition()
    }
    return <Text style={styles.textred}>{i18n.t('gpsCard.errorMessage')}</Text>
  }

  return (
    <Card m="1" p="1">
      <Body style={styles.card}>
        <Text style={styles.bold}>{i18n.t('gpsCard.yourPosition')}</Text>
        {renderPositionCard()}
      </Body>
    </Card>
  )
}

interface Style {
  bold: ViewStyle
  card: ViewStyle
  text: ViewStyle
  textred: ViewStyle
}

const styles = StyleSheet.create<Style>({
  bold: { fontWeight: 'bold', paddingBottom: theme.padding.x1 },
  text: {
    paddingBottom: theme.padding.x1,
    textAlign: 'center',
  },
  card: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.padding.x2,
    backgroundColor: theme.colors.white,
  },
  textred: {
    paddingBottom: theme.padding.x1,
    textAlign: 'center',
    color: theme.colors.red,
  },
})
