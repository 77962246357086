/* eslint-disable prettier/prettier */
import { StackScreenProps } from '@react-navigation/stack'
import { Button, Icon } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { CitysSelect } from '../../components/CitysSelect/CitysSelect.component'
import { Content } from '../../components/NativeBaseLegacy'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType, RoutesCityAdd } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'

export const MyCitys: FunctionComponent<StackScreenProps<ActionViewParamsType, 'MyCitys'>> = ({
  navigation,
}) => {
  const theme = useSelector(themeSelector)
  return (
    <Page
      left={<LeftButtonBack />}
      title={i18n.t('myCitys.title')}
      right={
        <Button
          variant="ghost"
          onPress={() => navigation.navigate({ name: RoutesCityAdd.cityAdd, params: {} })}
        >
          <Icon as={Ionicons} name="add-circle" color={theme?.titleFontColor} size={6} />
        </Button>
      }
    >
      <Content style={styles.content}>
        <CitysSelect />
      </Content>
    </Page>
  )
}
const styles = StyleSheet.create({
  content: { flex: 1 },
})
