import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { ThemeResult } from '../../api/Theme/types'
import { LoadThemeActionTypes } from './types'

export const ThemeActions = {
  loadThemeConfig: () => createAction(LoadThemeActionTypes.LOAD_THEME),
  storeThemeConfig: (theme: ThemeResult) => createAction(LoadThemeActionTypes.STORE_THEME, { theme }),
}

export type ThemeActionObjectTypes = ActionsUnion<typeof ThemeActions>
