import { capitalize } from 'lodash'
import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { CantineEliorItemResult } from '../../api/CantineElior/types'
import { dateToHugeFromISO } from '../../services/dateFormatter'
import { theme } from '../../theme'
import { CantinePrestation } from './components'

export interface CantineEliorDayProps {
  day: CantineEliorItemResult
}

export const CantineEliorDay: FunctionComponent<CantineEliorDayProps> = ({ day }) => {
  const prestas = day.Prestations.map((object, index) => <CantinePrestation key={index} presta={object} />)

  return (
    <View style={styles.content}>
      {day.Date ? <Text style={styles.title}>{capitalize(dateToHugeFromISO(day.Date))}</Text> : null}
      {prestas}
    </View>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1, paddingTop: theme.padding.x2 },
  title: {
    textAlign: 'center',
    fontSize: theme.textSize.x2 + theme.textSize.x1 / 3,
  },
})
