import { FavoritesCatalogResult } from '../../api/Favorites/types'

export type FavoritesCatalogState = {
  favoritesCatalogByMostUsed: FavoritesCatalogResult
  favoritesCatalogByThematic: FavoritesCatalogResult
  favoritesCatalogByName: FavoritesCatalogResult
  favoritesSubThematique: {
    [key: number]: FavoritesCatalogResult
  }
}

export enum FavoritesCatalogActionTypes {
  STORE_FAVORITES_USER = 'STORE_FAVORITES_USER',

  LOAD_FAVORITES_CATALOG_BY_MOST_USED = 'LOAD_FAVORITES_CATALOG_BY_MOST_USED',
  STORE_FAVORITES_CATALOG_BY_MOST_USED = 'STORE_FAVORITES_CATALOG_BY_MOST_USED',
  REFRESH_FAVORITES_CATALOG_BY_MOST_USED = 'REFRESH_FAVORITES_CATALOG_BY_MOST_USED',
  CLEAR_FAVORITES_CATALOG_BY_MOST_USED = 'CLEAR_FAVORITES_CATALOG_BY_MOST_USED',

  LOAD_FAVORITES_CATALOG_BY_THEMATIC = 'LOAD_FAVORITES_CATALOG_BY_THEMATIC',
  STORE_FAVORITES_CATALOG_BY_THEMATIC = 'STORE_FAVORITES_CATALOG_BY_THEMATIC',
  REFRESH_FAVORITES_CATALOG_BY_THEMATIC = 'REFRESH_FAVORITES_CATALOG_BY_THEMATIC',
  CLEAR_FAVORITES_CATALOG_BY_THEMATIC = 'CLEAR_FAVORITES_CATALOG_BY_THEMATIC',

  LOAD_FAVORITES_CATALOG_BY_NAME = 'LOAD_FAVORITES_CATALOG_BY_NAME',
  STORE_FAVORITES_CATALOG_BY_NAME = 'STORE_FAVORITES_CATALOG_BY_NAME',
  REFRESH_FAVORITES_CATALOG_BY_NAME = 'REFRESH_FAVORITES_CATALOG_BY_NAME',
  CLEAR_FAVORITES_CATALOG_BY_NAME = 'CLEAR_FAVORITES_CATALOG_BY_NAME',

  LOAD_FAVORITES_SUB_THEMATIC_CATALOG = 'LOAD_FAVORITES_SUB_THEMATIC_CATALOG',
  STORE_FAVORITES_SUB_THEMATIC_CATALOG = 'STORE_FAVORITES_SUB_THEMATIC_CATALOG',
  STORE_CLEAR_FAVORITES_SUB_THEMATIC_CATALOG = 'STORE_CLEAR_FAVORITES_SUB_THEMATIC_CATALOG',
  REFRESH_FAVORITES_SUB_THEMATIC_CATALOG = 'REFRESH_FAVORITES_SUB_THEMATIC_CATALOG',
  CLEAR_FAVORITES_SUB_THEMATIC_CATALOG = 'CLEAR_FAVORITES_SUB_THEMATIC_CATALOG',
}
