import { StackScreenProps } from '@react-navigation/stack'
import { View } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { FastImageBackground } from '../../components/FastImageBackground'
import { IntermediatePageItem } from '../../components/IntermediatePageItem'
import { Body, Header, Left } from '../../components/NativeBaseLegacy'
import { LeftRoundButtonBack } from '../../components/Page/Page.component'
import { ActionViewParamsType } from '../../navigation/Routes'
import { IntermediatePageActions } from '../../redux/intermediatePage/actions'
import { intermediatePageSelector } from '../../redux/intermediatePage/selector'
import { RootState } from '../../redux/reducers'
import { theme } from '../../theme'
import { apiTokenSelector } from '../../redux/authentication/selectors'

const IOS_TRANSPARENT_STATUS_BAR = 'light-content'
const ANDROID_TRANSPARENT_STATUS_BAR = 'rgba(0,0,0,0.251)'
const ITEM_CONTAINER_HEIGHT = 240

const IntermediatePage: FunctionComponent<StackScreenProps<ActionViewParamsType, 'IntermediatePage'>> = ({ route }) => {
  const id = route.params.id as number
  const dispatch = useDispatch()
  const authtoken = useSelector((state: RootState) => apiTokenSelector(state))

  const page = useSelector((state: RootState) => intermediatePageSelector(state, id))

  useEffect(() => {
    if (authtoken) dispatch(IntermediatePageActions.loadIntermediatePage(id))
  }, [authtoken])

  if (!page) {
    return null
  }

  return (
    <View style={styles.pageContainer}>
      <FastImageBackground
        source={{
          uri: page.image,
        }}
        style={styles.coverImage}
        imageStyle={styles.coverImageStyle}
        resizeMode="cover"
      >
        <View style={styles.transparentContainer}>
          <Header iosBarStyle={IOS_TRANSPARENT_STATUS_BAR} androidStatusBarColor={ANDROID_TRANSPARENT_STATUS_BAR} transparent>
            <Left style={styles.left}>
              <LeftRoundButtonBack />
            </Left>
            <Body style={styles.body} />
          </Header>
          <SafeAreaView style={styles.safeArea}>
            <View style={styles.listContainer}>
              {page.items.map(item => (
                <View key={item.id} style={styles.itemContainer}>
                  <IntermediatePageItem item={item} />
                </View>
              ))}
            </View>
          </SafeAreaView>
        </View>
      </FastImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
  },
  left: {
    zIndex: 2,
    flex: 1,
    width: 50,
  },
  body: {
    zIndex: 1,
    flex: 1,
  },
  coverImage: {
    flex: 1,
    width: theme.deviceWidth,
    height: theme.deviceHeight,
  },
  coverImageStyle: {
    width: theme.deviceWidth,
    height: theme.deviceHeight - ITEM_CONTAINER_HEIGHT,
  },
  transparentContainer: {
    backgroundColor: theme.colors.transparent,
    maxWidth: '100%',
    height: theme.deviceHeight,
  },
  safeArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: theme.deviceHeight - ITEM_CONTAINER_HEIGHT,
    backgroundColor: theme.colors.white,
  },
  listContainer: {
    // flex: 1,
    // height: ITEM_CONTAINER_HEIGHT,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  itemContainer: {
    width: '33%',
  },
})

export { IntermediatePage }
