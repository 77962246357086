import * as Sentry from '@sentry/react-native'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { LogBox } from 'react-native'
import 'react-native-gesture-handler'
import 'react-native-reanimated'
import { compose } from 'recompose'
import { env } from './environment'
import { withBootApp } from './hoc/withBootApp'
import { withRedux } from './hoc/withRedux'
import { withSafeArea } from './hoc/withSafeArea'
import { withTranslation } from './hoc/withTranslation'
import { storeManager } from './lib/storeManager'
import { MainNavigationContainer } from './navigation'
import { store as reduxStore } from './redux/store'

if (env().ENV !== 'local') {
  Sentry.init({
    dsn: env().sentryDsn,
    environment: env().ENV,
    tracesSampleRate: 1.0,
  })
}

LogBox.ignoreLogs(['componentWillReceiveProps', 'componentWillMount'])

const enhance = compose(withRedux, withTranslation, withSafeArea, withBootApp)

const AppContainer = enhance(MainNavigationContainer)

const App: FunctionComponent = () => {
  const [store, setStore] = useState()
  useEffect(() => {
    storeManager.store = reduxStore
    const newStore = storeManager.store as never
    setStore(newStore)
  }, [])

  if (!store) {
    return null
  }

  return <AppContainer />
}

export default App
