import { Reducer } from 'redux'
import { ActualitesState, ActualitesActionTypes, NewsState } from './types'
import { ActualitesActionObjectTypes } from './actions'

const initialActualitesState: ActualitesState = {}

const initialNewsState: NewsState = {
  paging: {
    content: [],
    last: undefined,
    size: undefined,
    page: undefined,
    totalElements: undefined,
    totalPages: undefined,
  },
}

export const actualitesReducer: Reducer<ActualitesState, ActualitesActionObjectTypes> = (state = initialActualitesState, action) => {
  switch (action.type) {
    case ActualitesActionTypes.STORE_ACTUALITE:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    default:
      return state
  }
}

export const newsReducer: Reducer<NewsState, ActualitesActionObjectTypes> = (state = initialNewsState, action) => {
  switch (action.type) {
    case ActualitesActionTypes.STORE_NEWS:
      return {
        ...state,
        paging: {
          last: action.payload.newsResult.last,
          page: action.payload.newsResult.page,
          size: action.payload.newsResult.size,
          totalElements: action.payload.newsResult.totalElements,
          totalPages: action.payload.newsResult.totalPages,
          content: [...(state.paging ? state.paging.content : []), ...action.payload.newsResult.content],
        },
      }
    case ActualitesActionTypes.CLEAR_NEWS:
      return initialNewsState
    default:
      return state
  }
}
