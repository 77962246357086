import { BaseApi } from '../BaseApi'
import { MovieListResult, MovieItemResult, MovieInTheater } from './types'
import { urlApi } from '../url'

export class MovieApi {
  public static async loadMovieList(id: number, inTheater: MovieInTheater, page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.movie.list)
      .query({
        page,
        size,
        id,
        etat: inTheater,
      })
      .get()
      .json(json => json) as Promise<MovieListResult>
  }

  public static async loadMovieItem(id: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.movie.item(id))
      .get()
      .json(json => json) as Promise<MovieItemResult>
  }
}
