import { View } from 'native-base'
import React, { PureComponent } from 'react'
import { StyleSheet } from 'react-native'
import { FormImage } from '../../../api/Form/types'
import { theme as themeBase } from '../../../theme'
import { ImageUpload } from '../ImageUpload.component'
import { LabelInput } from '../components'
import { FormElementProps, ImageValue } from '../types'

const DEFAULT_IMAGE_LIST_LENGTH = 3

interface ImageFieldState {
  images: ImageValue[]
}

const uniqueId = () => Date.now()

const mapToImageValues = (images: string[]): ImageValue[] => Array.from(images || [], data => ({ id: uniqueId(), data }))

export class ImageFieldClass extends PureComponent<FormElementProps<FormImage, string[]>, ImageFieldState> {
  public focus = () => {}

  private loadingImages = new Set<number>()

  public state: ImageFieldState = {
    images: mapToImageValues(this.props.value),
  }

  private updateImage(item: ImageValue) {
    const { images } = this.state
    const { value, setFieldValue } = this.props

    if (!value) {
      setFieldValue(this.elementId, [])
    }

    const newImages: ImageValue[] = [...images.filter(img => img.id !== item.id), { id: uniqueId(), data: item.data }]

    this.setState({
      images: newImages,
    })
    setFieldValue(
      this.elementId,
      newImages.map(img => img.data),
    )
  }

  private removeImage(id: number) {
    const { images } = this.state
    const { value, setFieldValue } = this.props

    if (!value) {
      setFieldValue(this.elementId, [])
    }

    const newImages = images.filter(img => img.id !== id)
    this.setState({
      images: newImages,
    })
    setFieldValue(
      this.elementId,
      newImages.map(img => img.data),
    )
  }

  private renderImageUploader() {
    const { field, setFieldValue, value } = this.props
    if (!value) {
      setFieldValue(this.elementId, [])
    }
    return Array.from({ length: field.constraint.nbImage || DEFAULT_IMAGE_LIST_LENGTH }, (_element, index) => (
      <ImageUpload
        {...this.props}
        key={index}
        id={index}
        onAdd={item => this.updateImage(item)}
        onRemove={id => this.removeImage(id)}
        onLoad={id => this.loading(id)}
        onEndLoad={id => this.loadFinished(id)}
      />
    ))
  }

  private get elementId() {
    const { field } = this.props
    return `${field.id}`
  }

  private loading(id: number) {
    this.loadingImages.add(id)
    if (this.loadingImages.size > 0 && this.props.onDataLoad) {
      this.props.onDataLoad()
    }
  }

  private loadFinished(id: number) {
    this.loadingImages.delete(id)
    if (this.loadingImages.size === 0 && this.props.onDataEndLoad) {
      this.props.onDataEndLoad()
    }
  }

  public render() {
    const { field } = this.props

    return (
      <View>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        <View style={styles.content}>{this.renderImageUploader()}</View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    marginRight: themeBase.padding.x1 * -1,
  },
})
