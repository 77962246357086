import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { WeeklySchedulesActionTypes } from './types'
import { WeeklySchedulesItemResult } from '../../api/WeeklySchedules/types'

export const WeeklySchedulesActions = {
  loadWeeklySchedules: (id: number, referentiels: number[]) => createAction(WeeklySchedulesActionTypes.LOAD_WEEKLY_SCHEDULES, { id, referentiels }),
  storeWeeklySchedules: (id: number, items: WeeklySchedulesItemResult[]) => createAction(WeeklySchedulesActionTypes.STORE_WEEKLY_SCHEDULES, { id, items }),
}

export type WeeklySchedulesActionObjectTypes = ActionsUnion<typeof WeeklySchedulesActions>
