import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Spinner } from 'native-base'
import React, { FunctionComponent, useEffect } from 'react'
import { FlatList, FlexStyle, RefreshControl, SafeAreaView, StyleSheet, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { GpsPositionCard } from '../../components/GpsPositionCard'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { PhoneNumberItem } from '../../components/PhoneNumberItem'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { UrgentNumbersAction } from '../../redux/UrgentNumbers/action'
import { lastPageSelector, urgentNumbersSelector } from '../../redux/UrgentNumbers/selector'

export const UrgentNumbers: FunctionComponent<StackScreenProps<ActionViewParamsType, 'UrgentNumbers'>> = ({ route }) => {
  const title = route.params.titre

  const dispatch = useDispatch()
  const urgentNumbers = useSelector(urgentNumbersSelector)
  const isLoadingUrgentNumbers = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_URGENTNUMBERS))
  const isRefreshingUrgentNumbers = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_URGENTNUMBERS))
  const isLastPage = useSelector(lastPageSelector)

  useEffect(() => {
    dispatch(UrgentNumbersAction.loadUrgentNumbers(true))
  }, [])

  const isLoadingPage = () => isEmpty(urgentNumbers) && isLoadingUrgentNumbers && !isRefreshingUrgentNumbers

  const isLoadingMore = () => !isLastPage && !isRefreshingUrgentNumbers

  const canLoadMore = () => !isLoadingUrgentNumbers && isLoadingMore()

  const onRefresh = () => {
    dispatch(UrgentNumbersAction.refreshUrgentNumbers())
  }

  const onEndReached = () => (canLoadMore() ? dispatch(UrgentNumbersAction.loadUrgentNumbers(false)) : null)

  const renderFooter = () => {
    if (isLoadingMore()) {
      return <Spinner />
    }
    return null
  }

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <SafeAreaView style={styles.safeArea}>
        {isLoadingPage() ? (
          <Spinner />
        ) : (
          <FlatList
            data={urgentNumbers}
            style={styles.flatlist}
            onEndReachedThreshold={0.5}
            onEndReached={onEndReached}
            keyExtractor={item => item.id.toString()}
            ListEmptyComponent={<ListEmpty />}
            renderItem={({ item }) => <PhoneNumberItem key={item.id} item={item} />}
            ListFooterComponent={renderFooter}
            ListHeaderComponent={<GpsPositionCard isRefreshing={isRefreshingUrgentNumbers} />}
            refreshControl={<RefreshControl refreshing={isRefreshingUrgentNumbers} onRefresh={onRefresh} />}
          />
        )}
      </SafeAreaView>
    </Page>
  )
}

interface Style {
  flatlist: FlexStyle
  safeArea: ViewStyle
}

const styles = StyleSheet.create<Style>({
  flatlist: {
    // padding: theme.padding.x2,
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
})
