/* eslint-disable prettier/prettier */
import { StackScreenProps } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'
import { Button, Icon } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { FieldValueRequest, FormElement } from '../../api/Form/types'
import { ReferentielsApi } from '../../api/Referentiels'
import { ReferentielFilters } from '../../api/types'
import { DefaultFieldValue } from '../../components/Fields/types'
import { LeftButtonBack, Page } from '../../components/Page'
import { ReferentielList as Referentiels } from '../../components/ReferentielList'
import { ReferentielSearch } from '../../components/ReferentielSearch'
import { ActionViewParamsType } from '../../navigation/Routes'
import { themeSelector } from '../../redux/theme/selector'
import { sanitizeFormikValues } from '../../services/form'

const ReferentielList: FunctionComponent<
  StackScreenProps<ActionViewParamsType, 'ReferentielList'>
> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.idParams as number
  const referentielFilters = route.params.referentielFilters as ReferentielFilters

  const theme = useSelector(themeSelector)
  const [searchFields, setSearchFields] = useState<FormElement[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [displaySearch, setDisplaySearch] = useState(false)
  const [mapFieldIdTypes, setMapFieldIdTypes] = useState<Map<number, string | null>>(new Map())
  const [defaultValues, setDefaultValues] = useState<DefaultFieldValue | null>(null)
  const [fieldValues, setFieldValues] = useState<FieldValueRequest[] | null>(null)

  const initSearchFields = async () => {
    if (referentielFilters.referentielType) {
      const searchFieldResult = await ReferentielsApi.loadSearchFields(
        referentielFilters.referentielType,
        referentielFilters.categories,
      )
      setSearchFields(searchFieldResult && searchFieldResult.elements)
    }
  }

  const initializeMapFieldTypeById = () => {
    if (isEmpty(searchFields)) {
      return
    }
    const map = new Map<number, string | null>()
    searchFields.forEach(field => map.set(field.id, field.type))
    setMapFieldIdTypes(map)
  }

  const resetDisplaySearch = () => {
    if (displaySearch) {
      setIsSearching(false)
      setDisplaySearch(false)
    }
  }

  useEffect(() => {
    initSearchFields()
  }, [])
  useEffect(initializeMapFieldTypeById, [searchFields])

  const querySearch = async (values: DefaultFieldValue) => {
    setIsSearching(true)
    setTimeout(() => {
      setFieldValues(sanitizeFormikValues(values, mapFieldIdTypes, false))
      setDefaultValues(values)
    }, 300)
    return new Map()
  }

  return (
    <Page
      left={<LeftButtonBack />}
      title={title}
      right={
        !isEmpty(searchFields) && (
          <Button variant="ghost" onPress={() => setDisplaySearch(true)}>
            <Icon as={Ionicons} name="options" style={styles.optionIcon} color={theme?.titleFontColor} size={6} />
          </Button>
        )
      }
    >
      <SafeAreaView style={styles.safeArea}>
        <Referentiels
          id={id}
          referentielFilters={referentielFilters}
          fieldValues={fieldValues}
          onLoaded={resetDisplaySearch}
        />
      </SafeAreaView>
      {displaySearch && (
        <ReferentielSearch
          isVisible={displaySearch}
          onClose={() => setDisplaySearch(false)}
          fields={searchFields}
          defaultValues={defaultValues}
          isSearching={isSearching}
          submit={querySearch}
        />
      )}
    </Page>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  optionIcon: {
    transform: [{ rotate: '90deg' }],
  },
})

export { ReferentielList }
