import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from '@redux-saga/core/effects'
import { CarouselApi } from '../../api/Carousel'
import { CarouselResult } from '../../api/Carousel/types'
import { GridPageApi } from '../../api/GridPage'
import { ActualitesResult, GridAirQualityConfig, GridPageResult, GridTideConfig, RssFeedConfigResult, WeatherResult } from '../../api/GridPage/types'
import { isOfflineError } from '../../services/errorManager'
import { getLoadingCarouselName } from '../../services/loading'
import { LoadingActions } from '../loading/actions'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { GridPageActionObjectTypes, GridPageActions } from './actions'
import { GridPageActionTypes } from './types'

function* loadGridPageSaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_GRID_PAGE>) {
  try {
    const gridPageConfig: GridPageResult = yield call(GridPageApi.load, action.payload.width, action.payload.id)
    const id = action.payload.id ? action.payload.id : 0
    yield put(GridPageActions.storeGridPageConfig(gridPageConfig, id))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR =', error)
    }
  }
}

function* loadCarousel(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_CAROUSEL>) {
  const loaderName = getLoadingCarouselName(action.payload.id)
  try {
    yield put(LoadingActions.showLoader(loaderName))
    const carousel: CarouselResult = yield call(CarouselApi.load, action.payload.id)
    yield put(GridPageActions.storeCarousel(action.payload.id, carousel))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  } finally {
    yield put(LoadingActions.hideLoader(loaderName))
  }
}

function* loadActualitesSaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_ACTUALITES>) {
  try {
    const result: ActualitesResult = yield call(GridPageApi.loadActualites, action.payload.id)
    yield put(GridPageActions.storeActualites(action.payload.id, result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* loadWeatherSaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_WEATHER>) {
  try {
    const result: WeatherResult = yield call(GridPageApi.loadWeather, action.payload.id)
    yield put(GridPageActions.storeWeather(action.payload.id, result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* loadRssFeedSaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_RSS_FEED_CONFIG>) {
  try {
    const result: RssFeedConfigResult = yield call(GridPageApi.loadRssFeed, action.payload.id)
    yield put(GridPageActions.storeRssFeedConfig(action.payload.id, result))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error('ERROR = ', error)
    }
  }
}

function* loadGridPageAirQualitySaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_GRID_AIR_QUALITY_CONFIG>) {
  try {
    const aqResult: GridAirQualityConfig = yield call(GridPageApi.loadGridPageAirQuality, action.payload.id)
    yield put(GridPageActions.storeGridAirQualityConfig(action.payload.id, aqResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
    }
  }
}

function* loadGridPageTideSaga(action: ActionsOfType<GridPageActionObjectTypes, GridPageActionTypes.LOAD_GRID_AIR_QUALITY_CONFIG>) {
  try {
    const tResult: GridTideConfig = yield call(GridPageApi.loadGridPageTide, action.payload.id)
    yield put(GridPageActions.storeGridTideConfig(action.payload.id, tResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
    }
  }
}
export function* loadPageWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_GRID_PAGE, addLoader(loadGridPageSaga, LoaderName.LOAD_GRID_PAGE))
}

export function* loadCarouselWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_CAROUSEL, loadCarousel)
}

export function* loadGridPageActualiteWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_ACTUALITES, addLoader(loadActualitesSaga, LoaderName.LOAD_GRID_ACTUALITES))
}

export function* loadGridWeatherWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_WEATHER, addLoader(loadWeatherSaga, LoaderName.LOAD_GRID_WHEATHER))
}

export function* loadGridRssFeedWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_RSS_FEED_CONFIG, addLoader(loadRssFeedSaga, LoaderName.LOAD_GRID_RSS_FEED_CONFIG))
}

export function* loadGridAirQualityWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_GRID_AIR_QUALITY_CONFIG, addLoader(loadGridPageAirQualitySaga, LoaderName.LOAD_GRID_AIR_QUALITY))
}

export function* loadGridTideWatcher() {
  yield takeEvery(GridPageActionTypes.LOAD_GRID_TIDE_CONFIG, addLoader(loadGridPageTideSaga, LoaderName.LOAD_GRID_TIDE))
}
