import AsyncStorage from '@react-native-async-storage/async-storage'
import _ from 'lodash'
import MatomoTracker from 'matomo-tracker-react-native'
import { v4 as uuidv4 } from 'uuid'

import { env } from '../../environment/index'
import { env as envGeneral } from '../../environment/index.general'

export const getUid = async () => {
  let deviceId = await AsyncStorage.getItem('deviceUUID')
  if (!deviceId) {
    deviceId = uuidv4()
    await AsyncStorage.setItem('deviceUUID', deviceId as string)
  }
  return deviceId as string
}

export const CONFIG_MATOMO = async () => ({
  urlBase: env().matomoUrlBase, // required
  siteId: env().matomoSiteId, // required, number matching your Matomo project
  userId: await getUid(), // optional, default value: `undefined`.
  log: true, // optional, default value: false. Enables some logs if set to true.
})

export const CONFIG_MATOMO_GENERAL = async () => ({
  urlBase: envGeneral.matomoUrlBase, // required
  siteId: envGeneral.matomoSiteId, // required, number matching your Matomo project
  userId: await getUid(), // optional, default value: `undefined`.
  log: true, // optional, default value: false. Enables some logs if set to true.
})

interface translationType {
  [key: string]: string
}

const VIEW_NAME_TRANSLATION: translationType = {
  CheatCodes: 'Code de triche',
  Notifications: 'Notifications',
  NotificationSettings: 'Paramètres de notification',
  Home: 'Accueil',
  MasterNavigator: "Page d'accueil",
  IntermediatePage: 'Popup',
  ReferentielList: "Liste d'annuaires",
  Referentiel: 'Annuaire',
  Actualite: 'Actualite',
  Actualites: 'Actualites',
  Cartographie: 'Cartographie',
  Cartography: 'Cartographie',
  CGU: 'CGU',
  Cantine: 'Cantine',
  Cinema: 'Cinema',
  Formulaire: 'Formulaire',
  UrgentNumbers: "numéros d'urgence",
  WeeklySchedules: 'Agenda Annuaires',
  SignalementList: 'Liste de signalement',
  Signalement: 'Signalement',
  ExternalLinks: 'Lien externes',
  AgendaList: "Liste d'agenda",
  CartographieZone: 'Cartographie de zone',
  CinemaSeance: 'Séance de cinéma',
  Gridpage: 'Page dynamique',
  SignalementItem: 'Signalement',
  Promotion: 'Promotion',
  GrcYpok: 'Ypok',
  RssFeed: 'Flux RSS',
  CartographieBeacon: 'Balise cartographie',
  CantineElior: 'Cantine Elior',
  Weather: 'Météo',
  AirQuality: "Qualité de l'air",
  CartographyRealTime: 'Cartographie temps réel',
  TaskReportList: 'Liste des tâches',
  TaskReportItem: 'Tâches',
  SignIn: 'Connexion',
  SignUp: 'Inscription',
  MyAccount: 'Mon compte',
  ResetPassword: 'Réinitialisation du mot de passe',
  PersonalInfo: 'Informations personelles',
  ChangePassword: 'Changement du mot de passe',
  EmailVerificationSent: "Page confirmation d'envoie du mail de vérification",
  FavoriesShop: 'Catalogue favoris',
  FavoriesSubThematic: 'Favoris sous thématique',
  FavoriesMultipleChoice: 'Favoris choix multiple',
  AppExterne: 'Application partenaire',
}

export const translateViewName = (viewName: string) => VIEW_NAME_TRANSLATION[viewName]

export interface trackScreenProps {
  name: string
  params?: {
    idParams: number
    titre: string
    id: string | number
  }
}

interface trackEventProps {
  category: string
  actionName: string
  value: number | string | undefined
  eventName: string
}

export const trackScreen = async (datas: Array<trackScreenProps>) => {
  if (!env().matomoSiteId) return
  const configMatomo = await CONFIG_MATOMO()
  const configMatomoGeneral = await CONFIG_MATOMO_GENERAL()
  const matomo = new MatomoTracker(configMatomo)
  const matomoGeneral = new MatomoTracker(configMatomoGeneral)
  const { path, actionName } = generateMatomoPath(datas)
  await AsyncStorage.setItem('ac-current-path', JSON.stringify({ path, actionName }))
  matomo.trackScreenView({
    name: actionName || 'inconnu',
    userInfo: {
      action_name: actionName,
      url: path,
      rand: Math.floor(Math.random() * (999999999 - 0 + 1) + 0),
    },
  })
  matomoGeneral.trackScreenView({
    name: actionName || 'inconnu',
    userInfo: {
      action_name: actionName,
      url: path,
      rand: Math.floor(Math.random() * (999999999 - 0 + 1) + 0),
    },
  })
}

export const trackEvent = async (params: trackEventProps) => {
  if (!env().matomoSiteId) return
  const configMatomo = await CONFIG_MATOMO()
  const configMatomoGeneral = await CONFIG_MATOMO_GENERAL()
  const matomo = new MatomoTracker(configMatomo)
  const matomoGeneral = new MatomoTracker(configMatomoGeneral)
  const { path, actionName } = await AsyncStorage.getItem('ac-current-path').then(result => JSON.parse(result as string))
  matomo.trackEvent({
    category: path,
    action: params.eventName,
    userInfo: {
      action_name: actionName,
      url: `${path}${params.value}`,
      rand: Math.floor(Math.random() * (999999999 - 0 + 1) + 0),
    },
  })
  matomoGeneral.trackEvent({
    category: path,
    action: params.eventName,
    userInfo: {
      action_name: actionName,
      url: `${path}${params.value}`,
      rand: Math.floor(Math.random() * (999999999 - 0 + 1) + 0),
    },
  })
}

export const generateMatomoPath = (datas: Array<trackScreenProps>) => {
  const path = _.map(datas, ({ params, name }: trackScreenProps) => {
    if (_.isEmpty(params)) return translateViewName(name)
    return params && params.titre ? params.titre : translateViewName(name)
  })
  const translatedName = translateViewName((_.last(datas) as trackScreenProps).name)
  return {
    actionName: translatedName,
    path: `${env().matomoUrlBase}${_.join(path, '/')}/`,
  }
}
