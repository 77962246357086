import { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent } from 'react'
import { GridPageList } from '../../components/GridPageList'
import { LeftButtonBack, Page } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'

export const GridPage: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Gridpage'>> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.id as number

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <GridPageList id={id} />
    </Page>
  )
}
