import { Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { EtatColorResult } from '../../../api/types'
import { theme } from '../../../theme'

interface ReportStatusProps {
  state: EtatColorResult | null
}

export const DisplayImageText: FunctionComponent<ReportStatusProps> = ({ state }) => {
  if (state && state.libelle) {
    return (
      <View style={styles.textImageContainer}>
        <View style={{ backgroundColor: state.color || theme.colors.orange }}>
          <Text style={styles.textImage}>{state.libelle.toUpperCase()}</Text>
        </View>
      </View>
    )
  }
  return null
}

const styles = StyleSheet.create({
  textImageContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderColor: theme.colors.transparent,
  },
  textImage: {
    color: theme.colors.black,
    padding: theme.padding.x05,
    fontSize: theme.textSize.x1_25,
    fontWeight: 'bold',
  },
})
