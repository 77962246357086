import { StackScreenProps } from '@react-navigation/stack'
import { isEmpty } from 'lodash'
import { View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { FlatListPaging } from '../../components/FlatListPaging'
import { LeftButtonBack, Page } from '../../components/Page'
import { PageSegment } from '../../components/PageSegment'
import TaskReportDisplay from '../../components/TaskReportDisplay/TaskReportDisplay.component'
import { TaskReportLoginErrors, TaskReportOtherErrors } from '../../components/TaskReportItem/components'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { isLoadingSelector } from '../../redux/loading/selectors'
import { LoaderName } from '../../redux/loading/types'
import { RootState } from '../../redux/reducers'
import { TaskReportActions } from '../../redux/taskReport/action'
import { grcIsAuthorizedSelector, lastPageSelector, reportProcessSelector, taskReportPagingSelector } from '../../redux/taskReport/selector'
import { theme } from '../../theme'

export const TaskReportList: FunctionComponent<StackScreenProps<ActionViewParamsType, 'TaskReportList'>> = ({ route }) => {
  const title = route.params.titre

  const dispatch = useDispatch()
  const taskReport = useSelector((state: RootState) => reportProcessSelector(state))

  const paging = useSelector((state: RootState) => taskReportPagingSelector(state))

  const isLoading = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.LOAD_TASKREPORT))

  const isRefreshing = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.REFRESH_TASKREPORT))

  const isLastPage = useSelector(lastPageSelector)
  const isLoadingPage = () => isEmpty(taskReport) && isLoading && !isRefreshing

  const isLoadingMore = () => !isLastPage && !isRefreshing

  const canLoadMore = () => !isLoading && isLoadingMore()

  const [currentButton, setCurrentButton] = useState(0)

  const grcIsAuth = useSelector((state: RootState) => grcIsAuthorizedSelector(state))

  useEffect(() => {
    dispatch(TaskReportActions.loadIsAuthorizedGrc())
  }, [])

  useEffect(() => {
    if (grcIsAuth.grcAccount) {
      dispatch(TaskReportActions.loadTaskReport(true, currentButton))
    }
  }, [grcIsAuth.grcAccount])

  useEffect(() => {
    dispatch(TaskReportActions.loadTaskReport(true, currentButton))
  }, [currentButton])

  const onEndReached = () => {
    canLoadMore() ? dispatch(TaskReportActions.loadTaskReport(false, currentButton)) : null
  }

  const onRefresh = () => {
    dispatch(TaskReportActions.loadTaskReport(false, currentButton))
  }

  const segments = [
    { id: 0, libelle: 'Les plus récentes' },
    { id: 1, libelle: 'Les plus anciennes' },
  ]

  const titleNotNull = title || ''
  const totalElements = paging.totalElements ? `(${paging.totalElements})` : ''

  const renderItems = () =>
    grcIsAuth.grcAccount ? (
      <PageSegment
        left={<LeftButtonBack />}
        title={`${titleNotNull} ${totalElements}`}
        currentSegment={segments[0]}
        segments={segments}
        onSegmentPress={item => setCurrentButton(item.id)}
        style={styles.page}
      >
        <FlatListPaging
          data={taskReport}
          renderItem={({ item }) => <TaskReportDisplay item={item} />}
          onEndReached={onEndReached}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          isLoadingPage={isLoadingPage()}
          isLoadingMore={isLoadingMore}
          keyExtractor={item => item.uuid}
        />
      </PageSegment>
    ) : null

  const renderErrors = () => {
    if (grcIsAuth.grcAccountError === 401) {
      return (
        <Page left={<LeftButtonBack />} title={i18n.t('taskReport.errorTitle')}>
          <TaskReportLoginErrors />
        </Page>
      )
    }
    if (grcIsAuth.grcAccountError) {
      return (
        <Page left={<LeftButtonBack />} title={i18n.t('taskReport.errorTitle')}>
          <TaskReportOtherErrors onReload={() => dispatch(TaskReportActions.loadIsAuthorizedGrc())} />
        </Page>
      )
    }
    return <View />
  }

  const render = () => {
    if (grcIsAuth.grcAccount) {
      return renderItems()
    }
    if (grcIsAuth.grcAccountError) {
      return renderErrors()
    }
    return <View />
  }

  return render()
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: theme.colors.paleGrey,
  },
})
