import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { ReferentielsActionTypes } from './types'
import { ReferencielLandFill, ReferentielItemResult, ReferentielListResult } from '../../api/Referentiels/types'
import { ReferentielFilters } from '../../api/types'
import { FieldValueRequest } from '../../api/Form/types'

export const ReferentielsActions = {
  loadReferentielItem: (id: number) => createAction(ReferentielsActionTypes.LOAD_REFERENTIEL_ITEM, id),
  storeReferentielItem: (id: number, item: ReferentielItemResult) => createAction(ReferentielsActionTypes.STORE_REFERENTIEL_ITEM, { id, item }),
  loadReferentielList: (clear: boolean, id: number, filters: ReferentielFilters, query?: FieldValueRequest[] | null) =>
    createAction(ReferentielsActionTypes.LOAD_REFERENTIEL_LIST, { clear, id, filters, query }),
  storeReferentielList: (id: number, page: ReferentielListResult) => createAction(ReferentielsActionTypes.STORE_REFERENTIEL_LIST, { id, page }),
  storeClearReferentielList: (id: number, page: ReferentielListResult) => createAction(ReferentielsActionTypes.STORE_CLEAR_REFERENTIEL_LIST, { id, page }),
  refreshReferentielList: (id: number, filters: ReferentielFilters, query?: FieldValueRequest[] | null) =>
    createAction(ReferentielsActionTypes.REFRESH_REFERENTIEL_LIST, { id, filters, query }),
  loadReferentielLandfills: (weekUrl: string, daysUrl: string) =>
    createAction(ReferentielsActionTypes.LOAD_REFERENTIEL_LANDFILLS, {
      weekUrl,
      daysUrl,
    }),
  storeReferentielLandfills: (week: ReferencielLandFill, days: ReferencielLandFill) => createAction(ReferentielsActionTypes.STORE_REFERENTIEL_LANDFILLS, { week, days }),
}

export type ReferentielsActionObjectTypes = ActionsUnion<typeof ReferentielsActions>
