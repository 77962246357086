import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, takeEvery } from 'redux-saga/effects'
import { CartographyRealTimeApi } from '../../api/CartographyRealTime/CartographyRealTimeApi'
import { RealTimeMap, RealTimeMapItems } from '../../api/CartographyRealTime/type'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'
import { CartographyRealTimeAction, CartographyRealTimeActionObjectTypes } from './actions'
import { CartographyRealTimeActionTypes } from './types'

function* loadCartographyRealTimeMapSaga(action: ActionsOfType<CartographyRealTimeActionObjectTypes, CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP>) {
  try {
    const realTimeMapResult: RealTimeMap = yield call(CartographyRealTimeApi.loadRealTimeMap, action.payload.id)
    yield put(CartographyRealTimeAction.storeRealTimeMap(realTimeMapResult.id, realTimeMapResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

function* loadCartographyRealTimeMapItemsSaga(action: ActionsOfType<CartographyRealTimeActionObjectTypes, CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP_ITEMS>) {
  try {
    const realTimeMapItemsResult: RealTimeMapItems = yield call(CartographyRealTimeApi.loadRealTimeMapItems, action.payload.id)
    yield put(CartographyRealTimeAction.storeRealTimeMapItems(action.payload.id, realTimeMapItemsResult))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadCartographyRealTimeMapWatcher() {
  yield takeEvery(CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP, addLoader(loadCartographyRealTimeMapSaga, LoaderName.LOAD_CARTOGRAPHY_REAL_TIME))
}

export function* loadCartographyRealTimeMapItemsWatcher() {
  yield takeEvery(CartographyRealTimeActionTypes.LOAD_REAL_TIME_MAP_ITEMS, addLoader(loadCartographyRealTimeMapItemsSaga, LoaderName.LOAD_CARTOGRAPHY_REAL_TIME_ITEMS))
}
