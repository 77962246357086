import { CarouselResult } from '../../api/Carousel/types'
import { ActualitesResult, GridAirQualityConfig, GridPageResult, GridTideConfig, RssFeedConfigResult, WeatherResult } from '../../api/GridPage/types'

export type GridPageState = {
  gridPages: {
    [key: number]: GridPageResult
  }
  carousels: {
    [key: number]: CarouselResult
  }
  actualites: {
    [key: number]: ActualitesResult
  }
  weathers: {
    [key: number]: WeatherResult | null
  }
  rssFeed: {
    [key: string]: RssFeedConfigResult | null
  }
  airQuality: {
    [key: string]: GridAirQualityConfig | null
  }
  tide: {
    [key: string]: GridTideConfig | null
  }
}

export enum GridPageActionTypes {
  LOAD_GRID_PAGE = 'LOAD_GRID_PAGE',
  STORE_GRID_PAGE = 'STORE_GRID_PAGE',
  LOAD_CAROUSEL = 'LOAD_CAROUSEL',
  STORE_CAROUSEL = 'STORE_CAROUSEL',
  LOAD_ACTUALITES = 'LOAD_ACTUALITES',
  STORE_ACTUALITES = 'STORE_ACTUALITES',
  LOAD_WEATHER = 'LOAD_WEATHER',
  STORE_WEATHER = 'STORE_WEATHER',
  LOAD_RSS_FEED_CONFIG = 'LOAD_RSS_FEED_CONFIG',
  STORE_RSS_FEED_CONFIG = 'STORE_RSS_FEED_CONFIG',
  LOAD_GRID_AIR_QUALITY_CONFIG = 'LOAD_GRID_AIR_QUALITY_CONFIG',
  STORE_GRID_AIR_QUALITY_CONFIG = 'STORE_GRID_AIR_QUALITY_CONFIG',
  LOAD_GRID_TIDE_CONFIG = 'LOAD_GRID_TIDE_CONFIG',
  STORE_GRID_TIDE_CONFIG = 'STORE_GRID_TIDE_CONFIG',
}
