export const urlApi = {
  sector: {
    list: 'v1/secteurs',
    days: (sectorId: number) => `v1/secteurs/${sectorId}/jours`,
  },
  form: {
    submit: (formId: number) => `v1/forms/${formId}/response`,
  },
  signalement: {
    default: 'v1/signalements/default',
    categories: 'v1/signalements/categories',
    submit: 'v1/signalements/response',
  },
  urgentNumbers: {
    submit: (page: number, size: number) => `v1/appelsurgents?page=${page}&size=${size}`,
  },
  news: {
    submit: (page: number, size: number, referentielId?: number | null) => {
      if (referentielId) {
        return `v1/actualites?page=${page}&size=${size}&referentielId=${referentielId}`
      }
      return `v1/actualites?page=${page}&size=${size}`
    },
  },
  cantine: {
    submit: (id: number) => `v1/cantine/${id}`,
  },
  cartographyZones: {
    submit: (zoneId: number) => `v1/mobile/map/zones/${zoneId}`,
  },
  movie: {
    list: 'v1/films',
    item: (id: number) => `v1/film/${id}`,
  },
}
