import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import FastImage from 'react-native-fast-image'
import { theme } from '../../../theme'
import { Body, Header, Left, Right } from '../../NativeBaseLegacy'

const BORDER_RADIUS = 20

interface HeaderMovieProps {
  foregroundImage?: string
  backgroundImage: string
  maxHeight: number
  displayBottom: boolean
  left?: React.ReactNode
  right?: React.ReactNode
}

export const HeaderMovie: FunctionComponent<HeaderMovieProps> = ({ foregroundImage, backgroundImage, maxHeight, displayBottom, left, right }) => (
  <View style={styles.content}>
    <Header style={theme.isAndroid ? styles.header : {}} transparent>
      <Left style={styles.left}>{left}</Left>
      <Body style={styles.body} />
      {right ? <Right>{right}</Right> : null}
    </Header>
    {backgroundImage && <FastImage style={[styles.image, { height: maxHeight }]} source={{ uri: backgroundImage }} resizeMode="cover" />}
    {foregroundImage && <FastImage style={styles.foregroundImage} source={{ uri: foregroundImage }} resizeMode="contain" />}

    {displayBottom && <View style={styles.renderForegroundBottom} />}
  </View>
)

const FOREGROUND_WIDTH = 100
const FOREGROUND_HEIGHT = 150

const styles = StyleSheet.create({
  left: {
    zIndex: 2,
    flex: 0,
    width: 100,
  },
  body: {
    zIndex: 1,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    height: '100%',
    backgroundColor: theme.colors.silver,
  },
  header: {
    paddingTop: 0,
  },
  image: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    alignSelf: 'stretch',
    zIndex: -1,
    opacity: 0.9,
  },
  foregroundImage: {
    position: 'absolute',
    left: BORDER_RADIUS,
    bottom: BORDER_RADIUS,
    width: FOREGROUND_WIDTH,
    height: FOREGROUND_HEIGHT,
  },
  renderForegroundBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.white,
  },
})
