import React, { FunctionComponent } from 'react'
import { View, Text } from 'native-base'
import { StyleSheet, ViewStyle, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { ActualiteItemResult } from '../../api/Actualites'
import { theme } from '../../theme'
import { toDDMMYYYYatHHMM } from '../../services/dateFormatter'
import { getItemAction } from '../../services/itemAction'
import { Routes } from '../../navigation/Routes'

interface NewsItemProps {
  item: ActualiteItemResult
}

export const NewsItem: FunctionComponent<NewsItemProps> = ({ item }) => {
  if (!item) {
    return null
  }
  const onPressProfil = () => {
    if (!item.author.action) {
      return
    }
    return getItemAction(item.author.action)
  }

  const date = `${item.author.libelle}, ${toDDMMYYYYatHHMM(item.date)}`

  const displayprofil = () => {
    if (!item.author.image) {
      return
    }
    if (!item.author.action) {
      return (
        <TouchableWithoutFeedback>
          <FastImage resizeMode="cover" source={{ uri: item.author.image }} style={styles.profil} />
        </TouchableWithoutFeedback>
      )
    }
    return (
      <TouchableOpacity onPress={onPressProfil()}>
        <FastImage resizeMode="cover" source={{ uri: item.author.image }} style={styles.profil} />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={getItemAction({
        type: 'view',
        viewName: Routes.Actualite,
        params: { id: item.id, titre: item.libelle, image: item.image },
      })}
    >
      <View style={styles.header}>
        {displayprofil()}
        <Text style={styles.titre} numberOfLines={2}>
          {item.libelle}
        </Text>
      </View>
      {item.image && <FastImage source={{ uri: item.image }} resizeMode={FastImage.resizeMode.cover} style={styles.image} />}

      <Text style={styles.description} numberOfLines={2}>
        {item.descriptionLight}
      </Text>
      <Text style={styles.date}>{date}</Text>
    </TouchableOpacity>
  )
}

const IMAGE_HEIGHT = 200
const RADIUS_IMAGE = 2
const SIZE_PROFIL = 50
const BORDER_WIDTH = 0.5
interface Style {
  container: ViewStyle
  header: ViewStyle
  profil: ImageStyle
  titre: ViewStyle
  image: ImageStyle
  description: ViewStyle
  date: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flex: 1,
    paddingVertical: theme.padding.x2,
    justifyContent: 'space-around',
    borderBottomWidth: BORDER_WIDTH,
    borderBottomColor: theme.colors.silver,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.padding.x1,
  },
  profil: {
    height: SIZE_PROFIL,
    width: SIZE_PROFIL,
    borderRadius: SIZE_PROFIL / 2,
    marginRight: theme.padding.x2,
  },
  titre: {
    flex: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: theme.textSize.x2,
  },
  image: {
    width: theme.deviceWidth - theme.padding.x4,
    height: IMAGE_HEIGHT,
    borderRadius: RADIUS_IMAGE,
    marginBottom: theme.padding.x1,
  },
  description: {
    color: theme.colors.black,
    textAlign: 'justify',
    marginBottom: theme.padding.x1,
  },
  date: {
    color: theme.colors.silver,
  },
})
