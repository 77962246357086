import { useCallback, useState } from 'react'
import { Routes } from '../../navigation/Routes'
import { navigate } from '../../services/navigation'
import { userAuthentication } from '../../services/userAuthentication'

export const useResetPasswordSendEmail = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const doResetPasswordSendEmail = useCallback(async email => {
    setLoading(true)
    try {
      const res = await userAuthentication.sendResetPasswordSendEmail(email)
      navigate(Routes.ResetPasswordVerification, { token: res.token, email })
    } catch (error) {
      console.warn((error as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { doResetPasswordSendEmail, loading }
}
