import { Reducer } from 'redux'
import { CartographyState, CartographyActionTypes, CartographyKeyState } from './types'
import { CartographyActionObjectTypes } from './actions'
import { MarkerResult } from '../../api/Cartography/types'

const initialCartographyState: CartographyState = {}

const initialCartography: CartographyKeyState = {
  config: null,
  markers: {},
  menuItems: {},
}

const setCartographyMenuItems = (cartography: CartographyKeyState, menuId: string, markers: MarkerResult[]) => ({
  ...cartography.menuItems,
  [menuId]: markers.map(marker => marker.id),
})

const setCartographyMenuMarkers = (cartography: CartographyKeyState, markers: MarkerResult[]) => {
  const menuMarkers = markers.reduce((markers: { [key: string]: MarkerResult }, marker) => {
    if (!(marker.id in markers)) {
      markers[marker.id.toString()] = marker
    }
    return markers
  }, {})
  return {
    ...cartography.markers,
    ...menuMarkers,
  }
}

export const CartographyReducer: Reducer<CartographyState, CartographyActionObjectTypes> = (state = initialCartographyState, action) => {
  switch (action.type) {
    case CartographyActionTypes.STORE_CARTOGRAPHY: {
      const cartography = { ...(state[action.payload.id] || initialCartography) }
      cartography.config = { ...action.payload.cartography }
      return {
        ...state,
        [action.payload.id]: cartography,
      }
    }
    case CartographyActionTypes.STORE_CARTOGRAPHY_MENU_MARKER: {
      const cartography = { ...(state[action.payload.cartographyId] || initialCartography) }
      cartography.menuItems = setCartographyMenuItems(cartography, action.payload.menuId, action.payload.menuMarker.content)
      cartography.markers = setCartographyMenuMarkers(cartography, action.payload.menuMarker.content)
      return {
        ...state,
        [action.payload.cartographyId]: cartography,
      }
    }
    default:
      return state
  }
}
