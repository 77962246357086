import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { AirQualityActionTypes } from './types'
import { AirQualityConfig, AirQualityDayResult } from '../../api/AirQuality/types'

export const AirQualityActions = {
  loadAirQualityConfig: (id: number) => createAction(AirQualityActionTypes.LOAD_AIR_QUALITY_CONFIG, { id }),
  storeAirQualityConfig: (id: number, airQualityConfig: AirQualityConfig) => createAction(AirQualityActionTypes.STORE_AIR_QUALITY_CONFIG, { id, airQualityConfig }),
  checkAirQualityDay: (airQualityConfig: AirQualityConfig) => createAction(AirQualityActionTypes.CHECK_AIR_QUALITY_DAY, { airQualityConfig }),
  storeAirQualityDay: (id: number, airQualityDayResult: AirQualityDayResult | null) => createAction(AirQualityActionTypes.STORE_AIR_QUALITY_DAY, { id, airQualityDayResult }),
}

export type AirQualityActionObjectTypes = ActionsUnion<typeof AirQualityActions>
