import { CantineItemResult } from '../../api/Cantine/types'

export type CantineState = {
  items: {
    [key: number]: CantineItemResult
  }
}

export enum CantineActionTypes {
  LOAD_CANTINE = 'LOAD_CANTINE',
  STORE_CANTINE = 'STORE_CANTINE',
}
