import { BaseApi } from '../BaseApi'
import { CarouselResult } from './types'

export class CarouselApi {
  public static async load(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/carrousel')
      .query({ id })
      .get()
      .json(json => json) as Promise<CarouselResult>
  }
}
