import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import RenderHtml from 'react-native-render-html'
import { theme } from '../../theme'

interface HtmlWebViewProps {
  content: string | null
}

const HtmlWebView: FunctionComponent<HtmlWebViewProps> = ({ content }) => {
  if (!content) {
    return null
  }
  const mediaViewport = '<meta name="viewport" content="width=device-width, initial-scale=1.0">'
  const htmlStyle = `<style>body { font-family: Bariol, sans-serif }</style>`
  const scriptHeight = `
    <script>
      var i = 1
      function setHeight() {
        window.location.hash = i++
        document.title = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
          document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight )
      }
      var images = document.querySelectorAll('img')
      for (var j = 0 j < images.length j++) {
        images[j].addEventListener('load', setHeight)
      }
      setHeight()
    </script>`
  const html = `<html><head>${mediaViewport}${htmlStyle}</head><body>${content}${scriptHeight}</body></html>`

  return (
    <View style={{ marginTop: theme.padding.x2 }}>
      <RenderHtml source={{ html }} />
    </View>
  )
}

export { HtmlWebView }
