import { Button, Icon } from 'native-base'
import React, { FunctionComponent, useCallback } from 'react'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { env } from '../../../../environment'
import { Routes } from '../../../../navigation/Routes'
import { themeSelector } from '../../../../redux/theme/selector'
import { navigate } from '../../../../services/navigation'
import { useUserAuthentication } from '../../../../services/userAuthentication'

export const UserAuthenticationButton: FunctionComponent = () => {
  const theme = useSelector(themeSelector)
  const { initializing: isAuthStateInitializing, isConnected } = useUserAuthentication()

  const onPress = useCallback(() => {
    if (isConnected) {
      navigate(Routes.MyAccount)
      return
    }
    navigate(Routes.SignIn)
  }, [isConnected])

  if (!env().featureFlags.authentication || !env().featureFlags.authentication.enabled) return null

  if (isAuthStateInitializing) return null

  const iconName = isConnected ? 'person' : 'log-in'

  return (
    <Button variant="ghost" onPress={onPress}>
      <Icon as={Ionicons} name={iconName} size={7} color={theme?.titleFontColor} />
    </Button>
  )
}
