import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { NotificationActionTypes, notificationDB } from './types'
import { NotificationsResult, NotificationSettingsResult } from '../../api/Notification/types'

export const NotificationActions = {
  sendToken: (token: string | null) => createAction(NotificationActionTypes.SEND_NOTIFICATION_TOKEN, { token }),
  storeNotificationEnabled: (enabled: boolean, token: string | null) => createAction(NotificationActionTypes.STORE_NOTIFICATION_ENABLED, { enabled, token }),
  loadHaveNotification: () => createAction(NotificationActionTypes.LOAD_HAS_NEW_NOTIFICATION),
  storeHaveNotification: (viewNotification: boolean) => createAction(NotificationActionTypes.STORE_HAS_NEW_NOTIFICATION, viewNotification),
  storeHasNewNotificationFirebase: (has: boolean) => createAction(NotificationActionTypes.STORE_HAS_NEW_NOTIFICATION_FIREBASE, has),
  clearHasNewNotification: () => createAction(NotificationActionTypes.CLEAR_HAS_NEW_NOTIFICATION),
  loadNotifications: (clear: boolean) => createAction(NotificationActionTypes.LOAD_NOTIFICATIONS, { clear }),
  storeClearNotifications: (notificationsResult: NotificationsResult) => createAction(NotificationActionTypes.STORE_CLEAR_NOTIFICATIONS, { notificationsResult }),
  storeNotifications: (notificationsResult: NotificationsResult) => createAction(NotificationActionTypes.STORE_NOTIFICATIONS, { notificationsResult }),
  refreshNotifications: () => createAction(NotificationActionTypes.REFRESH_NOTIFICATIONS),
  loadNotificationsSettings: () => createAction(NotificationActionTypes.LOAD_NOTIFICATION_SETTINGS),
  storeNotificationSettings: (settings: NotificationSettingsResult) =>
    createAction(NotificationActionTypes.STORE_NOTIFICATION_SETTINGS, {
      settings,
    }),
  notificationSettingsEnabled: (enabled: boolean) => createAction(NotificationActionTypes.NOTIFICATION_ALL_ENABLED, { enabled }),
  notificationCategorieEnabled: (id: number, enabled: boolean) => createAction(NotificationActionTypes.NOTIFICATION_CATEGORIE_ENABLED, { id, enabled }),
  getOneNotification: (id: string) => createAction(NotificationActionTypes.GET_ONE_NOTIFICATION, { id }),
  storeOneNotification: (notif: notificationDB) => createAction(NotificationActionTypes.STORE_ONE_NOTIFICATION, notif),
  resetCurrentNotification: () => createAction(NotificationActionTypes.RESET_CURRENT_NOTIFICATION),
}

export type ActionNotificationObjectTypes = ActionsUnion<typeof NotificationActions>
