import * as Keychain from 'react-native-keychain'
import { GrcYpokApi } from '../../api/GrcYpok'
import { env } from '../../environment'

const server = `${env().ENV}-ypok`

export const ypokSignin = async (userName: string, password: string, url: string) => GrcYpokApi.login(url, userName, password)

export const storeYpokUser = async (userName: string, password: string) => {
  await Keychain.setInternetCredentials(server, userName, password)
}

export const loadYpokUser = async () => Keychain.getInternetCredentials(server)

export const resetYpokUser = async () => {
  await Keychain.resetInternetCredentials(server)
}
