import { Icon, Spinner, Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { AirQualityConfig, AirQualityDayResult } from '../../../api/AirQuality/types'
import { i18n } from '../../../lib/i18n'
import { AirQualityActions } from '../../../redux/airQuality/action'
import { airQualityDaySelector } from '../../../redux/airQuality/selector'
import { isLoadingSelector } from '../../../redux/loading/selectors'
import { LoaderName } from '../../../redux/loading/types'
import { RootState } from '../../../redux/reducers'
import { getAirQualityIconConfig, getAirQualityLabel } from '../../../services/airQuality'
import { iconFont } from '../../../services/Icon'
import { theme } from '../../../theme'

interface AirQualityDayProps {
  airQualityConfig: AirQualityConfig
  color: string
  pictoStyle?: ViewStyle
  infoStyle?: ViewStyle
}
export const AirQualityDay: FunctionComponent<AirQualityDayProps> = ({ airQualityConfig, color, pictoStyle, infoStyle }) => {
  const [dayResult, setDayResult] = useState<AirQualityDayResult | null>(null)

  const dispatch = useDispatch()

  const isChecking = useSelector((state: RootState) => isLoadingSelector(state, LoaderName.CHECK_AIR_QUALITY))

  const airQualityDayResult = useSelector((state: RootState) => airQualityDaySelector(state, airQualityConfig))

  useEffect(() => {
    setDayResult(airQualityDayResult)
    if (!dayResult && airQualityConfig) {
      dispatch(AirQualityActions.checkAirQualityDay(airQualityConfig))
    }
  }, [airQualityConfig, airQualityDayResult])

  const styles = stylesAirQuality(color)

  if (isChecking) {
    return (
      <View style={styles.content}>
        <Spinner style={styles.spinner} />
      </View>
    )
  }

  if (!airQualityDayResult) {
    return <View style={styles.content} />
  }

  const { quality } = airQualityDayResult
  const renderIcon = () => {
    const iconConfig = getAirQualityIconConfig(quality)
    if (!iconConfig) {
      return null
    }

    return <Icon name={iconConfig.icon} as={iconFont(iconConfig.iconType)} style={styles.picto} color={color} size={8} />
  }

  const pictoView = pictoStyle || styles.pictoView
  const infoView = infoStyle || styles.infos

  return (
    <View style={styles.content}>
      <View style={pictoView}>{renderIcon()}</View>
      <View style={infoView}>
        <Text style={styles.text} numberOfLines={1}>
          {i18n.t('airQuality.title')}
        </Text>
        <Text style={styles.text} numberOfLines={1}>
          {getAirQualityLabel(quality)}
        </Text>
      </View>
    </View>
  )
}

const stylesAirQuality = (color: string) =>
  /* eslint react-native/no-unused-styles: 0 */
  StyleSheet.create({
    content: {
      flex: 2,
      alignItems: 'center',
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      height: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
    text: {
      color,
    },
    infos: {
      flex: 3,
      borderColor: color,
      paddingHorizontal: theme.padding.x1,
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
    pictoView: {
      flex: 2,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderLeftWidth: 1,
      borderColor: color,
    },
    picto: {
      // color: color,
      // fontSize: ICON_SIZE,
    },
    spinner: {
      height: '100%',
    },
  })
