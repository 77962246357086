import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { GrcAccountAuthorization, TaskReportItem, TaskReportListResult } from '../../api/TaskReport/types'
import { TaskReportActionTypes } from './types'

export const TaskReportActions = {
  loadTaskReport: (clear: boolean, sort_column: number) => createAction(TaskReportActionTypes.LOAD_TASKREPORT, { clear, sort_column }),
  storeClearTaskReport: (taskReportResult: TaskReportListResult) =>
    createAction(TaskReportActionTypes.STORE_CLEAR_TASK_REPORT, {
      taskReportResult,
    }),
  storeTaskReport: (taskReportResult: TaskReportListResult) => createAction(TaskReportActionTypes.STORE_TASKREPORT, { taskReportResult }),
  refreshTaskReport: (sort_column: number) => createAction(TaskReportActionTypes.REFRESH_TASKREPORT, { sort_column }),
  loadTaskReportItem: (id: string) => createAction(TaskReportActionTypes.LOAD_TASKREPORT_ITEM, { id }),
  storeClearTaskReportItem: (id: string, taskReportResult: TaskReportItem) =>
    createAction(TaskReportActionTypes.STORE_TASKREPORT_ITEM, {
      id,
      item: taskReportResult,
    }),
  loadIsAuthorizedGrc: () => createAction(TaskReportActionTypes.LOAD_IS_AUTHORIZED_GRC),
  storeIsAuthorizedGrc: (grcAccountAuthorization: GrcAccountAuthorization) => createAction(TaskReportActionTypes.STORE_IS_AUTHORIZED_GRC, { grcAccountAuthorization }),
}

export type ActionTaskReportObjectTypes = ActionsUnion<typeof TaskReportActions>
