import { BaseApi } from '../BaseApi'
import { SectorResult, SectorDayResult } from './types'
import { urlApi } from '../url'

const DAY_SIZE = 50

export class SectorApi {
  public static async loadSectorList() {
    return BaseApi.authenticatedCall()
      .url(urlApi.sector.list)
      .get()
      .json(json => json) as Promise<SectorResult>
  }

  public static async loadSectorDayList(id: number) {
    return BaseApi.authenticatedCall()
      .url(urlApi.sector.days(id))
      .query({ size: DAY_SIZE })
      .get()
      .json(json => json) as Promise<SectorDayResult>
  }
}
