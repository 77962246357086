import { Text, View } from 'native-base'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { AppState, StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { FavoritesApi } from '../../api/Favorites/FavoritesApi'
import { ExtraData, FavorieUserItem, FavoriteFooter, FavoriteHeader } from '../../api/Favorites/types'
import { FavoritesUsersActions } from '../../redux/favoritesUser/actions'
import { FavoritesTimerService } from '../../services/favoritesTimer'
import { theme } from '../../theme'
import { FastImageBackground } from '../FastImageBackground'

interface iProps {
  item: FavorieUserItem
  onPress: () => void
  buttonDisable?: boolean
}

const displayFontColor = (color: string | null) => {
  if (!color) {
    return {}
  }
  return {
    color,
  }
}

const displayBackgroundColor = (backgroundColor: string | null) => {
  if (!backgroundColor) {
    return {}
  }
  return {
    backgroundColor,
  }
}

const displayIcon = (iconURI?: string) => {
  if (!iconURI) {
    return null
  }
  return null // Afficher l'icône
}

const displayDataFavorite = (footer: FavoriteFooter) => {
  if (footer.data.length === 0) {
    return null
  }
  const style = [styles.element, displayBackgroundColor(footer.backgroundColor), displayFontColor(footer.fontColor)]
  if (footer.data.length === 1) {
    return (
      <View>
        {displayIcon(footer.data[0].iconURI)}
        <Text style={style}>{footer.data[0].title}</Text>
      </View>
    )
  }
  return footer.data.map((item: ExtraData, index: number) => {
    if (index !== 0) {
      return (
        <View key={item.title} style={[styles.marginExtraElementOnLeft]}>
          {displayIcon(item.iconURI)}
          <Text style={style}>{item.title}</Text>
        </View>
      )
    }
    return (
      <View key={item.title}>
        {displayIcon(item.iconURI)}
        <Text style={style}>{item.title}</Text>
      </View>
    )
  })
}

const getHeaderStyle = (header: FavoriteHeader | null) => {
  if (!header) {
    return [styles.title]
  }
  return [styles.title, displayBackgroundColor(header.backgroundColor), displayFontColor(header.fontColor)]
}

const displayHeader = (header: FavoriteHeader | null) => {
  if (!header) {
    return <Text style={getHeaderStyle(header)} />
  }
  return <Text style={getHeaderStyle(header)}>{header.title}</Text>
}

const displayFooter = (footer: FavoriteFooter | null) => {
  if (!footer) {
    return null
  }
  return <View style={styles.footerData}>{displayDataFavorite(footer)}</View>
}

const isButtonDisabled = (buttonDisable?: boolean) => {
  if (typeof buttonDisable === 'undefined') {
    return true
  }
  return buttonDisable
}

export const FavItemUser: FunctionComponent<iProps> = ({ item, onPress, buttonDisable }: iProps) => {
  const [favoritesTimer, setFavoriteTimer] = useState<FavoritesTimerService | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    reloadFavorite()
    initializeTimer()
    handleAppState()
  }, [])

  useEffect(() => {
    if (favoritesTimer) {
      startTimer()
    }
    return () => {
      favoritesTimer && favoritesTimer.stopTimer()
    }
  }, [favoritesTimer])

  const handleAppState = () => {
    AppState.addEventListener('change', nextAppState => {
      if (nextAppState === 'active') {
        reloadFavorite()
        initializeTimer()
      }
    })
  }

  const reloadFavorite = () => {
    if (!item.schedule || item.schedule <= 0) {
      return
    }
    dispatch(FavoritesUsersActions.loadFavoritesItem(item.id))
  }

  const initializeTimer = () => {
    if (!item.schedule || item.schedule <= 0) {
      return
    }
    const timer = new FavoritesTimerService(item.schedule)
    setFavoriteTimer(timer)
  }

  const startTimer = () => {
    favoritesTimer &&
      favoritesTimer.startTimer(() => {
        reloadFavorite()
      })
  }

  return (
    <TouchableOpacity
      disabled={isButtonDisabled(buttonDisable)}
      onPress={() => {
        onPress()
        FavoritesApi.storeClickFavorite(item.id)
        dispatch(FavoritesUsersActions.loadFavoritesItem(item.id))
      }}
      style={styles.card}
      key={item.id}
    >
      <FastImageBackground
        source={{
          uri: item.image,
        }}
        style={styles.fastImage}
        resizeMode="cover"
      >
        <View style={styles.contentWrapper}>
          {displayHeader(item.header)}
          {displayFooter(item.footer)}
        </View>
      </FastImageBackground>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingTop: 5,
  },
  title: {
    textAlign: 'center',
    fontSize: theme.textSize.x1_75,
    fontWeight: 'bold',
    // fontFamily: theme.fontFamily,
    paddingHorizontal: 4,
    overflow: 'hidden',
    borderRadius: 5,
  },
  footerData: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 0,
  },
  fastImage: {
    width: '100%',
    height: '100%',
  },
  element: {
    fontSize: 10,
    // fontFamily: theme.fontFamily,
    textAlign: 'center',
    borderRadius: 5,
    marginLeft: 0,
    marginBottom: 8,
    paddingVertical: 2,
    paddingHorizontal: 6,
    overflow: 'hidden',
  },
  marginExtraElementOnLeft: {
    marginLeft: 4,
  },
})
