import flatten from 'lodash/flatten'
import { Input, View } from 'native-base'
import React, { PureComponent } from 'react'
import { TouchableOpacity } from 'react-native'
import { withNextInputAutoFocusInput } from 'react-native-formik'
import { FormCategory } from '../../api/Form/types'
import { SignalementApi } from '../../api/Signalement'
import { CategoriesItemResult } from '../../api/Signalement/types'
import { CategoryList } from '../CategoryList'
import { LabelInput } from './components'
import { FormElementProps } from './types'

interface CategoryFieldState {
  show: boolean
  items: CategoriesItemResult[]
  mapItemIdValues: Map<number, string>
  hasInitValue: boolean
}

class CategoryFieldClass extends PureComponent<FormElementProps<FormCategory, number>, CategoryFieldState> {
  public state: CategoryFieldState = {
    show: false,
    items: [],
    mapItemIdValues: new Map<number, string>(),
    hasInitValue: true,
  }

  public UNSAFE_componentWillMount() {
    const { value, setFieldValue } = this.props
    if (!value) {
      this.setState({
        ...this.state,
        hasInitValue: false,
      })
      setFieldValue(this.fieldId, null)
    }
  }

  public focus = () => {}

  public async componentDidMount() {
    const categoryConfigResult = await SignalementApi.loadCategories()
    const categories = categoryConfigResult.content
    const subCategories = flatten(categories.map(category => category.sousCategories))
    const mapItemIdValues = new Map<number, string>(subCategories.map(subCategory => [subCategory.id, subCategory.libelle]))

    this.setState({
      ...this.state,
      items: categories,
      mapItemIdValues,
    })
  }

  private hide = () => {
    this.setState({
      ...this.state,
      show: false,
    })
  }

  private toggle = () => {
    this.setState({
      ...this.state,
      show: !this.state.show,
    })
  }

  private validateValue = (id: number) => {
    const { setFieldValue } = this.props
    setFieldValue(this.fieldId, id)
    this.hide()
  }

  private get fieldId() {
    const { field } = this.props
    return `${field.id}`
  }

  public render() {
    const { field, value } = this.props
    const { items, hasInitValue } = this.state

    if (hasInitValue) {
      return null
    }

    const inputValue = (value && this.state.mapItemIdValues.get(value)) || ''

    return (
      <TouchableOpacity onPress={this.toggle}>
        <LabelInput text={field.libelle} required={field.constraint.mandatory} />
        <View pointerEvents="none">
          <Input value={inputValue} />
        </View>
        <CategoryList title={field.libelle} show={this.state.show} categories={items} onBack={this.hide} selectedSubCategoryId={value} onSelect={this.validateValue} />
      </TouchableOpacity>
    )
  }
}

export const CategoryField = withNextInputAutoFocusInput(CategoryFieldClass)
