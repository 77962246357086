import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { WeatherDaysResult } from '../../api/Weather/types'
import { WeatherActionTypes } from './types'

export const WeatherActions = {
  loadWeatherDays: () => createAction(WeatherActionTypes.LOAD_WEATHER_DAYS),
  storeWeatherDays: (result: WeatherDaysResult) => createAction(WeatherActionTypes.STORE_WEATHER_DAYS, { result }),
}

export type WeatherActionObjectTypes = ActionsUnion<typeof WeatherActions>
