import { createAction, ActionsUnion } from '@martin_hotell/rex-tils'
import { CityActionTypes } from './types'
import { CitysResult, CityItemResult, NotificationItem, CitysUserResult } from '../../api/City/types'
import { ReferentielFilters } from '../../api/types'

export const CityActions = {
  loadCitys: (clear: boolean, search: string | null) => createAction(CityActionTypes.LOAD_CITYS, { clear, search }),
  storeCitys: (cityResult: CitysResult) => createAction(CityActionTypes.STORE_CITYS, { cityResult }),
  loadCitysUser: () => createAction(CityActionTypes.LOAD_CITYS_USER),
  storeCitysUser: (cityUserResult: CitysUserResult) => createAction(CityActionTypes.STORE_CITYS_USER, { cityUserResult }),
  refreshCitys: () => createAction(CityActionTypes.REFRESH_CITYS),
  clearCitysState: () => createAction(CityActionTypes.CLEAR_CITYS),
  addCity: (city: CityItemResult) => createAction(CityActionTypes.ADD_CITY, { city }),
  storeCity: (city: CityItemResult) => createAction(CityActionTypes.STORE_CITY, { city }),
  removeCity: (city: CityItemResult) => createAction(CityActionTypes.REMOVE_CITY, { city }),
  removeStoreCity: (city: CityItemResult) => createAction(CityActionTypes.REMOVE_STORE_CITY, { city }),
  loadCitysEventsConfig: () => createAction(CityActionTypes.LOAD_CITYS_EVENTS_CONFIG),
  storeCitysEventsConfig: (citysEventConfig: ReferentielFilters) => createAction(CityActionTypes.STORE_CITYS_EVENTS_CONFIG, { citysEventConfig }),
  loadTownHallsConfig: () => createAction(CityActionTypes.LOAD_TOWN_HALLS_CONFIG),
  storeTownHallsConfig: (townHallsConfig: ReferentielFilters) => createAction(CityActionTypes.STORE_TOWN_HALLS_CONFIG, { townHallsConfig }),
  loadTownHall: (id: number) => createAction(CityActionTypes.LOAD_TOWN_HALL, { id }),
  storeTownHall: (townId: number) => createAction(CityActionTypes.STORE_TOWN_HALL, { townId }),
  storeAddingCity: (city: number | null) => createAction(CityActionTypes.STORE_ADDING_CITY, { city }),
  updateNotificationSettings: (city: CityItemResult, notify: boolean, categories: NotificationItem[]) =>
    createAction(CityActionTypes.UPDATE_NOTIFICATION_SETTINGS, { city, notify, categories }),
}

export type CityActionObjectTypes = ActionsUnion<typeof CityActions>
