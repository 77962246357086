export const env = {
  baseUrl: 'https://api.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: '5af9c6d8-8836-4201-8224-b5d1f574545c',
  ENV: 'lognes',
  featureFlags: {
    areCheatCodesEnabled: false,
    isCodePushEnabled: false,
    isCodePushManual: false,
    isFavoritesEnabled: false,
    authentication: {
      enabled: false,
      appleProviderEnabled: false,
      facebookProviderEnabled: false,
      googleProviderEnabled: false,
    },
  },
  googleClientId: '',
  emailValidationDynamicLink: 'https://appvilledelognes.page.link/email-confirmation',
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 77,
}
