import React, { FunctionComponent } from 'react'
import { StyleSheet, FlatList } from 'react-native'
import { View } from 'native-base'
import { ActualiteItemResult } from '../../../api/GridPage/types'
import { CardLineItem } from './CardLineItem.component'

interface CardsLineProps {
  items: ActualiteItemResult[]
  height: number
}

export const CardsLine: FunctionComponent<CardsLineProps> = props => (
  <View style={cardsStyle.content}>
    <FlatList<ActualiteItemResult> data={props.items} renderItem={({ item }) => CardLineItem({ item, height: props.height })} keyExtractor={(item, index) => index.toString()} />
  </View>
)

export const cardsStyle = StyleSheet.create({
  content: {
    flex: 1,
  },
})
