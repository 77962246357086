import { View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet, ViewProps } from 'react-native'
import { theme } from '../../theme'

interface CardProps extends ViewProps {
  spacing?: boolean
  column?: boolean
  noShadow?: boolean
}

export const Card: FunctionComponent<CardProps> = ({ children, noShadow, spacing, column, style, ...props }) => (
  <View style={[!noShadow ? styles.shadow : undefined, spacing ? styles.spacingMargin : undefined, style]} {...props}>
    <View style={[styles.container, spacing ? styles.spacingPadding : undefined, column ? styles.column : undefined]}>{children}</View>
  </View>
)

const styles = StyleSheet.create({
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: theme.padding.x1,
    backgroundColor: theme.colors.white,
  },
  container: {
    overflow: 'hidden',
    borderRadius: theme.padding.x1,
  },
  spacingPadding: {
    padding: theme.padding.x1,
  },
  spacingMargin: {
    marginTop: theme.padding.x2,
  },
  column: {
    flexDirection: 'row',
  },
})
