import { Icon, Text, View } from 'native-base'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import { theme } from '../../../theme'

interface ReportStatusProps {
  author: string | null
}

const SIZE_ICON = 20
const SIZE_TEXT = 15

export const ReportAuthor: FunctionComponent<ReportStatusProps> = ({ author }) => {
  if (!author) {
    return null
  }

  return (
    <View style={styles.row}>
      <Icon style={styles.icon} as={SimpleLineIcons} name="user" size={6} />
      <Text style={styles.text}>{author}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginRight: theme.padding.x1,
    fontSize: SIZE_TEXT,
    color: theme.colors.silverStrong,
  },
  icon: {
    marginRight: theme.padding.x1,
    paddingVertical: theme.padding.x05,
    fontSize: SIZE_ICON,
    color: theme.colors.silverStrong,
  },
})
